/*global chrome*/
import React, { useEffect, useState } from "react";
import "./pricing.css";
import { useStateStore } from "../store/index";
import { mixpanel } from "../index";
import { Alert } from "react-bootstrap";
import axios from "axios";
import CustomAlert from "./modal";
import Review from "./dashboard/reviews";
import PricingBlock from "./PricingBlock";
import PricingBlockEnterprise from "./PricingBlockEnterprise";
import Toggle from "./Togge";
import BnplModal from "./dashboard/BnplModal";
import SplitModal from "./dashboard/SplitModal";
import { useHistory } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PlanSwitcher from "./dashboard/PlanSwitcher";

function Pricing() {
  const initialOptions = {
    "client-id":
      "AYRhg0vf42QF2jAiQnSIeAziY0DXBsbNcLWpQvmnrNea3jR4WLJi09DDq1dvJ6DANr12WfCO7TDMS07k",
    "enable-funding": "paylater,venmo",
  };
  // const isUserAuthenticated = useStateStore(
  //   (state) => state.isUserAuthenticated
  // );
  const history = useHistory();
  const [isYearly, setIsYearly] = useState(false);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const globalLogout = useStateStore((state) => state.globalLogout);
  const token = useStateStore((state) => state.token);
  const userData = useStateStore((state) => state.userData);
  function subscribe(name, email, id) {
    axios
      .post("https://backend.lazyapply.com/addEmailToSendPulse", {
        email: email,
        id: id,
        name: name,
      })
      .then((response) => {
        console.log("subscibed to hotjar list", response, name, email, id);
      })
      .catch((error) => {
        console.log("some error occured in hotjar list", error);
      });
  }

  function goto(name) {
    mixpanel.identify(userData?.email);
    mixpanel.people.increment("purchase_plan_button pricing_page dashboard");
    mixpanel.track("purchase_plan_button pricing_page dashboard", {
      email: userData?.email,
      planname: name,
      ip: ip,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });

    if (country === "IN") {
      subscribe(userData.name, userData.email, 178684);
      if (isReferral) {
        openPaymentWithReferral(name, userData.email);
      } else {
        openPayment(name, userData.email);
      }
      // mixpanel.track("Dashboard - razorpay opened", {
      //   email: userData.email,
      //   name: name,
      //   ...ip,
      // });
    } else {
      subscribe(userData.name, userData.email, 178681);
      getSuccessUrl(name);
      // mixpanel.track("Dashboard - stripe opened", {
      //   email: userData.email,
      //   name: name,
      //   ...ip,
      // });
    }
  }

  function gotoSplitPayments(name) {
    // mixpanel.identify(userData.email);
    // mixpanel.people.increment("Dashboard - split payments click");
    // mixpanel.track("Dashboard - split payments click", {
    //   email: userData.email,
    //   name: name,
    // });
    subscribe(userData.name, userData.email, 178681);
    getSuccessUrlSplitPayments(name);
    // mixpanel.track("Dashboard - stripe opened", {
    //   email: userData.email,
    //   name: name,
    //   type: "splitpayments",
    //   ...ip,
    // });
  }
  const [processingSplitPayments, setProcessingSplitPayments] = useState("");
  function getSuccessUrlSplitPayments(name) {
    setProcessingSplitPayments(name);
    axios
      .get(
        `https://backend.lazyapply.com/create-checkout-session-subscription?planName=${name}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setProcessingSplitPayments("");
        console.log(response);
        if (
          typeof response.data === "object" &&
          response.data !== null &&
          "url" in response.data
        ) {
          window.open(response.data.url, "_blank");
        } else {
          console.log("error");
          setmessage("Some error occured, please try again in some time!");
          settitle("Error");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2500);
        }
      })
      .catch((error) => {
        setProcessingSplitPayments("");
        console.log(error);
        if (error.message == "Request failed with status code 403") {
          console.log("reauthenticate");
          setmessage("Please relogin again!");
          settitle("Not authorized");
          setmodalshow(true);
          setTimeout(() => {
            globalLogout();
            history.push("/");
          }, 1500);
        } else {
          console.log("error");
          setmessage("Some error occured, please try again in some time!");
          settitle("Error");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2500);
        }
      });
  }

  function getSuccessUrl(name) {
    setProcessing(name);
    let priceid = "";
    const defaultStripe =
      toggleValue === "individuals" ? "default1" : "default4";
    if (Object.keys(paymentsMain).length > 0) {
      console.log("okok1");
      priceid =
        paymentsMain.paymentlinksstripe[
          paymentsMain[defaultStripe][`link${name}`]
        ];
    } else {
      priceid = "price_1KShmlSBv8rKlDgOdvikFHLn";
    }
    axios
      .get(
        `https://backend.lazyapply.com/create-checkout-session-updated/${priceid}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setProcessing("");
        console.log(response);
        if (
          typeof response.data === "object" &&
          response.data !== null &&
          "url" in response.data
        ) {
          window.open(response.data.url, "_blank");
        } else {
          console.log("error");
          setmessage("Some error occured, please try again in some time!");
          settitle("Error");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2500);
        }
      })
      .catch((error) => {
        setProcessing("");
        console.log(error);
        if (error.message == "Request failed with status code 403") {
          console.log("reauthenticate");
          setmessage("Please relogin again!");
          settitle("Not authorized");
          setmodalshow(true);
          setTimeout(() => {
            globalLogout();
            history.push("/");
          }, 1500);
        } else {
          console.log("error");
          setmessage("Some error occured, please try again in some time!");
          settitle("Error");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2500);
        }
      });
  }

  function getpayments(payments) {
    let startDateFinal, endDateFinal;
    const c = payments.referralcodes.codes;
    let codes = [];
    c.forEach((code) => {
      codes.push({
        code: code.referralcode,
        link: code.paymentlink,
        mainprice: code.mainprice,
        discountprice: code.discountprice,
      });
    });
    setReferralCodes(codes);
    const startDate = payments.paymentoffer.startdate;
    const endDate = payments.paymentoffer.enddate;
    const todayDate = new Date(new Date().toLocaleDateString("en-US"));
    endDateFinal = new Date(endDate._seconds * 1000).toLocaleDateString(
      "en-US"
    );
    setEndDate(new Date(endDate._seconds * 1000).toDateString());
    endDateFinal = new Date(endDateFinal);

    startDateFinal = new Date(startDate._seconds * 1000).toLocaleDateString(
      "en-US"
    );
    startDateFinal = new Date(startDateFinal);

    const endDiffToday = Math.ceil(
      (endDateFinal - todayDate) / (1000 * 60 * 60 * 24)
    );
    const startDiffToday = Math.ceil(
      (startDateFinal - todayDate) / (1000 * 60 * 60 * 24)
    );
    console.log("startDate", startDateFinal);
    console.log("endDate", endDateFinal);
    if (startDiffToday <= 0 && endDiffToday > 0) {
      console.log("Plan is active");
      setPriceLink(
        payments.paymentlinksrazorpay[payments["paymentoffer"].linknumber]
      );
      const dis =
        ((payments["paymentoffer"].mainprice -
          payments["paymentoffer"].discountprice) /
          payments["paymentoffer"].mainprice) *
        100;
      setOfferActiveMainPrice(payments["paymentoffer"].mainprice);
      setOfferActiveDiscount(Math.floor(dis));
      setOfferActiveDiscountPrice(payments["paymentoffer"].discountprice);
      setOfferActive(true);
    } else {
      setOfferActiveMainPrice(payments["default"].mainprice);
      setPriceLink(
        payments.paymentlinksrazorpay[payments["default"].linknumber]
      );
      console.log("Disable the plan");
      setOfferActive(false);
    }
    console.log(startDiffToday, endDiffToday);
  }
  const [paymentsMain, setPayments] = useState({});
  const [endDate, setEndDate] = useState("");

  // function discountOutsideIndia() {
  //   if (Object.keys(paymentsMain).length > 0) {
  //     return Math.floor(
  //       ((paymentsMain.default1.cutprice - paymentsMain.default1.mainprice) /
  //         paymentsMain.default1.cutprice) *
  //         100
  //     );
  //   } else {
  //     return 90;
  //   }
  // }

  function getData() {
    axios
      .get("https://backend.lazyapply.com/pricingDetails", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response.data);
        setPayments(response.data);
        // getpayments(response.data);
      })
      .catch((error) => {
        console.log("error");
      });
  }
  const paymentLinksIndia = {
    individuals: {
      basic: "https://pages.razorpay.com/pl_KK7VsF2eOOkTi4/view",
      premium: "https://pages.razorpay.com/pl_KK7X358G5UKml6/view",
      unlimited: "https://pages.razorpay.com/pl_KK7XzedDVVwi68/view",
    },
    enterprises: {
      basic: "https://pages.razorpay.com/pl_KKBIyk0UJCFMyW/view",
      premium: "https://pages.razorpay.com/pl_KKBKrUlhQqqZdm/view",
      unlimited: "https://pages.razorpay.com/pl_KKBJvXMAYrxLTt/view",
    },
  };

  const [processing, setProcessing] = useState("");
  const [toggleValue, setToggleValue] = useState("enterprise");
  function openPayment(planName, email) {
    let linkRazorpay = "";
    if (Object.keys(paymentsMain).length > 0) {
      const defaultStripe =
        toggleValue === "individuals" ? "default2" : "default3";
      linkRazorpay =
        paymentsMain.paymentlinksrazorpay[
          paymentsMain[defaultStripe][`link${planName}`]
        ];
    } else {
      linkRazorpay = paymentLinksIndia[toggleValue][planName];
    }
    window.open(`${linkRazorpay}?email=${email}`, "_blank");
    // if (planName === "premium") {
    //   window.open(`${pricelink}?email=${email}`, "_blank");
    // } else {
    //   console.log("Nothing");
    // }
  }

  function openPaymentWithReferral(planName, email) {
    if (planName === "premium") {
      window.open(
        `${pricelinkreferral}?email=${email}&referral_code=${referral}`,
        "_blank"
      );
    } else {
      console.log("Nothing");
    }
  }

  const [ipFound, setIpFound] = useState(false);
  async function findIP() {
    // if (!ipFound) {
    //   console.log("main ip");
    //   try {
    //     const request = await fetch(
    //       "https://ipinfo.io/json?token=7987a2c0dfe3af"
    //     );
    //     const json = await request.json();
    //     console.log(json, "IP DETAILS");
    //     setCountry(json.country);
    //     setIpFound(true);
    //     setIp(json);
    //   } catch (err) {
    console.log("some error occured");
    setCountry("US");
    // }
    // }
  }
  const [ip, setIp] = useState({});
  const [country, setCountry] = useState("");
  const [referralCodes, setReferralCodes] = useState([]);
  const [referral, setReferral] = useState("");
  const [isReferral, setIsReferral] = useState(false);
  const [referraldetails, setreferraldetails] = useState({
    discountprice: 2332,
    mainprice: 2580,
    discount: 10,
  });
  const [offeractive, setOfferActive] = useState(false);
  const [offeractivediscount, setOfferActiveDiscount] = useState(10);
  const [offeractivemainprice, setOfferActiveMainPrice] = useState(2580);
  const [offeractivediscountprice, setOfferActiveDiscountPrice] =
    useState(2332);

  const [pricelink, setPriceLink] = useState(
    "https://pages.razorpay.com/pl_I9X2vivB6dHsZX/view"
  );
  const [pricelinkreferral, setPriceLinkReferral] = useState(
    "https://pages.razorpay.com/pl_I3Lpx9SEuYM9pA/view"
  );

  // const setReferralCode = (value) => {
  //   let c = referralCodes;
  //   setReferral(value);
  //   c.forEach((obj) => {
  //     if (obj.code.toLowerCase() === value.toLowerCase()) {
  //       let discount = Math.floor(
  //         ((obj.mainprice - obj.discountprice) / obj.mainprice) * 100
  //       );
  //       let discountprice = obj.discountprice;
  //       let mainprice = obj.mainprice;
  //       let object = {
  //         discountprice,
  //         mainprice,
  //         discount,
  //       };
  //       setIsReferral(true);
  //       setPriceLinkReferral(obj.link);
  //       setreferraldetails(object);
  //     } else {
  //       setIsReferral(false);
  //     }
  //   });
  // };

  useEffect(() => {
    if (Object.keys(paymentsMain).length > 0) getpayments(paymentsMain);
  }, [country, paymentsMain]);

  useEffect(() => {
    getData();
    findIP();
    mixpanel.identify(userData?.email);
    mixpanel.people.increment("pricing_page dashboard");
    mixpanel.track("pricing_page dashboard", {
      email: userData?.email,
      href: window.location.href,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
  }, []);

  function closeModal() {
    setshowbnpl(false);
  }
  function closeSplit() {
    setShowSplitPayments(false);
  }
  const [bnplplan, setbnplplan] = useState("");
  const [showbnpl, setshowbnpl] = useState(false);

  const [splitPaymentsPlan, setSplitPaymentsPlan] = useState("");
  const [showSplitPayments, setShowSplitPayments] = useState(false);
  return (
    <>
      <BnplModal
        showProp={showbnpl}
        hideShow={closeModal}
        planName={bnplplan}
      />
      <SplitModal
        showProp={showSplitPayments}
        hideShow={closeSplit}
        planName={splitPaymentsPlan}
      />
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      {offeractive ? (
        <Alert show={true}>
          <Alert.Heading>
            Lifetime deal ending soon,
            <span className="mainTime"> Offer Ends on {endDate}</span>
          </Alert.Heading>
        </Alert>
      ) : (
        <></>
      )}
      <div className="pricingheading">
        <p className="pricingheading1">Grab Full Access To LazyApply Today…</p>
        {toggleValue == "individuals" && (
          <p className="pricingheading2">
            One Time Investment – <strong>No Monthly Fees</strong>
          </p>
        )}
        <p className="pricingheading3">Special DISCOUNT</p>
      </div>
      <PayPalScriptProvider options={initialOptions}>
        {/* <Toggle setToggleValue={setToggleValue} />
        {toggleValue == "enterprises" && (
          <PlanSwitcher isYearly={isYearly} setIsYearly={setIsYearly} />
        )} */}
        {toggleValue === "individuals" ? (
          <>
            {" "}
            {country == "IN" ? (
              <div className="planOutside">
                <div className="row">
                  <div className="col-lg-4">
                    <PricingBlock
                      country={"IN"}
                      title="basic"
                      planValue={userData.planDetails.planStarted}
                      paymentsMain={paymentsMain}
                      processing={processing}
                      defaultCutPrice={49}
                      defaultMainPrice={29}
                      mostPopularTag={false}
                      features={["Linkedin automation", "Indeed automation"]}
                      featuresCut={[
                        "Ziprecruiter automation",
                        "Glassdoor automation",
                        "Career Builder automation",
                        // "Monster automation",
                        // "Seek automation",
                        "Dice automation",
                        // "Foundit automation",
                        "SimplyHired automation",
                      ]}
                      goto={goto}
                      userPlan={userData.planDetails.planName}
                      extrafeatures={[
                        "Day wise analytics",
                        "CV Improvement Tips",
                        "Single User",
                      ]}
                      showbnpl={
                        country === "US" || country === "CA" ? true : false
                      }
                      setbnplplan={setbnplplan}
                      setshowbnpl={setshowbnpl}
                      jobLimit={"150"}
                      resumeLimit={1}
                      showSplitPayments={
                        country === "US" || country === "CA" ? true : false
                      }
                      processingSplitPayments={processingSplitPayments}
                      setSplitPaymentsPlan={setSplitPaymentsPlan}
                      setShowSplitPayments={setShowSplitPayments}
                      gotoSplitPayments={gotoSplitPayments}
                    />
                  </div>
                  <div className="col-lg-4">
                    <PricingBlock
                      country={"IN"}
                      title="premium"
                      planValue={userData.planDetails.planStarted}
                      paymentsMain={paymentsMain}
                      processing={processing}
                      defaultCutPrice={59}
                      defaultMainPrice={39}
                      mostPopularTag={false}
                      features={[
                        "Linkedin automation",
                        "Indeed automation",
                        "Ziprecruiter automation",
                        "Glassdoor automation",
                        "Career Builder automation",
                        // "Monster automation",
                        // "Seek automation",
                        "Dice automation",
                        // "Foundit automation",
                        "SimplyHired automation",
                      ]}
                      goto={goto}
                      userPlan={userData.planDetails.planName}
                      featuresCut={[]}
                      extrafeatures={[
                        "Day wise analytics",
                        "CV Improvement Tips",
                        "Single User",
                      ]}
                      showbnpl={
                        country === "US" || country === "CA" ? true : false
                      }
                      setbnplplan={setbnplplan}
                      setshowbnpl={setshowbnpl}
                      jobLimit={"750"}
                      resumeLimit={5}
                      showSplitPayments={
                        country === "US" || country === "CA" ? true : false
                      }
                      processingSplitPayments={processingSplitPayments}
                      setSplitPaymentsPlan={setSplitPaymentsPlan}
                      setShowSplitPayments={setShowSplitPayments}
                      gotoSplitPayments={gotoSplitPayments}
                    />
                  </div>
                  <div className="col-lg-4">
                    <PricingBlock
                      country={"IN"}
                      title="unlimited"
                      planValue={userData.planDetails.planStarted}
                      paymentsMain={paymentsMain}
                      processing={processing}
                      defaultCutPrice={79}
                      defaultMainPrice={59}
                      mostPopularTag={false}
                      features={[
                        "Linkedin automation",
                        "Indeed automation",
                        "Ziprecruiter automation",
                        "Glassdoor automation",
                        "Career Builder automation",
                        // "Monster automation",
                        // "Seek automation",
                        "Dice automation",
                        // "Foundit automation",
                        "SimplyHired automation",
                      ]}
                      goto={goto}
                      userPlan={userData.planDetails.planName}
                      featuresCut={[]}
                      extrafeatures={[
                        "Day wise analytics",
                        "CV Improvement Tips",
                        "Single User",
                      ]}
                      showbnpl={
                        country === "US" || country === "CA" ? true : false
                      }
                      setbnplplan={setbnplplan}
                      setshowbnpl={setshowbnpl}
                      jobLimit={"Unlimited"}
                      resumeLimit={10}
                      showSplitPayments={
                        country === "US" || country === "CA" ? true : false
                      }
                      processingSplitPayments={processingSplitPayments}
                      setSplitPaymentsPlan={setSplitPaymentsPlan}
                      setShowSplitPayments={setShowSplitPayments}
                      gotoSplitPayments={gotoSplitPayments}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="planOutside">
                <div className="row">
                  <div className="col-lg-4">
                    <PricingBlock
                      country={"US"}
                      title="basic"
                      planValue={userData.planDetails.planStarted}
                      paymentsMain={paymentsMain}
                      processing={processing}
                      defaultCutPrice={149}
                      defaultMainPrice={99}
                      mostPopularTag={false}
                      features={["Linkedin automation", "Indeed automation"]}
                      featuresCut={[
                        "Ziprecruiter automation",
                        "Glassdoor automation",
                        "Career Builder automation",
                        // "Monster automation",
                        // "Seek automation",
                        "Dice automation",
                        // "Foundit automation",
                        "SimplyHired automation",
                      ]}
                      goto={goto}
                      userPlan={userData.planDetails.planName}
                      extrafeatures={[
                        "Day wise analytics",
                        "CV Improvement Tips",
                        "Single User",
                      ]}
                      showbnpl={
                        country === "US" || country === "CA" ? true : false
                      }
                      setbnplplan={setbnplplan}
                      setshowbnpl={setshowbnpl}
                      jobLimit={"150"}
                      resumeLimit={1}
                      showSplitPayments={
                        country === "US" || country === "CA" ? true : false
                      }
                      processingSplitPayments={processingSplitPayments}
                      setSplitPaymentsPlan={setSplitPaymentsPlan}
                      setShowSplitPayments={setShowSplitPayments}
                      gotoSplitPayments={gotoSplitPayments}
                    />
                  </div>
                  <div className="col-lg-4">
                    <PricingBlock
                      country={"US"}
                      title="premium"
                      planValue={userData.planDetails.planStarted}
                      paymentsMain={paymentsMain}
                      processing={processing}
                      defaultCutPrice={199}
                      defaultMainPrice={129}
                      mostPopularTag={false}
                      features={[
                        "Linkedin automation",
                        "Indeed automation",
                        "Ziprecruiter automation",
                        "Glassdoor automation",
                        "Career Builder automation",
                        // "Monster automation",
                        // "Seek automation",
                        "Dice automation",
                        // "Foundit automation",
                        "SimplyHired automation",
                      ]}
                      showbnpl={
                        country === "US" || country === "CA" ? true : false
                      }
                      setbnplplan={setbnplplan}
                      setshowbnpl={setshowbnpl}
                      goto={goto}
                      userPlan={userData.planDetails.planName}
                      featuresCut={[]}
                      extrafeatures={[
                        "Day wise analytics",
                        "CV Improvement Tips",
                        "Single User",
                      ]}
                      jobLimit={"750"}
                      resumeLimit={5}
                      showSplitPayments={
                        country === "US" || country === "CA" ? true : false
                      }
                      processingSplitPayments={processingSplitPayments}
                      setSplitPaymentsPlan={setSplitPaymentsPlan}
                      setShowSplitPayments={setShowSplitPayments}
                      gotoSplitPayments={gotoSplitPayments}
                    />
                  </div>
                  <div className="col-lg-4">
                    <PricingBlock
                      country={"US"}
                      title="unlimited"
                      planValue={userData.planDetails.planStarted}
                      paymentsMain={paymentsMain}
                      processing={processing}
                      defaultCutPrice={349}
                      defaultMainPrice={249}
                      mostPopularTag={false}
                      features={[
                        "Linkedin automation",
                        "Indeed automation",
                        "Ziprecruiter automation",
                        "Glassdoor automation",
                        "Career Builder automation",
                        // "Monster automation",
                        // "Seek automation",
                        "Dice automation",
                        // "Foundit automation",
                        "SimplyHired automation",
                      ]}
                      showbnpl={
                        country === "US" || country === "CA" ? true : false
                      }
                      setbnplplan={setbnplplan}
                      setshowbnpl={setshowbnpl}
                      goto={goto}
                      userPlan={userData.planDetails.planName}
                      featuresCut={[]}
                      extrafeatures={[
                        "Day wise analytics",
                        "CV Improvement Tips",
                        "Single User",
                      ]}
                      jobLimit={"Unlimited"}
                      resumeLimit={10}
                      showSplitPayments={
                        country === "US" || country === "CA" ? true : false
                      }
                      processingSplitPayments={processingSplitPayments}
                      setSplitPaymentsPlan={setSplitPaymentsPlan}
                      setShowSplitPayments={setShowSplitPayments}
                      gotoSplitPayments={gotoSplitPayments}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {isYearly ? (
              <>
                <div className="planOutside">
                  <div className="row">
                    <div className="col-lg-4">
                      <PricingBlockEnterprise
                        country={"US"}
                        yearly={true}
                        title="gold"
                        secondtitle="basic"
                        paymentsMain={paymentsMain}
                        processing={processing}
                        defaultCutPrice={7188}
                        defaultMainPrice={6469}
                        mostPopularTag={false}
                        goto={goto}
                        features={[
                          "Linkedin automation",
                          "Indeed automation",
                          "Ziprecruiter automation",
                          "Glassdoor automation",
                          "Career Builder automation",
                          // "Monster automation",
                          // "Seek automation",
                          "Dice automation",
                          // "Foundit automation",
                          "SimplyHired automation",
                          "Saved Automation Searches",
                        ]}
                        featuresCut={[]}
                        extrafeatures={["Per user day wise analytics"]}
                        resumeLimit={20}
                        userLimit={4}
                      />
                    </div>
                    <div className="col-lg-4">
                      <PricingBlockEnterprise
                        country={"US"}
                        title="platinum"
                        yearly={true}
                        secondtitle="premium"
                        paymentsMain={paymentsMain}
                        processing={processing}
                        defaultCutPrice={7990}
                        defaultMainPrice={6990}
                        mostPopularTag={true}
                        goto={goto}
                        features={[
                          "Linkedin automation",
                          "Indeed automation",
                          "Ziprecruiter automation",
                          "Glassdoor automation",
                          "Career Builder automation",
                          // "Monster automation",
                          // "Seek automation",
                          "Dice automation",
                          // "Foundit automation",
                          "SimplyHired automation",
                          "Saved Automation Searches",
                        ]}
                        featuresCut={[]}
                        extrafeatures={["Per user day wise analytics"]}
                        resumeLimit={100}
                        userLimit={15}
                      />
                    </div>
                    <div className="col-lg-4">
                      <PricingBlockEnterprise
                        country={"US"}
                        title="diamond"
                        yearly={true}
                        secondtitle="unlimited"
                        paymentsMain={paymentsMain}
                        goto={goto}
                        processing={processing}
                        defaultCutPrice={29988}
                        defaultMainPrice={26989}
                        mostPopularTag={false}
                        features={[
                          "Linkedin automation",
                          "Indeed automation",
                          "Ziprecruiter automation",
                          "Glassdoor automation",
                          "Career Builder automation",
                          // "Monster automation",
                          // "Seek automation",
                          "Dice automation",
                          // "Foundit automation",
                          "SimplyHired automation",
                          "Saved Automation Searches",
                        ]}
                        featuresCut={[]}
                        extrafeatures={[
                          "Per user day wise analytics",
                          "Unlimited users",
                        ]}
                        resumeLimit={"Unlimited"}
                        userLimit={"Unlimited"}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {country == "IN" ? (
                  <div className="planOutside">
                    <div className="row">
                      <div className="col-lg-4">
                        <PricingBlockEnterprise
                          country={"IN"}
                          title="gold"
                          yearly={false}
                          secondtitle="basic"
                          paymentsMain={paymentsMain}
                          goto={goto}
                          processing={processing}
                          defaultCutPrice={799}
                          defaultMainPrice={599}
                          mostPopularTag={false}
                          features={[
                            "Linkedin automation",
                            "Indeed automation",
                            "Ziprecruiter automation",
                            "Glassdoor automation",
                            "Career Builder automation",
                            // "Monster automation",
                            // "Seek automation",
                            "Dice automation",
                            // "Foundit automation",
                            "SimplyHired automation",
                            "Saved Automation Searches",
                          ]}
                          featuresCut={[]}
                          extrafeatures={["Per user day wise analytics"]}
                          resumeLimit={20}
                          userLimit={4}
                        />
                      </div>
                      <div className="col-lg-4">
                        <PricingBlockEnterprise
                          country={"IN"}
                          title="platinum"
                          yearly={false}
                          secondtitle="premium"
                          paymentsMain={paymentsMain}
                          goto={goto}
                          processing={processing}
                          defaultCutPrice={999}
                          defaultMainPrice={699}
                          mostPopularTag={true}
                          features={[
                            "Linkedin automation",
                            "Indeed automation",
                            "Ziprecruiter automation",
                            "Glassdoor automation",
                            "Career Builder automation",
                            // "Monster automation",
                            // "Seek automation",
                            "Dice automation",
                            // "Foundit automation",
                            "SimplyHired automation",
                            "Saved Automation Searches",
                          ]}
                          featuresCut={[]}
                          extrafeatures={["Per user day wise analytics"]}
                          resumeLimit={100}
                          userLimit={15}
                        />
                      </div>
                      <div className="col-lg-4">
                        <PricingBlockEnterprise
                          country={"IN"}
                          title="diamond"
                          yearly={false}
                          secondtitle="unlimited"
                          paymentsMain={paymentsMain}
                          processing={processing}
                          defaultCutPrice={3499}
                          defaultMainPrice={2499}
                          mostPopularTag={false}
                          goto={goto}
                          features={[
                            "Linkedin automation",
                            "Indeed automation",
                            "Ziprecruiter automation",
                            "Glassdoor automation",
                            "Career Builder automation",
                            // "Monster automation",
                            // "Seek automation",
                            "Dice automation",
                            // "Foundit automation",
                            "SimplyHired automation",
                            "Saved Automation Searches",
                          ]}
                          featuresCut={[]}
                          extrafeatures={["Per user day wise analytics"]}
                          resumeLimit={"Unlimited"}
                          userLimit={"Unlimited"}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="planOutside">
                    <div className="row">
                      <div className="col-lg-4">
                        <PricingBlockEnterprise
                          country={"US"}
                          title="gold"
                          secondtitle="basic"
                          yearly={false}
                          paymentsMain={paymentsMain}
                          processing={processing}
                          defaultCutPrice={799}
                          defaultMainPrice={599}
                          mostPopularTag={false}
                          goto={goto}
                          features={[
                            "Linkedin automation",
                            "Indeed automation",
                            "Ziprecruiter automation",
                            "Glassdoor automation",
                            "Career Builder automation",
                            // "Monster automation",
                            // "Seek automation",
                            "Dice automation",
                            // "Foundit automation",
                            "SimplyHired automation",
                            "Saved Automation Searches",
                          ]}
                          featuresCut={[]}
                          extrafeatures={["Per user day wise analytics"]}
                          resumeLimit={20}
                          userLimit={4}
                        />
                      </div>
                      <div className="col-lg-4">
                        <PricingBlockEnterprise
                          country={"US"}
                          title="platinum"
                          yearly={false}
                          secondtitle="premium"
                          paymentsMain={paymentsMain}
                          processing={processing}
                          defaultCutPrice={999}
                          defaultMainPrice={699}
                          mostPopularTag={true}
                          goto={goto}
                          features={[
                            "Linkedin automation",
                            "Indeed automation",
                            "Ziprecruiter automation",
                            "Glassdoor automation",
                            "Career Builder automation",
                            // "Monster automation",
                            // "Seek automation",
                            "Dice automation",
                            // "Foundit automation",
                            "SimplyHired automation",
                            "Saved Automation Searches",
                          ]}
                          featuresCut={[]}
                          extrafeatures={["Per user day wise analytics"]}
                          resumeLimit={100}
                          userLimit={15}
                        />
                      </div>
                      <div className="col-lg-4">
                        <PricingBlockEnterprise
                          country={"US"}
                          title="diamond"
                          yearly={false}
                          secondtitle="unlimited"
                          paymentsMain={paymentsMain}
                          goto={goto}
                          processing={processing}
                          defaultCutPrice={3499}
                          defaultMainPrice={2499}
                          mostPopularTag={false}
                          features={[
                            "Linkedin automation",
                            "Indeed automation",
                            "Ziprecruiter automation",
                            "Glassdoor automation",
                            "Career Builder automation",
                            // "Monster automation",
                            // "Seek automation",
                            "Dice automation",
                            // "Foundit automation",
                            "SimplyHired automation",
                            "Saved Automation Searches",
                          ]}
                          featuresCut={[]}
                          extrafeatures={[
                            "Per user day wise analytics",
                            "Unlimited users",
                          ]}
                          resumeLimit={"Unlimited"}
                          userLimit={"Unlimited"}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </PayPalScriptProvider>
      {toggleValue == "enterprises" && (
        <>
          <div
            className="freePlan"
            style={{
              gridTemplateColumns: "4fr",
            }}
          >
            <div className="freePlanChild0">
              Book A DEMO
              <p>
                Experience a live, customized demo of Lazyapply Enterprise Plan
                and get answers to any questions you might have.
              </p>
              <a
                style={{ color: "white", fontSize: "20px" }}
                href="https://calendly.com/vivek-lazyapply/15min"
                target="_blank"
                rel="noreferrer"
              >
                CLick here to book now
                <i
                  style={{ color: "white", fontSize: "20px" }}
                  className="fas fa-external-link-alt ml-2"
                ></i>
              </a>
            </div>
            <div className="freePlanChild1"></div>
          </div>
        </>
      )}
      <Review />
    </>
  );
}

export default Pricing;
