import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useStateStore } from "../../store/index";
import FileUpload from "./FileUploader";
import { useParams } from "react-router-dom";
function ReplaceResumeModal({ showProp, hideShow, isReplaced = () => {} }) {
  const [show, setShow] = useState(false);
  const params = useParams();
  const handleClose = () => {
    setShow(false);
    hideShow();
  };
  const [showSpinner, setShowSpinner] = useState(false);
  const userResumeV3 = useStateStore((state) => state.userResumeV3);
  const userData = useStateStore((state) => state.userData);
  const token = useStateStore((state) => state.token);
  const [file, setFile] = useState(null);

  let buttonSave = {
    backgroundColor: "#35AD46",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "10px 20px",
    textAlign: "center",
    borderRadius: "10px",
    width: "100%",
    outline: "none",
    border: "none",
    marginTop: "10px",
  };

  let showr = {
    textAlign: "center",
    fontWeight: "bold",
    color: "black",
    fontSize: "16.5px",
    marginTop: "10px",
  };
  const [showMessage, setShowMessage] = useState("");

  const showCustomMessage = (message) => {
    setShowMessage(message);
    setTimeout(() => {
      setShowMessage("");
    }, 2500);
  };

  const createNewResume = () => {
    setDisable(true);
    const v2Id = userData?.planDetails?.v2Id;
    // const newResumeId = uuidv1();
    const newResumeId = params.id;
    if (file) {
      const formData = new FormData();
      console.log("file");
      //   formData.append("resumename", value);
      formData.append("resumeId", newResumeId);
      formData.append("file", file);
      console.log("formData", formData);
      setShowSpinner(true);
      console.log("okay");
      let url = `https://backend.lazyapply.com/v2/replaceResume/${v2Id}`;
      if (
        userData?.planDetails &&
        userData?.planDetails?.planType &&
        userData?.planDetails?.planType == "enterprise"
      ) {
        url = `https://backend.lazyapply.com/v2/replaceResumeEnterprise/${v2Id}`;
      }
      axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setShowSpinner(false);
          console.log(response, "response submitcode");
          if (response.data.message == "Successfully Replaced") {
            showCustomMessage("Successfully Replaced");
            // setUserResumeV2IndividualV3({
            //   resumename: value,
            //   resumeId: newResumeId,
            //   createdBy: userData.email,
            // });
            isReplaced();
            setDisable(false);
            setTimeout(() => {
              handleClose();
            }, 2500);
          } else {
            if ("error" in response.data) {
              showCustomMessage(response.data.message);
              // setUserResumeV2IndividualV3({
              //   resumename: value,
              //   resumeId: newResumeId,
              //   createdBy: userData.email,
              // });
              setFile(null);
              setDisable(false);
              // handleClose();
              // setTimeout(() => {
              //   history.push(`/dashboard/editresumesv2/${newResumeId}`);
              // }, 3000);
            }
          }
        })
        .catch((error) => {
          setShowSpinner(false);
          setDisable(false);
          showCustomMessage("Some error occured, please try again!");
          console.log(error, "error submitcode");
        });
    } else {
      if (!file) {
        setDisable(false);
        showCustomMessage("No resume is uploaded!");
      }
    }
  };

  function submitCoupanCode(e) {
    e.preventDefault();
    const resumev2 = userResumeV3;
    if (resumev2) {
      createNewResume();
    } else {
      createNewResume();
    }
  }

  const resetResumeFile = () => {
    setFile(null);
  };

  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="md"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Replace Resume/CV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: "20px" }}>
          {/* <p style={{color:'darkblue'}}>We're currently experiencing a server issue for resume upload. We appreciate your patience, it will be resolved asap.</p> */}
          <Form onSubmit={submitCoupanCode}>
            {/* <Form.Control
              required={true}
              type="text"
              id="resumename"
              value={value}
              placeholder="Enter resume name, example -> Aman_Resume"
              onChange={(e) => setValue(e.target.value)}
            /> */}

            <div className="resumeTrackerUpload">
              <p style={{ marginTop: "20px" }}>
                Upload your Resume/ CV{" "}
                <span
                  onClick={resetResumeFile}
                  style={{
                    border: "none",
                    outline: "none",
                    background: "orange",
                    color: "white",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                >
                  Reset Resume
                </span>
              </p>

              <FileUpload file={file} setFile={setFile}></FileUpload>
            </div>
            {file && (
              <p style={{ color: "green" }}>
                Resume Upload Successfully! press Replace Now button
              </p>
            )}

            <button style={buttonSave} disabled={disable}>
              Replace Now{" "}
              {showSpinner && (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ marginLeft: "5px" }}
                />
              )}
            </button>
          </Form>
          <p style={showr}>{showMessage}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ReplaceResumeModal;
