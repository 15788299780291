/*global chrome*/
import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import { useStateStore } from "../../store";
import NotFound from "./NotFound";
import PeopleBlock from "./PeopleBlock";
import { mixpanel } from "../../index";

function ReferralPeopleList({ setSendEmail }) {
  const [employees, setEmployees] = useState([]);
  const buttonClick = useStateStore((state) => state.buttonClick);
  const setButtonClick = useStateStore((state) => state.setButtonClick);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [employeeQuery, setEmployeeQuery] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const extensionPresent = useStateStore((state) => state.extensionPresent);
  const yourExtensionId = useStateStore((state) => state.yourExtensionId);
  const employeeList = useStateStore((state) => state.employeeList);
  const setEmployeeList = useStateStore((state) => state.setEmployeeList);
  const mainCompanySet = useStateStore((state) => state.mainCompanySet);
  const token = useStateStore((state) => state.token);
  const userData = useStateStore((state) => state.userData);
  const setGlobalTabId = useStateStore((state) => state.setGlobalTabId);
  const setScriptInjectedGlobal = useStateStore(
    (state) => state.setScriptInjectedGlobal
  );
  const scriptInjectedGlobal = useStateStore(
    (state) => state.scriptInjectedGlobal
  );
  const setExtensionPresent = useStateStore(
    (state) => state.setExtensionPresent
  );

  const findEmployees = async () => {
    if (employeeQuery === "") {
      getEmployees();
    } else {
      setShowLoader(true);
      if (typeof chrome !== "undefined" && chrome && chrome.runtime) {
        console.log("linkedin", mainCompanySet?.socialNetworks?.linkedin);
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            dashboard: true,
            global: true,
            employeeQuery: employeeQuery,
            message: "getEmployeesReferral",
            url: generateUrl(
              mainCompanySet?.socialNetworks?.linkedinIdNumeric,
              employeeQuery
            ),
          },
          (response) => {
            console.log(
              "response from extension",
              response,
              chrome.runtime.lastError
            );
          }
        );
      }
      // const response = await axios.post(
      //   `https://backend.lazyapply.com/referral/findEmployees`,
      //   {
      //     company: mainCompanySet,
      //     employeeQuery: employeeQuery,
      //   },
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      // console.log("getEmployeesResponse", response.data.employees);
      // setEmployees(response.data.employees);
      // setShowLoader(false);
      //setEmployeeList(response.data.employees);
    }
  };

  const generateUrl = (id, employeeQuery = "") => {
    console.log("genid", id);
    let company = "currentCompany=" + encodeURI(JSON.stringify([Number(id)]));
    console.log("gencompany", company);
    let url = `https://www.linkedin.com/search/results/people/?${company}&origin=FACETED_SEARCH`;
    if (employeeQuery != "") {
      url = `https://www.linkedin.com/search/results/people/?${company}&keywords=${encodeURI(
        employeeQuery
      )}&origin=FACETED_SEARCH`;
    }
    return url;
  };

  const getEmployees = async () => {
    // console.log("employeeList[0].company", employeeList, mainCompanySet);
    if (
      Object.keys(employeeList).length > 0 &&
      employeeList[mainCompanySet.domain]
    ) {
      const employee_list = employeeList[mainCompanySet.domain];
      setShowLoader(false);
      setEmployees(employee_list);
      setEmployeeList(employee_list);
    } else {
      setShowLoader(true);
      if (typeof chrome !== "undefined" && chrome && chrome.runtime) {
        console.log("linkedin", mainCompanySet?.socialNetworks?.linkedin);
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            dashboard: true,
            global: true,
            message: "getEmployeesReferral",
            url: generateUrl(mainCompanySet?.socialNetworks?.linkedinIdNumeric),
          },
          (response) => {
            console.log(
              "response from extension",
              response,
              chrome.runtime.lastError
            );
          }
        );
      }
      // const response = await axios.post(
      //   `https://backend.lazyapply.com/referral/getEmployees`,
      //   {
      //     company: mainCompanySet,
      //   },
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      // console.log("getEmployeesResponse", response.data.employees);
      // setEmployees(response.data.employees);
      // setShowLoader(false);
      // setEmployeeList({
      //   domain: mainCompanySet.domain,
      //   employees: response.data.employees,
      // });
    }
  };

  const verifyNow = () => {
    console.log("Sending initial request to extension");
    setVerifyLoading(true);
    const usermain = JSON.parse(window.localStorage.getItem("user") || "{}");
    const tokenmain = window.localStorage.getItem("token");
    if (
      tokenmain != "" &&
      usermain != undefined &&
      Object.keys(usermain).length > 0
    ) {
      if (typeof chrome !== "undefined" && chrome && chrome.runtime) {
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            dashboard: true,
            saveValues: {
              token: tokenmain,
              user: usermain,
            },
            injected: scriptInjectedGlobal,
            global: true,
            message: "loginToken",
          },
          (response) => {
            setVerifyLoading(false);
            console.log(
              "response from extension",
              response,
              chrome.runtime.lastError
            );
            var lastError = chrome.runtime?.lastError;
            if (lastError) {
              console.log("some error");
              setScriptInjectedGlobal(false);
              setExtensionPresent(false);
            } else {
              console.log("response of initial request", response);
              setScriptInjectedGlobal(true);
              setExtensionPresent(true);
              setGlobalTabId(response.globalTabId);
              // if (Object.keys(mainCompanySet).length > 0) getEmployees();
            }
          }
        );
      } else {
        setVerifyLoading(false);
      }
    } else {
      setVerifyLoading(false);
    }
  };

  const reset = async () => {
    setEmployeeQuery("");
    getEmployees();
  };

  const onFind = () => {
    mixpanel.identify(userData.email);
    mixpanel.people.increment(
      "find_employees_button secret_network_popup step_1 dashboard"
    );
    mixpanel.track(
      "find_employees_button secret_network_popup step_1 dashboard",
      {
        email: userData?.email,
        plan_details: userData?.planDetails || {},
        v2Id: userData?.planDetails?.v2Id || "",
      }
    );
    setButtonClick(true);
    if (Object.keys(mainCompanySet).length > 0) getEmployees();
  };

  function reduceArraySize(array, maxSize) {
    if (array.length > maxSize) {
      return array.slice(0, maxSize);
    }
    return array;
  }

  function mainFunction(request) {
    if ("referral" in request) {
      if ("message" in request) {
        if (request.message == "employeesData") {
          console.log("request", request.employeesData);
          let employeeRawData = request.employeesData;
          employeeRawData = employeeRawData.map((e) => {
            return {
              gender: null,
              fullName: e.fullName.split("\n")[0].trim(),
              linkedinUrl: "https://linkedin.com/in/" + e.publicId,
              locationCountry: e.locationName,
              jobTitle: e.title,
            };
          });
          if (employeeRawData.length > 0) {
            const mainReducedArray = reduceArraySize(employeeRawData, 4);
            setEmployees(mainReducedArray);
            setEmployeeList({
              domain: mainCompanySet.domain,
              employees: mainReducedArray,
            });
            console.log(mainReducedArray, employeeRawData);
          }
          setShowLoader(false);
        }
      }
    }
  }

  const messageFn = useCallback((event) => {
    console.log("referral", event.data);
    if (
      typeof event.data === "object" &&
      !Array.isArray(event.data) &&
      event.data !== null
    ) {
      mainFunction(event.data);
      event.source.postMessage("finished", event.origin);
    }
  }, []);

  useEffect(() => {
    if (buttonClick) {
      if (Object.keys(mainCompanySet).length > 0) getEmployees();
    }
  }, []);

  useEffect(() => {
    console.log("added");
    window.addEventListener("message", messageFn);
    return () => {
      console.log("removed");
      window.removeEventListener("message", messageFn);
    };
  }, []);

  return (
    <>
      <div className="referral-filters-search-box">
        <div className="referral-fitlers-seach-box-block">
          <Form.Control
            required
            type="text"
            disabled={showLoader}
            id="input_companies"
            value={employeeQuery}
            placeholder="Job title, Name,  Keyword. Example - hr,john, software"
            onChange={(e) => setEmployeeQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                mixpanel.identify(userData.email);
                mixpanel.people.increment(
                  "find_button secret_network_popup step_1 dashboard"
                );
                mixpanel.track(
                  "find_button secret_network_popup step_1 dashboard",
                  {
                    query: e?.target?.value,
                    email: userData?.email,
                    plan_details: userData?.planDetails || {},
                    v2Id: userData?.planDetails?.v2Id || "",
                  }
                );
                findEmployees();
              }
            }}
          />
        </div>
        <div className="referral-fitlers-search-box-button-block">
          <button
            disabled={showLoader}
            onClick={() => {
              mixpanel.identify(userData.email);
              mixpanel.people.increment(
                "find_button secret_network_popup step_1 dashboard"
              );
              mixpanel.track(
                "find_button secret_network_popup step_1 dashboard",
                {
                  email: userData?.email,
                  plan_details: userData?.planDetails || {},
                  v2Id: userData?.planDetails?.v2Id || "",
                }
              );
              findEmployees();
            }}
          >
            <i class="fa fa-search"></i> Find
          </button>
        </div>
        {/* <div className="referral-fitlers-search-box-button-block referral-fitlers-search-box-button-block-reset">
          <button disabled={showLoader} onClick={() => reset()}>
            Reset
          </button>
        </div> */}
      </div>
      {!extensionPresent ? (
        <>
          <div className="main-referral-no-extension">
            <p>Install our extension to find employees in real time.</p>
            <button
              disabled={verifyLoading}
              onClick={() => {
                mixpanel.identify(userData.email);
                mixpanel.people.increment(
                  "install_lazyapply_extension_button secret_network_popup step_1 dashboard"
                );
                mixpanel.track(
                  "install_lazyapply_extension_button secret_network_popup step_1 dashboard",
                  {
                    email: userData?.email,
                    plan_details: userData?.planDetails || {},
                    v2Id: userData?.planDetails?.v2Id || "",
                  }
                );
                window.open(
                  "https://chrome.google.com/webstore/detail/lazyapply-job-application/pgnfaifdbfoiehcndkoeemaifhhbgkmm",
                  "_blank"
                );
              }}
            >
              <img src={"/assests/chrome-extension.svg"}></img>Install Lazyapply
              Extension
            </button>
            <p
              className="main-referral-already-installed"
              onClick={() => {
                mixpanel.identify(userData.email);
                mixpanel.people.increment(
                  "click_here_to_verify_button secret_network_popup step_1 dashboard"
                );
                mixpanel.track(
                  "click_here_to_verify_button secret_network_popup step_1 dashboard",
                  {
                    email: userData?.email,
                    plan_details: userData?.planDetails || {},
                    v2Id: userData?.planDetails?.v2Id || "",
                  }
                );
                verifyNow();
              }}
            >
              Already installed, click here to verify{" "}
              <i class="fas fa-check-double"></i>
              {verifyLoading && (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ marginLeft: "5px", marginBottom: "5px" }}
                />
              )}
            </p>
          </div>
        </>
      ) : (
        <>
          {showLoader === true ? (
            <>
              <div className="main-referral-modal-loading">
                Getting Employees In Real Time...{" "}
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ marginLeft: "10px" }}
                />
              </div>
              <div
                style={{
                  display: "block",
                  marginLeft: "10px",
                  fontSize: "14px",
                  color: "darkgreen",
                }}
              >
                It can take upto 2 minutes.
              </div>
              <Card className="skeleton-loading skeleton-loading-referral">
                <Card.Body className="skeleton-loading"></Card.Body>
              </Card>
              <Card className="skeleton-loading skeleton-loading-referral">
                <Card.Body className="skeleton-loading"></Card.Body>
              </Card>
            </>
          ) : (
            <>
              {employees.length === 0 && (
                <NotFound
                  onFind={onFind}
                  buttonClick={buttonClick}
                  notFoundText={"Employees Not Found!"}
                  showPic={false}
                />
              )}
              {employees.map((employee) => {
                return (
                  <>
                    <PeopleBlock
                      employee={employee}
                      setSendEmail={setSendEmail}
                    />
                  </>
                );
              })}
            </>
          )}
        </>
      )}
    </>
  );
}

export default ReferralPeopleList;
