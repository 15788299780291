import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useStateStore } from "../../store/index";
import axios from "axios";
function ModalConfirmNew({ show, handleClose, currentName, resumeId }) {
  const deleteUserResumeV3 = useStateStore((state) => state.deleteUserResumeV3);
  const userData = useStateStore((state) => state.userData);
  const token = useStateStore((state) => state.token);
  const deleteResume = () => {
    setDisable(true);
    const v2Id = userData?.planDetails?.v2Id;
    axios
      .post(
        `https://backend.lazyapply.com/v2/deleteResumeV3/${v2Id}`,
        {
          resumeId: resumeId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        showCustomMessage(response.data);
        setDisable(false);
        deleteUserResumeV3(resumeId);
        // setTimeout(() => {
        handleClose();
        // }, 1000);
      })
      .catch((error) => {
        setDisable(false);
        showCustomMessage("Some error occured, please try again!");
        console.log(error, "error submitcode");
      });
  };

  let showr = {
    textAlign: "center",
    fontWeight: "bold",
    color: "black",
    fontSize: "16.5px",
    marginTop: "10px",
  };
  const [showMessage, setShowMessage] = useState("");
  const showCustomMessage = (message) => {
    setShowMessage(message);
    setTimeout(() => {
      setShowMessage("");
    }, 2500);
  };
  const [disable, setDisable] = useState(false);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="md"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Resume - {currentName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: "10px 5px 5px 5px" }}>
          <p>Are you sure you want to delete this resume ?</p>
          <p style={showr}>{showMessage}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          No
        </Button>

        <Button variant="primary" onClick={deleteResume} disabled={disable}>
          Yes, Delete!
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalConfirmNew;
