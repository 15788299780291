import React from "react";
import makeData from "./makeDataLazyapplyXCompanies";
import MainTable from "./mainTable";
import { useHistory } from "react-router-dom";
import { useStateStore } from "../../store/index";

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function CompaniesList({ companies = [] }) {
  console.log("companies", companies);
  const setSettingsClick = useStateStore((state) => state.setSettingsClick);
  const history = useHistory();
  const columns = React.useMemo(
    () => [
      {
        Header: "Companies Fetched",
        columns: [
          {
            Header: "Company Name",
            accessor: "companyName",
            minWidth: 200,
          },
          {
            Header: "Company Domain",
            accessor: "companyDomain",
            minWidth: 200,
          },
          {
            Header: "Total Exployees",
            accessor: "totalEmployees",
            filter: "includes",
            Filter: SelectColumnFilter,
            collapse: true,
          },
          {
            Header: "Year Founder",
            accessor: "yearFounder",
            disableFilters: true,
            collapse: true,
          },
          {
            Header: "Revenue",
            accessor: "revenue",
            disableFilters: true,
            collapse: true,
          },
        ],
      },
    ],
    []
  );

  const tabledata = makeData([companies.length], companies);

  return companies.length > 0 ? (
    <div>
      <div className="appliedJobsTable">
        <MainTable columns={columns} data={tabledata} />
      </div>
    </div>
  ) : (
    <>
      <div>
        <div className="linkedinAppliedDetailsSeperateTop row notfound">
          <div className="col-12" style={{ paddingTop: "20px" }}>
            <div className="col-12">No Companies Found</div>
          </div>
          <p
            style={{
              textAlign: "center",
              width: "100%",
              color: "black",
              fontWeight: "bold",
              marginTop: "20px",
            }}
          >
            No companies found matching your search criteria, please update it
            to to find matching companies
          </p>
          <p
            style={{
              textAlign: "center",
              width: "100%",
              color: "blue",
              fontWeight: "bold",
              marginTop: "20px",
              cursor: "pointer",
            }}
            onClick={() => {
              setSettingsClick(true);
              history.push("/dashboard/lazyapply-x");
            }}
          >
            Click here to change search criteria
          </p>
        </div>
      </div>
    </>
  );
}

export default CompaniesList;
