import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import axios from "axios";
import { useStateStore } from "../../store/index";
import { v1 as uuidv1 } from "uuid";
import { useHistory } from "react-router-dom";
function ChangeResumeNameModal({ showProp, hideShow, currentName, resumeId }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    hideShow();
  };
  const updateUserResumeV3 = useStateStore((state) => state.updateUserResumeV3);
  const userResumeV3 = useStateStore((state) => state.userResumeV3);
  const history = useHistory();
  const userData = useStateStore((state) => state.userData);
  const [value, setValue] = useState("");
  const token = useStateStore((state) => state.token);
  let buttonSave = {
    backgroundColor: "#35AD46",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "10px 20px",
    textAlign: "center",
    borderRadius: "10px",
    width: "100%",
    outline: "none",
    border: "none",
    marginTop: "10px",
  };

  let showr = {
    textAlign: "center",
    fontWeight: "bold",
    color: "black",
    fontSize: "16.5px",
    marginTop: "10px",
  };
  const [showMessage, setShowMessage] = useState("");

  const showCustomMessage = (message) => {
    setShowMessage(message);
    setTimeout(() => {
      setShowMessage("");
    }, 2500);
  };

  const createNewResume = () => {
    setDisable(true);
    const v2Id = userData?.planDetails?.v2Id;
    const newResumeId = uuidv1();
    if (value != "") {
      axios
        .post(
          `https://backend.lazyapply.com/v2/changeResumeNameV3/${v2Id}`,
          {
            resumeId: resumeId,
            newResumeName: value,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response, "response submitcode");
          showCustomMessage(response.data);
          setDisable(false);
          updateUserResumeV3(resumeId, value);
          handleClose();
        })
        .catch((error) => {
          setDisable(false);
          showCustomMessage("Some error occured, please try again!");
          console.log(error, "error submitcode");
        });
    } else {
      setDisable(false);
      showCustomMessage("Resume name cannot be empty");
    }
  };

  function submitCoupanCode(e) {
    e.preventDefault();
    const resumev2 = userResumeV3;
    if (resumev2) {
      let present = false;
      for (let index = 0; index < resumev2.length; index++) {
        const resume = resumev2[index];
        if (resume.resumename === value) {
          present = true;
          break;
        }
      }
      if (present) {
        showCustomMessage("Resume with this name already present!");
      } else {
        createNewResume();
      }
    } else {
      createNewResume();
    }
  }

  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="md"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Change Resume Name For Resume - {currentName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: "20px" }}>
          <Form onSubmit={submitCoupanCode}>
            <Form.Control
              required={true}
              type="text"
              id="resumename"
              value={value}
              placeholder="Enter the new name here.."
              onChange={(e) => setValue(e.target.value)}
            />
            <button style={buttonSave} disabled={disable}>
              Update Name Now
            </button>
          </Form>
          <p style={showr}>{showMessage}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ChangeResumeNameModal;
