/*global chrome*/
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useMultipleRefs } from "./customHooks";
import { Form, Col, Row, FormCheck } from "react-bootstrap";
import "./jobplatform.css";
import { useHistory } from "react-router-dom";
import Progress from "./progress";
import axios from "axios";
import { useStateStore } from "../../store/index";
import CustomAlert from "../modal";
import { mixpanel } from "../../index";
import Select from "react-select";
import Feedback from "../feedback";
import MoreFiltersHelp from "../morefiltershelp";
import { v1 as uuidv1 } from "uuid";
import StepsWithCut from "../stepswithcut";
// import CreatableSelect from "react-select/creatable";
import { MESSAGETYPE, messages, MESSAGES } from "./slackMessages";
import AdvanceSearchModal from "./AdvanceSearchModal";
import { GrantAccess } from "./grantAccess";

// import ExactModal from "./exactModal";
const Seek = () => {
  const setScriptInjectedGlobal = useStateStore(
    (state) => state.setScriptInjectedGlobal
  );
  const scriptInjectedGlobal = useStateStore(
    (state) => state.scriptInjectedGlobal
  );
  const setExtensionPresent = useStateStore(
    (state) => state.setExtensionPresent
  );
  const yourExtensionId = useStateStore((state) => state.yourExtensionId);
  const setGlobalTabId = useStateStore((state) => state.setGlobalTabId);
  const setStreakAnalytics = useStateStore((state) => state.setStreakAnalytics);
  const streakAnalytics = useStateStore((state) => state.streakAnalytics);

  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [mustInclude, setMustInclude] = useState({});
  const [mustIncludeAnyOne, setMustIncludeAnyOne] = useState({});
  const [excludeKeywords, setExcludeKeywords] = useState({});
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const handleCloseSearch = () => {
    setShowAdvanceSearch(false);
  };

  let urlParts = {};
  let filterRefs = useMultipleRefs(10);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    if (advanceSearch) {
      setShowAdvanceSearch(true);
    }
  }, [advanceSearch]);

  const formMain1 = useRef(null);
  const selectResumeRef = useRef(null);
  const selectResumeRef1 = useRef(null);
  const chooseParaText = useRef(null);
  const [formDisable, setformDisable] = useState(false);
  const spinner = useRef(null);
  const fetchbutton = useRef(null);
  const button1 = useRef(null);
  const [disable2, setdisable2] = useState(0);
  const saveSessionState = useRef(0);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const globalLogout = useStateStore((state) => state.globalLogout);
  const history = useHistory();
  const userData = useStateStore((state) => state.userData);
  const userLinksUpdate = useStateStore((state) => state.userLinksUpdate);
  const configDummy = useStateStore((state) => state.configDummy);
  const configDummyUpdate = useStateStore((state) => state.configDummyUpdate);
  const [startApplying, setstartApplying] = useState(false);
  const sessionLinks = useRef([]);
  const sessionLinksBefore = useRef([]);
  const [progressPercent, setProgressPercent] = useState(15);
  const [count, setCount] = useState(0);
  const totalCountUpdate = useStateStore((state) => state.totalCountUpdate);
  const formRef = useRef({});
  const c = useRef(0);
  const pp = useRef(15);
  const processing = useRef(null);
  const initprocessing = useRef(null);
  const formMain = useRef(null);
  const buttonFetch = useRef(null);
  const setLinkedinRunning = useStateStore((state) => state.setLinkedinRunning);
  const [form, setForm] = useState({
    skill: "",
    location: "",
    numberOfJobs: 0,
  });
  const token = useStateStore((state) => state.token);
  const [errors, setErrors] = useState({});
  const userResumeV3 = useStateStore((state) => state.userResumeV3);
  const [resumeId, setResumeId] = useState("");
  const resumeData = useRef({});
  const [openHelper, setOpenHelper] = useState(false);
  const [resumeChanged, setResumeChanged] = useState(false);

  const [countryCode, setCountryCode] = useState("au");

  let countries = [
    { label: "Australia", value: "au" },
    { label: "New Zealand", value: "nz" },
  ];
  let countryUrlMap = {
    au: "https://www.seek.com.au/",
    nz: "https://www.seek.co.nz/",
  };
  let countryLoginUrlMap = {
    au: "https://www.seek.com.au/oauth/login/",
    nz: "https://www.seek.co.nz/oauth/login/",
  };

  // const labelsMap = {"All Job Types: "aaas"};

  const setField = (field, value) => {
    console.log(field, value);
    setForm({
      ...form,
      [field]: value,
    });

    // Check and see if errors exist, and remove them from the error object:
    console.log(form);

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const handleSubmit = (e) => {
    console.log("handle submit");
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    console.log(newErrors);
    // Conditional logic:
    if (userData?.planDetails?.v2Id) {
      //v2
      if (resumeId === "") {
        setResumeChanged(true);
      } else if (Object.keys(newErrors).length > 0) {
        // We got errors!
        setErrors(newErrors);
      } else {
        // No errors! Put any logic here for the form submission!
        startLinkedinAutomation1();
        // startLinkedinAutomation();
      }
    } else {
      if (Object.keys(newErrors).length > 0) {
        // We got errors!
        setErrors(newErrors);
      } else {
        // No errors! Put any logic here for the form submission!
        startLinkedinAutomation1();
        // startLinkedinAutomation();
      }
    }
  };

  const findFormErrors = () => {
    const { skill, numberOfJobs } = form;
    const newErrors = {};
    // name errors
    if (!skill || skill === "") newErrors.skill = "Please enter a skill!";
    if (numberOfJobs <= 0)
      newErrors.numberOfJobs = "Please enter the no. of jobs to apply";

    if (numberOfJobs < 20 && configDummy.planName != "free") {
      if (configDummy.dailyLimit - configDummy.todaysessioncount <= 20) {
        console.log("go for it");
      } else {
        newErrors.numberOfJobs = "Please enter a value more than 35";
      }
    } else if (numberOfJobs < 35 && configDummy.planName == "free") {
      if (configDummy.dailyLimit - configDummy.todaysessioncount < 35) {
        console.log("go for it");
      } else {
        newErrors.numberOfJobs = "Please enter a value more than 35";
      }
    }

    return newErrors;
  };

  const styleform = {
    padding: "20px",
    paddingTop: "0px",
  };

  const filtersStyle = {
    padding: "20px",
    boxShadow: "0px 2px 4px 2px lightgrey, 0px 2px 4px 2px #efefef",
    margin: "5px",
    position: "relative",
  };

  function goBack() {
    console.log("go back");
    mixpanel.identify(userData.email);
    mixpanel.people.increment("Extension - seek page go back button clicked");
    mixpanel.track("Extension - seek page go back button clicked", {
      email: userData.email,
    });
    history.goBack();
  }
  const [urlMain, setUrlMain] = useState("");

  function startLinkedinAutomation1() {
    spinner.current.style.display = "inline-block";

    if (
      configDummy.dailyLimit - configDummy.todaysessioncount >=
      form.numberOfJobs
    ) {
      fetchbutton.current.disabled = true;
      formRef.current = {
        ...form,
      };

      console.log(form.skill + "skill");

      let url =
        countryUrlMap[countryCode] +
        `${form.skill.split(" ").join("-")}` +
        "-jobs/" +
        (form.location.length > 1
          ? `in-${form.location
              .replace(/[^a-zA-Z\s]/g, "")
              .split(" ")
              .join("-")}`
          : "");

      console.log("url to open", url);

      setUrlMain(url);
      setLinkedinRunning(1);
      if (typeof chrome !== "undefined" && chrome && chrome.runtime)
        chrome.runtime.sendMessage(yourExtensionId, {
          dashboard: true,
          saveValues: {
            seekExclude: advanceSearch ? excludeKeywords : {},
            seekMustInclude: advanceSearch ? mustInclude : {},
            seekMustIncludeAnyOne: advanceSearch ? mustIncludeAnyOne : {},
            seekFetchFilters: 0,
            seekLimit: Math.min(form.numberOfJobs, 200),
            seekJobLinks: [],
            seekLocation: form.location,
            seekCountryCode: countryCode,
            uniquesessionid: uuidv1(),
          },
          seek: true,
          message: "seekFetchFilters",
          data: {
            url: `${url}`,
            selected: true,
            seek: true,
            message: "tabid",
          },
        });
    } else {
      spinner.current.style.display = "none";
      if (configDummy.dailyLimit - configDummy.todaysessioncount == 0) {
        setmessage("You have used your daily limit! Come back tomorrow :)");
        settitle("Daily limit exceeded!");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 2500);
        setTimeout(() => {
          history.push("/dashboard");
        }, 3000);
      } else {
        setmessage(
          `You have applied to ${
            configDummy.todaysessioncount
          } jobs today. Please enter a value less than ${
            configDummy.dailyLimit - configDummy.todaysessioncount
          }`
        );
        settitle("Daily limit update!");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 2500);
      }
    }
  }

  function formUrl() {
    let url = urlMain;
    let mn = "0",
      mx = "";
    for (let [key, val] of Object.entries(urlParts)) {
      if (key.toLowerCase().includes("minimum salary")) {
        mn = val;
      } else if (key.toLowerCase().includes("maximum salary")) {
        mx = val;
      } else url = url + (url === urlMain ? "?" : "&") + key + "=" + val;
    }
    let str = "";
    if (mn == "0" && mx == "") str = "";
    else str = "salaryrange=" + mn + "-" + mx;
    return str == "" ? url : url + "&" + str;
  }

  const [resumeDisable, setResumeDisable] = useState(false);
  function startLinkedinAutomation() {
    mixpanel.identify(userData.email);
    mixpanel.people.increment(
      "Extension - seek successfully started automation"
    );
    mixpanel.track("Extension - seek successfully started automation", {
      email: userData.email,
    });
    setLinkedinRunning(1);
    setstartApplying(true);
    formRef.current = {
      ...form,
    };
    saveSessionState.current = 0;
    setResumeDisable(true);
    button1.current.style.backgroundColor = "grey";
    button1.current.style.color = "black";
    button1.current.innerText = "Process Started!";
    console.log("Filters selected according to which  now everything will run");

    const finalUrl = formUrl();

    console.log(finalUrl, "final url --------------------------------seek");

    initprocessing.current.style.display = "block";
    if (typeof chrome !== "undefined" && chrome && chrome.runtime)
      chrome.runtime.sendMessage(yourExtensionId, {
        dashboard: true,
        saveValues: {
          seekExclude: advanceSearch ? excludeKeywords : {},
          seekMustInclude: advanceSearch ? mustInclude : {},
          seekMustIncludeAnyOne: advanceSearch ? mustIncludeAnyOne : {},
          seekBaseUrl: finalUrl,
          seekLimit: Math.min(form.numberOfJobs, 200),
          seekJobLinks: [],
          seekResumeId: resumeId,
        },
        seek: true,
        message: "seekStartApplying",
        data: {
          message: "setBaseUrl",
          url: finalUrl,
        },
      });
  }

  const handleSubmit1 = (e) => {
    e.preventDefault();
    console.log("Filter Submit", form);
    startLinkedinAutomation();
  };

  function filterReset() {
    urlParts = {};
    for (let ref of filterRefs) {
      if (ref.current && ref.current.select) {
        ref.current.select.clearValue();
      }
    }
    setForm({
      ...form,
      location: "",
    });
  }

  function seekReset() {
    console.log("seek Reset");
    if (typeof chrome !== "undefined" && chrome && chrome.runtime)
      chrome.runtime.sendMessage(yourExtensionId, {
        dashboard: true,
        seek: true,
        message: "resetseek",
      });
  }

  function resetFull() {
    mixpanel.identify(userData.email);
    mixpanel.people.increment("Extension - seek reset full button clicked");
    mixpanel.track("Extension - seek reset full button clicked", {
      email: userData.email,
    });
    if (saveSessionState.current == 0) {
      saveSession(c.current, "seek");
    }
    resumeData.current = {};
    window.wasRun = false;
    setResumeId("");
    setResumeDisable(false);
    if (selectResumeRef.current)
      selectResumeRef.current.style.display = "block";
    if (selectResumeRef1.current)
      selectResumeRef1.current.style.display = "block";
    chooseParaText.current.style.display = "none";
    setformDisable(false);
    setLinkedinRunning(0);
    setCountryCode("au");
    // setExactSearch(false);
    // setExtraSkillValue([]);
    // setExtraSkills("");
    formMain1.current.style.display = "none";
    sessionLinks.current = [];
    sessionLinksBefore.current = [];
    fetchbutton.current.disabled = false;
    buttonFetch.current.style.display = "block";
    setForm({
      skill: "",
      location: "",
      numberOfJobs: 0,
    });
    setAdvanceSearch(false);
    setMustInclude({});
    setMustIncludeAnyOne({});
    setExcludeKeywords({});
    seekReset();
    filterReset();
    formRef.current = {};
    document.getElementById("inputskill").value = "";
    document.getElementById("inputlocation").value = "";
    document.getElementById("inputnumber").value = "";
    c.current = 0;
    pp.current = 15;
    button1.current.style.backgroundColor = "#F8F9FA";
    button1.current.style.color = "#007bff";
    button1.current.innerText = "Start Applying";
    setProgressPercent(15);
    initprocessing.current.style.display = "none";
    formMain.current.reset();
    setstartApplying(false);
    setCount(0);
  }

  const setheightMain = useStateStore((state) => state.setheightMain);
  const mainheight = useRef(null);
  useEffect(() => {
    let height = 0;
    // const checking = localStorage.getItem("stepcheckseek");
    // if (checking && checking == "true") {
    //   setChecks(true);
    // }
    if (mainheight) height = mainheight.current.scrollHeight;
    // if (document.querySelector(".dashboardRightEnd")) {
    //   document.querySelector(".dashboardRightEnd").style.height = height + "px";
    //   // if (!checks) {
    //   //   document.querySelector(".dashboardRightEnd").style.height = "inherit";
    //   // }
    // }
    if (document.querySelector(".dashboardLeft"))
      document.querySelector(".dashboardLeft").style.height = height + "px";
    setheightMain(height);
    mixpanel.identify(userData.email);
    mixpanel.people.increment("Extension - seek apply page visit");
    mixpanel.track("Extension - seek apply page visit", {
      email: userData.email,
    });
    console.log("config", configDummy);
    if (initprocessing.current) initprocessing.current.style.display = "none";
    if (formMain1.current) formMain1.current.style.display = "none";
    if (chooseParaText.current) chooseParaText.current.style.display = "none";
    if (spinner.current) spinner.current.style.display = "none";
    seekReset();
  }, []);

  useEffect(() => {
    const fn = async () => {
      const result = await GrantAccess("seek");
      console.log("grantAccess", result);
      if (!result.access) {
        history.push("/dashboard");
      }
    };
    fn();
  }, []);

  useEffect(() => {
    console.log("Sending initial request to extension");
    const usermain = JSON.parse(window.localStorage.getItem("user") || "{}");
    const tokenmain = window.localStorage.getItem("token");
    if (
      tokenmain != "" &&
      usermain != undefined &&
      Object.keys(usermain).length > 0
    ) {
      if (typeof chrome !== "undefined" && chrome && chrome.runtime)
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            dashboard: true,
            saveValues: {
              token: tokenmain,
              user: usermain,
            },
            injected: scriptInjectedGlobal,
            global: true,
            message: "loginToken",
          },
          (response) => {
            console.log(
              "response from extension",
              response,
              chrome.runtime.lastError
            );
            var lastError = chrome.runtime?.lastError;
            if (lastError) {
              setScriptInjectedGlobal(false);
              setExtensionPresent(false);
              history.push("/dashboard");
              return;
            } else {
              console.log("response of initial request", response);
              setScriptInjectedGlobal(true);
              setGlobalTabId(response.globalTabId);
            }
          }
        );
    }
  }, []);

  const messageFn = useCallback((event) => {
    console.log("seek message", event.data);
    if (
      typeof event.data === "object" &&
      !Array.isArray(event.data) &&
      event.data !== null
    ) {
      console.log("seekmainmessage", event.data);
      h(event.data);
      event.source.postMessage("finished", event.origin);
    }
  }, []);

  useEffect(() => {
    console.log("added");
    window.addEventListener("message", messageFn);
    return () => {
      console.log("removed");
      window.removeEventListener("message", messageFn);
    };
  }, []);

  function saveSession(count, platformName) {
    console.log(formRef.current);
    console.log(saveSessionState.current, "savesessionstate0");
    if (
      (count > 0 && saveSessionState.current == 0) ||
      (count == 0 &&
        saveSessionState.current == 0 &&
        sessionLinksBefore.current.length > 0)
    ) {
      console.log(saveSessionState.current, "savesessionstate1");
      let todaysessioncount = configDummy.todaysessioncount + count;
      let totalJobs = configDummy.totalJobs + count;
      let seekJobsApplied = configDummy.seekJobsApplied + count;
      console.log(configDummy.totalJobs, count, "yooooo");
      let totalsessions = configDummy.totalsessions + 1;
      let totalsessionstoday = configDummy.totalsessionstoday + 1;
      //   totalCountUpdate({
      //     seekJobsApplied: seekJobsApplied,
      //     todaysessioncount: todaysessioncount,
      //     totalJobs: totalJobs,
      //     totalsessions: totalsessions,
      //     totalsessionstoday: totalsessionstoday,
      //   });
      let seekJobApplicationLinksFinal = [...sessionLinks.current];
      if ("seekJobApplicationLinksFinal" in userData) {
        seekJobApplicationLinksFinal = [
          ...userData.seekJobApplicationLinksFinal,
          ...seekJobApplicationLinksFinal,
        ];
      }
      setStreakAnalytics({
        ...streakAnalytics,
        seek: streakAnalytics.seek + count,
      });
      let totalsession = configDummy.totalsession;
      configDummyUpdate({
        ...configDummy,
        seekJobsApplied: seekJobsApplied,
        todaysessioncount: todaysessioncount,
        totalJobs: totalJobs,
        totalsessions: totalsessions,
        totalsessionstoday: totalsessionstoday,
        totalsession: [
          ...totalsession,
          {
            resumeData: resumeData.current,
            sessionLinks: sessionLinks.current,
            sessionLinksBefore: sessionLinksBefore.current,
            platformName: "seek",
            form: formRef.current,
            count: count,
            email: userData.email,
          },
        ],
      });
      userLinksUpdate({
        seekJobApplicationLinksFinal: seekJobApplicationLinksFinal,
      });
      console.log(sessionLinks.current, "current------------");
      // setSaveSession(1);
      saveSessionState.current = 1;
      const v2Id = userData?.planDetails?.v2Id;
      const url = v2Id
        ? `https://backend.lazyapply.com/v2/saveSession/${v2Id}`
        : "http://backend.lazyapply.com/savesessionv2";

      axios
        .post(
          url,
          {
            sessionLinks: sessionLinks.current,
            sessionLinksBefore: sessionLinksBefore.current,
            resumeData: resumeData.current,
            session: {
              count: count,
              platformName: platformName,
              form: formRef.current,
            },
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          seekReset();
          console.log(response);
          setmessage("Process completed!");
          settitle("Success");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2500);
        })
        .catch((error) => {
          seekReset();
          console.log(error);
          if (error.message == "Request failed with status code 403") {
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
              history.push("/");
            }, 1500);
          } else {
            console.log("error");
            setmessage("Some error occured, please try again in some time!");
            settitle("Error");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2500);
          }
        });
    } else {
      saveSessionState.current = 1;
      seekReset();
    }
  }

  const chromeVersion = useStateStore((state) => state.chromeVersion);

  function h(request) {
    if ("seek" in request) {
      if ("message" in request) {
        if (request.message == "Applied successfully") {
          // let countseek = count;
          // countseek++;
          // console.log(countseek)
          console.log("updating count from ", c.current);

          console.log(request.link, "link------------");
          sessionLinks.current.push(request.link);
          ++c.current;
          pp.current = Math.floor(
            (c.current / formRef.current.numberOfJobs) * 100
          );
          setCount(c.current);
          setProgressPercent(pp.current);
          console.log("update count");
        } else if (request.message == "Already Applied Before") {
          sessionLinksBefore.current.push(request.link);
        } else if (request.message == "filterValues") {
          setFilters(request.filterValues);
          console.log("filters", filters);
          if (Object.keys(request.filterValues).length == 0) {
            fetchbutton.current.disabled = false;
            spinner.current.style.display = "none";

            setLinkedinRunning(0);
            // console.log("Try again for better input details");
            if (request.message2 == "signInRequired") {
              setmessage(
                "You're logged out of Seek. Try again after logging in!"
              );
            } else {
              setmessage("Try again with better input details!");
            }
            settitle("No results found");
            setmodalshow(true);
            // messages()[MESSAGES.NOFILTERSFETCHED](
            //   MESSAGETYPE.USER_REPORT_FRONTEND,
            //   MESSAGES.NOFILTERSFETCHED,
            //   `${userData.email},${userData.name},${userData.planDetails.planName}`
            // );
            setTimeout(() => {
              setmodalshow(false);
              if (request.message2 == "signInRequired") {
                // window.open(countryLoginUrlMap[countryCode], "_blank");
                window.location.reload();
              }
            }, 2500);
            resetFull();
          } else {
            setLinkedinRunning(0);
            setformDisable(true);
            //perform all the filter operations

            chooseParaText.current.style.display = "block";
            spinner.current.style.display = "none";

            console.log("making formMain1 visible");
            formMain1.current.style.display = "block";

            buttonFetch.current.style.display = "none";
            if (typeof chrome !== "undefined" && chrome && chrome.runtime)
              chrome.runtime.sendMessage(yourExtensionId, {
                dashboard: true,
                saveValues: {
                  seekFetchFilters: 1,
                },
                seek: true,
                message: "seekFiltersFetched",
              });
            function smoothScrollToBottom() {
              let start = null;
              const initialScrollTop = window.scrollY;
              const targetScrollTop =
                document.body.scrollHeight - window.innerHeight;

              function easeOutCubic(t) {
                return 1 - Math.pow(1 - t, 3);
              }

              function step(timestamp) {
                if (start === null) start = timestamp;
                const elapsed = timestamp - start;
                const progress = Math.min(elapsed / 500, 1); // take 2 seconds to scroll

                const easedProgress = easeOutCubic(progress);
                const newScrollTop =
                  initialScrollTop +
                  (targetScrollTop - initialScrollTop) * easedProgress;

                window.scrollTo(0, newScrollTop);

                if (newScrollTop < targetScrollTop) {
                  window.requestAnimationFrame(step);
                }
              }

              window.requestAnimationFrame(step);
            }

            // Call the function
            smoothScrollToBottom();
          }
        } else if (request.message == "debugSession") {
          fetchbutton.current.disabled = false;
          spinner.current.style.display = "none";
          setLinkedinRunning(0);
          setmessage(
            "We have detected some issue in LazyApply. Our team has been notified and we will fix this issue within next 1 hour. You have to do nothing in your part, this will be automatically fixed. \n\nThank you for being a wonderful customer."
          );
          settitle("Some problem occured");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 20000);
          resetFull();
        }
        if (request.message == "applypage") {
          if ("message2" in request) {
            console.log("Request.message2------>", request.message2);
            if (request.message2 == "completed") {
              console.log("completed full");
              // ++c.current;
              // setCount(c.current);
              setLinkedinRunning(0);
              button1.current.innerText = "Completed";
              processing.current.style.display = "none";
              if (selectResumeRef.current)
                selectResumeRef.current.style.display = "none";
              if (selectResumeRef1.current)
                selectResumeRef1.current.style.display = "none";
              pp.current = 100;
              setProgressPercent(pp.current);
              console.log(form);
              console.log(formMain.current.children);
              if (!window.wasRun) {
                messages()[MESSAGES.ZEROJOBSAPPLIED](
                  MESSAGETYPE.USER_REPORT_FRONTEND_SEEK,
                  MESSAGES.TOTALJOBSAPPLIED,
                  c.current,
                  JSON.stringify({ ...formRef.current }),
                  `${userData.email},total jobs applied this session ${c.current},${userData.name},${userData.planDetails.planName}`,
                  chromeVersion
                );
                saveSession(c.current, "seek");
                window.wasRun = true;
              }
            } else if (
              request.message2 ==
              "No results found, try changing the filter values"
            ) {
              console.log("no results found");
              setmessage("Try again for better input details!");
              settitle("No results found");
              setmodalshow(true);
              setTimeout(() => {
                setmodalshow(false);
              }, 2500);
              setLinkedinRunning(0);
              button1.current.innerText = "Completed";
              processing.current.style.display = "none";
              if (selectResumeRef.current)
                selectResumeRef.current.style.display = "none";
              if (selectResumeRef1.current)
                selectResumeRef1.current.style.display = "none";
              pp.current = 100;
              setProgressPercent(pp.current);
              saveSession(c.current, "seek");
            } else if (request.message2 == "unauthorized") {
              //  alert("unauthorised");
              fetchbutton.current.disabled = false;
              setLinkedinRunning(0);
              console.log("reauthenticate");
              setmessage("Please relogin again!");
              settitle("Not authorized");
              setmodalshow(true);
              setTimeout(() => {
                globalLogout();
                history.push("/");
              }, 1500);
              resetFull();
            }
          }
        }
        if (request.message == "updateLocationUrl") {
          console.log("url final here", request.url);
          setUrlMain(request.url);
        }
      }
    }
  }

  useEffect(() => {
    console.log("ppcurrent", pp.current);
    if (pp.current === 100) {
      if (formMain.current) formMain.current.style.display = "none";
      if (formMain1.current) formMain1.current.style.display = "none";
      if (button1.current) button1.current.style.display = "none";
      if (chooseParaText.current) chooseParaText.current.style.display = "none";
    } else {
      if (formMain.current) formMain.current.style.display = "block";
      if (button1.current) button1.current.style.display = "block";
    }
  }, [pp.current]);

  // const [checks, setChecks] = useState(false);
  // const [exactSearch, setExactSearch] = useState(false);
  // const hideExactSearchModal = () => {
  //   setExactSearchShow(false);
  // };
  // const [exactSearchShow, setExactSearchShow] = useState(false);
  // const [extraSkillValue, setExtraSkillValue] = useState([]);
  // const [extraSkills, setExtraSkills] = useState("");
  // const createOption = (label) => ({
  //   label,
  //   value: label,
  // });
  // const handleKeyDown = (event) => {
  //   if (!extraSkills) return;
  //   switch (event.key) {
  //     case "Enter":
  //     case "Tab":
  //       setExtraSkillValue((prev) => [...prev, createOption(extraSkills)]);
  //       setExtraSkills("");
  //       event.preventDefault();
  //   }
  // };

  return (
    <>
      {/* <ExactModal
        exactSearchShow={exactSearchShow}
        hideExactSearchModal={hideExactSearchModal}
      /> */}
      <div style={{ position: "relative" }} ref={mainheight}>
        <div>
          <MoreFiltersHelp
            openHelper={openHelper}
            setOpenHelper={setOpenHelper}
          />
          <CustomAlert
            message={message}
            s={modalshow}
            title={title}
          ></CustomAlert>
          <p className="startApplying">Start Applying on seek</p>
          {/* <button
            className="buttonGoBack btn buttonSetup"
            onClick={() => setChecks(false)}
          >
            <i className="fas fa-wrench"></i> One Time Setup Steps
          </button> */}
          <button className="buttonGoBack btn" onClick={goBack}>
            <i className="fas fa-arrow-left"></i> Go Back
          </button>
          {userData?.planDetails?.v2Id && (
            <div className="v2resumeSelect" ref={selectResumeRef}>
              <p className="makesure">
                Make sure to login in the seek account of the person with the
                selected resume and his/her seek profile should be completed
                before applying
              </p>
              <Form.Group>
                <Form.Label>Choose a Profile to apply jobs for</Form.Label>
                <Form.Control
                  as="select"
                  id="resumeId"
                  disabled={resumeDisable}
                  isInvalid={resumeChanged}
                  value={resumeId}
                  onChange={(e) => {
                    if (e.target.value != "") {
                      setResumeChanged(false);
                    }

                    userResumeV3.map((key) => {
                      const item = key;
                      if (item.resumeId === e.target.value) {
                        resumeData.current = {
                          resumeId: item.resumeId,
                          resumeName: item.resumename,
                        };
                      }
                    });
                    setResumeId(e.target.value);
                  }}
                >
                  <option value="">Select a resume</option>
                  {userResumeV3.map((key) => {
                    const item = key;
                    if (key.validation == 1)
                      return (
                        <option value={item.resumeId}>{item.resumename}</option>
                      );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please select a resume/cv
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          )}
          <Form onSubmit={handleSubmit} ref={formMain}>
            <Row style={styleform}>
              <Col md={6}>
                <Form.Label htmlFor="inputskill">
                  Enter skill / job title*
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={formDisable}
                  id="inputskill"
                  aria-describedby="passwordHelpBlock"
                  onChange={(e) => setField("skill", e.target.value)}
                  isInvalid={!!errors.skill}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.skill}
                </Form.Control.Feedback>
                <Form.Text id="passwordHelpBlock" muted>
                  For example - Enter only one single skill or job title like
                  react or data analyst or marketing manager etc
                </Form.Text>
              </Col>
              <Col md={6}>
                <Form.Label htmlFor="inputlocation">
                  Enter the job location (optional)
                </Form.Label>
                <Form.Control
                  disabled={formDisable}
                  type="text"
                  id="inputlocation"
                  aria-describedby="passwordHelpBlock"
                  onChange={(e) => setField("location", e.target.value)}
                />
                <Form.Text id="passwordHelpBlock" muted>
                  For example - you can enter your job location like - bangalore
                  or delhi or any other place etc.
                </Form.Text>
              </Col>

              <Col md={6} style={{ margin: "20px 0px" }}>
                <Form.Label>{"Country"}</Form.Label>
                <Select
                  id={"id2"}
                  onChange={(option) => {
                    if (option) {
                      console.log("set url as ", countryUrlMap[option.value]);
                      setCountryCode(option.value);
                    }
                  }}
                  value={countries.filter((x) => x.value === countryCode)}
                  key={"country"}
                  name={"country"}
                  placeholder={"Australia"}
                  options={countries}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </Col>

              <Col md={12}>
                <Form.Label htmlFor="inputnumber">
                  Number of jobs to apply*
                </Form.Label>
                <Form.Control
                  disabled={formDisable}
                  type="number"
                  id="inputnumber"
                  aria-describedby="passwordHelpBlock"
                  onChange={(e) => setField("numberOfJobs", e.target.value)}
                  isInvalid={!!errors.numberOfJobs}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.numberOfJobs}
                </Form.Control.Feedback>
                <Form.Text id="passwordHelpBlock" muted>
                  For example - 50 or 100 etc.
                </Form.Text>
              </Col>

              {/* <div className="advanceSearchContainer">
                <div className="advanceSearchContainerLeft">Advance Search</div>
                <div className="advanceSearchContainerRight">
                  <div className="exactsearch">
                    <div className="exactsearchchild">
                      <p
                        style={{
                          color: "#007bff",
                          marginBottom: "0px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          cursor: "pointer",
                        }}
                      >
                        {advanceSearch ? (
                          <span onClick={() => setShowAdvanceSearch(true)}>
                            Configure
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <label
                        className={
                          "switch3" + (advanceSearch ? " switch3-checked" : "")
                        }
                      >
                        <input
                          type="checkbox"
                          checked={advanceSearch}
                          onChange={(e) => {
                            setAdvanceSearch(!advanceSearch);
                          }}
                        />
                        <div></div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <AdvanceSearchModal
                setMustIncludeAnyOne={setMustIncludeAnyOne}
                setMustInclude={setMustInclude}
                setExcludeKeywords={setExcludeKeywords}
                handleClose={handleCloseSearch}
                show={showAdvanceSearch}
                mustInclude={mustInclude}
                mustIncludeAnyOne={mustIncludeAnyOne}
                excludeKeywords={excludeKeywords}
              /> */}

              <Col md={12} ref={buttonFetch}>
                <button
                  type="submit"
                  className="btn linkedinSubmitButton"
                  ref={fetchbutton}
                >
                  Click to fetch filters first
                  <i className="fas fa-arrow-right"></i>{" "}
                  <span
                    className="spinner-border spinner-border-sm"
                    style={{ marginLeft: "5px" }}
                    role="status"
                    aria-hidden="true"
                    ref={spinner}
                  ></span>
                </button>
              </Col>
            </Row>
          </Form>
          <p ref={chooseParaText} style={{ marginLeft: "20px", color: "gray" }}>
            Choose one or more filters (optional)
          </p>
          <Form onSubmit={handleSubmit1} ref={formMain1}>
            <Row style={filtersStyle}>
              {Object.keys(filters).map((key, index) => (
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>{key}</Form.Label>
                    <Select
                      id={key}
                      onChange={(option) => {
                        console.log("onchange", option);
                        console.log("key", key);
                        if (option) {
                          let customKey =
                            filters[key].value === "salaryrange"
                              ? key
                              : filters[key].value;
                          urlParts[customKey] = option.value;
                          console.info("url-parts", urlParts);
                        }
                      }}
                      key={index}
                      name={key}
                      placeholder={key}
                      ref={filterRefs[index]}
                      options={filters[key].options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Form.Group>
                </Col>
              ))}
            </Row>
            <Row>
              <Col md={12}>
                <button
                  type="submit"
                  ref={button1}
                  disabled={startApplying}
                  className="btn linkedinSubmitButton"
                >
                  Start Applying <i className="fas fa-arrow-right"></i>
                </button>
              </Col>
            </Row>
          </Form>
          <Row style={{ margin: "10px auto", padding: "0 5px" }}>
            {pp.current === 100 ? (
              <Col md={5} style={{ marginBottom: "20px" }}>
                <Feedback platformName="seek" totalJobsApplied={c.current} />
              </Col>
            ) : (
              <Col md={5}></Col>
            )}
            <Col md={7} ref={initprocessing} className="linkedinInit">
              <button
                type="button"
                className="linkedinReset btn"
                onClick={resetFull}
              >
                Reset
              </button>
              <div className="linkedinInitTop">
                <div ref={processing} class="processing">
                  Processing ...
                </div>
              </div>
              <div className="linkedinInitTop">
                <div>Percentage complete ({pp.current} %)</div>
                <div>Total jobs applied ({c.current})</div>
              </div>
              <div className="linkedinInitTop">
                <div>
                  <Progress done={pp.current} />
                </div>
                <div className="jobsApplied">{c.current}</div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Seek;
