import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useStateStore } from "../../store";
import { mixpanel } from "../../index";

function PeopleBlock({
  employee = {},
  setSendEmail = () => {},
  showSendEmailButton = true,
  EmailDataComponent = <></>,
}) {
  const userData = useStateStore((state) => state.userData);
  const setShowEmailPermissionModal = useStateStore(
    (state) => state.setShowEmailPermissionModal
  );
  const stepNoReferralFeedback = useStateStore(
    (state) => state.stepNoReferralFeedback
  );
  const [isAllowed, setIsAllowed] = useState(false);
  const referralInitData = useStateStore((state) => state.referralInitData);
  const setEmployeeSelected = useStateStore(
    (state) => state.setEmployeeSelected
  );
  const imgSvg = (gender) => {
    return gender === "male"
      ? "/assests/male.svg"
      : gender === "female"
      ? "/assests/female.svg"
      : "/assests/neutral.webp";
  };

  useEffect(() => {
    console.log(
      "refe",
      referralInitData,
      referralInitData?.emailConnected && referralInitData.emailConnected
    );
    if (referralInitData?.emailConnected && referralInitData.emailConnected) {
      setIsAllowed(true);
    }
  }, [referralInitData]);

  return (
    <div className="main-referral-container main-referral-container-peoplelist">
      <div className="main-referral-top">
        <div className="main-referral-top-first">
          <img
            alt="employee_avataar"
            src={imgSvg(employee.gender)}
            className="navbarImg"
          ></img>
        </div>
        <div className="main-referral-top-second">
          <div className="main-referral-company-name">
            {employee.fullName}{" "}
            <i
              onClick={() => {
                mixpanel.identify(userData.email);
                mixpanel.people.increment(
                  `employee_linkedin_link secret_network_popup step_${stepNoReferralFeedback} dashboard`
                );
                mixpanel.track(
                  `employee_linkedin_link secret_network_popup step_${stepNoReferralFeedback} dashboard`,
                  {
                    email: userData?.email,
                    plan_details: userData?.planDetails || {},
                    v2Id: userData?.planDetails?.v2Id || "",
                  }
                );

                window.open(employee.linkedinUrl, "_blank");
              }}
              className="fab fa-linkedin"
            ></i>
          </div>
          {EmailDataComponent}
          <div className="main-referral-company-domain">
            {/* {employee.email} */}

            {employee.locationCountry}
          </div>
          <div className="main-referral-company-otherdetails">
            {employee.jobTitle}
          </div>
        </div>
        {showSendEmailButton && (
          <div className="main-referral-top-third">
            <button
              onClick={() => {
                mixpanel.identify(userData.email);
                mixpanel.people.increment(
                  "send_email_button secret_network_popup step_1 dashboard"
                );
                mixpanel.track(
                  "send_email_button secret_network_popup step_1 dashboard",
                  {
                    email: userData?.email,
                    plan_details: userData?.planDetails || {},
                    v2Id: userData?.planDetails?.v2Id || "",
                  }
                );

                if (isAllowed) {
                  setSendEmail(true);
                  setEmployeeSelected(employee);
                } else {
                  setEmployeeSelected(employee);
                  setShowEmailPermissionModal(true);
                }
              }}
            >
              Send Email
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PeopleBlock;
