import axios from "axios";
import React, { useState, useEffect } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { useStateStore } from "../../store";

function FeedbackPopup({ s, closeFn }) {
  const [show, setShow] = useState(false);
  const [feedbackLoader, setFeedbackLoader] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const token = useStateStore((state) => state.token);
  const userData = useStateStore((state) => state.userData);
  const stepNoReferralFeedback = useStateStore(
    (state) => state.stepNoReferralFeedback
  );

  const handleClose = () => {
    setShow(false);
    closeFn();
  };

  useEffect(() => {
    setShow(s);
  }, [s]);

  const submitFeedback = async () => {
    setFeedbackLoader(true);
    const feedbackResponse = await axios.post(
      `https://backend.lazyapply.com/referral/feedback`,
      {
        feedback_text: feedbackText,
        step_no: stepNoReferralFeedback,
        v2Id: userData?.planDetails?.v2Id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setFeedbackText("");
    console.log("response", feedbackResponse);
    setFeedbackLoader(false);
    handleClose();
  };

  return (
    <div>
      <Modal
        className="main-referral-modal"
        show={show}
        onHide={handleClose}
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton className="main-referral-modal-header">
          <Modal.Title>Secret Network Feedback </Modal.Title>
        </Modal.Header>
        <Modal.Body className="main-referral-modal-feedback-body">
          <Form.Label>
            <p>Any suggestions / what went wrong / what should we improve ?</p>
          </Form.Label>
          <Form.Control
            value={feedbackText}
            as="textarea"
            placeholder="Type your issue, suggestion, feedback here..."
            rows={3}
            onChange={(e) => setFeedbackText(e.target.value)}
          />
          <button
            onClick={() => submitFeedback()}
            className="main-referral-modal-feedback-submit-button"
          >
            Submit Feedback
            {feedbackLoader && (
              <Spinner
                animation="border"
                size="sm"
                style={{ marginLeft: "10px" }}
              />
            )}
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default FeedbackPopup;
