/*global chrome*/
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useMultipleRefs } from "./customHooks";
import { Form, Col, Row, FormCheck } from "react-bootstrap";
import "./jobplatform.css";
import { useHistory } from "react-router-dom";
import Progress from "./progress";
import axios from "axios";
import { useStateStore } from "../../store/index";
import CustomAlert from "../modal";
import { mixpanel } from "../../index";
import Select from "react-select";
import Feedback from "../feedback";
import MoreFiltersHelp from "../morefiltershelp";
import { v1 as uuidv1 } from "uuid";
import StepsWithCut from "../stepswithcut";
// import CreatableSelect from "react-select/creatable";
import { MESSAGETYPE, messages, MESSAGES } from "./slackMessages";
import AdvanceSearchModal from "./AdvanceSearchModal";
import { GrantAccess } from "./grantAccess";
import SaveSearchModal from "./saveSearchModal";
import GetSavedSearches from "./getSearches";
import UploadResume from "./uploadResume";
import faqObj from "./faqJson";
import IndeedArticle from "./indeedArticle";
import ExtensionInstallPopup from "./ExtensionInstallPopup";
import MainWatchDemoModal from "./MainWatchDemoModal";
import mainFilters from "../filters.json";
import ResumeJobScore from "./resumeJobScore/resumeJobScore";

// import ExactModal from "./exactModal";
const GlassDoor = () => {
  const setScriptInjectedGlobal = useStateStore(
    (state) => state.setScriptInjectedGlobal
  );
  const scriptInjectedGlobal = useStateStore(
    (state) => state.scriptInjectedGlobal
  );
  const setExtensionPresent = useStateStore(
    (state) => state.setExtensionPresent
  );
  const setUserResumeV3 = useStateStore((state) => state.setUserResumeV3);
  const [showPlanRequired, setShowPlanRequired] = useState(false);
  const { availableChromeVersion } = useStateStore.getState();
  const [showPlanPurchase, setShowPlanPurchase] = useState(false);
  const [showDemoPopup, setShowDemoPopup] = useState(false);
  const [showExtensionInstallPopup, setShowExtensionInstallPopup] =
    useState(false);
  const [showUpdateExtension, setShowUpdateExtension] = useState(false);
  const yourExtensionId = useStateStore((state) => state.yourExtensionId);
  const setGlobalTabId = useStateStore((state) => state.setGlobalTabId);
  const setStreakAnalytics = useStateStore((state) => state.setStreakAnalytics);
  const streakAnalytics = useStateStore((state) => state.streakAnalytics);
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);
  const [selectedSearchData, setSelectedSearchData] = useState({
    searchId: "",
    searchName: "",
  });
  const [searchInputValue, setSearchInputValue] = useState({
    value: "",
    label: "Select a saved search setting",
  });
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [showSavedSearchModal, setShowSavedSearchModal] = useState(false);
  const [showSavedSearchButton, setShowSavedSearchButton] = useState(true);
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [excludeString, setExcludeString] = useState("");
  const [mayIncludeString, setMayIncludeString] = useState("");
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const handleCloseSearch = () => {
    setShowAdvanceSearch(false);
  };

  const closeModal = () => {
    setShowIndeedModal(false);
  };

  let filterValues = useRef({});
  let filterRefs = useMultipleRefs(12);
  let urlParts = {};

  let buttonMain1 = {
    backgroundColor: "#35AD46",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "10px 10px",
    textAlign: "center",
    borderRadius: "10px",
    outline: "none",
    border: "none",
  };

  let buttonMain = {
    backgroundColor: "grey",
    color: "white",
    cursor: "default",
    fontSize: "16px",
    padding: "10px 10px",
    textAlign: "center",
    borderRadius: "10px",
    outline: "none",
    border: "none",
  };

  useEffect(() => {
    if (advanceSearch) {
      setShowAdvanceSearch(true);
    }
  }, [advanceSearch]);

  const formMain1 = useRef(null);
  const selectResumeRef = useRef(null);
  const selectResumeRef1 = useRef(null);
  const chooseParaText = useRef(null);
  const [formDisable, setformDisable] = useState(false);
  const spinner = useRef(null);
  const fetchbutton = useRef(null);
  const button1 = useRef(null);
  const [disable2, setdisable2] = useState(0);
  const saveSessionState = useRef(0);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const globalLogout = useStateStore((state) => state.globalLogout);
  const history = useHistory();
  const userData = useStateStore((state) => state.userData);
  const userLinksUpdate = useStateStore((state) => state.userLinksUpdate);
  const configDummy = useStateStore((state) => state.configDummy);
  const configDummyUpdate = useStateStore((state) => state.configDummyUpdate);
  const [startApplying, setstartApplying] = useState(false);
  const sessionLinks = useRef([]);
  const sessionLinksBefore = useRef([]);
  const [progressPercent, setProgressPercent] = useState(15);
  const [count, setCount] = useState(0);
  const totalCountUpdate = useStateStore((state) => state.totalCountUpdate);
  const formRef = useRef({});
  const [finalJobCount, setFinalJobCount] = useState(0);
  const c = useRef(0);
  const pp = useRef(15);
  const processing = useRef(null);
  const initprocessingFinal = useRef(null);
  const initprocessing = useRef(null);
  const formMain = useRef(null);
  const buttonFetch = useRef(null);
  const setLinkedinRunning = useStateStore((state) => state.setLinkedinRunning);
  const questionRefs = useRef([]);
  const [showIndeedModal, setShowIndeedModal] = useState(null);
  const [modalTitle, setModalTitle] = useState("Issue");
  const [form, setForm] = useState({
    skill: "",
    location: "",
    numberOfJobs: 0,
    resumeJobScore: 40,
  });
  const token = useStateStore((state) => state.token);
  const [errors, setErrors] = useState({});
  const [filter, setFilter] = useState(0);
  const userResumeV3 = useStateStore((state) => state.userResumeV3);
  const [resumeId, setResumeId] = useState("");
  const [resumeUrlId, setResumeUrlId] = useState("");
  const resumeData = useRef({});
  const [openHelper, setOpenHelper] = useState(false);
  const [resumeChanged, setResumeChanged] = useState(false);
  const [resumeUrlChanged, setResumeUrlChanged] = useState(false);
  const [countryCode, setCountryCode] = useState("us");

  let countries = [
    { label: "United States", value: "us" },
    { label: "United Kingdom", value: "uk" },
    { label: "Canada", value: "ca" },
    { label: "Australia", value: "au" },
    { label: "India", value: "in" },
    { label: "Singapore", value: "sg" },
    { label: "New Zealand", value: "nz" },
    { label: "Ireland", value: "ie" },
    { label: "New Zealand", value: "nz" },
    { label: "Ireland", value: "ie" },
  ];
  let countryUrlMap = {
    us: "https://www.glassdoor.com/Job/",
    uk: "https://www.glassdoor.co.uk/Job/",
    ca: "https://www.glassdoor.ca/Job/",
    au: "https://www.glassdoor.com.au/Job/",
    in: "https://www.glassdoor.co.in/Job/",
    sg: "https://www.glassdoor.sg/Job/",
    nz: "https://www.glassdoor.co.nz/Job/",
    ie: "https://www.glassdoor.ie/Job/",
  };

  const sessionId = useRef(null);

  // const labelsMap = {"All Job Types: "aaas"};
  function smoothScrollToTop() {
    let start = null;
    const initialScrollTop = window.scrollY;

    function easeOutCubic(t) {
      return 1 - Math.pow(1 - t, 3);
    }

    function step(timestamp) {
      if (start === null) start = timestamp;
      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / 1000, 1); // take 2 seconds to scroll

      const easedProgress = easeOutCubic(progress);
      const newScrollTop = initialScrollTop * (1 - easedProgress);

      window.scrollTo(0, newScrollTop);

      if (newScrollTop > 0) {
        window.requestAnimationFrame(step);
      }
    }

    window.requestAnimationFrame(step);
  }

  const setField = (field, value) => {
    console.log(field, value);
    setForm({
      ...form,
      [field]: value,
    });

    // Check and see if errors exist, and remove them from the error object:
    console.log(form);

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const handleSubmit = async (e) => {
    console.log("handle submit");
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    console.log(newErrors);
    // Conditional logic:
    if (userData?.planDetails?.v2Id) {
      //v2
      if (resumeId === "" && Object.keys(userResumeV3).length > 0) {
        setResumeChanged(true);
      }
      // } else if (resumeUrlId === "") {
      //   setResumeUrlChanged(true);
      // }
      else if (Object.keys(newErrors).length > 0) {
        // We got errors!
        setErrors(newErrors);
      } else {
        // No errors! Put any logic here for the form submission!
        if (await fetchFilterUserHandler()) {
          startLinkedinAutomation1();
        }
        // startLinkedinAutomation();
      }
    } else {
      if (Object.keys(newErrors).length > 0) {
        // We got errors!
        setErrors(newErrors);
      } else {
        // No errors! Put any logic here for the form submission!
        if (await fetchFilterUserHandler()) {
          startLinkedinAutomation1();
        }
        // startLinkedinAutomation();
      }
    }
  };

  const findFormErrors = () => {
    const { skill, numberOfJobs } = form;
    const newErrors = {};
    // name errors
    if (!skill || skill === "") newErrors.skill = "Please enter a skill!";
    if (numberOfJobs <= 0)
      newErrors.numberOfJobs = "Please enter the no. of jobs to apply";

    if (numberOfJobs < 20 && configDummy.planName != "free") {
      if (configDummy.dailyLimit - configDummy.todaysessioncount <= 20) {
        console.log("go for it");
      } else {
        newErrors.numberOfJobs = "Please enter a value more than 35";
      }
    } else if (numberOfJobs < 35 && configDummy.planName == "free") {
      if (configDummy.dailyLimit - configDummy.todaysessioncount < 35) {
        console.log("go for it");
      } else {
        newErrors.numberOfJobs = "Please enter a value more than 35";
      }
    }

    return newErrors;
  };

  const styleform = {
    padding: "20px",
    paddingTop: "0px",
  };

  const filtersStyle = {
    padding: "20px",
    boxShadow: "0px 2px 4px 2px lightgrey, 0px 2px 4px 2px #efefef",
    margin: "5px",
    position: "relative",
  };

  function goBack() {
    console.log("go back");
    mixpanel.identify(userData.email);
    mixpanel.people.increment("go_back_button glassdoor_page dashboard");
    mixpanel.track("go_back_button dashboard", {
      href: window.location.href,
      email: userData?.email,
      platformName: "glassdoor",
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    history.goBack();
  }
  const [urlMain, setUrlMain] = useState("");

  const stringToArray = (string) => {
    return string
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s.length > 0);
  };

  function startLinkedinAutomation1() {
    spinner.current.style.display = "inline-block";

    if (
      configDummy.dailyLimit - configDummy.todaysessioncount >=
        form.numberOfJobs ||
      !userData.planDetails.planStarted
    ) {
      fetchbutton.current.disabled = true;
      formRef.current = {
        ...form,
      };

      console.log(form.skill + "skill");

      const skills = form.skill.split(" ");
      let string1 = "";
      for (let index = 0; index < skills.length; index++) {
        const element = skills[index];
        if (index == 0) {
          string1 += element;
        } else {
          string1 += "-" + element;
        }
      }

      string1 += `-jobs-SRCH_KO0,${string1.length}.htm`;
      let url = "";
      url = countryUrlMap[countryCode] + `${string1}?applicationType=1`;
      console.log("url, string1", url, string1);
      url = countryUrlMap[countryCode] + `${string1}?applicationType=1`;
      console.log("url, string1", url, string1);
      setUrlMain(url);
      setLinkedinRunning(1);
      console.log("resumeid", resumeUrlId);

      mixpanel.identify(userData?.email);
      mixpanel.people.increment(
        "click_to_fetch_filter_button glassdoor_page dashboard"
      );
      mixpanel.track("click_to_fetch_filter_button glassdoor_page dashboard", {
        href: window.location.href,
        email: userData?.email,
        skill: form.skill,
        location: form.location,
        numberOfJobs: form.numberOfJobs,
        excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
        mayIncludeKeywords: advanceSearch
          ? stringToArray(mayIncludeString)
          : [],
        plan_details: userData?.planDetails || {},
        v2Id: userData?.planDetails?.v2Id || "",
      });
      if (typeof chrome !== "undefined" && chrome && chrome.runtime)
        chrome.runtime.sendMessage(yourExtensionId, {
          dashboard: true,
          saveValues: {
            excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
            mayIncludeKeywords: advanceSearch
              ? stringToArray(mayIncludeString)
              : [],
            glassdoorFetchFilters: 0,
            glassdoorLimit: Math.min(form.numberOfJobs, 200),
            glassdoorJobLinks: [],
            glassdoorResumeUrlId: resumeUrlId,
            glassdoorLocation: form.location,
            glassdoorCountryCode: countryCode,
            uniquesessionid: uuidv1(),
            resumeJobScore: form.resumeJobScore,
            userProvidedJobTitle: form.skill,
            unappliedJobsDueToScore: 0,
          },
          glassdoor: true,
          message: "glassdoorFetchFilters",
          data: {
            url: `${url}`,
            selected: true,
            glassdoor: true,
            message: "tabid",
          },
        });
    } else {
      spinner.current.style.display = "none";
      if (configDummy.dailyLimit - configDummy.todaysessioncount == 0) {
        setmessage("You have used your daily limit! Come back tomorrow :)");
        settitle("Daily limit exceeded!");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 2500);
        setTimeout(() => {
          history.push("/dashboard");
        }, 3000);
      } else {
        setmessage(
          `You have applied to ${
            configDummy.todaysessioncount
          } jobs today. Please enter a value less than ${
            configDummy.dailyLimit - configDummy.todaysessioncount
          }`
        );
        settitle("Daily limit update!");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 2500);
      }
    }
  }

  const closeExtensionInstallPopup = () => {
    setShowExtensionInstallPopup(false);
  };

  const onClickWatchDemo = () => {
    setShowExtensionInstallPopup(false);
    setShowDemoPopup(true);
  };

  let chromeExtensionPresent = async () => {
    let extensionPresentMain = 0;
    if (typeof chrome !== "undefined" && chrome && chrome.runtime) {
      console.log("check1");
      extensionPresentMain = await new Promise((resolve, reject) => {
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            lazyapply: "lazyapply",
            message: "check",
            injected: scriptInjectedGlobal,
          },
          function (response) {
            // Do whatever you want, background script is ready now
            var lastError = chrome.runtime.lastError;
            if (lastError) {
              console.log("error", lastError.message);

              // 'Could not establish connection. Receiving end does not exist.'
              resolve(0);
            }
            console.log("check0", response);
            if ("status" in response && response.status == "installed") {
              setScriptInjectedGlobal(true);
              setExtensionPresent(true);
              console.log("check2");

              resolve(1);
            }
          }
        );
      });
      console.log("extensionpresent", extensionPresentMain);
      return extensionPresentMain;
    } else {
      extensionPresentMain = 0;
      return extensionPresentMain;
    }
  };

  const fetchFilterUserHandler = async () => {
    if (userData.planDetails.planStarted) {
      const response = await GrantAccess("linkedin");
      if (!response.access) {
        if (response.resultObj.extensionNotPresent) {
          setShowExtensionInstallPopup(true);
        } else if (response.resultObj.updateExtension) {
          setShowUpdateExtension(true);
          setShowExtensionInstallPopup(true);
        } else {
          setmessage(response.resultObj.message);
          settitle(response.resultObj.title);
          setmodalshow(true);
          if (response?.resultObj?.gotolinknew) {
            window.open(response.resultObj.gotolinknew, "_blank");
          }
          if (response?.resultObj?.gotolink) {
            history.push(response.resultObj.gotolink);
          }
        }
        return 0;
      } else {
        return 1;
      }
    } else {
      if (resumeId == "") setResumeId("demo_id_lazyapply");
      const extensionPresent = await chromeExtensionPresent();
      console.log("heree111", extensionPresent);
      if (!extensionPresent) {
        setShowExtensionInstallPopup(true);
        return 0;
      } else if (
        chromeVersion != availableChromeVersion &&
        chromeVersion != -1 &&
        availableChromeVersion != -1
      ) {
        setShowExtensionInstallPopup(true);
        setShowUpdateExtension(true);
        return 0;
      } else {
        return 1;
      }
    }
  };

  const [resumeDisable, setResumeDisable] = useState(false);
  function startLinkedinAutomation() {
    setLinkedinRunning(1);
    setstartApplying(true);
    formRef.current = {
      ...form,
    };
    saveSessionState.current = 0;
    setResumeDisable(true);
    button1.current.style.backgroundColor = "grey";
    button1.current.style.color = "black";
    button1.current.innerText = "Process Started!";
    console.log("Filters selected according to which  now everything will run");

    const finalUrl = urlMain;

    console.log(
      finalUrl,
      "final url --------------------------------glassdoor"
    );

    initprocessing.current.style.display = "flex";
    smoothScrollToBottom();
    sessionId.current = uuidv1();
    console.log("current sessionId", sessionId.current);

    mixpanel.identify(userData?.email);
    mixpanel.people.increment("start_applying_button glassdoor_page dashboard");
    mixpanel.track("start_applying_button glassdoor_page dashboard", {
      href: window.location.href,
      email: userData?.email,
      skill: form.skill,
      location: form.location,
      numberOfJobs: form.numberOfJobs,
      excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
      mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
      formData: formRef.current,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    if (typeof chrome !== "undefined" && chrome && chrome.runtime)
      chrome.runtime.sendMessage(yourExtensionId, {
        dashboard: true,
        saveValues: {
          glassdoorSessionId: sessionId.current,
          excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
          mayIncludeKeywords: advanceSearch
            ? stringToArray(mayIncludeString)
            : [],
          glassdoorBaseUrl: finalUrl,
          glassdoorLimit: Math.min(form.numberOfJobs, 200),
          glassdoorJobLinks: [],
          glassdoorResumeId: resumeId,
          session: {
            platformName: "glassdoor",
            formData: formRef.current,
          },
          resumeData: resumeData.current,
          glassdoorResumeUrlId: resumeUrlId,
          glassdoorSetFilters: urlParts,
        },
        glassdoor: true,
        message: "glassdoorStartApplying",
        data: {
          message: "setBaseUrl",
          url: finalUrl,
        },
      });
  }

  const handleSubmit1 = (e) => {
    e.preventDefault();
    console.log("Filter Submit", form);
    startLinkedinAutomation();
  };

  function filterReset() {
    urlParts = {};
    for (let ref of filterRefs) {
      if (ref.current && ref.current.select) {
        ref.current.select.clearValue();
      }
    }
    filterValues.current = {};
    setForm({
      ...form,
    });
  }

  function glassdoorReset() {
    console.log("glassdoor Reset");
    if (typeof chrome !== "undefined" && chrome && chrome.runtime)
      chrome.runtime.sendMessage(yourExtensionId, {
        dashboard: true,
        glassdoor: true,
        message: "resetglassdoor",
      });
  }

  function getUnappliedJobsDueToResumeScore() {
    console.log("Unapplied jobs due to resume score");

    if (typeof chrome !== "undefined" && chrome && chrome.runtime)
      chrome.runtime.sendMessage(
        yourExtensionId,
        {
          dashboard: true,
          glassdoor: true,
          message: "totalUnappliedJobsDueToResumeScore",
        },
        function (response) {
          if (chrome.runtime.lastError) {
            console.error(chrome.runtime.lastError);
          } else {
            console.log("Received response:", response);
          }
        }
      );
  }

  function resetFull() {
    mixpanel.identify(userData?.email);
    mixpanel.people.increment("reset_button glassdoor_page dashboard");
    mixpanel.track("reset_button dashboard", {
      href: window.location.href,
      email: userData?.email,
      platformName: "glassdoor",
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    if (saveSessionState.current == 0) {
      saveSession(c.current, "glassdoor");
    }
    resumeData.current = {};
    window.wasRun = false;
    setResumeId("");
    setShowPlanPurchase(false);
    setResumeUrlId("");
    setResumeDisable(false);
    if (selectResumeRef.current)
      selectResumeRef.current.style.display = "block";
    if (selectResumeRef1.current)
      selectResumeRef1.current.style.display = "block";
    chooseParaText.current.style.display = "none";
    setformDisable(false);
    setCountryCode("us");
    setLinkedinRunning(0);
    // setExactSearch(false);
    // setExtraSkillValue([]);
    // setExtraSkills("");
    formMain1.current.style.display = "none";
    sessionLinks.current = [];
    sessionLinksBefore.current = [];
    fetchbutton.current.disabled = false;
    buttonFetch.current.style.display = "block";
    setShowSavedSearchButton(true);
    setForm({
      skill: "",
      location: "",
      numberOfJobs: 0,
      resumeJobScore: 40,
    });
    setAdvanceSearch(false);
    setMayIncludeString("");
    setExcludeString("");
    setSelectedSearchData({
      searchId: "",
      searchName: "",
    });
    setSearchInputValue({
      value: "",
      label: "Select a saved search setting",
    });
    glassdoorReset();
    filterReset();
    formRef.current = {};
    document.getElementById("inputskill").value = "";
    document.getElementById("inputlocation").value = "";
    document.getElementById("inputnumber").value = "";
    document.getElementById("sliderResumeJobScore").value = 40;
    c.current = 0;
    pp.current = 15;
    button1.current.style.backgroundColor = "#F8F9FA";
    button1.current.style.color = "#007bff";
    button1.current.innerText = "Start Applying";
    setProgressPercent(15);
    initprocessingFinal.current.style.display = "none";
    initprocessing.current.style.display = "none";
    formMain.current.reset();
    setstartApplying(false);
    setCount(0);
  }

  const [resumefetch, setresumefetch] = useState(false);
  const fetchAllResumes = (v2Id) => {
    axios
      .get(
        `https://backend.lazyapply.com/v2/resumesV3/checkResumesValidation/${v2Id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setresumefetch(true);
        setUserResumeV3(response.data.resumes);
      })
      .catch((err) => {
        setresumefetch(true);
        console.log("some error occured");
      });
  };

  useEffect(() => {
    const v2Id = userData?.planDetails?.v2Id;
    if (v2Id) fetchAllResumes(v2Id);
  }, [userData]);

  useEffect(() => {
    if (form.skill != "" && form.numberOfJobs > 0 && resumeId != "") {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
  }, [form, resumeId]);

  useEffect(() => {
    console.log(
      "selected search data --> do processing here",
      selectedSearchData
    );
    if (selectedSearchData.form) {
      setForm((formData) => {
        let newFormData = {
          ...formData,
          numberOfJobs: selectedSearchData.form.numberOfJobs,
          skill: selectedSearchData.form.skill,
          location: selectedSearchData.form.location,
        };
        return newFormData;
      });
    } else {
      setForm((formData) => {
        let newFormData = {
          ...formData,
          numberOfJobs: 0,
          skill: "",
          location: "",
        };
        return newFormData;
      });
    }
    if (selectedSearchData.advanceSearch) {
      setAdvanceSearch(selectedSearchData.advanceSearch.advanceSearch);
      setExcludeString(selectedSearchData.advanceSearch.excludeString);
      setMayIncludeString(selectedSearchData.advanceSearch.mayIncludeString);
    } else {
      setAdvanceSearch(false);
      setExcludeString("");
      setMayIncludeString("");
    }
    if (selectedSearchData.extraConfig) {
      if (selectedSearchData.extraConfig.resumeId) {
        const found = userResumeV3.filter((key) => {
          return key.resumeId == selectedSearchData.extraConfig.resumeId;
        });
        if (found.length > 0) {
          userResumeV3.map((key) => {
            const item = key;
            if (item.resumeId === selectedSearchData.extraConfig.resumeId) {
              resumeData.current = {
                resumeId: item.resumeId,
                resumeName: item.resumename,
              };
            }
          });
          setResumeId(selectedSearchData.extraConfig.resumeId);
        } else {
          setResumeId("");
        }
      }
      if (selectedSearchData.extraConfig.countryCode) {
        setCountryCode(selectedSearchData.extraConfig.countryCode);
      } else {
        setCountryCode("us");
      }
    } else {
      setResumeId("");
      setCountryCode("us");
    }
  }, [selectedSearchData]);

  const setheightMain = useStateStore((state) => state.setheightMain);
  const mainheight = useRef(null);
  useEffect(() => {
    let height = 0;
    // const checking = localStorage.getItem("stepcheckglassdoor");
    // if (checking && checking == "true") {
    //   setChecks(true);
    // }
    if (mainheight) height = mainheight.current.scrollHeight;
    // if (document.querySelector(".dashboardRightEnd")) {
    //   document.querySelector(".dashboardRightEnd").style.height = height + "px";
    //   // if (!checks) {
    //   //   document.querySelector(".dashboardRightEnd").style.height = "inherit";
    //   // }
    // }
    if (document.querySelector(".dashboardLeft"))
      document.querySelector(".dashboardLeft").style.height = height + "px";
    setheightMain(height);
    mixpanel.identify(userData.email);
    mixpanel.people.increment("glassdoor_page dashboard");
    mixpanel.track("glassdoor_page dashboard", {
      href: window.location.href,
      email: userData?.email,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    console.log("config", configDummy);
    if (initprocessingFinal.current)
      initprocessingFinal.current.style.display = "none";
    if (initprocessing.current) initprocessing.current.style.display = "none";
    if (formMain1.current) formMain1.current.style.display = "none";
    if (chooseParaText.current) chooseParaText.current.style.display = "none";
    if (spinner.current) spinner.current.style.display = "none";
    glassdoorReset();
  }, []);

  const demoSubmit = () => {
    mixpanel.track("start_auto_apply_button dashboard", {
      href: window.location.href,
      email: userData?.email,
      platformName: "glassdoor",
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    setTimeout(() => {
      history.push("/pricing");
    }, 200);
  };

  const showFilters = () => {
    filterValues.current = mainFilters.data.glassdoor;
    setLinkedinRunning(0);
    chooseParaText.current.style.display = "block";
    spinner.current.style.display = "none";
    formMain1.current.style.display = "block";
    setShowSavedSearchButton(false);
    buttonFetch.current.style.display = "none";
    smoothScrollToBottom();
  };

  const checkIfDemoAndNoPlan = () => {
    return !userData.planDetails.planStarted && showDemo;
  };

  const showDemo = useStateStore((state) => state.showDemo);
  useEffect(() => {
    const fn = async () => {
      const result = await GrantAccess("glassdoor");
      console.log("grantAccess", result);
      if (!result.access && !showDemo) {
        history.push("/dashboard");
      } else {
        if (checkIfDemoAndNoPlan()) {
          showFilters();
        }
      }
    };
    fn();
  }, [userData]);

  useEffect(() => {
    console.log("Sending initial request to extension");
    const usermain = JSON.parse(window.localStorage.getItem("user") || "{}");
    const tokenmain = window.localStorage.getItem("token");
    if (
      tokenmain != "" &&
      usermain != undefined &&
      Object.keys(usermain).length > 0
    ) {
      if (typeof chrome !== "undefined" && chrome && chrome.runtime)
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            dashboard: true,
            saveValues: {
              token: tokenmain,
              user: usermain,
            },
            injected: scriptInjectedGlobal,
            global: true,
            message: "loginToken",
          },
          (response) => {
            console.log(
              "response from extension",
              response,
              chrome.runtime.lastError
            );
            var lastError = chrome.runtime?.lastError;
            if (lastError) {
              setScriptInjectedGlobal(false);
              setExtensionPresent(false);
              history.push("/dashboard");
              return;
            } else {
              console.log("response of initial request", response);
              setScriptInjectedGlobal(true);
              setGlobalTabId(response.globalTabId);
            }
          }
        );
    }
  }, []);

  const messageFn = useCallback((event) => {
    console.log("glassdoor message", event.data);
    if (
      typeof event.data === "object" &&
      !Array.isArray(event.data) &&
      event.data !== null
    ) {
      console.log("glassdoormainmessage", event.data);
      h(event.data);
      event.source.postMessage("finished", event.origin);
    }
  }, []);

  useEffect(() => {
    console.log("added");
    window.addEventListener("message", messageFn);
    return () => {
      console.log("removed");
      window.removeEventListener("message", messageFn);
    };
  }, []);

  useEffect(() => {
    console.log("here we are ", resumeUrlId);
  }, [resumeUrlId]);

  function saveSession(count, platformName) {
    console.log(formRef.current);
    console.log(saveSessionState.current, "savesessionstate0");
    if (
      (count > 0 && saveSessionState.current == 0) ||
      (count == 0 &&
        saveSessionState.current == 0 &&
        sessionLinksBefore.current.length > 0)
    ) {
      getUnappliedJobsDueToResumeScore();
      console.log(saveSessionState.current, "savesessionstate1");
      let todaysessioncount = configDummy.todaysessioncount + count;
      let totalJobs = configDummy.totalJobs + count;
      let glassdoorJobsApplied =
        configDummy.jobsByPlatform["glassdoor"] + count;
      console.log(configDummy.totalJobs, count, "yooooo");
      let totalsessions = configDummy.totalsessions + 1;
      let totalsessionstoday = configDummy.totalsessionstoday + 1;
      //   totalCountUpdate({
      //     glassdoorJobsApplied: glassdoorJobsApplied,
      //     todaysessioncount: todaysessioncount,
      //     totalJobs: totalJobs,
      //     totalsessions: totalsessions,
      //     totalsessionstoday: totalsessionstoday,
      //   });
      let glassdoorJobApplicationLinksFinal = [...sessionLinks.current];
      if ("glassdoorJobApplicationLinksFinal" in userData) {
        glassdoorJobApplicationLinksFinal = [
          ...userData.glassdoorJobApplicationLinksFinal,
          ...glassdoorJobApplicationLinksFinal,
        ];
      }
      // setStreakAnalytics({
      //   ...streakAnalytics,
      //   glassdoor: streakAnalytics.glassdoor + count,
      // });
      let totalsession = configDummy.totalsession;
      configDummyUpdate({
        ...configDummy,
        jobsByPlatform: {
          ...configDummy.jobsByPlatform,
          glassdoor: glassdoorJobsApplied,
        },
        todaysessioncount: todaysessioncount,
        totalJobs: totalJobs,
        totalsessions: totalsessions,
        totalsessionstoday: totalsessionstoday,
        totalsession: [
          ...totalsession,
          {
            resumeData: resumeData.current,
            sessionLinks: sessionLinks.current,
            sessionLinksBefore: sessionLinksBefore.current,
            platformName: "glassdoor",
            form: formRef.current,
            count: count,
            email: userData.email,
          },
        ],
      });
      userLinksUpdate({
        glassdoorJobApplicationLinksFinal: glassdoorJobApplicationLinksFinal,
      });
      console.log(sessionLinks.current, "current------------");
      // setSaveSession(1);
      saveSessionState.current = 1;
      const v2Id = userData?.planDetails?.v2Id;
      const url = v2Id
        ? `https://backend.lazyapply.com/v2/saveSession/${v2Id}`
        : "http://backend.lazyapply.com/savesessionv2";

      axios
        .post(
          url,
          {
            sessionLinks: sessionLinks.current,
            sessionLinksBefore: sessionLinksBefore.current,
            resumeData: resumeData.current,
            session: {
              count: count,
              platformName: platformName,
              form: formRef.current,
            },
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          glassdoorReset();
          console.log(response);
          setmessage("Process completed!");
          settitle("Success");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2500);
        })
        .catch((error) => {
          glassdoorReset();
          console.log(error);
          if (error.message == "Request failed with status code 403") {
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
              history.push("/");
            }, 1500);
          } else {
            console.log("error");
            setmessage("Some error occured, please try again in some time!");
            settitle("Error");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2500);
          }
        });
    } else {
      getUnappliedJobsDueToResumeScore();
      saveSessionState.current = 1;
      glassdoorReset();
    }
  }

  const checkIfPresent = () => {
    if (selectedSearchData.searchName != "") {
      return true;
    } else {
      return false;
    }
  };

  const hideSavedSearchModal = () => {
    setShowSavedSearchModal(false);
  };

  function smoothScrollToBottom() {
    let start = null;
    const initialScrollTop = window.scrollY;
    const targetScrollTop = document.body.scrollHeight - window.innerHeight;

    function easeOutCubic(t) {
      return 1 - Math.pow(1 - t, 3);
    }

    function step(timestamp) {
      if (start === null) start = timestamp;
      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / 500, 1); // take 2 seconds to scroll

      const easedProgress = easeOutCubic(progress);
      const newScrollTop =
        initialScrollTop + (targetScrollTop - initialScrollTop) * easedProgress;

      window.scrollTo(0, newScrollTop);

      if (newScrollTop < targetScrollTop) {
        window.requestAnimationFrame(step);
      }
    }

    window.requestAnimationFrame(step);
  }

  useEffect(() => {
    questionRefs.current.forEach((question) => {
      const clickHandler = (event) => {
        const active = document.querySelector(".question.active");
        if (active && active !== question) {
          active.classList.toggle("active");
          active.nextElementSibling.style.maxHeight = 0;
        }
        question.classList.toggle("active");
        const answer = question.nextElementSibling;
        if (question.classList.contains("active")) {
          answer.style.maxHeight = answer.scrollHeight + "px";
        } else {
          answer.style.maxHeight = 0;
        }
      };

      if (question) {
        question.addEventListener("click", clickHandler);
      }

      return () => {
        question.removeEventListener("click", clickHandler);
      };
    });
    if (c.current == 0) {
      smoothScrollToBottom();
    }
  }, [pp.current]);

  const chromeVersion = useStateStore((state) => state.chromeVersion);

  function h(request) {
    if ("glassdoor" in request) {
      if ("message" in request) {
        if (request.message == "Applied successfully") {
          // let countglassdoor = count;
          // countglassdoor++;
          // console.log(countglassdoor)

          console.log(request.link, "link------------");
          sessionLinks.current.push(request.link);
          ++c.current;
          pp.current = Math.floor(
            (c.current / formRef.current.numberOfJobs) * 100
          );
          setCount(c.current);
          setProgressPercent(pp.current);
          console.log("update count");
        } else if (request.message == "Already Applied Before") {
          sessionLinksBefore.current.push(request.link);
        } else if (request.message == "filterValues") {
          delete request.filterValues["Company rating"];
          filterValues.current = request.filterValues;
          if (filterValues.current.length == 0) {
            fetchbutton.current.disabled = false;
            spinner.current.style.display = "none";

            setLinkedinRunning(0);
            // console.log("Try again for better input details");
            if (request.message2 == "signInRequired") {
              setmessage(
                "You're logged out of Glassdoor. Try again after logging in!"
              );
            } else {
              setmessage("Try again with better input details!");
            }
            settitle("No results found");
            setmodalshow(true);
            // messages()[MESSAGES.NOFILTERSFETCHED](
            //   MESSAGETYPE.USER_REPORT_FRONTEND,
            //   MESSAGES.NOFILTERSFETCHED,
            //   `${userData.email},${userData.name},${userData.planDetails.planName}`
            // );
            setTimeout(() => {
              setmodalshow(false);
              if (request.message2 == "signInRequired") {
                window.open("https://www.glassdoor.com/index.htm", "_blank");
                window.location.reload();
              }
            }, 2500);
            resetFull();
          } else {
            setLinkedinRunning(0);
            setformDisable(true);
            //perform all the filter operations

            // ddsc create objs for useStates

            chooseParaText.current.style.display = "block";
            spinner.current.style.display = "none";

            console.log("making formMain1 visible");
            formMain1.current.style.display = "block";
            setShowSavedSearchButton(false);
            buttonFetch.current.style.display = "none";
            if (typeof chrome !== "undefined" && chrome && chrome.runtime)
              chrome.runtime.sendMessage(yourExtensionId, {
                dashboard: true,
                saveValues: {
                  glassdoorFetchFilters: 1,
                },
                glassdoor: true,
                message: "glassdoorFiltersFetched",
              });

            // Call the function
            smoothScrollToBottom();
          }
        } else if (request.message == "debugSession") {
          fetchbutton.current.disabled = false;
          spinner.current.style.display = "none";
          setLinkedinRunning(0);
          setmessage(
            "We have detected some issue in LazyApply. Our team has been notified and we will fix this issue within next 1 hour. You have to do nothing in your part, this will be automatically fixed. \n\nThank you for being a wonderful customer."
          );
          settitle("Some problem occured");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 20000);
          resetFull();
        }
        if (request.message == "applypage") {
          if ("message2" in request) {
            console.log("Request.message2------>", request.message2);
            if (request.message2 == "planRequired") {
              setShowPlanPurchase(true);
              setLinkedinRunning(0);
              button1.current.innerText = "Completed";
              processing.current.style.display = "none";
              smoothScrollToTop();
              initprocessingFinal.current.style.display = "flex";
              initprocessing.current.style.display = "none";
              if (selectResumeRef.current)
                selectResumeRef.current.style.display = "none";
              glassdoorReset();
            } else if (request.message2 == "completed") {
              console.log("completed full");
              // ++c.current;
              // setCount(c.current);
              setLinkedinRunning(0);
              button1.current.innerText = "Completed";
              processing.current.style.display = "none";
              smoothScrollToTop();

              const v2Id = userData?.planDetails?.v2Id;
              axios
                .post(
                  `https://backend.lazyapply.com/v2/jobCountInLastSession/${v2Id}`,
                  { sessionId: sessionId.current },
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  }
                )
                .then((response) => {
                  console.log("first response", response.data);
                  setFinalJobCount(response.data.jobCount || 0);
                  initprocessingFinal.current.style.display = "flex";
                  initprocessing.current.style.display = "none";
                })
                .catch((err) => {
                  console.log("error in request", err);
                });

              if (selectResumeRef.current)
                selectResumeRef.current.style.display = "none";
              if (selectResumeRef1.current)
                selectResumeRef1.current.style.display = "none";
              pp.current = 100;
              setProgressPercent(pp.current);
              console.log(form);
              console.log(formMain.current.children);
              if (!window.wasRun) {
                messages()[MESSAGES.ZEROJOBSAPPLIED](
                  MESSAGETYPE.USER_REPORT_FRONTEND_GLASSDOOR,
                  MESSAGES.TOTALJOBSAPPLIED,
                  c.current,
                  JSON.stringify(formRef.current),
                  `${userData.email},total jobs applied this session ${c.current},${userData.name},${userData.planDetails.planName}`,
                  chromeVersion
                );
                saveSession(c.current, "glassdoor");
                window.wasRun = true;
              }
            } else if (
              request.message2 ==
              "No results found, try changing the filter values"
            ) {
              console.log("no results found");
              setmessage("Try again for better input details!");
              settitle("No results found");
              setmodalshow(true);
              setTimeout(() => {
                setmodalshow(false);
              }, 2500);
              setLinkedinRunning(0);
              button1.current.innerText = "Completed";
              processing.current.style.display = "none";
              if (selectResumeRef.current)
                selectResumeRef.current.style.display = "none";
              if (selectResumeRef1.current)
                selectResumeRef1.current.style.display = "none";
              pp.current = 100;
              setProgressPercent(pp.current);
              saveSession(c.current, "glassdoor");
            } else if (request.message2 == "unauthorized") {
              //  alert("unauthorised");
              fetchbutton.current.disabled = false;
              setLinkedinRunning(0);
              console.log("reauthenticate");
              setmessage("Please relogin again!");
              settitle("Not authorized");
              setmodalshow(true);
              setTimeout(() => {
                globalLogout();
                history.push("/");
              }, 1500);
              resetFull();
            }
          }
        }
        if (request.message == "updateLocationUrl") {
          console.log("url final here", request.url);
          setUrlMain(request.url);
        }
      }
    }
  }

  useEffect(() => {
    console.log("ppcurrent", pp.current);
    if (pp.current === 100) {
      if (formMain.current) formMain.current.style.display = "none";
      if (formMain1.current) formMain1.current.style.display = "none";
      if (button1.current) button1.current.style.display = "none";
      if (chooseParaText.current) chooseParaText.current.style.display = "none";
    } else {
      if (formMain.current) formMain.current.style.display = "block";
      if (button1.current) button1.current.style.display = "block";
    }
  }, [pp.current]);

  // const [checks, setChecks] = useState(false);
  // const [exactSearch, setExactSearch] = useState(false);
  // const hideExactSearchModal = () => {
  //   setExactSearchShow(false);
  // };
  // const [exactSearchShow, setExactSearchShow] = useState(false);
  // const [extraSkillValue, setExtraSkillValue] = useState([]);
  // const [extraSkills, setExtraSkills] = useState("");
  // const createOption = (label) => ({
  //   label,
  //   value: label,
  // });
  // const handleKeyDown = (event) => {
  //   if (!extraSkills) return;
  //   switch (event.key) {
  //     case "Enter":
  //     case "Tab":
  //       setExtraSkillValue((prev) => [...prev, createOption(extraSkills)]);
  //       setExtraSkills("");
  //       event.preventDefault();
  //   }
  // };

  return (
    <>
      {/* <ExactModal
        exactSearchShow={exactSearchShow}
        hideExactSearchModal={hideExactSearchModal}
      /> */}
      <div style={{ position: "relative" }} ref={mainheight}>
        <div>
          <MainWatchDemoModal
            platform="Glassdoor"
            showDemoPopup={showDemoPopup}
            customFn={() => {
              setShowDemoPopup(false);
            }}
          ></MainWatchDemoModal>
          <ExtensionInstallPopup
            title={"Glassdoor"}
            onClickWatchDemo={onClickWatchDemo}
            s={showExtensionInstallPopup}
            closeFn={closeExtensionInstallPopup}
            showUpdateExtension={showUpdateExtension}
          />
          <MoreFiltersHelp
            openHelper={openHelper}
            setOpenHelper={setOpenHelper}
          />
          <CustomAlert
            message={message}
            s={modalshow}
            title={title}
          ></CustomAlert>
          <p className="startApplying">Start Applying on glassdoor</p>
          {/* <button
            className="buttonGoBack btn buttonSetup"
            onClick={() => setChecks(false)}
          >
            <i className="fas fa-wrench"></i> One Time Setup Steps
          </button> */}
          <button className="buttonGoBack btn" onClick={goBack}>
            <i className="fas fa-arrow-left"></i> Go Back
          </button>
          {userData?.planDetails?.v2Id && (
            <div className="v2resumeSelect" ref={selectResumeRef}>
              <p className="makesure" style={{ marginBottom: "40px" }}>
                Make sure to login in the glassdoor account of the person with
                the selected resume and his/her glassdoor profile should be
                completed before applying
              </p>
              <SaveSearchModal
                platformName="glassdoor"
                showProp={showSavedSearchModal}
                hideShow={hideSavedSearchModal}
                form={{ ...form }}
                advanceSearch={{
                  advanceSearch: advanceSearch,
                  excludeString,
                  mayIncludeString,
                }}
                extraConfig={{
                  resumeId: resumeId,
                  countryCode: countryCode,
                }}
                setSelectedSearchData={setSelectedSearchData}
                setSavedSuccessfully={setSavedSuccessfully}
                selectedSearchData={selectedSearchData}
              />
              <GetSavedSearches
                platformName="glassdoor"
                savedSuccessfully={savedSuccessfully}
                setSelectedSearchData={setSelectedSearchData}
                searchInputValue={searchInputValue}
                setSearchInputValue={setSearchInputValue}
              />
              <Form.Group>
                <Form.Label>Choose a Profile to apply jobs for</Form.Label>
                {Object.keys(userResumeV3).length == 0 ||
                !userResumeV3.some((resume) => resume.validation === 1) ? (
                  <>
                    <Form.Control
                      as="select"
                      id="resumeId"
                      value={"demo_id_lazyapply"}
                      onChange={(e) => {
                        if (e.target.value == "create_own_profile") {
                          mixpanel.track(
                            "create_your_own_profile_select dashboard",
                            {
                              href: window.location.href,
                              email: userData?.email,
                              platformName: "glassdoor",
                              plan_details: userData?.planDetails || {},
                              v2Id: userData?.planDetails?.v2Id || "",
                            }
                          );
                          history.push("/dashboard/editresumesv2");
                        }
                      }}
                    >
                      <option value="demo_id_lazyapply">
                        Demo Profile Selected
                      </option>
                      <option value="create_own_profile">
                        Create Your Own Profile
                      </option>
                    </Form.Control>
                  </>
                ) : (
                  <>
                    <Form.Control
                      as="select"
                      id="resumeId"
                      disabled={resumeDisable}
                      isInvalid={resumeChanged}
                      value={resumeId}
                      onChange={(e) => {
                        if (e.target.value != "") {
                          setResumeChanged(false);
                        }

                        userResumeV3.map((key) => {
                          const item = key;
                          if (item.resumeId === e.target.value) {
                            resumeData.current = {
                              resumeId: item.resumeId,
                              resumeName: item.resumename,
                            };
                          }
                        });
                        setResumeId(e.target.value);
                      }}
                    >
                      <option value="">Select a resume</option>
                      {userResumeV3.map((key) => {
                        const item = key;
                        if (key.validation == 1)
                          return (
                            <option value={item.resumeId}>
                              {item.resumename}
                            </option>
                          );
                      })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select a resume/cv
                    </Form.Control.Feedback>
                  </>
                )}
              </Form.Group>
            </div>
          )}
          <Row ref={selectResumeRef1}>
            <Col md={12}>
              <UploadResume
                resumeUrlId={resumeUrlId}
                setResumeUrlId={setResumeUrlId}
                resumeUrlChanged={resumeUrlChanged}
                setResumeUrlChanged={setResumeUrlChanged}
              />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit} ref={formMain}>
            <Row style={styleform}>
              <Col md={4}>
                <Form.Label htmlFor="inputskill">
                  Enter skill / job title*
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={formDisable}
                  id="inputskill"
                  value={form.skill}
                  aria-describedby="passwordHelpBlock"
                  onChange={(e) => setField("skill", e.target.value)}
                  isInvalid={!!errors.skill}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.skill}
                </Form.Control.Feedback>
                <Form.Text id="passwordHelpBlock" muted>
                  For example - Enter only one single skill or job title like
                  react or data analyst or marketing manager etc
                </Form.Text>
              </Col>
              <Col md={4}>
                <Form.Label htmlFor="inputlocation">
                  Enter the job location (optional)
                </Form.Label>
                <Form.Control
                  disabled={formDisable}
                  type="text"
                  id="inputlocation"
                  value={form.location}
                  aria-describedby="passwordHelpBlock"
                  onChange={(e) => setField("location", e.target.value)}
                />
                <Form.Text id="passwordHelpBlock" muted>
                  For example - you can enter your job location like - bangalore
                  or delhi or any other place etc.
                </Form.Text>
              </Col>

              <Col md={4}>
                <Form.Label htmlFor="inputnumber">
                  Number of jobs to apply*
                </Form.Label>
                <Form.Control
                  disabled={formDisable}
                  type="number"
                  id="inputnumber"
                  value={form.numberOfJobs}
                  aria-describedby="passwordHelpBlock"
                  onChange={(e) => setField("numberOfJobs", e.target.value)}
                  isInvalid={!!errors.numberOfJobs}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.numberOfJobs}
                </Form.Control.Feedback>
                <Form.Text id="passwordHelpBlock" muted>
                  For example - 50 or 100 etc.
                </Form.Text>
              </Col>
              <Col md={6} style={{ margin: "20px 0px" }}>
                <Form.Label>{"Country"}</Form.Label>
                <Select
                  id={"id2"}
                  onChange={(option) => {
                    console.log("set url as ", countryUrlMap[option.value]);
                    setCountryCode(option.value);
                  }}
                  value={countries.filter((x) => x.value === countryCode)}
                  key={"country"}
                  name={"country"}
                  placeholder={"United States"}
                  options={countries}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </Col>

              <Col md={12}>
                {" "}
                <ResumeJobScore form={form} setField={setField} />{" "}
              </Col>

              <div className="advanceSearchContainer">
                <div className="advanceSearchContainerLeft">
                  Job Title Filters - Advance Search
                </div>
                <div className="advanceSearchContainerRight">
                  <div className="exactsearch">
                    <div className="exactsearchchild">
                      <p
                        style={{
                          color: "#007bff",
                          marginBottom: "0px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          cursor: "pointer",
                        }}
                      >
                        {advanceSearch ? (
                          <span onClick={() => setShowAdvanceSearch(true)}>
                            Configure
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <label
                        className={
                          "switch3" + (advanceSearch ? " switch3-checked" : "")
                        }
                      >
                        <input
                          type="checkbox"
                          checked={advanceSearch}
                          onChange={(e) => {
                            setAdvanceSearch(!advanceSearch);
                          }}
                        />
                        <div></div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <AdvanceSearchModal
                show={showAdvanceSearch}
                excludeString={excludeString}
                setExcludeString={setExcludeString}
                mayIncludeString={mayIncludeString}
                setMayIncludeString={setMayIncludeString}
                handleClose={handleCloseSearch}
              />

              <Col md={8} ref={buttonFetch}>
                <button
                  type="submit"
                  className="btn linkedinSubmitButton"
                  ref={fetchbutton}
                >
                  Click to fetch filters first
                  <i className="fas fa-arrow-right"></i>{" "}
                  <span
                    className="spinner-border spinner-border-sm"
                    style={{ marginLeft: "5px" }}
                    role="status"
                    aria-hidden="true"
                    ref={spinner}
                  ></span>
                </button>
              </Col>
              {showSavedSearchButton && (
                <Col
                  md={4}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {configDummy?.totalJobs && configDummy.totalJobs >= 200 && (
                    <button
                      type="button"
                      style={saveButtonDisabled ? buttonMain : buttonMain1}
                      disabled={saveButtonDisabled}
                      onClick={() => {
                        setShowSavedSearchModal(true);
                      }}
                    >
                      {checkIfPresent()
                        ? "Update Search Configuration"
                        : "Save Search Configuration"}
                    </button>
                  )}
                </Col>
              )}
            </Row>
          </Form>
          <p ref={chooseParaText} style={{ marginLeft: "20px", color: "gray" }}>
            Choose one or more filters (optional)
          </p>
          <Form onSubmit={handleSubmit1} ref={formMain1}>
            <Row style={filtersStyle}>
              {Object.keys(filterValues.current).map((key, index) => (
                <Col md={6}>
                  <Form.Label>{key}</Form.Label>
                  <Select
                    id={key}
                    onChange={(option) => {
                      console.log("onchange", option);
                      console.log("filtervalues", filterValues.current);
                      if (option) {
                        urlParts[key] = option.label;
                        console.info("url-parts", urlParts);
                      }
                    }}
                    key={index}
                    name={key}
                    ref={filterRefs[index]}
                    placeholder={key}
                    options={filterValues.current[key].options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </Col>
              ))}
            </Row>
            <Row>
              <Col md={12}>
                {checkIfDemoAndNoPlan() ? (
                  <button
                    type="button"
                    onClick={() => demoSubmit()}
                    className="btn linkedinSubmitButton"
                  >
                    Start Auto Applying{" "}
                    {form.numberOfJobs != 0
                      ? `To ${form.numberOfJobs} Jobs`
                      : ""}
                    <i className="fas fa-arrow-right"></i>
                  </button>
                ) : (
                  <button
                    type="submit"
                    ref={button1}
                    disabled={startApplying}
                    className="btn linkedinSubmitButton"
                  >
                    Start Applying <i className="fas fa-arrow-right"></i>
                  </button>
                )}
              </Col>
            </Row>
          </Form>
          <Row style={{ margin: "10px auto", padding: "0 5px" }}>
            {pp.current === 100 ? (
              <Col md={5} style={{ marginBottom: "20px" }}>
                <Feedback
                  platformName="glassdoor"
                  totalJobsApplied={c.current}
                />
              </Col>
            ) : (
              <Col md={5}></Col>
            )}
            <Col md={7} ref={initprocessingFinal} className="linkedinInit">
              <button
                type="button"
                className="linkedinReset btn"
                onClick={resetFull}
              >
                Reset
              </button>
              {showPlanPurchase ? (
                <>
                  <div className="planPurchaseRequiredMainDiv">
                    <p className="planPurchaseRequired">
                      You don't have a plan, please purchase a plan to continue
                    </p>
                    <button
                      onClick={() => {
                        mixpanel.track("purchase_plan_button dashboard", {
                          href: window.location.href,
                          email: userData?.email,
                          platformName: "glassdoor",
                          plan_details: userData?.planDetails || {},
                          v2Id: userData?.planDetails?.v2Id || "",
                        });
                        setTimeout(() => {
                          history.push("/pricing");
                        }, 500);
                      }}
                      className="button1"
                    >
                      Purchase Plan
                    </button>
                  </div>
                </>
              ) : (
                <div className="linkedinInitTop">
                  <div>Percentage complete ({100} %)</div>
                  <div>Total jobs applied ({finalJobCount})</div>
                </div>
              )}
              {showPlanPurchase ? (
                <></>
              ) : (
                <div className="linkedinInitTop">
                  <div>
                    <Progress done={100} />
                  </div>
                  <div className="jobsApplied">{finalJobCount}</div>
                </div>
              )}
            </Col>
            <Col md={7} ref={initprocessing} className="linkedinInit">
              <button
                type="button"
                className="linkedinReset btn"
                onClick={resetFull}
              >
                Reset
              </button>
              <div className="linkedinInitTop1">
                <div ref={processing} className="processing">
                  Automation in Process...
                </div>
              </div>
              <div className="linkedinInitTop1">
                <div>
                  Please do not switch tabs till this session's completion.
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* <Row>
        {pp.current == 100 && (
          <>
            <div class="col-10 wrapper">
              <h3 class="feedbackform_header issuesHeader">Common Issues</h3>
              {(() => {
                let allIssues = [
                  ...faqObj.glassdoor.issues,
                  ...faqObj.common.issues,
                ];
                return allIssues.map((x, index) => (
                  <div className="container" key={index}>
                    <div
                      className="question"
                      ref={(el) => (questionRefs.current[index] = el)}
                    >
                      {x.question}
                    </div>
                    <div class="answercont">
                      <div class="answer">
                        {x.answer}
                        {x.answerPoints && (
                          <ul>
                            {x.answerPoints.map((point) => (
                              <li>{point}</li>
                            ))}
                          </ul>
                        )}

                        <a
                          href="#"
                          className="text-primary"
                          style={{
                            marginLeft: "0px",
                            marginTop: "20px",
                            marginBottom: "20px",
                            display: "inline-block",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setShowIndeedModal(true);
                          }}
                        >
                          {x.anchorText}
                        </a>

                        <div>
                          <video
                            loop={true}
                            autoplay={true}
                            controls={true}
                            id="vid"
                            className="featuresImage"
                            style={{ boxShadow: "none" }}
                          >
                            <source
                              src="https://content.lazyapply.com/addtochrome.webm"
                              type="video/webm"
                            />
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                ));
              })()}
            </div>
            <div class="col-12" style={{ height: "100px" }}>
              {" "}
            </div>
            <IndeedArticle
              showProp={showIndeedModal}
              hideShow={closeModal}
              title={modalTitle}
            />
          </>
        )}
      </Row> */}
    </>
  );
};

export default GlassDoor;
