import React, { useEffect, useState } from "react";
import "./homedash.css";
import { ProgressBar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useStateStore } from "../../store/index";

function Homedash() {
  const history = useHistory();
  const [navbarWidth, setnavbarWidth] = useState(0);
  const configDummy = useStateStore((state) => state.configDummy);

  useEffect(() => {
    setnavbarWidth(window.innerWidth);
  }, []);

  function dailyLimitBottom(today, daily) {
    let percent = daily == 0 ? 0 : (today / daily) * 100;
    if (percent > 100) {
      percent = 100;
    }
    return (
      <>
        <ProgressBar variant="success" now={percent} />

        <div className="dailyLimitBottom totalJobsProgress">
          <p>
            {Math.round(percent * 10) / 10}% used, today - {today} Jobs Applied
          </p>
          <p>Total sessions today - {configDummy.totalsessionstoday}</p>
        </div>
      </>
    );
  }

  function goToPricing() {
    history.push("/pricing");
  }

  function planFun() {
    if (configDummy.plan == 0 || configDummy.planName == "free") {
      return (
        <>
          <span onClick={() => goToPricing()}>
            Purchase Plan{" "}
            <i
              className="fas fa-credit-card ml-2"
              style={{ color: "white" }}
            ></i>
          </span>
        </>
      );
    } else if (
      configDummy.planName == "basic" ||
      configDummy.planName == "premium"
    ) {
      return (
        <>
          <span onClick={() => goToPricing()}>
            Upgrade plan{" "}
            <i
              className="fas fa-credit-card ml-2"
              style={{ color: "white" }}
            ></i>
          </span>
        </>
      );
    } else {
      return <>{configDummy.planName.toUpperCase()} Plan</>;
    }
  }

  function showPlan() {
    if (configDummy.planName == "free") {
      return "Free Trial";
    } else {
      return `${configDummy.planName}`;
    }
  }

  return (
    <>
      <div>
        <div className="dashboardHeading">
          <div className="dashboardHeadingLeft">
            <span className="dashboardText">Dashboard</span>
            <span className="overviewText">overview</span>
          </div>
          <div className="dashboardHeadingRight">
            <button>{planFun()}</button>
          </div>
        </div>
        {navbarWidth >= 768 && (
          <div className="dashboardRightTop">
            <div className="dailyLimit dashboardRightTopContent">
              <div className="dailyLimitTop">
                <div className="dailyLimitTopText">Daily Limit</div>
                <div className="limitLeftTopText">Daily Limit Left</div>
              </div>
              <div className="dailyLimitMiddle">
                <div className="dailyLimitText">
                  {configDummy.plan == 0 ? "No plan" : configDummy.dailyLimit}
                </div>
                <div className="limitLeftText">
                  {configDummy.plan == 0 ? (
                    0
                  ) : (
                    <>
                      {configDummy.dailyLimit - configDummy.todaysessioncount <
                      0
                        ? configDummy.todaysessioncount
                        : configDummy.dailyLimit -
                          configDummy.todaysessioncount}
                      {/* /<span>{configDummy.dailyLimit}</span> */}
                      {` Jobs Left`}
                    </>
                  )}
                </div>
              </div>
              <div className="dailyLimitBottom">
                {configDummy.plan == 0
                  ? dailyLimitBottom(0, 0)
                  : dailyLimitBottom(
                      configDummy.todaysessioncount,
                      configDummy.dailyLimit
                    )}
              </div>
            </div>
            <div className="payment dashboardRightTopContent">
              <div className="dailyLimitTop">
                <div className="dailyLimitTopText">Your Plan Details</div>
                <div className="limitLeftTopText">Plan Ending</div>
              </div>
              <div className="dailyLimitMiddle">
                <div className="dailyLimitText planText">
                  {" "}
                  {configDummy.plan == 0 ? "No Plan" : showPlan()}
                </div>
                <div className="limitLeftText planEnding">
                  {configDummy.plan == 0 ? "--" : "Lifetime Access"}
                </div>
              </div>
              <div className="dailyLimitBottom">
                <p>
                  {configDummy.plan == 0
                    ? "No Plan, purchase a plan.."
                    : `You are subscribed to Lazyapply ${configDummy.planName.toUpperCase()} Plan`}
                </p>
              </div>
            </div>
            <div className="totalJobs dashboardRightTopContent">
              <div className="dailyLimitTop">
                <div className="dailyLimitTopText">Total Jobs Applied </div>
                <div className="limitLeftTopText">
                  {configDummy.planName == "free" ? "Total" : "Yearly"} Limit
                  Left
                </div>
              </div>
              <div className="dailyLimitMiddle">
                <div className="dailyLimitText">
                  {" "}
                  {configDummy.plan == 0 ? "No plan" : configDummy.totalJobs}
                </div>
                <div className="limitLeftText">
                  {configDummy.plan == 0 ? 0 : "Unlimited"}
                </div>
              </div>
              <div className="dailyLimitBottom totalJobsProgress">
                <p>
                  {configDummy.plan == 0
                    ? "No Plan, purchase a plan.."
                    : "Apply to unlimited jobs today :)"}
                </p>
              </div>
              {configDummy.plan != 0 && (
                <div className="dailyLimitBottom totalJobsProgress">
                  <p>Total sessions - {configDummy.totalsessions}</p>
                </div>
              )}
            </div>
          </div>
        )}
        {navbarWidth <= 768 && (
          <>
            <div className="dashboardRightTop">
              <div className="totalJobs dashboardRightTopContent">
                <div className="dailyLimitTop">
                  <div className="dailyLimitTopText">Your Details! </div>
                </div>
                <div className="dailyLimitMiddle">
                  <div className="dailyLimitText">Total Jobs Applied</div>
                  <div className="limitLeftText">
                    {configDummy.plan == 0 ? "No plan" : configDummy.totalJobs}
                  </div>
                  <div className="dailyLimitText">Today Applied</div>
                  <div className="limitLeftText">
                    {configDummy.plan == 0
                      ? `0`
                      : `${configDummy.todaysessioncount} / ${configDummy.dailyLimit}`}
                  </div>
                  <div className="dailyLimitText">Your Plan</div>
                  <div className="limitLeftText">
                    {configDummy.plan == 0 ? "No Plan" : showPlan()}
                  </div>
                </div>
                <div className="dailyLimitBottom totalJobsProgress">
                  <p>
                    {configDummy.plan == 0
                      ? "No Plan, purchase a plan.."
                      : "Apply to unlimited jobs today :)"}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="dashboardRightMiddle">
        <div className="dashboardRightMiddleCard">
          <div className="jobdiv jobdivimage">
            <img src={"/assests/linkedin.png"} className="linkedinLogo"></img>
          </div>
          <div className="jobdiv">
            <p>linkedin</p>
            <ul>
              <li>
                740 million members with over 55 million registered companies.
              </li>
              <li>
                The largest social network of professional networking and career
                development.
              </li>
              <li>
                Make sure you have a complete profile along with a resume
                uploaded before start applying.
              </li>
              <li>
                Make sure you are logged in to Linkedin before you start
                applying.
              </li>
            </ul>
          </div>

          <div className="jobdiv jobdivbutton">
            <button className="startNow">Start Applying</button>
          </div>
        </div>
        <div className="dashboardRightMiddleCard">
          <div className="jobdiv jobdivimage">
            <img src={"/assests/indeed.png"} className="indeedLogo"></img>
          </div>
          <div className="jobdiv">
            <p>indeed</p>
            <ul>
              <li>
                Over 16 million postings and 8.2 jobs are posted every second.
              </li>
              <li>Indeed is the most popular job posting site in the world.</li>
              <li>
                Make sure you have a complete profile along with a resume
                uploaded, before start applying.
              </li>
              <li>
                Make sure you are logged in to Indeed before you start applying
              </li>
            </ul>
          </div>
          <div className="jobdiv jobdivbutton">
            <button
              // to="/dashboard/indeed"
              className="startNow"
            >
              Start Applying
            </button>
          </div>
        </div>

        <div className="dashboardRightMiddleCard">
          <div className="jobdiv jobdivimage">
            <img src={"/assests/main.png"} className="linkedinLogo"></img>
          </div>
          <div className="jobdiv">
            <p>Referral</p>
            <ul>
              <li>Access information on over 53.1 million companies.</li>
              <li>
                Request referrals directly from employees of these companies.
              </li>
              <li>Use LazyApply AI to write and send emails effortlessly.</li>
              <li>Ask for referrals from any company worldwide.</li>
            </ul>
          </div>
          <div className="jobdiv jobdivbutton">
            <button className="startNow">Ask For Referral</button>
          </div>
        </div>

        <div className="dashboardRightMiddleCard">
          <div className="jobdiv jobdivimage">
            <img src={"/assests/ziprecruiter.png"} className="zipLogo"></img>
          </div>
          <div className="jobdiv">
            <p>Ziprecruiter</p>
            <ul>
              <li>110M+ job seekers have used ZipRecruiter.</li>
              <li>#1 rated hiring site in the U.S.</li>
              <li>
                Make sure you have a complete profile along with a resume
                uploaded, before start applying.
              </li>
              <li>
                Make sure you are logged in to Ziprecruiter before you start
                applying.
              </li>
            </ul>
          </div>
          <div className="jobdiv jobdivbutton">
            <button
              // to="/dashboard/ziprecruiter"
              className="startNow"
            >
              Start Applying
            </button>
          </div>
        </div>
        <div className="dashboardRightMiddleCard">
          <div className="jobdiv jobdivimage">
            <img src={"/assests/dice.png"} className="diceLogo"></img>
          </div>
          <div className="jobdiv">
            <p>Dice</p>
            <ul>
              <li>Search 70000+ job openings from tech's hottest employers.</li>
              <li>Over 6.1 million tech professionals.</li>
              <li>
                Tech Professional Visits: Around 1.7 million visits per month.
              </li>
            </ul>
          </div>
          <div className="jobdiv jobdivbutton">
            <button className="startNow">Start Applying</button>
          </div>
        </div>
        <div className="dashboardRightMiddleCard">
          <div className="jobdiv jobdivimage">
            <img src={"/assests/cbfinal.png"} className="cbLogo"></img>
          </div>
          <div className="jobdiv">
            <p>Career Builder</p>
            <ul>
              <li>
                CareerBuilder leads the industry in recruiting solutions,
                employment screening
              </li>
              <li>
                Offers specialized recruitment solutions, uses advanced search
                technology to help companies identify the most suitable talent
              </li>
              <li>
                Connected with more than 80 million unique, diverse U.S. job
                seekers.
              </li>
            </ul>
          </div>
          <div className="jobdiv jobdivbutton">
            <button className="startNow">Start Applying</button>
          </div>
        </div>
        <div className="dashboardRightMiddleCard">
          <div className="jobdiv jobdivimage">
            <img
              src={"/assests/simplyHiredLogo.png"}
              className="simplyHiredLogo"
            ></img>
          </div>
          <div className="jobdiv">
            <p>SimplyHired</p>
            <ul>
              <li>
                Simply Hired lists job openings from 700,000 unique employers.
              </li>
              <li>
                The platform operates job search engines in 24 countries and 12
                languages.
              </li>
              <li>
                For the past 3 years, Simply Hired has been named a "Top job
                search website" by Forbes and PC Magazine.
              </li>
            </ul>
          </div>
          <div className="jobdiv jobdivbutton">
            <button className="startNow">Start Applying</button>
          </div>
        </div>
        <div className="dashboardRightMiddleCard">
          <div className="jobdiv jobdivimage">
            <img
              src={"/assests/Glassdoor-Logo.png"}
              className="zipLogo glassdoorLogo"
            ></img>
          </div>
          <div className="jobdiv">
            <p>Glassdoor</p>
            <ul>
              <li>
                Glassdoor serves a global community of millions, championing
                workplace transparency since 2007.
              </li>
              <li>
                Over 55 million unique monthly visitors rely on Glassdoor for
                valuable insights.
              </li>
              <li>
                Glassdoor partners with 2.5 million employer clients, connecting
                them with talented professionals worldwide.
              </li>
            </ul>
          </div>
          <div className="jobdiv jobdivbutton">
            <button className="startNow">Start Applying</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Homedash;
