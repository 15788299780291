import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import axios from "axios";
import { useStateStore } from "../store/index";
function PhoneModal({ showProp }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    console.log("no close");
  };

  const userData = useStateStore((state) => state.userData);
  const [value, setValue] = useState(""); // Initialize value as an empty string
  const [valid, setValid] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [candidateUserCount, setCandidateUserCount] = useState("");
  const [useLazyApplyFor, setUseLazyApplyFor] = useState("");
  const [isUpdating, setUpdating] = useState(false);
  const [formErrors, setFormErrors] = useState({}); // Track form errors
  const [phoneError, setPhoneError] = useState(""); // Track phone number error

  const token = useStateStore((state) => state.token);

  const buttonSave = {
    backgroundColor: "#35AD46",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "12px 25px",
    textAlign: "center",
    borderRadius: "5px",
    width: "100%",
    outline: "none",
    border: "none",
    marginTop: "20px",
    cursor: "pointer",
  };

  function setPhoneNumber(v) {
    const phoneValue = v || "";
    setValue(phoneValue);
    setValid(isValidPhoneNumber(phoneValue));
    setPhoneError(
      isValidPhoneNumber(phoneValue) ? "" : "Please enter a valid phone number"
    );
  }

  const handleSubmit = () => {
    const errors = {};

    // Validate form fields
    if (!companyName) errors.companyName = "Company name is required";
    if (!companyWebsite || !/^https?:\/\/.*$/.test(companyWebsite))
      errors.companyWebsite = "Please enter a valid URL (http:// or https://)";
    if (
      !candidateUserCount ||
      isNaN(candidateUserCount) ||
      candidateUserCount <= 0
    )
      errors.candidateUserCount = "Please enter a valid number for candidates";
    if (
      !useLazyApplyFor ||
      useLazyApplyFor.split(/\s+/).filter(Boolean).length > 200
    )
      errors.useLazyApplyFor = "Description must not exceed 200 words";
    if (!valid)
      errors.phoneNumber =
        phoneError == "" ? "Please enter a valid phone number" : phoneError;

    console.log("errors", errors);
    setFormErrors(errors);

    // If there are errors, don't submit the form
    if (Object.keys(errors).length === 0) {
      saveMobileNumber();
    }
  };

  const handleFieldChange = (field, value) => {
    // Update state for the respective field
    if (field === "companyName") setCompanyName(value);
    if (field === "companyWebsite") setCompanyWebsite(value);
    if (field === "candidateUserCount") setCandidateUserCount(value);
    if (field === "useLazyApplyFor") setUseLazyApplyFor(value);

    // Clear the error for the respective field if it becomes valid
    setFormErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (value) {
        if (field === "companyName" && updatedErrors.companyName) {
          delete updatedErrors.companyName;
        }
        if (field === "companyWebsite" && updatedErrors.companyWebsite) {
          delete updatedErrors.companyWebsite;
        }
        if (
          field === "candidateUserCount" &&
          updatedErrors.candidateUserCount
        ) {
          delete updatedErrors.candidateUserCount;
        }
        if (field === "useLazyApplyFor" && updatedErrors.useLazyApplyFor) {
          delete updatedErrors.useLazyApplyFor;
        }
        if (field === "phoneInput" && updatedErrors.phoneNumber) {
          delete updatedErrors.phoneNumber;
        }
      }
      return updatedErrors;
    });
  };

  function saveMobileNumber() {
    const phoneNumber = value;
    const country = parsePhoneNumber(value).country;
    setUpdating(true);
    console.log(
      phoneNumber,
      country,
      companyName,
      companyWebsite,
      candidateUserCount,
      useLazyApplyFor
    );

    // Preparing the payload with all the form fields
    axios
      .post(
        "https://backend.lazyapply.com/enterprise-service/organization_info",
        {
          phoneNumber,
          country,
          companyName,
          companyWebsite,
          candidateUserCount,
          useLazyApplyFor,
          v2Id: userData?.planDetails?.v2Id || "",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setUpdating(false);
        setShow(false);
      })
      .catch((error) => {
        setUpdating(false);
        console.error(error);
      });
  }

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  const wordCount = useLazyApplyFor.split(/\s+/).filter(Boolean).length; // Counting words
  const isFormValid =
    valid &&
    companyName &&
    companyWebsite &&
    candidateUserCount &&
    useLazyApplyFor &&
    wordCount <= 200;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Your Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div>
            <label
              htmlFor="companyName"
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: "500",
                color: "#007bff",
              }}
            >
              Company Name
            </label>
            <input
              id="companyName"
              type="text"
              placeholder="Enter company name"
              value={companyName}
              onChange={(e) => handleFieldChange("companyName", e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: formErrors.companyName
                  ? "1px solid red"
                  : "1px solid #ddd",
                marginBottom: "12px",
              }}
            />
            {formErrors.companyName && (
              <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
                {formErrors.companyName}
              </p>
            )}

            <label
              htmlFor="companyWebsite"
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: "500",
                color: "#007bff",
              }}
            >
              Company Website
            </label>
            <input
              id="companyWebsite"
              type="url"
              placeholder="Enter company website"
              value={companyWebsite}
              onChange={(e) =>
                handleFieldChange("companyWebsite", e.target.value)
              }
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: formErrors.companyWebsite
                  ? "1px solid red"
                  : "1px solid #ddd",
                marginBottom: "12px",
              }}
              pattern="https?://.*"
              required
            />
            {formErrors.companyWebsite && (
              <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
                {formErrors.companyWebsite}
              </p>
            )}

            <label
              htmlFor="candidateUserCount"
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: "500",
                color: "#007bff",
              }}
            >
              How many candidates will you apply for?
            </label>
            <input
              id="candidateUserCount"
              type="number"
              placeholder="How many candidates will you apply for?"
              value={candidateUserCount}
              onChange={(e) =>
                handleFieldChange("candidateUserCount", e.target.value)
              }
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: formErrors.candidateUserCount
                  ? "1px solid red"
                  : "1px solid #ddd",
                marginBottom: "12px",
              }}
            />
            {formErrors.candidateUserCount && (
              <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
                {formErrors.candidateUserCount}
              </p>
            )}

            <label
              htmlFor="useLazyApplyFor"
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: "500",
                color: "#007bff",
              }}
            >
              What do you want to use LazyApply for? (max 200 words)
            </label>
            <textarea
              id="useLazyApplyFor"
              placeholder="What do you want to use LazyApply for?"
              value={useLazyApplyFor}
              onChange={(e) =>
                handleFieldChange("useLazyApplyFor", e.target.value)
              }
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: formErrors.useLazyApplyFor
                  ? "1px solid red"
                  : "1px solid #ddd",
                marginBottom: "12px",
                height: "120px",
                resize: "vertical",
              }}
              maxLength="1200"
            />
            {formErrors.useLazyApplyFor && (
              <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
                {formErrors.useLazyApplyFor}
              </p>
            )}
          </div>

          {/* Phone Input Section */}
          <label
            htmlFor="phoneInput"
            style={{
              display: "block",
              marginBottom: "8px",
              fontWeight: "500",
              color: "#007bff",
            }}
          >
            Phone Number
          </label>
          <PhoneInput
            id="phoneInput"
            defaultCountry="IN"
            placeholder="Enter phone number"
            value={value}
            onChange={(v) => setPhoneNumber(v)}
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "5px",
              border: formErrors.phoneNumber
                ? "1px solid red"
                : "1px solid #ddd",
              marginBottom: "12px",
            }}
          />
          {formErrors.phoneNumber && (
            <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
              {formErrors.phoneNumber}
            </p>
          )}

          {/* Submit Button */}
          <button
            style={buttonSave}
            // disabled={!isFormValid || isUpdating}
            onClick={handleSubmit}
          >
            Submit Details To Get Started
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PhoneModal;
