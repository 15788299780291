import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
// import imgstripe1 from "../../security-icon.png";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
function SplitModal({ showProp, hideShow, planName }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    hideShow();
  };

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  const price = () => {
    if (planName == "basic") {
      return 99;
    } else if (planName == "premium") {
      return 129;
    } else {
      return 249;
    }
  };

  const amount = () => {
    if (planName == "basic") {
      return "$50";
    } else if (planName == "premium") {
      return "$65";
    } else {
      return "$125";
    }
  };

  const amountLeft = () => {
    if (planName == "basic") {
      return "$49";
    } else if (planName == "premium") {
      return "$64";
    } else {
      return "$124";
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      backdrop="static"
      className="stripemodal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Split Payment In Half - {planName.toUpperCase()} Plan
          <img className="stripeimgsecure" src={'/assests/security-icon.png'}></img>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="splitPaymentsContainer">
          <p className="splitPaymentsTitle">Pay in 2 payments</p>
          <p
            style={{ color: "black", textAlign: "center", fontWeight: "bold" }}
          >
            Split your purchase of {price()}$ into 2
          </p>
          <div className="splitCircular">
            <div style={{ width: 100, height: 100 }}>
              <CircularProgressbar
                value={50}
                text={`${50}%`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0.5,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",

                  // Text size
                  textSize: "16px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: `rgba(62, 152, 199, ${100 / 100})`,
                  textColor: "#f88",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#3e98c7",
                })}
              />
              <p className="halfamount">{amount()}</p>
              <p className="halfamountDesc">Today</p>
            </div>
            <div style={{ width: 100, height: 100 }}>
              <CircularProgressbar
                value={100}
                text={`100%`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0.5,
                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",

                  // Text size
                  textSize: "16px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: `rgba(62, 152, 199, ${100 / 100})`,
                  textColor: "#f88",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#3e98c7",
                })}
              />
              <p className="halfamount">{amountLeft()}</p>
              <p className="halfamountDesc">4 weeks</p>
            </div>
          </div>
          <div>
            <p className="splitMainText">
              Get your LazyApply Subscription now and We will automatically
              deduct the rest of the amount after the end of 4 weeks
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SplitModal;
