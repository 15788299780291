import React, { useEffect, useState } from "react";
import axios from "axios";
import { useStateStore } from "../../store";
import CompaniesList from "./companiesList";
import { useParams, useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import CustomAlert from "../modal";
import FeedbackPopup from "./FeedbackPopup";

function LazyapplyXIndividualSession() {
  const token = useStateStore((state) => state.token);
  const lazyapplyXData = useStateStore((state) => state.lazyapplyXData);
  const lazyapplyXCompaniesData = useStateStore(
    (state) => state.lazyapplyXCompaniesData
  );
  const setShowFeedbackPopup = useStateStore(
    (state) => state.setShowFeedbackPopup
  );
  const showFeedbackPopup = useStateStore((state) => state.showFeedbackPopup);
  const globalLogout = useStateStore((state) => state.globalLogout);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const setGeneratedSampleEmail = useStateStore(
    (state) => state.setGeneratedSampleEmail
  );
  const setLazyapplyXCompaniesData = useStateStore(
    (state) => state.setLazyapplyXCompaniesData
  );

  const { id } = useParams();
  console.log("id", id);
  const [processCompleted, setProcessCompleted] = useState(0);
  const [processStartedStep, setProcessStartedStep] = useState(1);
  const setLazyapplyXData = useStateStore((state) => state.setLazyapplyXData);
  const getCompanies = () => {
    axios
      .get(`https://backend.lazyapply.com/getCompanies/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("response", response.data);
        setProcessCompleted(response.data.processCompleted);
        setProcessStartedStep(response.data.processStartedStep);
        createDate(response.data.createdAt);
        setLazyapplyXCompaniesData(response.data);
      })
      .catch((err) => {
        console.log("err", err);
        if (err.message == "Request failed with status code 403") {
          console.log("reauthenticate");
          setmessage("Please relogin again!");
          settitle("Not authorized");
          setmodalshow(true);
          setTimeout(() => {
            globalLogout();
            history.push("/");
          }, 1500);
        }
      });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const writeAndSendEmails = () => {
    setLoading(true);
    axios
      .post(
        "https://backend.lazyapply.com/changeToLastStep",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setLoading(false);
        console.log("responselaststep", response.data);
        setGeneratedSampleEmail(response.data.emailGenerated);
        setLazyapplyXData({ ...lazyapplyXData, stepNo: response.data.stepNo });
        history.push(`/dashboard/lazyapply-x/sessions/${id}/sendEmails`);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
        if (err.message == "Request failed with status code 403") {
          console.log("reauthenticate");
          setmessage("Please relogin again!");
          settitle("Not authorized");
          setmodalshow(true);
          setTimeout(() => {
            globalLogout();
            history.push("/");
          }, 1500);
        }
      });
  };

  function goBack() {
    history.goBack();
  }

  const [date, sessionDate] = useState("");
  const createDate = (createdAt) => {
    if (createdAt) {
      let time = {
        seconds: createdAt._seconds,
        nanoseconds: createdAt._nanoseconds,
      };
      const fireBaseTime = new Date(
        time.seconds * 1000 + time.nanoseconds / 1000000
      );
      const date = fireBaseTime.toDateString();
      console.log("sessiondate", date);
      sessionDate(date);
    } else {
      sessionDate("");
    }
  };

  const hideShow = () => {
    setShowFeedbackPopup(false);
  };

  return (
    <>
      <FeedbackPopup showProp={showFeedbackPopup} hideShow={hideShow} />
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      <div style={{ position: "relative" }}>
        <button className="buttonGoBack btn" onClick={goBack}>
          <i className="fas fa-arrow-left"></i> Go Back
        </button>
        <div style={{ marginBottom: "50px" }}></div>
        <CompaniesList companies={lazyapplyXCompaniesData.companies} />
        {lazyapplyXCompaniesData.companies &&
          lazyapplyXCompaniesData.companies.length > 0 && (
            <div className="targetedSearchInfo">
              {processCompleted == 1 ? (
                <>
                  <p className="targetedSearchInfoTitle">
                    Process Already Completed
                  </p>
                  <p className="targetedSearchInfoDesc">
                    {`You can view your gmail inbox to check the emails sent to these companies mentioned above`}
                  </p>

                  {date != "" && (
                    <p style={{ fontWeight: "bold" }}>Session Date - {date}</p>
                  )}
                </>
              ) : (
                <>
                  {processStartedStep == 1 ? (
                    <>
                      <p className="targetedSearchInfoTitle">
                        Check Sample And Send Emails
                      </p>
                      <p className="targetedSearchInfoDesc">
                        {`We will email these companies automatically, Click on the button below to check sample email written using AI to go to next step`}
                      </p>

                      <div
                        style={{
                          margin: "10px",
                        }}
                      >
                        <button
                          className="targetedSearchInfoButton"
                          style={{ margin: "0px" }}
                          disabled={loading ? true : false}
                          onClick={() => writeAndSendEmails()}
                        >
                          {" "}
                          Click Here To Check Sample Email And Get Started{" "}
                          <span style={{ marginRight: "10px" }}></span>🚀
                          {loading && (
                            <Spinner
                              animation="border"
                              size="sm"
                              style={{ marginLeft: "10px" }}
                            />
                          )}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <p className="targetedSearchInfoTitle">
                        Process Started But Not Completed
                      </p>
                      <p className="targetedSearchInfoDesc">
                        {`You can view your gmail inbox to check the emails sent to these companies mentioned above, you will get an email once the process is completed`}
                      </p>

                      {date != "" && (
                        <p style={{ fontWeight: "bold" }}>
                          Session Date - {date}
                        </p>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
      </div>
    </>
  );
}

export default LazyapplyXIndividualSession;
