import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./extensionInstall.css";
import { mixpanel } from "../../index";
import { useStateStore } from "../../store";

function ExtensionInstallPopup({
  title = "Linkedin",
  s,
  closeFn = () => {},
  showUpdateExtension = false,
  onClickWatchDemo,
  home = false,
}) {
  const userData = useStateStore((state) => state.userData);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    mixpanel.track("extension_install_popup dashboard", {
      href: window.location.href,
      email: userData?.email,
      event: "close",
      platformName: title,
      home: home,
      type: showUpdateExtension ? "update" : "install",
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    setShow(false);
    closeFn();
  };

  useEffect(() => {
    console.log("show", s);
    if (s) {
      mixpanel.track("extension_install_popup dashboard", {
        href: window.location.href,
        email: userData?.email,
        event: "open",
        home: home,
        platformName: title,
        type: showUpdateExtension ? "update" : "install",
        plan_details: userData?.planDetails || {},
        v2Id: userData?.planDetails?.v2Id || "",
      });
    }
    setShow(s);
  }, [s]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="extensionInstallModalTitle">
            {showUpdateExtension
              ? "Extension Update Required"
              : `Start Applying On ${title} Now`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "10px" }}>
          <div className="extensionInstallMainContainer">
            <div className="extensoinInstallFirstDiv">
              <div
                className="extensoinInstallFirstDivTitle"
                onClick={() => {
                  mixpanel.track(
                    "extension_install_popup_heading_link dashboard",
                    {
                      home: home,
                      href: window.location.href,
                      email: userData?.email,
                      platformName: title,
                      type: showUpdateExtension ? "update" : "install",
                      plan_details: userData?.planDetails || {},
                      v2Id: userData?.planDetails?.v2Id || "",
                    }
                  );
                  window.open(
                    "https://chrome.google.com/webstore/detail/lazyapply-job-application/pgnfaifdbfoiehcndkoeemaifhhbgkmm",
                    "_blank"
                  );
                }}
              >
                {showUpdateExtension == 0
                  ? "Install Lazyapply Extension And Test Yourself "
                  : "Update Lazyapply Chrome Extension Now "}
                <i
                  style={{ color: "#007bff", fontSize: "30px" }}
                  className="fas fa-external-link-alt ml-2"
                ></i>
              </div>
              <div className="extensoinInstallPoints">
                {!showUpdateExtension ? (
                  <>
                    <div className="jobdiv">
                      <ul>
                        <li
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            mixpanel.track(
                              "go_to_lazyapply_chrome_extension_link dashboard",
                              {
                                home: home,
                                href: window.location.href,
                                email: userData?.email,
                                platformName: title,
                                type: showUpdateExtension
                                  ? "update"
                                  : "install",
                                plan_details: userData?.planDetails || {},
                                v2Id: userData?.planDetails?.v2Id || "",
                              }
                            );
                            window.open(
                              "https://chrome.google.com/webstore/detail/lazyapply-job-application/pgnfaifdbfoiehcndkoeemaifhhbgkmm",
                              "_blank"
                            );
                          }}
                        >
                          Go to Lazyapply Chrome Extension page by
                          <span
                            style={{
                              color: "blue",
                              marginLeft: "5px",
                            }}
                          >
                            clicking here
                          </span>
                        </li>
                        <li>
                          Install our extension{" "}
                          {/* <span
                            style={{
                              color: "blue",
                            }}
                          >
                            ( we need it to automate jobs and send referrals )
                          </span>{" "} */}
                          in order to send referrals and apply jobs
                          automatically
                        </li>
                        <li
                          style={{
                            color: "blue",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            mixpanel.track(
                              "only_1_click_is_required_link dashboard",
                              {
                                home: home,
                                href: window.location.href,
                                email: userData?.email,
                                platformName: title,
                                plan_details: userData?.planDetails || {},
                                v2Id: userData?.planDetails?.v2Id || "",
                              }
                            );
                            window.open(
                              "https://chrome.google.com/webstore/detail/lazyapply-job-application/pgnfaifdbfoiehcndkoeemaifhhbgkmm",
                              "_blank"
                            );
                          }}
                        >
                          Only 1 click is required to install our lazyapply
                          chrome extension
                        </li>
                      </ul>
                    </div>
                    <div className="extensoinInstallMainImgDiv">
                      <div className="extensoinInstallMainImgDivFirst">
                        <img
                          onClick={() =>
                            window.open(
                              "https://chrome.google.com/webstore/detail/lazyapply-job-application/pgnfaifdbfoiehcndkoeemaifhhbgkmm",
                              "_blank"
                            )
                          }
                          src={"/assests/install_extension_main.png"}
                        ></img>
                      </div>
                      <div className="extensoinInstallMainImgDivSecond">
                        <img src={"/assests/arrow_left.svg"}></img>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="jobdiv">
                      <ul>
                        <li
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            mixpanel.track(
                              "go_to_lazyapply_chrome_extension_link dashboard",
                              {
                                home: home,
                                href: window.location.href,
                                email: userData?.email,
                                platformName: title,
                                type: showUpdateExtension
                                  ? "update"
                                  : "install",
                                plan_details: userData?.planDetails || {},
                                v2Id: userData?.planDetails?.v2Id || "",
                              }
                            );
                            window.open(
                              "https://chrome.google.com/webstore/detail/lazyapply-job-application/pgnfaifdbfoiehcndkoeemaifhhbgkmm",
                              "_blank"
                            );
                          }}
                        >
                          1. Go to Lazyapply Chrome Extension page by
                          <span
                            style={{
                              color: "blue",
                              marginLeft: "5px",
                            }}
                          >
                            clicking here
                          </span>
                        </li>
                        <li>2. Click on "Remove From Chrome" button</li>
                        <li>
                          3. Again press "Add To Chrome Button" in order to
                          update extension.
                        </li>
                      </ul>
                    </div>
                    <div className="extensoinInstallMainImgDiv">
                      <div className="extensoinInstallMainImgDivFirst">
                        <img
                          onClick={() =>
                            window.open(
                              "https://chrome.google.com/webstore/detail/lazyapply-job-application/pgnfaifdbfoiehcndkoeemaifhhbgkmm",
                              "_blank"
                            )
                          }
                          src={"/assests/update_extension_main.png"}
                        ></img>
                      </div>
                      <div className="extensoinInstallMainImgDivSecond">
                        <img src={"/assests/arrow_left.svg"}></img>
                      </div>
                    </div>
                    <div className="extensoinInstallMainImgDiv">
                      <div className="extensoinInstallMainImgDivFirst">
                        <img
                          onClick={() =>
                            window.open(
                              "https://chrome.google.com/webstore/detail/lazyapply-job-application/pgnfaifdbfoiehcndkoeemaifhhbgkmm",
                              "_blank"
                            )
                          }
                          src={"/assests/install_extension_main.png"}
                        ></img>
                      </div>
                      <div className="extensoinInstallMainImgDivSecond">
                        <img src={"/assests/arrow_left.svg"}></img>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {showUpdateExtension ? (
              <></>
            ) : (
              <>
                <div className="extensoinInstallOr">OR</div>
                <div className="extensoinInstallSecondDiv">
                  <p>
                    Watch{" "}
                    <span style={{ color: "#007bff", fontWeight: "600" }}>
                      {title}
                    </span>{" "}
                    Lazyapply Automation Demo (↓)
                  </p>
                  <button
                    className="watch_demo_button_final"
                    onClick={() => {
                      mixpanel.track(
                        "watch_demo_button extension_install_popup dashboard",
                        {
                          home: home,
                          href: window.location.href,
                          email: userData?.email,
                          platformName: title,
                          type: showUpdateExtension ? "update" : "install",
                          plan_details: userData?.planDetails || {},
                          v2Id: userData?.planDetails?.v2Id || "",
                        }
                      );
                      onClickWatchDemo();
                    }}
                  >
                    <img src={"/assests/youtube_icon.webp"}></img>
                    Watch Demo
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ExtensionInstallPopup;
