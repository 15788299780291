import React from "react";
import { Card } from "react-bootstrap";
import "./skeletonLoader.css";
const SkeletonLoader = () => {
  return (
    <div className="skeleton-container">
      <div className="row">
        <div className="col">
          <Card className="skeleton-card">
            <Card.Body>
              <div className="skeleton-avatar"></div>
              <div className="skeleton-line"></div>
              <div className="skeleton-line"></div>
              <div className="skeleton-line"></div>
            </Card.Body>
          </Card>
        </div>
        <div className="col">
          <Card className="skeleton-card">
            <Card.Body>
              <div className="skeleton-avatar"></div>
              <div className="skeleton-line"></div>
              <div className="skeleton-line"></div>
              <div className="skeleton-line"></div>
            </Card.Body>
          </Card>
        </div>
        <div className="col">
          <Card className="skeleton-card">
            <Card.Body>
              <div className="skeleton-avatar"></div>
              <div className="skeleton-line"></div>
              <div className="skeleton-line"></div>
              <div className="skeleton-line"></div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="skeleton-post">
            <div className="skeleton-logo"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <button className="skeleton-button"></button>
          </div>
        </div>
        <div className="col">
          <div className="skeleton-post">
            <div className="skeleton-logo"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <button className="skeleton-button"></button>
          </div>
        </div>
        <div className="col">
          <div className="skeleton-post">
            <div className="skeleton-logo"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <button className="skeleton-button"></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
