import React, { useState, useEffect } from "react";
import "./applieddash.css";
import { useStateStore } from "../../store/index";
import { Form, Spinner } from "react-bootstrap";
import axios from "axios";
import CustomAlert from "../modal";
import { useHistory } from "react-router-dom";
import { mixpanel } from "../../index";
import moment from "moment-timezone";
// import AppliedJobSeperate from "./appliedJobSeperate";
// import AppliedJobSeperatePlatform from "./appliedJobSeperatePlatform";
import AppliedJobsTable from "./appliedJobsTable";
import AppliedJobsTableSeperate from "./appliedJobsTableSeperate";

function Applieddash() {
  const streakAnalytics = useStateStore((state) => state.streakAnalytics);
  const timeZone = useStateStore((state) => state.timeZone);
  const history = useHistory();
  const [sessiondata, setsessiondata] = useState([]);
  const userData = useStateStore((state) => state.userData);
  const [clicked, setclicked] = useState(0);
  const token = useStateStore((state) => state.token);
  const [date, setDate] = useState("");
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);

  const globalLogout = useStateStore((state) => state.globalLogout);
  const configDummy = useStateStore((state) => state.configDummy);

  // const [platformJobCount, setPlatformJobCount] = useState({
  //   linkedin: 0,
  //   indeed: 0,
  //   ziprecruiter: 0,
  //   monster: 0,
  //   seek: 0,
  //   careerBuilder: 0,
  //   glassdoor: 0,
  //   dice: 0,
  //   foundit: 0,
  //   simplyHired: 0,
  // });

  // useEffect(() => {
  //   let obj = {
  //     ...platformJobCount,
  //   };
  //   Object.keys(platformJobCount).map((key) => {
  //     obj[key] = streakAnalytics[key];
  //   });
  //   setPlatformJobCount(obj);
  // }, [streakAnalytics]);

  function CheckByLinks() {
    const [process, setProcess] = useState("");
    const [showLinksPlatform, setShowLinksPlatform] = useState(false);
    const [sessionLinksPlatform, setSessionLinksPlatform] = useState([]);
    const [sessionLinksPlatformData, setSessionLinksPlatformData] = useState({
      platformName: "",
      totalApplied: 0,
    });
    const [currentPlatform, setcurrentplatform] = useState("linkedin");

    const setPaginationActivePageNumber = useStateStore(
      (state) => state.setPaginationActivePageNumber
    );
    const getSessionLinksByPlatform = async (platform) => {
      try {
        setPaginationActivePageNumber(1);
        setcurrentplatform(platform);
        setProcess(platform);
        const v2Id = userData?.planDetails?.v2Id;
        if (v2Id) {
          const response = await axios.post(
            `https://backend.lazyapply.com/v2/getSessionLinksWithFilter/${v2Id}`,
            {
              filters: {
                platformName: platform,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setProcess("");
          console.log("response plattt", response.data);
          setSessionLinksPlatform(response.data.totaljobs);
          setSearchingJobsWithFilters(false);
          setShowLinksPlatform(true);
          setSessionLinksPlatformData({
            platformName: platform,
            totalApplied: response.data.totaljobs.length,
          });
        } else {
          setProcess("");
          console.log("v2Id not present");
        }
      } catch (err) {
        setProcess(false);
        console.log("error", err);
      }
    };

    const titleCase = (str) => {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    };

    return (
      <>
        {!showLinksPlatform ? (
          <>
            <div className="dashboardRightTop">
              {[
                "linkedin",
                "indeed",
                "ziprecruiter",
                "monster",
                "seek",
                "careerBuilder",
                "glassdoor",
                "dice",
                "foundit",
                "simplyHired",
              ].map((mainKey) => {
                return (
                  <div
                    className="dailyLimit dashboardRightTopContent dashboardRightPlatform"
                    onClick={() => {
                      getSessionLinksByPlatform(mainKey);
                    }}
                  >
                    <div className="dailyLimitTop">
                      <div className="dailyLimitTopText">Platform Name</div>
                      <div className="limitLeftTopText">Total Jobs</div>
                    </div>
                    <div className="dailyLimitMiddle">
                      <div className="dailyLimitText">
                        {titleCase(mainKey)}{" "}
                        {process == mainKey && (
                          <Spinner
                            animation="border"
                            size="sm"
                            style={{ marginLeft: "5px", marginBottom: "10px" }}
                          />
                        )}
                      </div>
                      <div className="limitLeftText">
                        {configDummy?.jobsByPlatform?.[mainKey] || 0}
                      </div>
                    </div>
                    <div className="dailyLimitBottom">
                      <div className="dailyLimitBottom totalJobsProgress">
                        <p>
                          Click to see all links
                          <i className="fas fa-external-link-alt ml-2"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <>
            {/* <AppliedJobSeperatePlatform
              data={sessionLinksPlatformData}
              goBack={() => setShowLinksPlatform(false)}
              sessionLinks={sessionLinksPlatform}
            /> */}
            <AppliedJobsTable
              totalJobs={configDummy?.jobsByPlatform?.[currentPlatform] || 0}
              data={sessionLinksPlatformData}
              currentPlatform={currentPlatform}
              goBack={() => setShowLinksPlatform(false)}
              sessionLinks={sessionLinksPlatform}
            />
          </>
        )}
      </>
    );
  }

  function indeedLinks(value, index) {
    if (typeof value === "object" && value !== null) {
      return (
        <div key={index} className="linkedinAppliedDetails">
          {typeof value === "object" && value !== null && (
            <>
              <span className="linkedinAppliedDetailsIndex">{index}</span>
              <div>Company Name - {value?.companyName} </div>
              <div>Job Title - {value?.title}</div>
              <div>
                <a href={value.href} target="_blank">
                  {" "}
                  Job Link - <i className="fas fa-external-link-alt ml-2"></i>
                </a>
              </div>
            </>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <a
            href={value}
            target="_blank"
            style={{
              display: "block",
              padding: "5px",
              color: "grey",
            }}
          >
            {" "}
            {index} Job Link Indeed -{" "}
            <i className="fas fa-external-link-alt ml-2"></i>
          </a>
        </div>
      );
    }
  }

  function linkedinLinks(value, index) {
    if (typeof value === "object" && value !== null) {
      return (
        <div key={index} className="linkedinAppliedDetails">
          {typeof value === "object" && value !== null && (
            <>
              <span className="linkedinAppliedDetailsIndex">{index}</span>
              <div>Company Name - {value?.companyName} </div>
              <div>Job Title - {value?.title}</div>
              <div>
                <a href={value.href} target="_blank">
                  {" "}
                  Job Link - <i className="fas fa-external-link-alt ml-2"></i>
                </a>
              </div>
            </>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <a
            href={value}
            target="_blank"
            style={{
              display: "block",
              padding: "5px",
              color: "grey",
            }}
          >
            {" "}
            {index} Job Link Linkedin -{" "}
            <i className="fas fa-external-link-alt ml-2"></i>
          </a>
        </div>
      );
    }
  }
  const [showLinks, setShowLinks] = useState(false);
  const [showSessionLinks, setShowSessionLinks] = useState({});
  const setSessionIdAnalytics = useStateStore(
    (state) => state.setSessionIdAnalytics
  );
  const setSearchingJobsWithFilters = useStateStore(
    (state) => state.setSearchingJobsWithFilters
  );

  function Session() {
    return (
      <>
        <CustomAlert
          message={message}
          s={modalshow}
          title={title}
        ></CustomAlert>
        {!showLinks ? (
          <>
            <Form.Group>
              <Form.Label>Check session by day -</Form.Label>
              <Form.Control
                type="date"
                name="selectdate"
                value={date}
                placeholder="Select a date"
                onChange={(e) => setDate(e.target.value)}
              />
            </Form.Group>
            <div className="row">
              {sessiondata.map((session, index) => {
                return (
                  <>
                    {sessiondata[index]?.aggregateData?.totalCount > 0 && (
                      <div className="col-4" key={index}>
                        <div className="sessiondiv">
                          <div className="sessiontop">
                            <div className="sessionLeft">Total Applied</div>
                            <div className="sessionRight">
                              <span className="sessionPlatformName">
                                {" "}
                                {sessiondata[index]?.platformName || "No Plat."}
                              </span>
                            </div>
                          </div>
                          {userData?.planDetails?.v2Id && (
                            <>
                              <div
                                style={{ display: "block", fontSize: "14px" }}
                              >
                                {`By User Account ->`}{" "}
                              </div>
                              <p style={{ fontSize: "14px", color: "#007bff" }}>
                                {sessiondata[index]?.email}
                              </p>
                              <div
                                style={{ display: "block", fontSize: "14px" }}
                              >
                                {`Resume Choosen ->`}{" "}
                              </div>
                              <p style={{ fontSize: "14px", color: "#007bff" }}>
                                {sessiondata[index]?.resumeName}
                              </p>
                            </>
                          )}
                          <div className="sessionmiddle">
                            <div className="sessionLeft1">
                              {sessiondata[index]?.aggregateData.totalCount ||
                                0}
                            </div>
                            {/* <div className="sessionRight1">
                          {sessiondata[index]?.platformName || "No Plat."}
                        </div> */}
                          </div>
                          <div className="sessionbottom">
                            Skill searched -{" "}
                            <span>
                              {sessiondata[index]?.formData?.skill ||
                                "No skill"}
                            </span>
                          </div>
                          <div
                            className="sessionbottom"
                            onClick={async () => {
                              const v2Id = userData?.planDetails?.v2Id;
                              console.log(
                                "sessioniddd",
                                sessiondata[index].sessionId
                              );
                              console.log("other");
                              setSessionIdAnalytics(
                                sessiondata[index].sessionId
                              );
                              setSearchingJobsWithFilters(true);
                              const response = await axios.post(
                                `https://backend.lazyapply.com/v2/getSessionLinksWithFilter/${v2Id}`,
                                {
                                  filters: {
                                    sessionId: sessiondata[index].sessionId,
                                  },
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${token}`,
                                  },
                                }
                              );
                              console.log("ressss", response);
                              setShowLinks(true);
                              if (
                                sessiondata[index]?.formData?.skill &&
                                response.data.totaljobs?.[0]
                              ) {
                                response.data.totaljobs[0].skill =
                                  sessiondata[index].formData.skill;
                              }
                              setShowSessionLinks(
                                response.data.totaljobs || {}
                              );
                            }}
                            style={{ fontWeight: "bold", cursor: "pointer" }}
                          >
                            Check by links
                            <i className="fas fa-external-link-alt ml-2"></i>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
              {sessiondata.length == 0 && (
                <>
                  <div className="nosessiondata">
                    No session data available for {date}
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div
            style={{
              height: "100%",
              margin: "10px",
            }}
          >
            <AppliedJobsTableSeperate
              goBack={() => setShowLinks(false)}
              showSessionLinks={showSessionLinks}
              userData={userData}
            />
          </div>
        )}
      </>
    );
  }

  function getDataByChoosenDate() {
    mixpanel.identify(userData?.email);
    mixpanel.people.increment(
      "date_selectbox check_session_by_day analytics_page dashboard"
    );
    mixpanel.track(
      "date_selectbox check_session_by_day analytics_page dashboard",
      {
        email: userData?.email,
        href: window.location.href,
        plan_details: userData?.planDetails || {},
        v2Id: userData?.planDetails?.v2Id || "",
      }
    );
    console.log("timeZone", timeZone);
    const v2Id = userData?.planDetails?.v2Id;
    const url = v2Id
      ? `https://backend.lazyapply.com/v2/getSessionsByDate/${v2Id}`
      : "https://backend.lazyapply.com/getsessionbydate";
    axios
      .post(
        url,
        {
          timeZone: timeZone,
          date: date,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        //console.log(response.data);
        response.data.sessions.forEach((data) => console.log(data));
        setsessiondata(response.data.sessions);
      })
      .catch((error) => {
        console.log(error);
        if (error.message == "Request failed with status code 403") {
          console.log("reauthenticate");
          setmessage("Please relogin again!");
          settitle("Not authorized");
          setmodalshow(true);
          setTimeout(() => {
            globalLogout();
            history.push("/");
          }, 1500);
        } else {
          console.log("error");
          setmessage("Some error occured, please try again in some time!");
          settitle("Error");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2500);
        }
      });
  }
  function addzeroes(number, length) {
    var my_string = "" + number;
    while (my_string.length < length) {
      my_string = "0" + my_string;
    }

    return my_string;
  }

  useEffect(() => {
    // Get current date in desired timezone
    const dt = moment.tz(timeZone);
    // Format the date to YYYY-MM-DD format
    const final = dt.format("YYYY-MM-DD");
    console.log(final);
    setDate(final);
  }, [timeZone]);

  useEffect(() => {
    if (date != "" && timeZone != "") {
      getDataByChoosenDate();
    }
  }, [date, timeZone]);

  useEffect(() => {
    mixpanel.identify(userData.email);
    mixpanel.people.increment("analytics_page dashboard");
    mixpanel.track("analytics_page dashboard", {
      email: userData.email,
      href: window.location.href,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
  }, []);

  return (
    <>
      <div
        className="applieddash"
        style={{ overflowX: "hidden", overflowY: "auto" }}
      >
        <div className="applieddashTop">
          <div
            className={
              "applieddashLeft " + (clicked == 0 ? "applieddashactive" : "")
            }
            onClick={() => {
              mixpanel.identify(userData?.email);
              mixpanel.people.increment(
                "session_details_toggle analytics_page dashboard"
              );
              mixpanel.track(
                "session_details_toggle analytics_page dashboard",
                {
                  email: userData?.email,
                  href: window.location.href,
                  plan_details: userData?.planDetails || {},
                  v2Id: userData?.planDetails?.v2Id || "",
                }
              );
              setclicked(0);
            }}
          >
            Session Detail's
          </div>
          <div
            className={
              "applieddashRight " + (clicked == 1 ? "applieddashactive" : "")
            }
            onClick={() => {
              mixpanel.identify(userData?.email);
              mixpanel.people.increment(
                "check_by_platform_toggle analytics_page dashboard"
              );
              mixpanel.track(
                "check_by_platform_toggle analytics_page dashboard",
                {
                  email: userData?.email,
                  href: window.location.href,
                  plan_details: userData?.planDetails || {},
                  v2Id: userData?.planDetails?.v2Id || "",
                }
              );
              setclicked(1);
            }}
          >
            Check by Platform
          </div>
        </div>
        <div className="applieddashMiddle">
          {clicked == 0 ? <Session /> : <CheckByLinks />}
        </div>
      </div>
    </>
  );
}

export default Applieddash;
