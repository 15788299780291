import React, { useEffect, useState } from "react";
import { Form, Col, Spinner } from "react-bootstrap";
import Select from "react-select";
import "./inviteuser.css";
import MainTable from "./mainTableInvites";
// import invites from "./invites.json";
import { useStateStore } from "../../store";
import axios from "axios";

let levels = [
  {
    value: "",
    label: "Select a option",
  },
  {
    value: "level1",
    label: "owner l1 access",
  },
  {
    value: "level2",
    label: "admin l2 access",
  },
  {
    value: "level3",
    label: "subadmin l3 access",
  },
];

function InviteUsers() {
  const searchParams = new URLSearchParams(window.location.search);
  const invitations = searchParams.get("invitations");
  const [invites, setInvites] = useState([]);
  const setPaginationActivePageNumber = useStateStore(
    (state) => state.setPaginationActivePageNumber
  );

  const paginationActivePageNumber = useStateStore(
    (state) => state.paginationActivePageNumber
  );

  const [form, setForm] = useState({
    level: {
      value: "",
      label: "Select a option",
    },
    name: "",
    email: "",
  });

  const setFormMain = (label, value) => {
    setForm({
      ...form,
      [label]: value,
    });
  };

  const organization = useStateStore((state) => state.organization);
  const userData = useStateStore((state) => state.userData);
  const token = useStateStore((state) => state.token);
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  const showErrorFunction = (
    errortext = "Please fill all the details to invite a user"
  ) => {
    setShowError(true);
    setErrorText(errortext);
    setTimeout(() => {
      setShowError(false);
    }, 2000);
  };

  const showSuccessFunction = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 2000);
  };

  const [showSpinner, setShowSpinner] = useState(false);

  const InviteUser = () => {
    setShowSpinner(true);
    if (form.name != "" && form.email != "" && form.level.value != "") {
      axios
        .post(
          `https://backend.lazyapply.com/enterprise-service/organization/${organization.organization_id}/invite/${userData.email}`,
          {
            invitee_email: form.email,
            invitee_role: form.level.value,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setShowSpinner(false);
          showSuccessFunction();
          setForm({
            level: {
              value: "",
              label: "Select a option",
            },
            name: "",
            email: "",
          });
        })
        .catch((err) => {
          console.log("err", err.response.data.message);
          setShowSpinner(false);
          showErrorFunction(err.response.data.message);
          console.log("error", err);
        });
    } else {
      setShowSpinner(false);
      showErrorFunction();
    }
  };

  let sendedInvitations = [
    {
      user_email: "gupta.19@iitj.ac.in",
      role: "level2",
      invited_by: "prakhar@lazyapply.com",
      invited_at: new Date(),
      invite_status: 0,
    },
  ];

  const [type, setType] = useState(-1);

  useEffect(() => {
    if (invitations && invitations != "") {
      setType(1);
    }
  }, [invitations]);

  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "All Invitations",
        columns: [
          {
            Header: "Invited By",
            accessor: "companyName",
            minWidth: 200,
            id: "companyName",
          },
          {
            Header: "Invited To",
            accessor: "jobTitle",
            minWidth: 200,
            id: "jobTitle",
          },
          {
            Header: "Role Level",
            accessor: "jobResume",
            filter: "includes",
            Filter: SelectColumnFilter,
            collapse: true,
          },
          {
            Header: "Invitation Status",
            accessor: "inviteStatus",
            filter: "includes",
            Filter: SelectColumnFilter,
            collapse: true,
          },
          {
            Header: "Date Of Invitation",
            accessor: "jobDate",
            disableFilters: true,
            collapse: true,
          },
        ],
      },
    ],
    []
  );

  const createDate = (createdAt) => {
    if (createdAt) {
      const date = new Date(createdAt);
      return date.toDateString();
    } else {
      return "";
    }
  };

  const newData = (value) => {
    let status = "";
    if (value.invite_status == -1) {
      status = "owner";
    } else {
      status =
        value.invite_status == 1 ? "Invitation Accepted" : "Invitation Pending";
    }

    return {
      companyName: value?.invited_by || "None",
      jobTitle: value?.user_email,
      inviteStatus: status,
      jobResume: value?.role || "",
      jobDate: createDate(value?.invited_at),
    };
  };

  function makeData(lens, data) {
    const makeDataLevel = (depth = 0) => {
      return data.reduce(function (result, d) {
        if (d) {
          console.log("new dataaaa", newData(d));
          result.push({
            ...newData(d),
            subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
          });
        }
        return result;
      }, []);
    };

    return makeDataLevel();
  }

  const [tabledata, settabledata] = useState([]);
  const [tabledata1, settabledata1] = useState([]);
  const [invites1, setInvites1] = useState([]);
  const userInvitations = useStateStore((state) => state.userInvitations);
  useEffect(() => {
    if (
      organization?.organization_id &&
      token != "" &&
      type == 1 &&
      userData?.email
    ) {
      axios
        .get(
          `https://backend.lazyapply.com/enterprise-service/user/${userData.email}/invitation`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          let mainInvites = response.data;
          console.log("response1", mainInvites);
          mainInvites = mainInvites.filter((x) => x.invited_status != -1);
          setInvites1(mainInvites);
          settabledata1(makeData(mainInvites.length, mainInvites));
        })
        .catch((err) => {
          console.log("error");
        });

      axios
        .post(
          `https://backend.lazyapply.com/enterprise-service/organization/${organization.organization_id}/user_multiple_invitations`,
          {
            user_emails: [
              ...userInvitations.invited_users.map((x) => x.user_email),
              userData.email,
            ],
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log("response_main", response);
          let mainInvites = [];
          response.data.map((r) => {
            r.invited_users.map((obj) => {
              obj.invited_by = r.user_email;
              mainInvites.push(obj);
              mainInvites.push(r.main_user);
            });
          });
          const removeDuplicates = (array) => {
            const unique = array
              .map((item) => JSON.stringify(item)) // Convert objects to strings
              .filter((item, index, self) => self.indexOf(item) === index) // Keep unique strings
              .map((item) => JSON.parse(item)); // Convert strings back to objects

            return unique;
          };
          setInvites(removeDuplicates(mainInvites));
          settabledata(
            makeData(
              removeDuplicates(mainInvites).length,
              removeDuplicates(mainInvites)
            )
          );
        })
        .catch((err) => {
          console.log("error_main", err);
        });
    }
  }, [organization, token, userInvitations, userData, type]);

  return (
    <>
      {type == -1 && (
        <div className="invite_user_select_option">
          <p className="invite_user_select_option_heading">Select A Option</p>
          <div className="row">
            <div className="col-lg-2"></div>
            <div
              className="col-lg-4 invite_user_select_option_main_block"
              onClick={() => setType(0)}
            >
              Invite A User
            </div>
            <div
              className="col-lg-4 invite_user_select_option_main_block"
              onClick={() => setType(1)}
            >
              Your Invitations
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      )}
      {type == 0 && (
        <div className="invite_user_main_block">
          <button
            className="btn invite_user_main_block_back_button"
            onClick={() => setType(-1)}
          >
            Back
          </button>
          <div className="invite_user_headline">Invite A User</div>
          <Form.Group as={Col} md={12}>
            <div className="invite_user_form_input_block">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setFormMain("name", e.target.value)}
                id="name"
                value={form.name}
                aria-describedby="passwordHelpBlock"
              />
            </div>
            <div className="invite_user_form_input_block">
              <Form.Label htmlFor="inputskill">Email</Form.Label>
              <Form.Control
                type="email"
                onChange={(e) => setFormMain("email", e.target.value)}
                id="email"
                value={form.email}
              />
            </div>
            <div className="invite_user_form_input_block">
              <Form.Label>Level Of Access</Form.Label>
              <Select
                value={{
                  label: form.level.label,
                  value: form.level.value,
                }}
                id="user_level"
                onChange={(value) => {
                  console.log("value", value);
                  setFormMain("level", value);
                }}
                placeholder="Select Major"
                options={[
                  ...levels.map((x) => {
                    return { value: x.value, label: x.label };
                  }),
                ]}
                isMulti={false}
              />
            </div>
          </Form.Group>
          <p className="invite_user_error_success">
            {showError ? (
              <p className="invite_user_error">{errorText}</p>
            ) : (
              <></>
            )}
            {showSuccess ? (
              <p className="invite_user_success">Invited successfully</p>
            ) : (
              <></>
            )}
          </p>

          <button
            className="invite_user_main_button"
            onClick={() => {
              InviteUser();
            }}
          >
            Send Invitation{" "}
            {showSpinner && (
              <Spinner
                animation="border"
                size="sm"
                style={{ marginLeft: "5px" }}
              />
            )}
          </button>
          <p className="invite_user_view_all" onClick={() => setType(1)}>
            View all sended invitations{" "}
            <i className="fas fa-external-link-alt ml-2"></i>
          </p>
        </div>
      )}
      {type == 1 && (
        <div className="invite_your_invitations_block">
          <button
            className="btn invite_user_main_block_back_button"
            onClick={() => setType(-1)}
          >
            Back
          </button>
          {invites.length > 0 ? (
            <>
              <div className="user_invite_invitations_block">
                <p className="user_invite_invitations_heading">
                  All Invitations
                </p>
                <div className="appliedJobsTable">
                  <MainTable
                    totalJobs={invites.length}
                    columns={columns}
                    data={tabledata}
                    paginationActivePageNumber={paginationActivePageNumber}
                  />
                </div>
                {/* <p className="user_invite_invitations_heading">
                  Your Pending Invites
                </p>
                <div className="user_invite_invitations">
                  <div className="row">
                    {invites.map((key, index) => {
                      const data = key;
                      return (
                        <div
                          className="col-12"
                          style={{
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <div className="row">
                            <div className="col-9 user_invite_invited_by">
                              <div>
                                {data.role} Invitation by
                                <span> {data.invited_by}</span>
                              </div>
                            </div>
                            <div className="col-3 user_invite_invitation_button">
                              {data.invite_status == 1 ? (
                                <div className="user_invite_accepted_invitation">
                                  Invite Accepted
                                </div>
                              ) : (
                                <div
                                  onClick={() => {}}
                                  className="user_invite_accept_invitation"
                                  style={{ cursor: "pointer" }}
                                >
                                  Accept Invitation
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div> */}
              </div>
            </>
          ) : (
            <>
              <div className="user_invite_invitations_block">
                <p className="user_invite_invitations_heading">
                  Your Pending Invites
                </p>

                <div
                  className="user_invite_invitations"
                  style={{ width: "100%" }}
                >
                  No pending invites
                </div>
              </div>
            </>
          )}

          <div className="user_invite_invitations_block">
            <p className="user_invite_invitations_heading">
              Invitations You Received
            </p>
            <div className="appliedJobsTable">
              <MainTable
                totalJobs={invites.length}
                columns={columns}
                data={tabledata1}
                paginationActivePageNumber={paginationActivePageNumber}
              />
            </div>

            {/* <div className="user_invite_invitations">
                    <div className="row">
                      {invites.map((key, index) => {
                        const data = key;
                        return (
                          <div
                            className="col-12"
                            style={{
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            <div className="row">
                              <div className="col-9 user_invite_invited_by">
                                <div>
                                  Invitation to
                                  <span> {data.user_email}</span>
                                </div>
                              </div>
                              <div className="col-3 user_invite_invitation_button">
                                <div className="user_invite_accepted_invitation">
                                  {data.role}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
          </div>
        </div>
      )}
    </>
  );
}

export default InviteUsers;
