import React from "react";
import { useHistory } from "react-router-dom";
import './payment-success.css'

function PaymentSuccess() {
  const history = useHistory();
  return (
    <div className="subscribebox">
      <div className="subscribeboxchild">
        <div className="subscribeboxTop">
          <div className="subscribeboxTopHeading">LazyApply</div>
          <div>
            <img
              src={"/assests/panda1.png"}
              alt="payment-success"
            />
          </div>
        </div>
        <div className="Result">
          <div className="ResultTitle" role="alert">
            Payment successful
            <i className="far fa-check-circle ml-2"></i>
          </div>
          <div className="ResultMessage">
            Payment is successful. Plan benifits will be added in next 5
            minutes.
            <button onClick={() => history.push("/dashboard")}>
              Go to home page
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccess;
