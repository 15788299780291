import React, { useEffect } from "react";
import "./privacy.css";
import { mixpanel } from "../index";
import { useStateStore } from "../store/index";

function Refundpolicy() {
  const userData = useStateStore((state) => state.userData);
  useEffect(() => {
    mixpanel.identify(userData?.email);
    mixpanel.people.increment("refund_policy_page dashboard");
    mixpanel.track("refund_policy_page dashboard", {
      email: userData?.email,
      href:window.location.href,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
  }, []);

  return (
    <>
      <div className="mainprivacy" style={{ zIndex: 100 }}>
        <h1
          className="mainprivacyh"
          style={{
            marginTop: 10,
            fontSize: 32,
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          Return &amp; Refund Policy
        </h1>
        <p className="p6">
          <br />
        </p>
        <h1>Definitions and key terms</h1>
        <p className="p8">
          <span className="s1">
            To help explain things as clearly as possible in this Return &amp;
            Refund Policy, every time any of these terms are referenced, are
            strictly defined as:
          </span>
        </p>
        <p className="p9">
          <br />
        </p>
        <ul className="ul1">
          <li className="li8">
            <span className="s1">
              <strong>Company:</strong> when this policy mentions “Company,”
              “we,” “us,” or “our,” it refers to <a>LazyApply </a>,
              <a>Amrutha Valley, Banjara Hills, Hyderabad </a> that is
              responsible for your information under this Return &amp; Refund
              Policy.
              <br />
            </span>
          </li>
          <li className="li8">
            <span className="s1">
              <strong>Customer:</strong> refers to the company, organization or
              person that signs up to use the Service to manage the
              relationships with your consumers or service users.
              <br />
            </span>
          </li>
          <li className="li8">
            <span className="s1">
              <strong>Device:</strong> any internet connected device such as a
              phone, tablet, computer or any other device that can be used to
              visit and use the services.
              <br />
            </span>
          </li>
          <li className="li8">
            <span className="s1">
              <strong>Service:</strong> refers to the service provided by
              <a>LazyApply</a> as described in the relative terms (if available)
              and on this platform.
              <br />
            </span>
          </li>
          <li className="li8">
            <span className="s1">
              <strong>Website:</strong> <a>LazyApply</a>’s site, which can be
              accessed via this URL: <a>https://lazyapply.com.</a>
              <br />
            </span>
          </li>
          <li className="li8">
            <span className="s1">
              <strong>You:</strong> a person or entity that is registered with
              <a>LazyApply</a> to use the Services.
              <br />
            </span>
          </li>
        </ul>
        <p className="p3">
          <br />
        </p>
        <h1>Shipping</h1>
        <p className="p10">
          <span className="s1">
            <a>LazyApply</a> is not responsible for return shipping costs. Every
            shipping has to be paid by the customer, even if the item had free
            shipping in the first place, the customer has to pay for the
            shipping in return.
          </span>
        </p>
        <p className="p3">
          <br />
        </p>
        <h1>Return &amp; Refund Policy</h1>
        <p className="p10">
          <span className="s1">
            Thanks for shopping at <a>LazyApply</a>. We appreciate the fact that
            you like to buy the stuff we build. We also want to make sure you
            have a rewarding experience while you’re exploring, evaluating, and
            purchasing our products.
          </span>
        </p>
        <p className="p10">
          <span className="s1">
            As with any shopping experience, there are terms and conditions that
            apply to transactions at <a>LazyApply</a>. We’ll be as brief as our
            attorneys will allow. The main thing to remember is that by placing
            an order or making a purchase at <a>LazyApply</a>, you agree to the
            terms set forth below along with <a>LazyApply</a>’s&nbsp;Privacy
            Policy.
          </span>
        </p>
        <p className="p10">
          <span className="s1">
            If there’s something wrong with the product you bought, or if you
            are not happy with it, you will not be able to issue a refund for
            your item.
          </span>
        </p>
        <p className="p3">
          <br />
        </p>
        <h1>Refunds</h1>
        <p className="p10">
          <span className="s1">
            We at&nbsp;<a>LazyApply</a>&nbsp;commit ourselves to serving our
            customers with the best products. Every single product that you
            choose is thoroughly inspected, checked for defects and packaged
            with utmost care. We do this to ensure that you fall in love with
            our products. <br />
            <br />
            Sadly, there are times when we may not have the product(s) that you
            choose in stock, or may face some issues with our inventory and
            quality check. In such cases, we may have to cancel your order. You
            will be intimated about it in advance so that you don't have to
            worry unnecessarily about your order. If you have purchased via
            Online payment (not Cash on Delivery), then you will be refunded
            once our team confirms your request.
            <br />
            <br />
            We carry out thorough quality check before processing the ordered
            item. We take utmost care while packing the product. At the same
            time we ensure that the packing is good such that the items won’t
            get damaged during transit. Please note that <a>LazyApply</a> is not
            liable for damages that are caused to the items during transit or
            transportation. <br />
            <br />
            We follow certain policies to ensure transparency, efficiency and
            quality customer care:
          </span>
        </p>
        <li className="li16">
          <span className="s3">
            We DO NOT allow returns on sold products - online or in retail
            outlets.
          </span>
        </li>
        <li className="li16">
          <span className="s3">
            We DO NOT accept returned goods, as we believe that customers should
            get the best quality products.
          </span>
        </li>
        <li className="li16">
          <span className="s3">
            Refunds are NOT given for any purchases made - be they online or in
            retail store.
          </span>
        </li>
        <li className="li16">
          <span className="s3">
            We DO NOT encourage exchanges of our products.
          </span>
        </li>
        <li className="li16">
          <span className="s3">
            We DO NOT engage in reselling used products and discourage the same,
            because we cannot ensure the best quality products for our
            customers.
          </span>
        </li>
        <p className="p10">
          <span className="s1">For International Orders:</span>
        </p>
        <li className="li16">
          <span className="s3">We DO NOT support Exchanges or Returns.</span>
        </li>
        <li className="li16">
          <span className="s3">
            If you cancel the order before we process it and dispatch for
            shipping, a refund can be processed. Orders generally take 1-2 days
            to process before dispatch.
          </span>
        </li>
        <li className="li16">
          <span className="s3">
            Orders already in shipping cannot be returned, canceled or refunded.
          </span>
        </li>
        <li className="li16">
          <span className="s3">
            If you face any issues, please contact our Support Team immediately.
          </span>
        </li>
        <p className="p3">
          <br />
        </p>
        <h1>Your Consent</h1>
        <p className="p10">
          <span className="s1">
            By using our platform, registering an account, or making a purchase,
            you hereby consent to our Return &amp; Refund Policy and agree to
            its terms.
          </span>
        </p>
        <p className="p3">
          <br />
        </p>
        <h1>Changes To Our Return &amp; Refund Policy</h1>
        <p className="p10">
          <span className="s1">
            Should we update, amend or make any changes to this document so that
            they accurately reflect our Service and policies. Unless otherwise
            required by law, those changes will be prominently posted here.
            Then, if you continue to use the Service, you will be bound by the
            updated Return &amp; Refund Policy. If you do not want to agree to
            this or any updated Return &amp; Refund Policy, you can delete your
            account.
          </span>
        </p>
        <p className="p3">
          <br />
        </p>
        <h1>Contact Us</h1>
        <p className="p10">
          <span className="s1">
            If, for any reason, You are not completely satisfied with any good
            or service that we provide, don't hesitate to contact us and we will
            discuss any of the issues you are going through with our product.
          </span>
        </p>
        <ul className="ul1">
          <li className="li16">
            <span className="s3">
              Via Email: <a>info@lazyapply.com</a>
            </span>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Refundpolicy;
