import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import StripeComponent from "./StripeComponent";
// import imgstripe1 from "./assests/security-icon.png"
function BnplModal({ showProp, hideShow, planName }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    hideShow();
  };

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      backdrop="static"
      className="stripemodal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Buy Now Pay Later - {planName.toUpperCase()} Plan{" "}
          <img
            className="stripeimgsecure"
            src={'/assests/security-icon.png'}
          ></img>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ color: "black" }}>
          Get your LazyApply Subscription now and We will automatically deduct
          the money on start of next month
        </p>
        <StripeComponent planName={planName} />
      </Modal.Body>
    </Modal>
  );
}

export default BnplModal;
