/*global chrome*/
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Form, Col, Row, FormCheck } from "react-bootstrap";
import "./jobplatform.css";
import { useHistory } from "react-router-dom";
import Progress from "./progress";
import axios from "axios";
import { useStateStore } from "../../store/index";
import CustomAlert from "../modal";
import { mixpanel } from "../../index";
import Select from "react-select";
import Feedback from "../feedback";
import MoreFiltersHelp from "../morefiltershelp";
import { v1 as uuidv1 } from "uuid";
import faqObj from "./faqJson";
import LinkedinFetchFiltersArticle from "./linkedinFetchFiltersArticle";
import mainFilters from "../filters.json";
import ResumeJobScore from "./resumeJobScore/resumeJobScore";
// import { isEqual } from "lodash";

// import StepsWithCut from "../stepswithcut";
// import CreatableSelect from "react-select/creatable";
import { MESSAGETYPE, messages, MESSAGES } from "./slackMessages";
import AdvanceSearchModal from "./AdvanceSearchModal";
import { GrantAccess } from "./grantAccess";
import SaveSearchModal from "./saveSearchModal";
import GetSavedSearches from "./getSearches";
import ExtensionInstallPopup from "./ExtensionInstallPopup";
import MainWatchDemoModal from "./MainWatchDemoModal";
// import ExactModal from "./exactModal";

function Linkedin() {
  const setScriptInjectedGlobal = useStateStore(
    (state) => state.setScriptInjectedGlobal
  );
  const scriptInjectedGlobal = useStateStore(
    (state) => state.scriptInjectedGlobal
  );
  const setExtensionPresent = useStateStore(
    (state) => state.setExtensionPresent
  );
  const setUserResumeV3 = useStateStore((state) => state.setUserResumeV3);
  const [showPlanRequired, setShowPlanRequired] = useState(false);
  const { availableChromeVersion } = useStateStore.getState();
  const [showPlanPurchase, setShowPlanPurchase] = useState(false);
  const [showDemoPopup, setShowDemoPopup] = useState(false);
  const [showExtensionInstallPopup, setShowExtensionInstallPopup] =
    useState(false);
  const [showUpdateExtension, setShowUpdateExtension] = useState(false);
  const setStreakAnalytics = useStateStore((state) => state.setStreakAnalytics);
  const streakAnalytics = useStateStore((state) => state.streakAnalytics);
  const yourExtensionId = useStateStore((state) => state.yourExtensionId);
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const setGlobalTabId = useStateStore((state) => state.setGlobalTabId);
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);
  const [selectedSearchData, setSelectedSearchData] = useState({
    searchId: "",
    searchName: "",
  });
  const [searchInputValue, setSearchInputValue] = useState({
    value: "",
    label: "Select a saved search setting",
  });
  const [showSavedSearchButton, setShowSavedSearchButton] = useState(true);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [showSavedSearchModal, setShowSavedSearchModal] = useState(false);
  const [excludeString, setExcludeString] = useState("");
  const [mayIncludeString, setMayIncludeString] = useState("");
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const userResumeV3 = useStateStore((state) => state.userResumeV3);
  const [resumeId, setResumeId] = useState("");
  const resumeData = useRef({});
  const [openHelper, setOpenHelper] = useState(false);
  const [resumeChanged, setResumeChanged] = useState(false);
  const setheightMain = useStateStore((state) => state.setheightMain);
  const [resumeDisable, setResumeDisable] = useState(false);
  const [urlMain, setUrlMain] = useState("");
  const token = useStateStore((state) => state.token);
  const [errors, setErrors] = useState({});
  const [filter, setFilter] = useState(0);
  const mainheight = useRef(null);
  const [jobCompanyOptions, setJobCompanyOptions] = useState([]);
  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [jobFunctionOptions, setJobFunctionOptions] = useState([]);
  const [jobWorkplaceOptions, setJobWorkplaceOptions] = useState([]);
  const [jobIndustryOptions, setJobIndustryOptions] = useState([]);
  const [jobSalaryOptions, setJobSalaryOptions] = useState([]);
  const [jobBenifitOptions, setJobBenifitOptions] = useState([]);
  const formMain1 = useRef(null);
  const selectResumeRef = useRef(null);
  const resumeRef1 = useRef(null);
  const resumeRef2 = useRef(null);
  const resumeRef3 = useRef(null);
  const chooseParaText = useRef(null);
  const [formDisable, setformDisable] = useState(false);
  const spinner = useRef(null);
  const fetchbutton = useRef(null);
  const button1 = useRef(null);
  const [disable2, setdisable2] = useState(0);
  const saveSessionState = useRef(0);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const globalLogout = useStateStore((state) => state.globalLogout);
  const history = useHistory();
  const userData = useStateStore((state) => state.userData);
  const userLinksUpdate = useStateStore((state) => state.userLinksUpdate);
  const configDummy = useStateStore((state) => state.configDummy);
  const configDummyUpdate = useStateStore((state) => state.configDummyUpdate);
  const [startApplying, setstartApplying] = useState(false);
  const sessionLinks = useRef([]);
  const sessionLinksBefore = useRef([]);
  const [progressPercent, setProgressPercent] = useState(15);
  const [count, setCount] = useState(0);
  const totalCountUpdate = useStateStore((state) => state.totalCountUpdate);
  const formRef = useRef({});
  const c = useRef(0);
  const [finalJobCount, setFinalJobCount] = useState(0);
  const pp = useRef(15);
  const processing = useRef(null);
  const initprocessingFinal = useRef(null);
  const initprocessing = useRef(null);
  const formMain = useRef(null);
  const buttonFetch = useRef(null);
  const questionRefs = useRef([]);
  const [showIndeedModal, setShowIndeedModal] = useState(null);
  const [modalTitle, setModalTitle] = useState("Issue");

  const [resumeLinkedin, setResumeLinkedin] = useState(false);
  const [resumeArr, setResumeArr] = useState(null);
  const [resumeInfo, setResumeInfo] = useState(null);
  const sessionId = useRef(null);

  useEffect(() => {
    let vall1 = window.localStorage.getItem("resumeInfo");
    let vall2 = window.localStorage.getItem("resumeArr");
    if (vall1 && !resumeInfo) {
      setResumeInfo(JSON.parse(vall1));
    }
    if (vall2 && !resumeArr) {
      setResumeArr(JSON.parse(vall2));
    }
  }, []);

  function smoothScrollToTop() {
    let start = null;
    const initialScrollTop = window.scrollY;

    function easeOutCubic(t) {
      return 1 - Math.pow(1 - t, 3);
    }

    function step(timestamp) {
      if (start === null) start = timestamp;
      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / 1000, 1); // take 2 seconds to scroll

      const easedProgress = easeOutCubic(progress);
      const newScrollTop = initialScrollTop * (1 - easedProgress);

      window.scrollTo(0, newScrollTop);

      if (newScrollTop > 0) {
        window.requestAnimationFrame(step);
      }
    }

    window.requestAnimationFrame(step);
  }

  const setLinkedinRunning = useStateStore((state) => state.setLinkedinRunning);
  const [form, setForm] = useState({
    jobtype: [],
    experience: [],
    skill: "",
    location: "",
    numberOfJobs: 0,
    datePosted: "",
    industry: [],
    jobFunction: [],
    jobUnder10: false,
    jobCompany: [],
    jobTitle: [],
    jobWorkplace: [],
    jobBenifits: [],
    jobSalary: "",
    resumeJobScore: 40,
  });

  const experienceOptions = [
    {
      value: "1",
      label: "Internship",
    },
    {
      value: "2",
      label: "Entry level",
    },
    {
      value: "3",
      label: "Associate",
    },
    {
      value: "4",
      label: "Mid-Senior level",
    },
    {
      value: "5",
      label: "Director",
    },
    {
      value: "6",
      label: "Executive",
    },
  ];

  const jobTypeOptions = [
    {
      value: "F",
      label: "Full-time",
    },
    {
      value: "P",
      label: "Part-time",
    },
    {
      value: "C",
      label: "Contract",
    },
    {
      value: "T",
      label: "Temporary",
    },
    {
      value: "V",
      label: "Volunteer",
    },
    {
      value: "I",
      label: "Internship",
    },
  ];

  const closeModal = () => {
    setShowIndeedModal(false);
  };

  let buttonMain1 = {
    backgroundColor: "#35AD46",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "10px 10px",
    textAlign: "center",
    borderRadius: "10px",
    outline: "none",
    border: "none",
  };

  let buttonMain = {
    backgroundColor: "grey",
    color: "white",
    cursor: "default",
    fontSize: "16px",
    padding: "10px 10px",
    textAlign: "center",
    borderRadius: "10px",
    outline: "none",
    border: "none",
  };

  const handleCloseSearch = () => {
    setShowAdvanceSearch(false);
  };

  const setField = (field, value) => {
    console.log(field, value);
    setForm({
      ...form,
      [field]: value,
    });

    // Check and see if errors exist, and remove them from the error object:
    console.log(form);

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const handleSubmit = async (e) => {
    console.log("handle submit");
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    console.log(newErrors);
    // Conditional logic:
    if (userData?.planDetails?.v2Id) {
      //v2
      if (resumeId === "" && Object.keys(userResumeV3).length > 0) {
        setResumeChanged(true);
      } else if (Object.keys(newErrors).length > 0) {
        // We got errors!
        setErrors(newErrors);
      } else {
        // No errors! Put any logic here for the form submission!
        if (await fetchFilterUserHandler()) {
          startLinkedinAutomation1();
        }
        // startLinkedinAutomation();
      }
    } else {
      if (Object.keys(newErrors).length > 0) {
        // We got errors!
        setErrors(newErrors);
      } else {
        // No errors! Put any logic here for the form submission!
        if (await fetchFilterUserHandler()) {
          startLinkedinAutomation1();
        }
        // startLinkedinAutomation();
      }
    }
  };

  const findFormErrors = () => {
    const { skill, numberOfJobs } = form;
    const newErrors = {};
    // name errors
    if (!skill || skill === "") newErrors.skill = "Please enter a skill!";
    if (numberOfJobs <= 0)
      newErrors.numberOfJobs = "Please enter the no. of jobs to apply";

    if (numberOfJobs < 20 && configDummy.planName != "free") {
      if (configDummy.dailyLimit - configDummy.todaysessioncount <= 20) {
        console.log("go for it");
      } else {
        newErrors.numberOfJobs = "Please enter a value more than 35";
      }
    } else if (numberOfJobs < 35 && configDummy.planName == "free") {
      if (configDummy.dailyLimit - configDummy.todaysessioncount < 35) {
        console.log("go for it");
      } else {
        newErrors.numberOfJobs = "Please enter a value more than 35";
      }
    }

    return newErrors;
  };

  const styleform = {
    padding: "20px",
    paddingTop: "0px",
  };

  const filtersStyle = {
    padding: "20px",
    boxShadow: "0px 2px 4px 2px lightgrey, 0px 2px 4px 2px #efefef",
    margin: "5px",
    position: "relative",
  };

  function goBack() {
    console.log("go back");
    mixpanel.identify(userData?.email);
    mixpanel.people.increment("go_back_button linkedin_page dashboard");
    mixpanel.track("go_back_button dashboard", {
      href: window.location.href,
      email: userData?.email,
      platformName: "linkedin",
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    history.goBack();
  }

  const stringToArray = (string) => {
    return string
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s.length > 0);
  };

  function startLinkedinAutomation1() {
    spinner.current.style.display = "inline-block";

    if (
      configDummy.dailyLimit - configDummy.todaysessioncount >=
        form.numberOfJobs ||
      !userData.planDetails.planStarted
    ) {
      fetchbutton.current.disabled = true;
      formRef.current = {
        ...form,
      };

      console.log(form.skill + "skill");
      console.log(form.location + "location");
      let url = `https://linkedin.com/jobs/`;
      mixpanel.identify(userData?.email);
      mixpanel.people.increment(
        "click_to_fetch_filter_button linkedin_page dashboard"
      );
      mixpanel.track("click_to_fetch_filter_button linkedin_page dashboard", {
        href: window.location.href,
        email: userData?.email,
        skill: form.skill,
        location: form.location,
        numberOfJobs: form.numberOfJobs,
        linkedinResumeInfo: resumeInfo,
        linkedinResumeArr: resumeArr,
        excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
        mayIncludeKeywords: advanceSearch
          ? stringToArray(mayIncludeString)
          : [],
        plan_details: userData?.planDetails || {},
        v2Id: userData?.planDetails?.v2Id || "",
      });
      setUrlMain(url);
      setLinkedinRunning(1);
      if (typeof chrome !== "undefined" && chrome && chrome.runtime) {
        chrome.runtime.sendMessage(yourExtensionId, {
          dashboard: true,
          saveValues: {
            excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
            mayIncludeKeywords: advanceSearch
              ? stringToArray(mayIncludeString)
              : [],
            linkedinFetchFilters: 0,
            linkedinLimit: Math.min(form.numberOfJobs, 500),
            linkedinJobLinks: [],
            uniquesessionid: uuidv1(),
            linkedinResumeInfo: resumeInfo,
            linkedinResumeArr: resumeArr,
            linkedinLocation: form.location,
            linkedinSkill: form.skill,
            linkedinPageNumber: 1,
            resumeJobScore: form.resumeJobScore,
            userProvidedJobTitle: form.skill,
            unappliedJobsDueToScore: 0,
          },
          linkedin: true,
          message: "linkedinFetchFilters",
          data: {
            url: `${url}&start=0`,
            selected: true,
            linkedin: true,
            message: "tabid",
          },
        });
      }
    } else {
      spinner.current.style.display = "none";
      if (configDummy.dailyLimit - configDummy.todaysessioncount == 0) {
        setmessage("You have used your daily limit! Come back tomorrow :)");
        settitle("Daily limit exceeded!");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 2500);
        setTimeout(() => {
          history.push("/dashboard");
        }, 3000);
      } else {
        setmessage(
          `You have applied to ${
            configDummy.todaysessioncount
          } jobs today. Please enter a value less than ${
            configDummy.dailyLimit - configDummy.todaysessioncount
          }`
        );
        settitle("Daily limit update!");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 2500);
      }
    }
  }

  function makeFilter(value) {
    let string = "";
    for (let index = 0; index < value.length; index++) {
      const element = value[index].value;
      if (index == 0) {
        string += element;
      } else {
        string += "%2C" + element;
      }
    }
    return string;
  }

  function startLinkedinAutomation() {
    setLinkedinRunning(1);
    setstartApplying(true);
    formRef.current = {
      ...form,
    };
    mixpanel.identify(userData?.email);
    mixpanel.people.increment("start_applying_button linkedin_page dashboard");
    mixpanel.track("start_applying_button linkedin_page dashboard", {
      href: window.location.href,
      email: userData?.email,
      skill: form.skill,
      location: form.location,
      numberOfJobs: form.numberOfJobs,
      linkedinResumeInfo: resumeInfo,
      linkedinResumeArr: resumeArr,
      excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
      mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
      formData: formRef.current,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    saveSessionState.current = 0;
    setResumeDisable(true);
    button1.current.style.backgroundColor = "grey";
    button1.current.style.color = "black";
    button1.current.innerText = "Process Started!";
    console.log(form.skill + "skill");
    console.log(form.location + "location");
    console.log("Filters selected according to which  now everything will run");
    console.log("Run the main process again");
    const filter3Value = form.datePosted;
    const filter1Value = form.jobSalary;
    let url = urlMain;
    let string3 = "&f_TPR=" + filter3Value;
    let string1 = "&f_SB2=" + filter1Value;

    if (makeFilter(form.experience) != "") {
      url = url + "&f_E=" + makeFilter(form.experience);
    }
    if (makeFilter(form.jobtype) != "") {
      url = url + "&f_JT=" + makeFilter(form.jobtype);
    }
    if (makeFilter(form.jobCompany) != "") {
      url = url + "&f_C=" + makeFilter(form.jobCompany);
    }
    if (makeFilter(form.jobFunction) != "") {
      url = url + "&f_F=" + makeFilter(form.jobFunction);
    }
    if (makeFilter(form.jobTitle) != "") {
      url = url + "&f_T=" + makeFilter(form.jobTitle);
    }
    if (makeFilter(form.jobWorkplace) != "") {
      url = url + "&f_WT=" + makeFilter(form.jobWorkplace);
    }
    if (makeFilter(form.jobBenifits) != "") {
      url = url + "&f_BE=" + makeFilter(form.jobBenifits);
    }
    if (makeFilter(form.industry) != "") {
      url = url + "&f_I=" + makeFilter(form.industry);
    }

    if (filter3Value != "") url = url + string3;
    if (filter1Value != "") url = url + string1;
    if (form.jobUnder10) {
      url = url + "&f_EA=true";
    }

    console.log(url, "final url --------------------------------linkedin");
    initprocessing.current.style.display = "flex";
    smoothScrollToBottom();
    sessionId.current = uuidv1();
    console.log("current sessionId", sessionId.current);
    if (typeof chrome !== "undefined" && chrome && chrome.runtime)
      chrome.runtime.sendMessage(yourExtensionId, {
        dashboard: true,
        saveValues: {
          linkedinSessionId: sessionId.current,
          excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
          mayIncludeKeywords: advanceSearch
            ? stringToArray(mayIncludeString)
            : [],
          linkedinBaseUrl: url,
          linkedinLimit: Math.min(form.numberOfJobs, 500),
          linkedinJobLinks: [],
          linkedinResumeId: resumeId,
          session: {
            platformName: "linkedin",
            formData: formRef.current,
          },
          resumeData: resumeData.current,
        },
        linkedin: true,
        message: "linkedinStartApplying",
        data: {
          url: `${url}&start=0`,
          selected: true,
          linkedin: true,
          message: "setBaseUrl",
          linkedinBaseUrl: url,
        },
      });
  }

  const handleSubmit1 = (e) => {
    e.preventDefault();
    console.log("Filter Submit", form);
    startLinkedinAutomation();
  };

  const closeExtensionInstallPopup = () => {
    setShowExtensionInstallPopup(false);
  };

  const onClickWatchDemo = () => {
    setShowExtensionInstallPopup(false);
    setShowDemoPopup(true);
  };

  let chromeExtensionPresent = async () => {
    let extensionPresentMain = 0;
    if (typeof chrome !== "undefined" && chrome && chrome.runtime) {
      console.log("check1");
      extensionPresentMain = await new Promise((resolve, reject) => {
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            lazyapply: "lazyapply",
            message: "check",
            injected: scriptInjectedGlobal,
          },
          function (response) {
            // Do whatever you want, background script is ready now
            var lastError = chrome.runtime.lastError;
            if (lastError) {
              console.log("error", lastError.message);

              // 'Could not establish connection. Receiving end does not exist.'
              resolve(0);
            }
            console.log("check0", response);
            if ("status" in response && response.status == "installed") {
              setScriptInjectedGlobal(true);
              setExtensionPresent(true);
              console.log("check2");

              resolve(1);
            }
          }
        );
      });
      console.log("extensionpresent", extensionPresentMain);
      return extensionPresentMain;
    } else {
      extensionPresentMain = 0;
      return extensionPresentMain;
    }
  };

  const fetchFilterUserHandler = async () => {
    if (userData.planDetails.planStarted) {
      const response = await GrantAccess("linkedin");
      if (!response.access) {
        if (response.resultObj.extensionNotPresent) {
          setShowExtensionInstallPopup(true);
        } else if (response.resultObj.updateExtension) {
          setShowUpdateExtension(true);
          setShowExtensionInstallPopup(true);
        } else {
          setmessage(response.resultObj.message);
          settitle(response.resultObj.title);
          setmodalshow(true);
          if (response?.resultObj?.gotolinknew) {
            window.open(response.resultObj.gotolinknew, "_blank");
          }
          if (response?.resultObj?.gotolink) {
            history.push(response.resultObj.gotolink);
          }
        }
        return 0;
      } else {
        return 1;
      }
    } else {
      if (resumeId == "") setResumeId("demo_id_lazyapply");
      const extensionPresent = await chromeExtensionPresent();
      console.log("heree111", extensionPresent);
      if (!extensionPresent) {
        setShowExtensionInstallPopup(true);
        return 0;
      } else if (
        chromeVersion != availableChromeVersion &&
        chromeVersion != -1 &&
        availableChromeVersion != -1
      ) {
        setShowExtensionInstallPopup(true);
        setShowUpdateExtension(true);
        return 0;
      } else {
        return 1;
      }
    }
  };

  function filterReset() {
    selectExperienceLevel?.current?.select?.clearValue();
    selectJobType?.current?.select?.clearValue();
    selectJobFunction?.current?.select?.clearValue();
    selectJobIndustry?.current?.select?.clearValue();
    selectJobTitle?.current?.select?.clearValue();
    selectJobWorkplace?.current?.select?.clearValue();
    selectJobCompany?.current?.select?.clearValue();
    selectJobBenifit?.current?.select?.clearValue();
    if (document.getElementById("jobSalary")) {
      document.getElementById("jobSalary").value = "";
    }
    document.getElementById("dateposted").value = "";
    document.getElementById("switchEnabled").checked = false;
    setForm({
      ...form,
      jobtype: [],
      experience: [],
      datePosted: "",
      industry: [],
      jobFunction: [],
      jobUnder10: false,
      jobCompany: [],
      jobTitle: [],
      jobWorkplace: [],
      jobBenifits: [],
      jobSalary: "",
    });
  }
  const selectJobFunction = useRef(null);
  const selectJobCompany = useRef(null);
  const selectJobIndustry = useRef(null);
  const selectJobTitle = useRef(null);
  const selectJobWorkplace = useRef(null);
  const selectExperienceLevel = useRef(null);
  const selectJobBenifit = useRef(null);
  const selectJobType = useRef(null);
  function linkedinReset() {
    console.log("Linkein Reset");
    if (typeof chrome !== "undefined" && chrome && chrome.runtime)
      chrome.runtime.sendMessage(yourExtensionId, {
        dashboard: true,
        linkedin: true,
        message: "resetlinkedin",
      });
  }

  function getUnappliedJobsDueToResumeScore() {
    console.log("Unapplied jobs due to resume score");

    if (typeof chrome !== "undefined" && chrome && chrome.runtime)
      chrome.runtime.sendMessage(
        yourExtensionId,
        {
          dashboard: true,
          linkedin: true,
          message: "totalUnappliedJobsDueToResumeScore",
        },
        function (response) {
          if (chrome.runtime.lastError) {
            console.error(chrome.runtime.lastError);
          } else {
            console.log("Received response:", response);
          }
        }
      );
  }

  function resetFull() {
    mixpanel.identify(userData?.email);
    mixpanel.people.increment("reset_button linkedin_page dashboard");
    mixpanel.track("reset_button dashboard", {
      href: window.location.href,
      email: userData?.email,
      platformName: "linkedin",
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    if (saveSessionState.current == 0) {
      saveSession(c.current, "linkedin");
    }
    resumeData.current = {};
    window.wasRun = false;
    setShowPlanPurchase(false);
    setResumeId("");
    setResumeDisable(false);
    if (selectResumeRef.current)
      selectResumeRef.current.style.display = "block";
    chooseParaText.current.style.display = "none";
    setformDisable(false);
    setLinkedinRunning(0);
    // setExactSearch(false);
    // setExtraSkillValue([]);
    // setExtraSkills("");
    formMain1.current.style.display = "none";
    sessionLinks.current = [];
    sessionLinksBefore.current = [];
    fetchbutton.current.disabled = false;
    buttonFetch.current.style.display = "block";
    setShowSavedSearchButton(true);
    setForm({
      jobtype: [],
      experience: [],
      skill: "",
      location: "",
      numberOfJobs: 0,
      datePosted: "",
      industry: [],
      jobFunction: [],
      jobUnder10: false,
      jobCompany: [],
      jobTitle: [],
      jobWorkplace: [],
      jobBenifits: [],
      jobSalary: "",
      resumeJobScore: 40,
    });
    setAdvanceSearch(false);
    setMayIncludeString("");
    setExcludeString("");
    setSelectedSearchData({
      searchId: "",
      searchName: "",
    });
    setSearchInputValue({
      value: "",
      label: "Select a saved search setting",
    });
    linkedinReset();
    filterReset();
    formRef.current = {};
    document.getElementById("inputskill").value = "";
    document.getElementById("inputlocation").value = "";
    document.getElementById("inputnumber").value = "";
    document.getElementById("sliderResumeJobScore").value = 40;
    c.current = 0;
    pp.current = 15;
    button1.current.style.backgroundColor = "#F8F9FA";
    button1.current.style.color = "#007bff";
    button1.current.innerText = "Start Applying";
    setProgressPercent(15);
    initprocessingFinal.current.style.display = "none";
    formMain.current.reset();
    setstartApplying(false);
    setCount(0);
  }

  const messageFn = useCallback((event) => {
    console.log("linkedinmessage", event.data);
    if (
      typeof event.data === "object" &&
      !Array.isArray(event.data) &&
      event.data !== null
    ) {
      console.log("linkedinmainmessage", event.data);
      h(event.data);
      event.source.postMessage("finished", event.origin);
    }
  }, []);

  const checkIfPresent = () => {
    if (selectedSearchData.searchName != "") {
      return true;
    } else {
      return false;
    }
  };

  const hideSavedSearchModal = () => {
    setShowSavedSearchModal(false);
  };

  const chromeVersion = useStateStore((state) => state.chromeVersion);

  function h(request) {
    if ("linkedin" in request) {
      if ("message" in request) {
        console.log("message receveiddd", request);
        if (request.message == "Applied successfully") {
          // let countlinkedin = count;
          // countlinkedin++;
          // console.log(countlinkedin)
          console.log(request.link, "link------------");
          sessionLinks.current.push(request.link);
          ++c.current;
          pp.current = Math.floor(
            (c.current / formRef.current.numberOfJobs) * 100
          );
          setCount(c.current);
          setProgressPercent(pp.current);
          console.log("update count");
        } else if (request.message == "Already Applied Before") {
          sessionLinksBefore.current.push(request.link);
        } else if (request.message == "resumeArr") {
          console.log("resumeArrrr", request.resumeArr);
          setResumeArr(request.resumeArr.slice(0, 5));
          window.localStorage.setItem(
            "resumeArr",
            JSON.stringify(request.resumeArr.slice(0, 5))
          );
        } else if (request.message == "filterValues") {
          if (request.initUrl) {
            // console.log('init urllllll', request.initUrl);
            setUrlMain(request.initUrl.replace(/\/?#?\/?$/, ""));
          }
          if (request.filterValues.length == 0) {
            fetchbutton.current.disabled = false;
            spinner.current.style.display = "none";
            setLinkedinRunning(0);
            // console.log("Try again for better input details");
            if (request.message2 == "signInRequired") {
              setmessage(
                "You're logged out of linkedin. Try again after logging in!"
              );
            } else {
              setmessage("Try again with better input details!");
            }
            settitle("No results found");
            setmodalshow(true);
            messages()[MESSAGES.NOFILTERSFETCHED](
              MESSAGETYPE.USER_REPORT_FRONTEND,
              MESSAGES.NOFILTERSFETCHED,
              `${userData.email},${userData.name},${userData.planDetails.planName}`
            );
            setTimeout(() => {
              setmodalshow(false);
              if (request.message2 == "signInRequired") {
                window.open("https://www.linkedin.com/login", "_blank");
                window.location.reload();
              }
            }, 2500);
            resetFull();
          } else {
            setLinkedinRunning(0);
            setformDisable(true);
            //perform all the filter operations
            let jobcompanyOptions = [];
            let jobtitleOptions = [];
            let jobindustryOptions = [];
            let jobfunctionOptions = [];
            let jobWorkplaceOptions = [];
            let jobSalaryOptions = [];
            let jobBenifitOptions = [];
            console.log("allfilterslinkedin", request.filterValues);
            request.filterValues.forEach((filter) => {
              let filterObj = {};
              if ("name" in filter && "text" in filter) {
                filterObj.label = filter.text.split("\n")[0];
                if (filter?.name.includes("advanced-filter-timePostedRange-")) {
                  console.log(
                    filter?.name.split("advanced-filter-timePostedRange-"),
                    "time posted"
                  );
                  filterObj.value = filter?.name.split(
                    "advanced-filter-timePostedRange-"
                  )[1];
                } else if (filter?.name.includes("advanced-filter-company-")) {
                  filterObj.value = filter?.name.split(
                    "advanced-filter-company-"
                  )[1];
                  jobcompanyOptions.push(filterObj);
                } else if (
                  filter?.name.includes("advanced-filter-populatedPlace-")
                ) {
                  filterObj.value = filter?.name.split(
                    "advanced-filter-populatedPlace-"
                  )[1];
                } else if (filter?.name.includes("advanced-filter-industry-")) {
                  filterObj.value = filter?.name.split(
                    "advanced-filter-industry-"
                  )[1];
                  jobindustryOptions.push(filterObj);
                } else if (filter?.name.includes("advanced-filter-function-")) {
                  filterObj.value = filter?.name.split(
                    "advanced-filter-function-"
                  )[1];
                  jobfunctionOptions.push(filterObj);
                } else if (filter?.name.includes("advanced-filter-title-")) {
                  filterObj.value = filter?.name.split(
                    "advanced-filter-title-"
                  )[1];
                  jobtitleOptions.push(filterObj);
                } else if (
                  filter?.name.includes("advanced-filter-workplaceType-")
                ) {
                  filterObj.value = filter?.name.split(
                    "advanced-filter-workplaceType-"
                  )[1];
                  jobWorkplaceOptions.push(filterObj);
                } else if (
                  filter?.name.includes("advanced-filter-salaryBucketV2-")
                ) {
                  filterObj.value = filter?.name.split(
                    "advanced-filter-salaryBucketV2-"
                  )[1];
                  jobSalaryOptions.push(filterObj);
                } else if (filter?.name.includes("advanced-filter-benefits-")) {
                  filterObj.value = filter?.name.split(
                    "advanced-filter-benefits-"
                  )[1];
                  jobBenifitOptions.push(filterObj);
                } else {
                  console.log("Not required");
                }
              }
            });

            setJobFunctionOptions(jobfunctionOptions);
            setJobIndustryOptions(jobindustryOptions);
            setJobCompanyOptions(jobcompanyOptions);
            setJobTitleOptions(jobtitleOptions);
            setJobWorkplaceOptions(jobWorkplaceOptions);
            setJobBenifitOptions(jobBenifitOptions);
            setJobSalaryOptions(jobSalaryOptions);
            chooseParaText.current.style.display = "block";
            spinner.current.style.display = "none";
            formMain1.current.style.display = "block";
            buttonFetch.current.style.display = "none";
            setShowSavedSearchButton(false);
            if (typeof chrome !== "undefined" && chrome && chrome.runtime)
              chrome.runtime.sendMessage(yourExtensionId, {
                dashboard: true,
                saveValues: {
                  linkedinFetchFilters: 1,
                },
                linkedin: true,
                message: "linkedinFiltersFetched",
              });
            // Call the function
            smoothScrollToBottom();
          }
        } else if (request.message == "debugSession") {
          fetchbutton.current.disabled = false;
          spinner.current.style.display = "none";
          setLinkedinRunning(0);
          setmessage(
            "We have detected some issue in LazyApply. Our team has been notified and we will fix this issue within next 1 hour. You have to do nothing in your part, this will be automatically fixed. \n\nThank you for being a wonderful customer."
          );
          settitle("Some problem occured");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 20000);
          resetFull();
        }
        if (request.message == "applypage") {
          if ("message2" in request) {
            console.log("Request.message2------>", request.message2);
            if (request.message2 == "completed") {
              console.log("completed full");
              // ++c.current;
              // setCount(c.current);
              setLinkedinRunning(0);
              button1.current.innerText = "Completed";
              processing.current.style.display = "none";
              smoothScrollToTop();

              const v2Id = userData?.planDetails?.v2Id;
              axios
                .post(
                  `https://backend.lazyapply.com/v2/jobCountInLastSession/${v2Id}`,
                  { sessionId: sessionId.current },
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  }
                )
                .then((response) => {
                  console.log("first response", response.data);
                  setFinalJobCount(response.data.jobCount || 0);
                  initprocessingFinal.current.style.display = "flex";
                  initprocessing.current.style.display = "none";
                })
                .catch((err) => {
                  console.log("error in request", err);
                });

              if (selectResumeRef.current)
                selectResumeRef.current.style.display = "none";
              if (resumeRef1.current) resumeRef1.current.style.display = "none";
              if (resumeRef2.current) resumeRef2.current.style.display = "none";
              if (resumeRef3.current) resumeRef3.current.style.display = "none";

              pp.current = 100;
              setProgressPercent(pp.current);
              console.log(form);
              console.log(formMain.current.children);
              if (!window.wasRun) {
                messages()[MESSAGES.ZEROJOBSAPPLIED](
                  MESSAGETYPE.USER_REPORT_FRONTEND_LINKEDIN,
                  MESSAGES.TOTALJOBSAPPLIED,
                  c.current,
                  JSON.stringify(formRef.current),
                  `${userData.email},total jobs applied this session ${c.current},${userData.name},${userData.planDetails.planName}`,
                  chromeVersion
                );
                saveSession(c.current, "linkedin");
                window.wasRun = true;
              }
            } else if (request.message2 == "planRequired") {
              setShowPlanPurchase(true);
              setLinkedinRunning(0);
              button1.current.innerText = "Completed";
              processing.current.style.display = "none";
              smoothScrollToTop();
              initprocessingFinal.current.style.display = "flex";
              initprocessing.current.style.display = "none";
              if (selectResumeRef.current)
                selectResumeRef.current.style.display = "none";
              if (resumeRef1.current) resumeRef1.current.style.display = "none";
              if (resumeRef2.current) resumeRef2.current.style.display = "none";
              if (resumeRef3.current) resumeRef3.current.style.display = "none";
              linkedinReset();
            } else if (
              request.message2 ==
              "No results found, try changing the filter values"
            ) {
              console.log("no results found");
              setmessage("Try again for better input details!");
              settitle("No results found");
              setmodalshow(true);
              setTimeout(() => {
                setmodalshow(false);
              }, 2500);
              setLinkedinRunning(0);
              button1.current.innerText = "Completed";
              processing.current.style.display = "none";
              if (selectResumeRef.current)
                selectResumeRef.current.style.display = "none";
              pp.current = 100;
              setProgressPercent(pp.current);
              saveSession(c.current, "linkedin");
            } else if (request.message2 == "unauthorized") {
              //  alert("unauthorised");
              fetchbutton.current.disabled = false;
              setLinkedinRunning(0);
              console.log("reauthenticate");
              setmessage("Please relogin again!");
              settitle("Not authorized");
              setmodalshow(true);
              setTimeout(() => {
                globalLogout();
                history.push("/");
              }, 1500);
              resetFull();
            }
          }
        }
      }
    }
  }

  function saveSession(count, platformName) {
    console.log(formRef.current);
    console.log(saveSessionState.current, "savesessionstate0");
    if (
      (count > 0 && saveSessionState.current == 0) ||
      (count == 0 &&
        saveSessionState.current == 0 &&
        sessionLinksBefore.current.length > 0)
    ) {
      getUnappliedJobsDueToResumeScore();
      console.log(saveSessionState.current, "savesessionstate1");
      let todaysessioncount = configDummy.todaysessioncount + count;
      let totalJobs = configDummy.totalJobs + count;
      let linkedinJobsApplied = configDummy.jobsByPlatform["linkedin"] + count;
      console.log(configDummy.totalJobs, count, "yooooo");
      let totalsessions = configDummy.totalsessions + 1;
      let totalsessionstoday = configDummy.totalsessionstoday + 1;
      // totalCountUpdate({
      //   linkedinJobsApplied: linkedinJobsApplied,
      //   todaysessioncount: todaysessioncount,
      //   totalJobs: totalJobs,
      //   totalsessions: totalsessions,
      //   totalsessionstoday: totalsessionstoday,
      // });
      let linkedinJobApplicationLinksFinal = [...sessionLinks.current];
      if ("linkedinJobApplicationLinksFinal" in userData) {
        linkedinJobApplicationLinksFinal = [
          ...userData.linkedinJobApplicationLinksFinal,
          ...linkedinJobApplicationLinksFinal,
        ];
      }
      // setStreakAnalytics({
      //   ...streakAnalytics,
      //   linkedin: streakAnalytics.linkedin + count,
      // });
      let totalsession = configDummy.totalsession;
      configDummyUpdate({
        ...configDummy,
        jobsByPlatform: {
          ...configDummy.jobsByPlatform,
          linkedin: linkedinJobsApplied,
        },
        todaysessioncount: todaysessioncount,
        totalJobs: totalJobs,
        totalsessions: totalsessions,
        totalsessionstoday: totalsessionstoday,
        totalsession: [
          ...totalsession,
          {
            resumeData: resumeData.current,
            sessionLinks: sessionLinks.current,
            sessionLinksBefore: sessionLinksBefore.current,
            platformName: "linkedin",
            form: formRef.current,
            count: count,
            email: userData.email,
          },
        ],
      });
      userLinksUpdate({
        linkedinJobApplicationLinksFinal: linkedinJobApplicationLinksFinal,
      });
      console.log(sessionLinks.current, "current------------");
      // setSaveSession(1);
      saveSessionState.current = 1;
      const v2Id = userData?.planDetails?.v2Id;
      const url = v2Id
        ? `https://backend.lazyapply.com/v2/saveSession/${v2Id}`
        : "http://backend.lazyapply.com/savesessionv2";

      axios
        .post(
          url,
          {
            sessionLinks: sessionLinks.current,
            sessionLinksBefore: sessionLinksBefore.current,
            resumeData: resumeData.current,
            session: {
              count: count,
              platformName: platformName,
              form: formRef.current,
            },
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          linkedinReset();
          console.log(response);
          setmessage("Process completed!");
          settitle("Success");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2500);
        })
        .catch((error) => {
          linkedinReset();
          console.log(error);
          if (error.message == "Request failed with status code 403") {
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
              history.push("/");
            }, 1500);
          } else {
            console.log("error");
            setmessage("Some error occured, please try again in some time!");
            settitle("Error");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2500);
          }
        });
    } else {
      getUnappliedJobsDueToResumeScore();
      saveSessionState.current = 1;
      linkedinReset();
    }
  }

  const [resumefetch, setresumefetch] = useState(false);
  const fetchAllResumes = (v2Id) => {
    axios
      .get(
        `https://backend.lazyapply.com/v2/resumesV3/checkResumesValidation/${v2Id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setresumefetch(true);
        setUserResumeV3(response.data.resumes);
      })
      .catch((err) => {
        setresumefetch(true);
        console.log("some error occured");
      });
  };

  useEffect(() => {
    const v2Id = userData?.planDetails?.v2Id;
    if (v2Id) fetchAllResumes(v2Id);
  }, [userData]);

  // useEffect(() => {
  //   questionRefs.current.forEach((question) => {
  //     question.addEventListener("click", (event) => {
  //       const active = document.querySelector(".question.active");
  //       if (active && active !== question) {
  //         active.classList.toggle("active");
  //         active.nextElementSibling.style.maxHeight = 0;
  //       }
  //       question.classList.toggle("active");
  //       const answer = question.nextElementSibling;
  //       if (question.classList.contains("active")) {
  //         answer.style.maxHeight = answer.scrollHeight + "px";
  //       } else {
  //         answer.style.maxHeight = 0;
  //       }
  //     });
  //   });
  // }, [pp.current]);

  function smoothScrollToBottom() {
    let start = null;
    const initialScrollTop = window.scrollY;
    const targetScrollTop = document.body.scrollHeight - window.innerHeight;

    function easeOutCubic(t) {
      return 1 - Math.pow(1 - t, 3);
    }

    function step(timestamp) {
      if (start === null) start = timestamp;
      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / 500, 1); // take 2 seconds to scroll

      const easedProgress = easeOutCubic(progress);
      const newScrollTop =
        initialScrollTop + (targetScrollTop - initialScrollTop) * easedProgress;

      window.scrollTo(0, newScrollTop);

      if (newScrollTop < targetScrollTop) {
        window.requestAnimationFrame(step);
      }
    }

    window.requestAnimationFrame(step);
  }
  useEffect(() => {
    questionRefs.current.forEach((question) => {
      const clickHandler = (event) => {
        const active = document.querySelector(".question.active");
        if (active && active !== question) {
          active.classList.toggle("active");
          active.nextElementSibling.style.maxHeight = 0;
        }
        question.classList.toggle("active");
        const answer = question.nextElementSibling;
        if (question.classList.contains("active")) {
          answer.style.maxHeight = answer.scrollHeight + "px";
        } else {
          answer.style.maxHeight = 0;
        }
      };

      if (question) {
        question.addEventListener("click", clickHandler);
      }

      return () => {
        question.removeEventListener("click", clickHandler);
      };
    });
    if (c.current == 0) {
      smoothScrollToBottom();
    }
  }, [pp.current]);

  useEffect(() => {
    if (advanceSearch) {
      setShowAdvanceSearch(true);
    }
  }, [advanceSearch]);

  useEffect(() => {
    if (form.skill != "" && form.numberOfJobs > 0 && resumeId != "") {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
  }, [form, resumeId]);

  useEffect(() => {
    console.log(
      "selected search data --> do processing here",
      selectedSearchData
    );
    if (selectedSearchData.form) {
      setForm((formData) => {
        let newFormData = {
          ...formData,
          numberOfJobs: selectedSearchData.form.numberOfJobs,
          skill: selectedSearchData.form.skill,
          location: selectedSearchData.form.location,
        };
        return newFormData;
      });
    } else {
      setForm((formData) => {
        let newFormData = {
          ...formData,
          numberOfJobs: 0,
          skill: "",
          location: "",
        };
        return newFormData;
      });
    }
    if (selectedSearchData.advanceSearch) {
      setAdvanceSearch(selectedSearchData.advanceSearch.advanceSearch);
      setExcludeString(selectedSearchData.advanceSearch.excludeString);
      setMayIncludeString(selectedSearchData.advanceSearch.mayIncludeString);
    } else {
      setAdvanceSearch(false);
      setExcludeString("");
      setMayIncludeString("");
    }
    if (selectedSearchData.extraConfig) {
      if (selectedSearchData.extraConfig.resumeId) {
        const found = userResumeV3.filter((key) => {
          return key.resumeId == selectedSearchData.extraConfig.resumeId;
        });
        if (found.length > 0) {
          userResumeV3.map((key) => {
            const item = key;
            if (item.resumeId === selectedSearchData.extraConfig.resumeId) {
              resumeData.current = {
                resumeId: item.resumeId,
                resumeName: item.resumename,
              };
            }
          });
          setResumeId(selectedSearchData.extraConfig.resumeId);
        } else {
          setResumeId("");
        }
      } else {
        setResumeId("");
      }
    } else {
      setResumeId("");
    }
  }, [selectedSearchData]);

  useEffect(() => {
    console.log("added");
    window.addEventListener("message", messageFn);
    return () => {
      console.log("removed");
      window.removeEventListener("message", messageFn);
    };
  }, []);

  useEffect(() => {
    console.log("Sending initial request to extension");
    const usermain = JSON.parse(window.localStorage.getItem("user") || "{}");
    const tokenmain = window.localStorage.getItem("token");
    if (
      tokenmain != "" &&
      usermain != undefined &&
      Object.keys(usermain).length > 0
    ) {
      if (typeof chrome !== "undefined" && chrome && chrome.runtime)
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            dashboard: true,
            saveValues: {
              token: tokenmain,
              user: usermain,
            },
            injected: scriptInjectedGlobal,
            global: true,
            message: "loginToken",
          },
          (response) => {
            console.log(
              "response from extension",
              response,
              chrome.runtime.lastError
            );
            var lastError = chrome.runtime?.lastError;
            if (lastError) {
              setScriptInjectedGlobal(false);
              setExtensionPresent(false);
              history.push("/dashboard");
              return;
            } else {
              console.log("response of initial request", response);
              setScriptInjectedGlobal(true);
              setGlobalTabId(response.globalTabId);
            }
          }
        );
    }
  }, []);

  useEffect(() => {
    let height = 0;
    // const checking = localStorage.getItem("stepchecklinkedin");
    // if (checking && checking == "true") {
    //   setChecks(true);
    // }
    if (mainheight) height = mainheight.current.scrollHeight;
    // if (document.querySelector(".dashboardRightEnd")) {
    //   document.querySelector(".dashboardRightEnd").style.height = height + "px";
    //   // if (!checks) {
    //   //   document.querySelector(".dashboardRightEnd").style.height = "inherit";
    //   // }
    // }
    if (document.querySelector(".dashboardLeft"))
      document.querySelector(".dashboardLeft").style.height = height + "px";
    setheightMain(height);
    mixpanel.identify(userData.email);
    mixpanel.people.increment("linkedin_page dashboard");
    mixpanel.track("linkedin_page dashboard", {
      href: window.location.href,
      email: userData?.email,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    console.log("config", configDummy);
    if (initprocessingFinal.current)
      initprocessingFinal.current.style.display = "none";
    if (initprocessing.current) initprocessing.current.style.display = "none";
    if (formMain1.current) formMain1.current.style.display = "none";
    if (chooseParaText.current) chooseParaText.current.style.display = "none";
    if (spinner.current) spinner.current.style.display = "none";
    linkedinReset();
  }, []);

  useEffect(() => {
    let fc = 0;
    Object.keys(form).forEach((k) => {
      if (
        k === "jobTitle" ||
        k === "jobCompany" ||
        k === "jobFunction" ||
        k === "industry" ||
        k === "jobWorkplace" ||
        k === "experience" ||
        k === "experience" ||
        k === "jobBenifits"
      ) {
        form[k].length > 0 ? fc++ : console.log("do nothing");
      } else if (k === "datePosted" || k === "jobSalary") {
        form[k] != "" ? fc++ : console.log("do nothing");
      } else if (k === "jobUnder10") {
        form[k] ? fc++ : console.log("do nothing");
      } else {
        console.log("do nothing");
      }
    });
    if (fc > 1) {
      setOpenHelper(true);
    }
  }, [form]);

  useEffect(() => {
    console.log("ppcurrent", pp.current);
    if (pp.current === 100) {
      if (formMain.current) formMain.current.style.display = "none";
      if (formMain1.current) formMain1.current.style.display = "none";
      if (button1.current) button1.current.style.display = "none";
      if (chooseParaText.current) chooseParaText.current.style.display = "none";
    } else {
      if (formMain.current) formMain.current.style.display = "block";
      if (button1.current) button1.current.style.display = "block";
    }
  }, [pp.current]);

  // const [checks, setChecks] = useState(false);
  // const [exactSearch, setExactSearch] = useState(false);
  // const hideExactSearchModal = () => {
  //   setExactSearchShow(false);
  // };
  // const [exactSearchShow, setExactSearchShow] = useState(false);
  // const [extraSkillValue, setExtraSkillValue] = useState([]);
  // const [extraSkills, setExtraSkills] = useState("");
  // const createOption = (label) => ({
  //   label,
  //   value: label,
  // });
  // const handleKeyDown = (event) => {
  //   if (!extraSkills) return;
  //   switch (event.key) {
  //     case "Enter":
  //     case "Tab":
  //       setExtraSkillValue((prev) => [...prev, createOption(extraSkills)]);
  //       setExtraSkills("");
  //       event.preventDefault();
  //   }
  // };

  const demoSubmit = () => {
    mixpanel.track("start_auto_apply_button dashboard", {
      href: window.location.href,
      email: userData?.email,
      platformName: "linkedin",
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    setTimeout(() => {
      history.push("/pricing");
    }, 200);
  };

  const showFilters = () => {
    setLinkedinRunning(0);
    setJobFunctionOptions(mainFilters.data.linkedin.jobfunctionOptions);
    setJobIndustryOptions(
      mainFilters.data.linkedin.jobBenifitOptionsjobindustryOptions
    );
    setJobCompanyOptions(mainFilters.data.linkedin.jobcompanyOptions);
    setJobTitleOptions(mainFilters.data.linkedin.jobtitleOptions);
    setJobWorkplaceOptions(mainFilters.data.linkedin.jobWorkplaceOptions);
    setJobBenifitOptions(mainFilters.data.linkedin.jobBenifitOptions);
    setJobSalaryOptions(mainFilters.data.linkedin.jobSalaryOptions);
    chooseParaText.current.style.display = "block";
    spinner.current.style.display = "none";
    formMain1.current.style.display = "block";
    buttonFetch.current.style.display = "none";
    setShowSavedSearchButton(false);
    smoothScrollToBottom();
  };

  const checkIfDemoAndNoPlan = () => {
    return !userData.planDetails.planStarted && showDemo;
  };

  const showDemo = useStateStore((state) => state.showDemo);
  useEffect(() => {
    const fn = async () => {
      const result = await GrantAccess("linkedin");
      console.log("grantAccess", result);
      if (!result.access && !showDemo) {
        history.push("/dashboard");
      } else {
        if (checkIfDemoAndNoPlan()) {
          showFilters();
        }
      }
    };
    fn();
  }, [userData]);

  return (
    <>
      {/* <ExactModal
        exactSearchShow={exactSearchShow}
        hideExactSearchModal={hideExactSearchModal}
      /> */}
      <MainWatchDemoModal
        platform="Linkedin"
        showDemoPopup={showDemoPopup}
        customFn={() => {
          setShowDemoPopup(false);
        }}
      ></MainWatchDemoModal>
      <ExtensionInstallPopup
        title={"Linkedin"}
        onClickWatchDemo={onClickWatchDemo}
        s={showExtensionInstallPopup}
        closeFn={closeExtensionInstallPopup}
        showUpdateExtension={showUpdateExtension}
      />
      <div style={{ position: "relative" }} ref={mainheight}>
        {/* {!checks && (
          <>
            <button
              className="buttonGoBack btn buttonSetup"
              onClick={() => {
                localStorage.setItem("stepchecklinkedin", true);
                setChecks(true);
              }}
            >
              Skip Setup Steps
            </button>
            <button className="buttonGoBack btn" onClick={goBack}>
              <i className="fas fa-arrow-left"></i> Go Back
            </button>
            <div className="stepswithcutmain">
              <p className="stepswithcutmainheading">
                One time setup steps for Linkedin
              </p>
              <StepsWithCut
                platform="linkedin"
                setComplete={(value) => {
                  if (value) {
                    localStorage.setItem("stepchecklinkedin", true);
                  }
                  setChecks(value);
                }}
              />
            </div>
          </>
        )} */}
        {/* style={{ display: !checks ? "none" : "block" }} */}
        <div>
          <MoreFiltersHelp
            openHelper={openHelper}
            setOpenHelper={setOpenHelper}
          />
          <CustomAlert
            message={message}
            s={modalshow}
            title={title}
          ></CustomAlert>
          <p className="startApplying">Start Applying on linkedin</p>
          {/* <button
            className="buttonGoBack btn buttonSetup"
            onClick={() => setChecks(false)}
          >
            <i className="fas fa-wrench"></i> One Time Setup Steps
          </button> */}
          <button className="buttonGoBack btn" onClick={goBack}>
            <i className="fas fa-arrow-left"></i> Go Back
          </button>
          {userData?.planDetails?.v2Id && (
            <div className="v2resumeSelect" ref={selectResumeRef}>
              <p className="makesure" style={{ marginBottom: "40px" }}>
                Make sure to login in the linkedin account of the person with
                the selected resume and his/her linkedin profile should be
                completed before applying
              </p>
              <SaveSearchModal
                platformName="linkedin"
                showProp={showSavedSearchModal}
                hideShow={hideSavedSearchModal}
                form={{ ...form }}
                advanceSearch={{
                  advanceSearch: advanceSearch,
                  excludeString,
                  mayIncludeString,
                }}
                extraConfig={{
                  resumeId: resumeId,
                }}
                setSelectedSearchData={setSelectedSearchData}
                setSavedSuccessfully={setSavedSuccessfully}
                selectedSearchData={selectedSearchData}
              />
              <GetSavedSearches
                platformName="linkedin"
                savedSuccessfully={savedSuccessfully}
                setSelectedSearchData={setSelectedSearchData}
                searchInputValue={searchInputValue}
                setSearchInputValue={setSearchInputValue}
              />
              <Form.Group>
                <Form.Label>Choose a Profile to apply jobs for</Form.Label>
                {Object.keys(userResumeV3).length == 0 ||
                !userResumeV3.some((resume) => resume.validation === 1) ? (
                  <>
                    <Form.Control
                      as="select"
                      id="resumeId"
                      value={"demo_id_lazyapply"}
                      onChange={(e) => {
                        if (e.target.value == "create_own_profile") {
                          mixpanel.track(
                            "create_your_own_profile_select dashboard",
                            {
                              href: window.location.href,
                              email: userData?.email,
                              platformName: "linkedin",
                              plan_details: userData?.planDetails || {},
                              v2Id: userData?.planDetails?.v2Id || "",
                            }
                          );
                          history.push("/dashboard/editresumesv2");
                        }
                      }}
                    >
                      <option value="demo_id_lazyapply">
                        Demo Profile Selected
                      </option>
                      <option value="create_own_profile">
                        Create Your Own Profile
                      </option>
                    </Form.Control>
                  </>
                ) : (
                  <>
                    <Form.Control
                      as="select"
                      id="resumeId"
                      disabled={resumeDisable}
                      isInvalid={resumeChanged}
                      value={resumeId}
                      onChange={(e) => {
                        if (e.target.value != "") {
                          setResumeChanged(false);
                        }

                        userResumeV3.map((key) => {
                          const item = key;
                          if (item.resumeId === e.target.value) {
                            resumeData.current = {
                              resumeId: item.resumeId,
                              resumeName: item.resumename,
                            };
                          }
                        });
                        setResumeId(e.target.value);
                      }}
                    >
                      <option value="">Select a resume</option>
                      {userResumeV3.map((key) => {
                        const item = key;
                        if (key.validation == 1)
                          return (
                            <option value={item.resumeId}>
                              {item.resumename}
                            </option>
                          );
                      })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select a resume/cv
                    </Form.Control.Feedback>
                  </>
                )}
              </Form.Group>
            </div>
          )}

          <div
            class={resumeArr ? "lknResumeFullBorder" : "lknResumeFull"}
            ref={resumeRef3}
          >
            {resumeInfo && (
              <Form.Group>
                <Form.Label
                  style={{ padding: "20px 20px 0 20px", width: "100%" }}
                >
                  Selected Resume for Upload on Linkedin
                </Form.Label>
              </Form.Group>
            )}

            {resumeArr && !resumeInfo && (
              <>
                <div class="row">
                  <div class="col-12">
                    <Form.Group>
                      <Form.Label
                        style={{ padding: "25px 20px 0px 30px", width: "100%" }}
                      >
                        Select Resume for Linkedin
                        {resumeArr.length > 0 && (
                          <a
                            className="btn btn-primary"
                            style={{ float: "right", marginRight: "40px" }}
                            href="https://www.linkedin.com/jobs/application-settings/"
                            target="_blank"
                          >
                            Upload new
                          </a>
                        )}
                      </Form.Label>
                    </Form.Group>
                  </div>

                  {resumeArr.length > 0 &&
                    resumeArr.map((arr, index) => (
                      <div class="col-md-5 d-flex lknResumeCol">
                        <div className="form-check form-check-inline custom-radio d-flex align-items-center">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id={`flexRadioDefault${index}`}
                            onClick={() => {
                              mixpanel.track(
                                "resume_selected_linkedin linkedin_page dashboard",
                                {
                                  email: userData?.email,
                                  plan_details: userData?.planDetails || {},
                                  v2Id: userData?.planDetails?.v2Id || "",
                                }
                              );
                              setResumeInfo({
                                index: index,
                                data: resumeArr[index],
                              });
                              window.localStorage.setItem(
                                "resumeInfo",
                                JSON.stringify({
                                  index: index,
                                  data: resumeArr[index],
                                })
                              );
                            }}
                          />
                        </div>

                        <div
                          class="col-4 myclass d-flex align-items-center"
                          onClick={() => {
                            setResumeInfo({
                              index: index,
                              data: resumeArr[index],
                            });
                            window.localStorage.setItem(
                              "resumeInfo",
                              JSON.stringify({
                                index: index,
                                data: resumeArr[index],
                              })
                            );
                          }}
                        >
                          <div class="redBox">PDF</div>
                          <div class="textDiv">
                            <div class="upper">{arr[0]}</div>
                            <div class="lower">{arr[1]}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {resumeArr.length == 0 && (
                    <div className="col-12" style={{ paddingLeft: "50px" }}>
                      <p>You currently have no resume present on linkedin. </p>
                      <br />
                      <a
                        onClick={() => {
                          mixpanel.track(
                            "upload_now_button select_resume_linkedin linkedin_page dashboard",
                            {
                              email: userData?.email,
                              plan_details: userData?.planDetails || {},
                              v2Id: userData?.planDetails?.v2Id || "",
                            }
                          );
                        }}
                        className="btn btn-primary"
                        href="https://www.linkedin.com/jobs/application-settings/"
                        target="_blank"
                      >
                        Upload now
                      </a>
                    </div>
                  )}
                </div>
              </>
            )}

            {resumeInfo && (
              <div class="col-md-5 d-flex lknResumeCol">
                <div className="form-check form-check-inline custom-radio d-flex align-items-center">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id={`flexRadioDefaultSelected`}
                    checked="true"
                  />
                </div>

                <div
                  class="col-4 myclass d-flex align-items-center"
                  ref={resumeRef2}
                >
                  <div class="redBox">PDF</div>
                  <div class="textDiv">
                    <div class="upper">{resumeInfo.data[0]}</div>
                    <div class="lower">{resumeInfo.data[1]}</div>
                  </div>
                </div>
              </div>
            )}

            {!checkIfDemoAndNoPlan() &&
              !resumeLinkedin && ( // ajay
                <div class="col-md-12" ref={resumeRef1}>
                  <a
                    href="#"
                    className="text-primary"
                    style={{
                      marginLeft: "20px",
                      marginTop: "20px",
                      marginBottom: "20px",
                      display: "inline-block",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      mixpanel.track(
                        "select_linkedin_resume_link linkedin_page dashboard",
                        {
                          email: userData?.email,
                          plan_details: userData?.planDetails || {},
                          v2Id: userData?.planDetails?.v2Id || "",
                        }
                      );
                      window.localStorage.removeItem("resumeInfo");
                      setResumeInfo(null);
                      chrome.runtime.sendMessage(yourExtensionId, {
                        dashboard: true,
                        linkedin: true,
                        message: "openSettingsUrl",
                        url: "https://www.linkedin.com/jobs/application-settings/",
                      });
                    }}
                  >
                    {resumeInfo
                      ? "Change Again"
                      : "Select Resume to use while Applying on Linkedin"}{" "}
                    &nbsp;
                    <i class="fas fa-arrow-right"></i>{" "}
                  </a>
                </div>
              )}
          </div>

          <Form onSubmit={handleSubmit} ref={formMain}>
            <Row style={styleform}>
              <Col md={4}>
                <Form.Label htmlFor="inputskill">
                  Enter skill / job title*
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={formDisable}
                  id="inputskill"
                  value={form.skill}
                  aria-describedby="passwordHelpBlock"
                  onChange={(e) => setField("skill", e.target.value)}
                  isInvalid={!!errors.skill}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.skill}
                </Form.Control.Feedback>
                <Form.Text id="passwordHelpBlock" muted>
                  For example - Enter only one single skill or job title like
                  react or data analyst or marketing manager etc
                </Form.Text>
              </Col>
              <Col md={4}>
                <Form.Label htmlFor="inputlocation">
                  Enter the job location (optional)
                </Form.Label>
                <Form.Control
                  disabled={formDisable}
                  type="text"
                  value={form.location}
                  id="inputlocation"
                  aria-describedby="passwordHelpBlock"
                  onChange={(e) => setField("location", e.target.value)}
                />
                <Form.Text id="passwordHelpBlock" muted>
                  For example - you can enter your job location like - bangalore
                  or delhi or any other place etc.
                </Form.Text>
              </Col>
              <Col md={4}>
                <Form.Label htmlFor="inputnumber">
                  Number of jobs to apply*
                </Form.Label>
                <Form.Control
                  disabled={formDisable}
                  type="number"
                  id="inputnumber"
                  aria-describedby="passwordHelpBlock"
                  value={form.numberOfJobs}
                  onChange={(e) => setField("numberOfJobs", e.target.value)}
                  isInvalid={!!errors.numberOfJobs}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.numberOfJobs}
                </Form.Control.Feedback>
                <Form.Text id="passwordHelpBlock" muted>
                  For example - 50 or 100 etc.
                </Form.Text>
              </Col>
              {/* <Col md={12}>
                <div className="exactsearch">
                  <div className="exactsearchchild">Exact Search Filter</div>
                  <div className="exactsearchchild">
                    <label
                      className={
                        "switch3" + (exactSearch ? " switch3-checked" : "")
                      }
                    >
                      <input
                        type="checkbox"
                        checked={exactSearch}
                        onChange={(e) => {
                          setExactSearch(!exactSearch);
                        }}
                      />
                      <div></div>
                    </label>
                  </div>
                </div>
                <p
                  className="knowwhat"
                  style={{ cursor: "pointer" }}
                  onClick={() => setExactSearchShow(true)}
                >
                  Click here to know more about it
                </p>
                {exactSearch ? (
                  <>
                    <label>
                      Type other designation's, keywords that you want in your
                      job title's
                    </label>
                    <CreatableSelect
                      components={{
                        DropdownIndicator: null,
                      }}
                      inputValue={extraSkills}
                      isClearable
                      isMulti
                      menuIsOpen={false}
                      onChange={(newValue) => setExtraSkillValue(newValue)}
                      onInputChange={(newValue) => setExtraSkills(newValue)}
                      onKeyDown={handleKeyDown}
                      placeholder="Type something and press enter..."
                      value={extraSkillValue}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Col> */}

              <Col md={12}>
                {" "}
                <ResumeJobScore form={form} setField={setField} />{" "}
              </Col>

              <div className="advanceSearchContainer">
                <div className="advanceSearchContainerLeft">
                  Job Title Filters - Advance Search
                </div>
                <div className="advanceSearchContainerRight">
                  <div className="exactsearch">
                    <div className="exactsearchchild">
                      <p
                        style={{
                          color: "#007bff",
                          marginBottom: "0px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          cursor: "pointer",
                        }}
                      >
                        {advanceSearch ? (
                          <span onClick={() => setShowAdvanceSearch(true)}>
                            Configure
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <label
                        className={
                          "switch3" + (advanceSearch ? " switch3-checked" : "")
                        }
                      >
                        <input
                          type="checkbox"
                          checked={advanceSearch}
                          onChange={(e) => {
                            setAdvanceSearch(!advanceSearch);
                          }}
                        />
                        <div></div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <AdvanceSearchModal
                show={showAdvanceSearch}
                excludeString={excludeString}
                setExcludeString={setExcludeString}
                mayIncludeString={mayIncludeString}
                setMayIncludeString={setMayIncludeString}
                handleClose={handleCloseSearch}
              />

              <Col md={8} ref={buttonFetch}>
                <button
                  type="submit"
                  className="btn linkedinSubmitButton"
                  ref={fetchbutton}
                >
                  Click to fetch filters first
                  <i className="fas fa-arrow-right"></i>{" "}
                  <span
                    className="spinner-border spinner-border-sm"
                    style={{ marginLeft: "5px" }}
                    role="status"
                    aria-hidden="true"
                    ref={spinner}
                  ></span>
                </button>
              </Col>
              {showSavedSearchButton && (
                <Col
                  md={4}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {configDummy?.totalJobs && configDummy.totalJobs >= 200 ? (
                    <button
                      type="button"
                      style={saveButtonDisabled ? buttonMain : buttonMain1}
                      disabled={saveButtonDisabled}
                      onClick={() => {
                        setShowSavedSearchModal(true);
                      }}
                    >
                      {checkIfPresent()
                        ? "Update Search Configuration"
                        : "Save Search Configuration"}
                    </button>
                  ) : (
                    <></>
                  )}
                </Col>
              )}
            </Row>
          </Form>
          <p ref={chooseParaText} style={{ marginLeft: "20px", color: "gray" }}>
            Choose one or more filters (optional)
          </p>
          <Form onSubmit={handleSubmit1} ref={formMain1}>
            <Row style={filtersStyle}>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Experience Level</Form.Label>
                  <Select
                    isMulti
                    id="experience"
                    ref={selectExperienceLevel}
                    onChange={(value) => setField("experience", value)}
                    name="experience"
                    placeholder="Select Experience Level:"
                    options={experienceOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <button
                  className="btn filterReset"
                  type="button"
                  onClick={filterReset}
                >
                  Reset
                </button>
                <Form.Group>
                  <Form.Label>Job Type</Form.Label>
                  <Select
                    isMulti
                    id="jobtype"
                    ref={selectJobType}
                    onChange={(value) => setField("jobtype", value)}
                    name="jobtype"
                    placeholder="Select Job Type:"
                    options={jobTypeOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </Form.Group>
              </Col>
              {/* f_TPR=r604800 */}
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Date Posted</Form.Label>
                  <Form.Control
                    as="select"
                    id="dateposted"
                    onChange={(e) => setField("datePosted", e.target.value)}
                  >
                    <option value="">Any time</option>
                    <option value="r2592000">Past month</option>
                    <option value="r604800">Past week</option>
                    <option value="r86400">Past 24 hours</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              {/* f_I=45%2C104 */}
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Industry</Form.Label>
                  <Select
                    isMulti
                    onChange={(value) => setField("industry", value)}
                    name="industry"
                    id="jobIndustry"
                    ref={selectJobIndustry}
                    placeholder="Select Industry:"
                    options={jobIndustryOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </Form.Group>
              </Col>
              {/* f_F=qa%2Cbd */}
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Job Function</Form.Label>
                  <Select
                    isMulti
                    id="jobFuntion"
                    ref={selectJobFunction}
                    onChange={(value) => setField("jobFunction", value)}
                    name="jobFunction"
                    placeholder="Select Job Function:"
                    options={jobFunctionOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </Form.Group>
              </Col>
              {/* f_C=20423248 */}
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Companies</Form.Label>
                  <Select
                    isMulti
                    id="jobCompany"
                    ref={selectJobCompany}
                    onChange={(value) => setField("jobCompany", value)}
                    name="jobCompany"
                    placeholder="Select Job Companies:"
                    options={jobCompanyOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </Form.Group>
              </Col>
              {/* f_T=25169%2C437 */}
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Job Title</Form.Label>
                  <Select
                    isMulti
                    id="jobTitle"
                    ref={selectJobTitle}
                    onChange={(value) => setField("jobTitle", value)}
                    name="jobTitle"
                    placeholder="Select Job Title's:"
                    options={jobTitleOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </Form.Group>
              </Col>

              {/* f_WT */}
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Job Workplace (Remote / Onsite)</Form.Label>
                  <Select
                    isMulti
                    id="jobWorkplace"
                    ref={selectJobWorkplace}
                    onChange={(value) => setField("jobWorkplace", value)}
                    name="jobWorkplace"
                    placeholder="Select Job Workplace:"
                    options={jobWorkplaceOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </Form.Group>
              </Col>
              {jobSalaryOptions.length > 0 && (
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Job Salary</Form.Label>
                    <Form.Control
                      as="select"
                      id="jobSalary"
                      onChange={(e) => setField("jobSalary", e.target.value)}
                    >
                      {[
                        { value: "", label: "Select Experience Level:" },
                        ...jobSalaryOptions,
                      ].map((obj) => {
                        return <option value={obj.value}>{obj.label}</option>;
                      })}
                    </Form.Control>
                  </Form.Group>
                </Col>
              )}
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Job Benfitis </Form.Label>
                  <Select
                    isMulti
                    id="jobBenifits"
                    ref={selectJobBenifit}
                    onChange={(value) => setField("jobBenifits", value)}
                    name="jobBenifits"
                    placeholder="Select Job Benifit's:"
                    options={jobBenifitOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </Form.Group>
              </Col>
              {/* f_EA=true */}
              <Col md={6} className="under10 mt-2">
                <FormCheck
                  id="switchEnabled"
                  type="switch"
                  onChange={(e) => setField("jobUnder10", e.target.checked)}
                  label="Job Under 10 Applicants"
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {checkIfDemoAndNoPlan() ? (
                  <button
                    type="button"
                    ref={button1}
                    onClick={() => demoSubmit()}
                    className="btn linkedinSubmitButton"
                  >
                    Start Auto Applying{" "}
                    {form.numberOfJobs != 0
                      ? `To ${form.numberOfJobs} Jobs`
                      : ""}
                    <i className="fas fa-arrow-right"></i>
                  </button>
                ) : (
                  <button
                    type="submit"
                    ref={button1}
                    disabled={startApplying}
                    className="btn linkedinSubmitButton"
                  >
                    Start Applying <i className="fas fa-arrow-right"></i>
                  </button>
                )}
              </Col>
            </Row>
          </Form>
          <Row style={{ margin: "10px auto", padding: "0 5px" }}>
            {pp.current === 100 ? (
              <Col md={5} style={{ marginBottom: "20px" }}>
                <Feedback
                  platformName="linkedin"
                  totalJobsApplied={c.current}
                />
              </Col>
            ) : (
              <Col md={5}></Col>
            )}

            <Col md={7} ref={initprocessingFinal} className="linkedinInit">
              <button
                type="button"
                className="linkedinReset btn"
                onClick={resetFull}
              >
                Reset
              </button>
              {showPlanPurchase ? (
                <>
                  <div className="planPurchaseRequiredMainDiv">
                    <p className="planPurchaseRequired">
                      You don't have a plan, please purchase a plan to continue
                    </p>
                    <button
                      onClick={() => {
                        mixpanel.track("purchase_plan_button dashboard", {
                          href: window.location.href,
                          email: userData?.email,
                          platformName: "linkedin",
                          plan_details: userData?.planDetails || {},
                          v2Id: userData?.planDetails?.v2Id || "",
                        });
                        setTimeout(() => {
                          history.push("/pricing");
                        }, 500);
                      }}
                      className="button1"
                    >
                      Purchase Plan
                    </button>
                  </div>
                </>
              ) : (
                <div className="linkedinInitTop">
                  <div>Percentage complete ({100} %)</div>
                  <div>Total jobs applied ({finalJobCount})</div>
                </div>
              )}
              {showPlanPurchase ? (
                <></>
              ) : (
                <div className="linkedinInitTop">
                  <div>
                    <Progress done={100} />
                  </div>
                  <div className="jobsApplied">{finalJobCount}</div>
                </div>
              )}
            </Col>
            <Col md={7} ref={initprocessing} className="linkedinInit">
              <button
                type="button"
                className="linkedinReset btn"
                onClick={resetFull}
              >
                Reset
              </button>
              <div className="linkedinInitTop1">
                <div ref={processing} className="processing">
                  Automation in Process...
                </div>
              </div>
              <div className="linkedinInitTop1">
                <div>
                  Please do not switch tabs till this session's completion.
                </div>
              </div>
            </Col>
          </Row>

          {/* <Row>
            {pp.current == 100 && (
              <>
                <div class="col-10 wrapper">
                  <h3 class="feedbackform_header issuesHeader">
                    Common Issues
                  </h3>
                  {(() => {
                    let allIssues = [
                      ...faqObj.linkedin.issues,
                      ...faqObj.common.issues,
                    ];
                    return allIssues.map((x, index) => (
                      <div className="container" key={index}>
                        <div
                          className="question"
                          ref={(el) => (questionRefs.current[index] = el)}
                        >
                          {x.question}
                        </div>
                        <div class="answercont">
                          <div class="answer">
                            <p style={{ marginTop: "20px" }}>{x.answer}</p>

                            {x.anchorText && (<a
                              href="#"
                              className="text-primary"
                              style={{
                                marginLeft: "0px",
                                marginTop: "20px",
                                display: "inline-block",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setModalTitle(x.question);
                                setShowIndeedModal(true);
                              }}
                            >
                              {x.anchorText}
                            </a>)}
                            
                            {x.videoId && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginBottom: "20px",
                                }}
                              >
                                <iframe
                                  className="myVideoClass"
                                  loop="true"
                                  autoPlay="true"
                                  controls="true"
                                  id="vid"
                                  src={`https://www.youtube.com/embed/${x.videoId}?autoplay=1&loop=1&vq=hd1080`}
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                ></iframe>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ));
                  })()}
                </div>
                <div class="col-12" style={{ height: "100px" }}>
                  {" "}
                </div>
                <LinkedinFetchFiltersArticle
                  showProp={showIndeedModal}
                  hideShow={closeModal}
                  title={modalTitle}
                />
              </>
            )}
          </Row> */}
        </div>
      </div>
    </>
  );
}

export default Linkedin;
