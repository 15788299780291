import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useStateStore } from "../../store";
import "./EmailBlock.css";
import PeopleBlock from "./PeopleBlock";
import { mixpanel } from "../../index";

function EmailBlock({ setSendEmail }) {
  const textareaRef = useRef(null);
  const history = useHistory();
  const resumeData = useRef({});
  const setShowEmailSentSuccess = useStateStore(
    (state) => state.setShowEmailSentSuccess
  );
  const setEmailList = useStateStore((state) => state.setEmailList);
  const emailList = useStateStore((state) => state.emailList);
  const [regenerateLoading, setRegenerateLoading] = useState(false);
  const [activeEmailLoading, setActiveEmailLoading] = useState(false);
  const [resumefetchingStatus, setResumeFetchingStatus] = useState(false);
  const setReferralDataOtherDetails = useStateStore(
    (state) => state.setReferralDataOtherDetails
  );
  const setStepNoReferralFeedback = useStateStore(
    (state) => state.setStepNoReferralFeedback
  );
  const [emailNotFound, setEmailNotFound] = useState(false);
  const [sendEmailButtonLoadingStatus, setSendEmailButtonLoadingStatus] =
    useState(false);
  const userData = useStateStore((state) => state.userData);
  const [selectedResumeId, setSelectedResumeId] = useState("");
  const [showImcompleteDataError, setShowIncompleteDataError] = useState(false);
  const setReferralResumeData = useStateStore(
    (state) => state.setReferralResumeData
  );
  const setShowMainReferralModal = useStateStore(
    (state) => state.setShowMainReferralModal
  );
  const referralResumeData = useStateStore((state) => state.referralResumeData);
  const referralInitData = useStateStore((state) => state.referralInitData);
  const [referralTitle, setReferralTitle] = useState("");
  const employeeSelected = useStateStore((state) => state.employeeSelected);
  const mainCompanySet = useStateStore((state) => state.mainCompanySet);
  const [initialString, setInitialString] = useState("");
  const [text, setText] = useState("");
  const [emailTitle, setEmailTitle] = useState("");
  const [emailData, setEmailData] = useState({});
  const [showLoading, setShowLoading] = useState(false);
  const token = useStateStore((state) => state.token);

  const getEmail = async () => {
    if (employeeSelected.linkedinUrl in emailList) {
      setEmailData({
        email: emailList[employeeSelected.linkedinUrl],
      });
      return {
        email: emailList[employeeSelected.linkedinUrl],
      };
    } else {
      const response = await axios.post(
        `https://backend.lazyapply.com/referral/getEmailMain`,
        {
          employee: employeeSelected,
          // employeeId: employeeSelected.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("getEmailData", response.data.emailData);
      setEmailData(response.data.emailData);
      setEmailList({
        linkedin: employeeSelected.linkedinUrl,
        email: response.data.emailData.email,
      });
      return response.data.emailData;
    }
  };

  const sendEmail = async () => {
    if (emailData?.email && emailData.email != "") {
      setSendEmailButtonLoadingStatus(true);
      const sendResponse = await axios.post(
        "https://backend.lazyapply.com/referral/sendEmail",
        {
          employee: employeeSelected,
          referraltitle: referralInitData?.referral?.referralTitle,
          companyDetails: mainCompanySet,
          resumeJson:
            referralResumeData.resumes[referralInitData?.referral?.resumeId],
          emailData: emailData,
          emailDescription: text,
          emailTitle: emailTitle,
          userData: userData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response send email", sendResponse);
      setSendEmailButtonLoadingStatus(false);
      setTimeout(() => {
        setSendEmail(false);
        setShowMainReferralModal(false);
        setShowEmailSentSuccess(true);
      }, 500);
    } else {
      // email not found
    }
  };

  const activeAiEmails = async () => {
    console.log(selectedResumeId, referralTitle);
    const resumeData = referralResumeData.resumes[selectedResumeId];
    console.log(resumeData);
    setActiveEmailLoading(true);
    setReferralDataOtherDetails({
      referralTitle: referralTitle,
      selectedResumeId: selectedResumeId,
    });
    const response = await axios.post(
      `https://backend.lazyapply.com/referral/updateReferralData`,
      {
        referralTitle: referralTitle,
        v2Id: userData?.planDetails?.v2Id,
        resumeId: selectedResumeId,
        permissionGranted: 1,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setTimeout(() => {
      writeEmail(referralTitle, selectedResumeId);
      setShowIncompleteDataError(false);
      setActiveEmailLoading(false);
      console.log("response", response);
    }, 2000);
  };

  const writeEmail = async (rtitle = "", rid = "", email_data = {}) => {
    console.log("showActivateOrNot", email_data);
    if (
      Object.keys(email_data).length > 0 &&
      email_data?.email &&
      email_data.email != ""
    ) {
      let title = referralInitData?.referral?.referralTitle || "";
      let id = referralInitData?.referral?.resumeId || "";
      if (rtitle != "") title = rtitle;
      if (rid != "") id = rid;
      console.log("email", emailData);
      setEmailNotFound(false);
      setShowLoading(true);
      const response = await axios.post(
        `https://backend.lazyapply.com/referral/writeEmail`,
        {
          employee: employeeSelected,
          referraltitle: title,
          companyDetails: mainCompanySet,
          resumeJson: referralResumeData.resumes[id].resume,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("getEmailData", response.data);
      setShowLoading(false);
      setEmailTitle(`Referral For ${title} Position`);
      if (response.data.parsed) {
        setInitialString(response.data.content);
      } else {
        console.log("some error ocurred");
      }
    } else {
      setEmailNotFound(true);
    }
  };

  const fetchUserResumeData = async () => {
    setResumeFetchingStatus(true);
    const response = await axios.get(
      `https://backend.lazyapply.com/v2/resumesV3/completed/${userData.planDetails.v2Id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setResumeFetchingStatus(false);
    setReferralResumeData(response.data);
  };

  const checkDisabled = () => {
    if (referralTitle != "" && selectedResumeId != "") {
      return false;
    } else {
      return true;
    }
  };

  const showActivateOrNot = async () => {
    let email_data = {};
    email_data = await getEmail();
    console.log(
      referralInitData?.referral?.referralTitle,
      referralResumeData?.resumes
    );
    if (
      referralInitData?.referral?.referralTitle &&
      referralInitData.referral.referralTitle != "" &&
      referralResumeData?.resumes &&
      Object.keys(referralResumeData.resumes).length > 0
    ) {
      setReferralTitle(referralInitData.referral.referralTitle);
      setSelectedResumeId(referralInitData.referral.resumeId);
      setShowIncompleteDataError(false);
      console.log("showActivateOrNot", email_data);
      writeEmail(
        referralInitData.referral.referralTitle,
        referralInitData.referral.resumeId,
        email_data
      );
    } else {
      setShowIncompleteDataError(true);
    }
  };

  useEffect(() => {
    mixpanel.identify(userData.email);
    mixpanel.people.increment("secret_network_popup step_2 dashboard");
    mixpanel.track("secret_network_popup step_2 dashboard", {
      email: userData?.email,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });

    console.log("referral", referralInitData, referralResumeData?.resumes);
    showActivateOrNot();
    setStepNoReferralFeedback(2);
  }, [referralResumeData, referralInitData]);

  useEffect(() => {
    if (initialString) {
      const words = initialString.split(" ");
      let wordIndex = 0;
      const intervalId = setInterval(() => {
        if (wordIndex < words.length) {
          setText((prevText) => {
            const newText =
              prevText + (wordIndex > 0 ? " " : "") + (words[wordIndex] || "");
            if (textareaRef.current) {
              textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
            }
            return newText;
          });
          wordIndex++;
        } else {
          clearInterval(intervalId);
        }
      }, 100);
      return () => clearInterval(intervalId);
    }
  }, [initialString]);

  return (
    <div style={{ marginTop: "50px" }}>
      <button
        className="btn main-referral-goback"
        onClick={() => {
          mixpanel.identify(userData.email);
          mixpanel.people.increment(
            "go_back_button secret_network_modal_step_2 dashboard"
          );
          mixpanel.track(
            "go_back_button secret_network_modal_step_2 dashboard",
            {
              email: userData?.email,
              plan_details: userData?.planDetails || {},
              v2Id: userData?.planDetails?.v2Id || "",
            }
          );

          setSendEmail(false);
        }}
      >
        <i className="fas fa-arrow-left mr-2"></i> Go Back
      </button>

      <div className="main-referral-email-title">
        {showImcompleteDataError && (
          <div className="main-referral-email-error-activate">
            <>
              <p>Complete the details to activate AI emails</p>
              {!(
                referralInitData?.referralTitle &&
                referralInitData.referralTitle != ""
              ) && (
                <div className="main-referral-email-error-block">
                  <Form.Label>Enter Referral Job Title - </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={referralTitle}
                    id="referrerfaltitle"
                    placeholder="Referral Title - marketing manager, analyst etc"
                    onChange={(e) => setReferralTitle(e.target.value)}
                  />
                </div>
              )}
              {referralResumeData?.resumes &&
                Object.keys(referralResumeData.resumes).length > 0 && (
                  <>
                    <Form.Group
                      style={{
                        margin: "10px 0px 20px 0px",
                      }}
                    >
                      <Form.Label>Choose your Profile -</Form.Label>
                      <Form.Control
                        as="select"
                        id="resumeId"
                        value={selectedResumeId}
                        onChange={(e) => {
                          Object.keys(referralResumeData.resumes).map((key) => {
                            const item = referralResumeData.resumes[key];
                            if (item.resumeId === e.target.value) {
                              resumeData.current = {
                                ...item,
                              };
                            }
                          });
                          setSelectedResumeId(e.target.value);
                        }}
                      >
                        <option value="">Choose your profile</option>
                        {Object.keys(referralResumeData.resumes).map((key) => {
                          const item = referralResumeData.resumes[key];
                          return (
                            <option value={item.resumeId}>
                              {item.resumename}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </>
                )}
              {!(
                referralResumeData?.resumes &&
                Object.keys(referralResumeData.resumes).length > 0
              ) && (
                <div className="main-referral-emamil-error-createprofile-block">
                  Profile not created, please create your profile -{" "}
                  <span
                    className="main-referral-email-error-createprofile"
                    onClick={() => {
                      mixpanel.identify(userData.email);
                      mixpanel.people.increment(
                        "create_now_button secret_network_popup step_2 dashboard"
                      );
                      mixpanel.track(
                        "create_now_button secret_network_popup step_2 dashboard",
                        {
                          email: userData?.email,
                          plan_details: userData?.planDetails || {},
                          v2Id: userData?.planDetails?.v2Id || "",
                        }
                      );
                      window.open(
                        "https://app.lazyapply.com/dashboard/editresumesv2",
                        "_blank"
                      );
                    }}
                  >
                    Create Now
                  </span>
                  <p
                    onClick={() => {
                      fetchUserResumeData();
                      mixpanel.identify(userData.email);
                      mixpanel.people.increment(
                        "already_create_click_now_button secret_network_popup step_2 dashboard"
                      );
                      mixpanel.track(
                        "already_create_click_now_button secret_network_popup step_2 dashboard",
                        {
                          email: userData?.email,
                          plan_details: userData?.planDetails || {},
                          v2Id: userData?.planDetails?.v2Id || "",
                        }
                      );
                    }}
                  >
                    If Already created, click here{" "}
                    {resumefetchingStatus && (
                      <Spinner
                        animation="border"
                        size="sm"
                        style={{ marginLeft: "10px" }}
                      />
                    )}
                  </p>
                </div>
              )}
            </>

            <button
              disabled={checkDisabled()}
              className="main-referral-email-error-button"
              onClick={() => {
                mixpanel.identify(userData.email);
                mixpanel.people.increment(
                  "activate_ai_emails_button secret_network_popup step_2 dashboard"
                );
                mixpanel.track(
                  "activate_ai_emails_button secret_network_popup step_2 dashboard",
                  {
                    email: userData?.email,
                    plan_details: userData?.planDetails || {},
                    v2Id: userData?.planDetails?.v2Id || "",
                  }
                );
                activeAiEmails();
              }}
            >
              Activate AI Emails
              {activeEmailLoading && (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ marginLeft: "10px" }}
                />
              )}
            </button>
          </div>
        )}
        <PeopleBlock
          employee={employeeSelected}
          showSendEmailButton={false}
          emailData={emailData}
          EmailDataComponent={
            <>
              {Object.keys(emailData).length > 0 ? (
                <div className="main-referral-company-domain">
                  {emailData?.email && emailData.email != "" ? (
                    emailData.email
                  ) : (
                    <span style={{ color: "red" }}>Email Not Found!</span>
                  )}
                </div>
              ) : (
                <>
                  <div className="main-referral-modal-loading-email">
                    Getting Email...{" "}
                    <Spinner
                      animation="border"
                      size="sm"
                      style={{ marginLeft: "10px" }}
                    />
                  </div>
                </>
              )}
            </>
          }
        />
        {emailNotFound && (
          <p
            style={{
              margin: "20px 0px",
              color: "#007bff",
              fontWeight: "500",
            }}
          >
            Email not found, try again with another employee!
          </p>
        )}
        {showLoading && (
          <div className="main-referral-modal-loading-email-body">
            Writing Email Using AI...{" "}
            <Spinner
              animation="border"
              size="sm"
              style={{ marginLeft: "10px" }}
            />
          </div>
        )}
        <Form.Control
          style={{ marginTop: "20px" }}
          required
          disabled={emailNotFound || showImcompleteDataError}
          type="text"
          value={emailTitle}
          id="emailsubject"
          placeholder="Email Subject Here..."
          onChange={(e) => setEmailTitle(e.target.value)}
        />
      </div>
      <div className="main-referral-email-body">
        <Form.Control
          as="textarea"
          rows={showImcompleteDataError ? 4 : 12}
          disabled={emailNotFound || showImcompleteDataError}
          id="emailbody"
          placeholder="Email Body Here..."
          value={text}
          ref={textareaRef}
          onChange={(e) => setText(e.target.value)}
        />
      </div>
      <div className="main-referral-emailsend-button">
        <button
          style={{
            background: "cadetblue",
          }}
          disabled={showLoading || regenerateLoading}
          onClick={async () => {
            mixpanel.identify(userData.email);
            mixpanel.people.increment(
              "regenerate_email_button secret_network_popup step_2 dashboard"
            );
            mixpanel.track(
              "regenerate_email_button secret_network_popup step_2 dashboard",
              {
                email: userData?.email,
                plan_details: userData?.planDetails || {},
                v2Id: userData?.planDetails?.v2Id || "",
              }
            );

            setInitialString("");
            setText("");
            setRegenerateLoading(true);
            await writeEmail("", "", emailData);
            setRegenerateLoading(false);
          }}
        >
          Regenerate Email
          {regenerateLoading && (
            <Spinner
              animation="border"
              size="sm"
              style={{ marginLeft: "10px" }}
            />
          )}
        </button>
        <button
          disabled={sendEmailButtonLoadingStatus}
          onClick={() => {
            mixpanel.identify(userData.email);
            mixpanel.people.increment(
              "send_email_button secret_network_popup step_2 dashboard"
            );
            mixpanel.track(
              "send_email_button secret_network_popup step_2 dashboard",
              {
                email: userData?.email,
                plan_details: userData?.planDetails || {},
                v2Id: userData?.planDetails?.v2Id || "",
              }
            );

            sendEmail();
          }}
        >
          Send Email
          {sendEmailButtonLoadingStatus && (
            <Spinner
              animation="border"
              size="sm"
              style={{ marginLeft: "10px" }}
            />
          )}
        </button>
      </div>
    </div>
  );
}

export default EmailBlock;
