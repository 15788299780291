import React from "react";
import "./Pagination.css";

function Pagination({ pagination, setCurrentPage }) {
  const { currentPage, firstPage, lastPage } = pagination;

  const handlePrevious = () => {
    if (currentPage > firstPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="main-referral-pagination-block">
      {currentPage > firstPage && (
        <div className="main-referral-pagination-first">
          <button onClick={handlePrevious}>Previous</button>
        </div>
      )}
      {currentPage < lastPage && (
        <div className="main-referral-pagination-second">
          <button onClick={handleNext}>Next</button>
        </div>
      )}
    </div>
  );
}

export default Pagination;
