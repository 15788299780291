import React, { useEffect, useState } from 'react';
import { useStateStore } from '../store/index';
import './filtershelp.css';
function MoreFiltersHelp({ openHelper, setOpenHelper }) {
	const [forceClose, setForceClose] = useState(false);

	useEffect(() => {
		if (!forceClose) {
			if (openHelper) {
				setFilterStyle({ ...filterStyle, ...newStyle });
			} else {
				setFilterStyle({ ...filterStyle, ...newStyle1 });
			}
		} else {
			setFilterStyle({ ...filterStyle, ...newStyle1 });
		}
	}, [openHelper, forceClose]);

	const userData = useStateStore((state) => state.userData);

	const closeHelper = () => {
		setOpenHelper(false);
	};


	const [filterStyle, setFilterStyle] = useState({
		width: '0px',
		position: 'fixed',
		right: '0px',
		top: '20px',
		visibility: 'hidden',
		height: '230px',
		zIndex: '1000',
	});

	const newStyle = {
		transition: 'width 0.5s ease-in-out',
		width: '375px',
		visibility: 'visible',
	};

	const newStyle1 = {
		transition: 'width 0.5s ease-in',
		width: '0px',
		visibility: 'hidden',
	};

	const permanentClose = () => {
		setForceClose(true);
		setFilterStyle({ ...filterStyle, ...newStyle1 });
	};

	return (
		<div className="filtershelp_main" style={filterStyle}>
			<div className="filtershelp_header row">
				<div className="col-10">
					Hey {userData.name.toUpperCase()} <i className="fas fa-smile ml-1 mt-1"></i>
				</div>
				<div className="col-2">
					<i className="fas fa-times ml-1" onClick={closeHelper}></i>
				</div>
			</div>
			<div className="filtershelp_body">
				It seems like you are using many filters. More the number of filters, less is the number of jobs
				applied.
				<p style={{ marginBottom: '5px' }}>
					PS - Use only <strong>one filter</strong> for optimum output.
				</p>
				<button className="dontshow" onClick={permanentClose}>
					Don't show it again
				</button>
			</div>
		</div>
	);
}

export default MoreFiltersHelp;
