import { Modal } from "react-bootstrap";

function SuccessModal({ successFeedbackModal, setSuccessFeedbackModal }) {
  const handleClose = () => {
    setSuccessFeedbackModal(false);
  };

  return (
    <Modal
      show={successFeedbackModal}
      onHide={handleClose}
      centered
      size="md"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Help Us Improve!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>Feedback Submitted Successfully</div>
      </Modal.Body>
    </Modal>
  );
}

export default SuccessModal;
