import React, { useEffect, useState } from "react";
import PayPalButton from "./dashboard/paypalButton";
import { mixpanel } from "../index";
import { useStateStore } from "../store";

// import { Spinner } from "react-bootstrap";
function PricingBlockEnterprise({
  yearly,
  title,
  secondtitle,
  paymentsMain,
  processing,
  goto,
  defaultCutPrice,
  defaultMainPrice,
  mostPopularTag = false,
  features = [
    "Linkedin automation",
    "Indeed automation",
    "Ziprecruiter automation",
  ],
  featuresCut = [],
  extrafeatures = ["Day wise analytics", "CV Improvement Tips"],
  resumeLimit,
  userLimit,
  country,
}) {
  const isPaymentsFetched = () => {
    if (!yearly) {
      return Object.keys(paymentsMain).length > 0;
    } else {
      return false;
    }
  };

  const userData = useStateStore((state) => state.userData);
  const [cutpricetext, setCutPriceText] = useState("");
  const [mainpricetext, setMainPriceText] = useState("");
  const [chooseDefault, setChooseDefault] = useState("default1");
  const [currency, setCurrency] = useState("$");
  useEffect(() => {
    setCutPriceText("cutprice" + secondtitle);
    setMainPriceText("mainprice" + secondtitle);
    if (country === "IN") {
      setChooseDefault("default3");
      setCurrency("Rs");
    } else {
      setChooseDefault("default4");
      setCurrency("$");
    }
  }, [secondtitle, country]);

  return (
    <div className="card cardone cardoutside">
      {mostPopularTag && (
        <div className="card-ribbon">
          <span>MOST POPULAR</span>
        </div>
      )}

      <div className="card-title">
        <h3>{title} Plan</h3>
      </div>
      <div className="card-price">
        <h1>
          <p>
            {isPaymentsFetched() ? (
              <>
                <span className="cutprice1">
                  {currency}
                  {paymentsMain[chooseDefault][cutpricetext]}
                </span>
                {currency}
                {paymentsMain[chooseDefault][mainpricetext]}
              </>
            ) : (
              <>
                <span className="cutprice1">
                  {currency}
                  {defaultCutPrice}
                </span>
                {currency}
                {defaultMainPrice}
              </>
            )}
            {yearly ? "/ year" : "/ month"}
          </p>
        </h1>
      </div>
      <div className="card-description">
        <ul>
          {/* <li className="lifetime">Lifetime Access</li> */}
          {features.map((feature, index) => {
            return <li key={index}>{feature}</li>;
          })}
          {featuresCut.map((feature, index) => {
            return (
              <li key={index}>
                {" "}
                <span className="cutpricezip">{feature}</span>
              </li>
            );
          })}
          {/* <li>Unlimited emails from Linkedin profiles</li> */}
          <li className="lifetime">
            Up to - {resumeLimit} {`Resumes / CV's`}
          </li>
          <li className="lifetime">
            Up to - {userLimit} {`Users`}
          </li>
          {extrafeatures.map((feature, index) => {
            return <li key={index}>{feature}</li>;
          })}
        </ul>
      </div>

      <div className="card-action">
        {!yearly ? (
          <PayPalButton titlemain={title} titleSecondary={title} />
        ) : (
          <>
            <button
              type="button"
              className="buttonPricing1 buttonPricing2 calendarlink"
              onClick={() => {
                mixpanel.identify(userData?.email);
                mixpanel.people.increment(
                  "book_a_demo_button pricing_page dashboard"
                );
                mixpanel.track("book_a_demo_button pricing_page dashboard", {
                  email: userData?.email,
                  plan_details: userData?.planDetails || {},
                  v2Id: userData?.planDetails?.v2Id || "",
                });
                window.open(
                  "https://calendly.com/vivek-lazyapply/15min",
                  "_blank"
                );
              }}
              disabled={processing}
            >
              BOOK A DEMO{" "}
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default PricingBlockEnterprise;
