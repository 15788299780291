import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Form } from "react-bootstrap";
import AssistantIcon from "@mui/icons-material/Assistant";
import ReportIcon from "@mui/icons-material/Report";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Button } from "@mui/material";
import { useStateStore } from "../../../store/index";
import axios from "axios";
import ReportModal from "./ReportModal";
import { mixpanel } from "../../../index";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: 12,
            backgroundColor: "#fff",
            "&.Mui-focused": {
              borderColor: "#1976d2",
              boxShadow: "0 0 5px rgba(25, 118, 210, 0.5)",
            },
            "& .MuiOutlinedInput-input": {
              color: "#333",
              padding: "12px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#ced4da",
            },
            "& .MuiOutlinedInput-placeholder": {
              color: "#aaa",
            },
          },
        },
      },
    },
  },
});

function Queries() {
  const userData = useStateStore((state) => state.userData);
  const token = useStateStore((state) => state.token);
  const [userResumeData, setUserResumeData] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryDisabled, setSearchQueryDisabled] = useState(false);
  const [question, setQuestion] = useState(false);
  const [answer, setAnswer] = useState(false);
  const [userResume, setUserResume] = useState("");
  const [showReportModal, setShowReportModal] = useState(false);
  const [answersLayer, setAnswersLayer] = useState("");
  const [selectedResumeId, setSelectedResumeId] = useState("");
  const [reportedData, setReportedData] = useState({
    question: "",
    email: userData.email,
    options: [],
    answer: "",
    answersLayer: "",
    resumeId: "",
    context: "",
  });

  const fetchUserResumeData = async () => {
    const response = await axios.get(
      `https://backend.lazyapply.com/v2/resumesV3/completed/${userData.planDetails.v2Id}`,

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const resumeIds = Object.keys(response.data.resumes);
    setSelectedResumeId(resumeIds[0]);
    setUserResumeData(response.data);
    setUserResume(response.data.resumes[resumeIds[0]].resume);
  };

  const fetchAnswer = async () => {
    const response = await axios.post(
      "https://backend.lazyapply.com/saveQuestions",
      {
        question: searchQuery,
        inputtype: "text",
        platformname: "Users Questions",
        options: [],
        sessionId: "",
        resumeData: userResume,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setAnswer(response.data.answer);
    setAnswersLayer(response.data.answersLayer);
    setSearchQueryDisabled(false);
  };

  useEffect(() => {
    mixpanel.identify(userData?.email);
    mixpanel.people.increment("queries_page dashboard");
    mixpanel.track("queries_page dashboard", {
      email: userData?.email,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    fetchUserResumeData();
  }, []);

  const handleAnswerSearch = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setSearchQueryDisabled(true);
      setQuestion(searchQuery);
      setAnswer("Processing your answer ....");
      fetchAnswer();
      setSearchQuery("");

      mixpanel.track(
        "asked_questions_to_our_ai_model_enter_press queries_page dashboard",
        {
          email: userData?.email,
          plan_details: userData?.planDetails || {},
          v2Id: userData?.planDetails?.v2Id || "",
          searchQuery: searchQuery,
        }
      );
    }
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const hideResults = () => {
    mixpanel.track("ask_again_button queries_page dashboard", {
      email: userData?.email,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    setQuestion(false);
    setAnswer(false);
  };

  const handleReport = () => {
    setReportedData({
      question: question,
      email: userData.email,
      options: [],
      answer: answer,
      answersLayer: answersLayer,
      resumeId: selectedResumeId,
      context: "",
    });

    mixpanel.track("report_button queries_page dashboard", {
      email: userData?.email,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });

    mixpanel.track("report_question_popup queries_page dashboard", {
      email: userData?.email,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
      popup_event: true,
    });

    setShowReportModal(true);
  };

  if (userResumeData) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 20,
          height: "90vh",
        }}
      >
        <ReportModal
          reportedData={reportedData}
          setReportedData={setReportedData}
          showModal={showReportModal}
          setShowReportModal={setShowReportModal}
          setQuestion={setQuestion}
          setAnswer={setAnswer}
        ></ReportModal>

        <div
          style={{
            width: "90%",
            margin: "0 5%",
          }}
        >
          <Form.Group>
            <Form.Label
              style={{ color: "#1976d2", fontSize: "1.5rem", fontWeight: 600 }}
            >
              Choose a resume as the source of information to answer questions
            </Form.Label>
            <Form.Control
              as="select"
              id="resumeId"
              style={{
                backgroundColor: "#fff",
                borderRadius: 12,
                fontSize: "1.2rem",
                color: "#333",
              }}
              onChange={(e) => {
                setSelectedResumeId(e.target.value);
                setUserResume(userResumeData.resumes[e.target.value].resume);
              }}
            >
              {Object.entries(userResumeData.resumes).map(
                ([key, value], index) => (
                  <option value={key}>{value.resumename}</option>
                )
              )}
            </Form.Control>
          </Form.Group>
        </div>

        <div
          style={{
            width: "90%",
            alignContent: "center",
            margin: "2% 5% 5%",
          }}
        >
          <ThemeProvider theme={theme}>
            <TextField
              variant="outlined"
              disabled={searchQueryDisabled}
              placeholder="Ask questions to our AI model!"
              fullWidth
              value={searchQuery}
              onChange={handleInputChange}
              onKeyDown={handleAnswerSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fontSize: "1.5rem" }} />
                  </InputAdornment>
                ),
                style: { fontSize: "1.5rem" },
              }}
              style={{ margin: "10px 0" }}
            />
          </ThemeProvider>
        </div>

        <div style={{ marginLeft: "5%" }}>
          {question !== false && (
            <div style={{ marginBottom: "2%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "left",
                  width: "80%",
                  fontSize: "1.5rem",
                  color: "#333",
                  fontWeight: 600,
                  marginBottom: "0.5%",
                }}
              >
                <AccountCircle
                  style={{ marginRight: "10px", fontSize: "2.5rem" }}
                />{" "}
                <span>You</span>
              </div>
              <span
                style={{
                  paddingLeft: "52px",
                  fontSize: "1.5rem",
                  fontWeight: 500,
                }}
              >
                {question}
              </span>
            </div>
          )}
          {answer !== false && (
            <div style={{ marginBottom: "3%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "left",
                  width: "80%",
                  fontSize: "1.5rem",
                  color: "#333",
                  fontWeight: 600,
                  marginBottom: "0.5%",
                }}
              >
                <AssistantIcon
                  style={{ marginRight: "10px", fontSize: "2.5rem" }}
                />{" "}
                <span>LazyApply AI</span>
              </div>
              <span
                style={{
                  paddingLeft: "52px",
                  fontSize: "1.5rem",
                  fontWeight: 500,
                }}
              >
                {answer}
              </span>
            </div>
          )}

          <div style={{}}>
            {answer !== false && answer !== "Processing your answer ...." && (
              <>
                <Button
                  variant="contained"
                  onClick={hideResults}
                  style={{
                    fontSize: "1.2rem",
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    padding: "10px 20px",
                    borderRadius: 20,
                    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, 0.2)",
                    flex: "1 1 auto",
                    textTransform: "none",
                    fontWeight: 600,
                    marginRight: "10px",
                  }}
                >
                  Ask Again
                  <ThumbUpIcon
                    style={{
                      fontSize: "1.5rem",
                      color: "#fff",
                      marginLeft: "5px",
                    }}
                  />
                </Button>
                <Button
                  variant="contained"
                  onClick={handleReport}
                  style={{
                    fontSize: "1.2rem",
                    backgroundColor: "#FFA500",
                    color: "#fff",
                    padding: "10px 20px",
                    borderRadius: 20,
                    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, 0.2)",
                    flex: "1 1 auto",
                    textTransform: "none",
                    fontWeight: 600,
                  }}
                >
                  Report{" "}
                  <ReportIcon
                    style={{
                      fontSize: "1.75rem",
                      color: "#fff",
                      marginLeft: "5px",
                    }}
                  />
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Queries;
