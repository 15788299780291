import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import targetJson from "../targeted.json";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { FileUploader } from "react-drag-drop-files";
import { useStateStore } from "../../store";
import CustomAlert from "../modal";
import { v1 as uuidv1 } from "uuid";
// import commerce from "../../commerce.svg";
import { mixpanel } from "../../index";
import "./targetsearch.css";
function TargetedSearch() {
  const history = useHistory();
  const [step, setstep] = useState(0);
  const fileTypes = ["PDF", "DOCX"];
  const loading = useRef(null);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const token = useStateStore((state) => state.token);
  const [file, setFile] = useState(null);
  const setHeightChange = useStateStore((state) => state.setHeightChange);
  const userData = useStateStore((state) => state.userData);
  const [presentdata, setpresentdata] = useState(false);
  const globalLogout = useStateStore((state) => state.globalLogout);
  const [formdata, setformdata] = useState({
    companiesisanyof: [],
    companiesisnotanyof: [],
    industryisanyof: [],
    industryisnotanyof: [],
    revenues: [],
    fundings: [],
    employees: [],
    titles: [],
    resumeReferenceId: "",
    additionalData: "",
  });

  const showmessage = (text, title) => {
    setmessage(text);
    settitle(title);
    setmodalshow(true);
    setTimeout(() => {
      setmodalshow(false);
    }, 2500);
  };

  const setField = (key, value) => {
    setformdata({ ...formdata, [key]: value });
  };

  const [payment, setpayment] = useState(false);
  useEffect(() => {
    loading.current.style.display = "block";
    axios
      .get(`https://backend.lazyapply.com/gettargetsearch`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        loading.current.style.display = "none";
        if (res.data && "present" in res.data) {
          setpresentdata(res.data.present);
          if (res.data?.paymentStatus && res.data.paymentStatus == 1) {
            setpayment(true);
          }
          setstep(res.data.present ? 1 : 0);
        } else {
          setstep(0);
          setpresentdata("false");
        }
      })
      .catch((err) => {
        loading.current.style.display = "none";
        console.log("some error occured");
      });
  }, []);

  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      if (inputValue.length > 0) {
        axios
          .get(
            `https://autocomplete.clearbit.com/v1/companies/suggest?query=${inputValue}`
          )
          .then((res) => {
            const data = res.data;
            const namearray = data.map((options) => {
              return {
                label: `${options.name}, (domain - ${options.domain})`,
                value: options.name,
              };
            });
            resolve(namearray);
          })
          .catch((err) => {
            console.log("some error occured");
            resolve(targetJson.companyDefaultOptions);
          });
      } else {
        resolve(targetJson.companyDefaultOptions);
      }
    });
  };

  const promiseOptionsPeople = (inputValue) => {
    return new Promise((resolve) => {
      if (inputValue.length > 0) {
        axios
          .get(
            `https://autocomplete.clearbit.com/v1/companies/suggest?query=${inputValue}`
          )
          .then((res) => {
            const data = res.data;
            const namearray = data.map((options) => {
              return {
                label: `${options.name}, (domain - ${options.domain})`,
                value: options.domain,
              };
            });
            resolve(namearray);
          })
          .catch((err) => {
            console.log("some error occured");
            resolve(targetJson.companyDefaultOptions);
          });
      } else {
        resolve(targetJson.companyDefaultOptions);
      }
    });
  };

  const titlePromiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      if (inputValue.length > 0) {
        axios
          .get(
            `https://app.apollo.io/api/v1/tags/search?q_tag_fuzzy_name=${inputValue}&kind=person_title&display_mode=fuzzy_select_mode`
          )
          .then((res) => {
            const data = res.data.tags;
            const namearray = data.map((options) => {
              return {
                label: options.cleaned_name,
                value: options.cleaned_name,
              };
            });
            resolve(namearray);
          })
          .catch((err) => {
            console.log("some error occured");
            resolve(defaultFn(targetJson.titleDefaultOptions));
          });
      } else {
        resolve(defaultFn(targetJson.titleDefaultOptions));
      }
    });
  };

  const defaultFn = (options) => {
    return options.map((option) => ({ value: option, label: option }));
  };

  const styleform = {
    padding: "20px",
  };

  const openPayment = () => {
    mixpanel.track("Dashboard - targetsearch open payment");
    axios
      .get(
        `https://backend.lazyapply.com/create-checkout-session-targetedSearch`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        loading.current.style.display = "none";
        console.log(response);
        if (
          typeof response.data === "object" &&
          response.data !== null &&
          "url" in response.data
        ) {
          window.open(response.data.url, "_blank");
        } else {
          console.log("error");
          setmessage("Some error occured, please try again in some time!");
          settitle("Error");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2500);
        }
      })
      .catch((error) => {
        loading.current.style.display = "none";
        console.log(error);
        if (error.message == "Request failed with status code 403") {
          console.log("reauthenticate");
          setmessage("Please relogin again!");
          settitle("Not authorized");
          setmodalshow(true);
          setTimeout(() => {
            globalLogout();
            history.push("/");
          }, 1500);
        } else {
          console.log("error");
          setmessage("Some error occured, please try again in some time!");
          settitle("Error");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2500);
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    loading.current.style.display = "block";
    mixpanel.track("Dashboard - targetsearch submit button clicked");
    axios
      .post(
        "https://backend.lazyapply.com/targetedsearchdata",
        {
          data: formdata,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        console.log("successfully updated");
        openPayment();
        // showmessage("data successfully updated", "Updated success");
        // setTimeout(() => {
        //   setpresentdata(true);
        // }, 1000);
      })
      .catch((err) => {
        loading.current.style.display = "none";
        console.log("some error occured in uploading form");
      });
    console.log("form", formdata);
  };

  const handleChange = async (file) => {
    const uid = uuidv1();
    const url = `https://backend.lazyapply.com/targetedSearch/presignedurl/${uid}`;
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.data && "url" in response.data) {
      const uploadurl = response.data.url;
      axios
        .put(uploadurl, file, {
          headers: {
            "Content-Type": file.type,
          },
        })
        .then((response) => {
          const key = `${userData.email}/${uid}_resume`;
          setField("resumeReferenceId", key);
          showmessage("File uploaded successfully", "Uploaded success");
          console.log("blob uploaded successfully", response.data);
        })
        .catch((error) => {
          showmessage("Error occured in uploading file", "Error occured");
          console.log("blob uploaded error", error.message);
        });
    }
    setFile(file);
  };

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    let valid = true;
    Object.keys(formdata).forEach((key) => {
      const data = formdata[key];
      if (key === "resumeReferenceId" && data == "") {
        valid = false;
      } else if (key === "titles" && data.length === 0) {
        valid = false;
      }
    });
    setIsValid(valid);
  }, [formdata]);

  function goBack() {
    history.goBack();
  }

  useEffect(() => {
    mixpanel.track("Dashboard - targetsearch page visit");
  }, []);

  const TargetedForm = () => {
    return (
      <div style={{ position: "relative" }}>
        <button className="buttonGoBack btn" onClick={goBack}>
          <i className="fas fa-arrow-left"></i> Go Back
        </button>
        {presentdata ? (
          <>
            <div className="v2resume">
              {payment ? (
                <button
                  className="createfirstresume"
                  style={{ border: "none", cursor: "unset" }}
                >
                  Your response is submitted, we will reach out to you in 24
                  Hours
                </button>
              ) : (
                <>
                  <div className="paytocontinuecontainer">
                    <div className="paytocontinue continuetext">
                      To continue using LazyApply-X Targeted Search, Please
                      continue with the payment
                    </div>
                    <div className="paytocontinue">
                      <button onClick={openPayment} className="buttontarget">
                        <img src={"/assests/commerce.svg"} />
                        Get LazyApply-X
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div style={{ marginTop: "50px" }}>
              <p className="targetedFormText">
                Fill in the details to start your targeted search process
              </p>
              <Form onSubmit={handleSubmit}>
                <Row style={styleform}>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>
                        Job Title{" "}
                        <span className="compulsoryf">
                          ( compulsory field )*
                        </span>
                      </Form.Label>
                      <AsyncSelect
                        isMulti
                        cacheOptions
                        defaultOptions={defaultFn(
                          targetJson.titleDefaultOptions
                        )}
                        loadOptions={titlePromiseOptions}
                        id="titles"
                        value={formdata.titles}
                        onChange={(value) => setField("titles", value)}
                        placeholder="Select title"
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Companies To Include</Form.Label>
                      <AsyncSelect
                        isMulti
                        cacheOptions
                        defaultOptions={defaultFn(
                          targetJson.companyDefaultOptions
                        )}
                        value={formdata.companiesisanyof}
                        loadOptions={promiseOptions}
                        id="companiesisanyof"
                        onChange={(value) =>
                          setField("companiesisanyof", value)
                        }
                        placeholder="Select job companies"
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Companies To Exclude</Form.Label>
                      <AsyncSelect
                        isMulti
                        cacheOptions
                        value={formdata.companiesisnotanyof}
                        defaultOptions={defaultFn(
                          targetJson.companyDefaultOptions
                        )}
                        loadOptions={promiseOptions}
                        id="companiesisnotanyof"
                        onChange={(value) =>
                          setField("companiesisnotanyof", value)
                        }
                        placeholder="Select job companies"
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Number Of Employees</Form.Label>
                      <Select
                        isMulti
                        id="employees"
                        value={formdata.employees}
                        onChange={(value) => setField("employees", value)}
                        name="employees"
                        placeholder="Select no of employees:"
                        options={defaultFn(targetJson.employeesOptions)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Industries To Include</Form.Label>
                      <Select
                        isMulti
                        value={formdata.industryisanyof}
                        id="industryisanyof"
                        onChange={(value) => setField("industryisanyof", value)}
                        name="industryisanyof"
                        placeholder="Select industry"
                        options={defaultFn(targetJson.industryOptions)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Industries To Exclude</Form.Label>
                      <Select
                        isMulti
                        value={formdata.industryisnotanyof}
                        id="industryisnotanyof"
                        onChange={(value) =>
                          setField("industryisnotanyof", value)
                        }
                        name="industryisnotanyof"
                        placeholder="Select industry"
                        options={defaultFn(targetJson.industryOptions)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Revenue</Form.Label>
                      <Select
                        isMulti
                        id="revenues"
                        value={formdata.revenues}
                        onChange={(value) => setField("revenues", value)}
                        name="revenues"
                        placeholder="Select revenue"
                        options={defaultFn(targetJson.revenueOptions)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Funding Round</Form.Label>
                      <Select
                        isMulti
                        value={formdata.fundings}
                        id="fundings"
                        onChange={(value) => setField("fundings", value)}
                        name="revenues"
                        placeholder="Select funding round"
                        options={defaultFn(targetJson.fundingOptions)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Additional Information </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        id="additionalInfo"
                        value={formdata.additionalData}
                        placeholder="Enter additional requirements here.."
                        onChange={(e) => {
                          setField("additionalData", e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <p style={{ color: "grey" }}>
                      Upload your cv/resume in pdf/docx format{" "}
                      <span className="compulsoryf">( compulsory field )*</span>
                    </p>
                    <FileUploader
                      handleChange={handleChange}
                      name="file"
                      types={fileTypes}
                    />
                  </Col>
                  <Col md={6}>
                    <button
                      type="submit"
                      disabled={!isValid}
                      className={
                        "buttontarget" + (!isValid ? " buttontargetvalid" : "")
                      }
                    >
                      <img src={"/assests/commerce.svg"} />
                      Get LazyApply-X
                    </button>
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        )}
      </div>
    );
  };

  const TargetedInfo = () => {
    return (
      <>
        <div className="targetedSearchInfo">
          <p className="targetedSearchInfoTitle">
            LazyApply-X ( Targeted Search )
          </p>
          <p className="targetedSearchInfoDesc">
            {`Choose the type of company where you want to work and LazyApplyX will automatically email your resume to Senior HR, Junior Hr, Recruiter, and other relevant persons in the company`}
          </p>
          <p className="targetedSearchInfoDesc">
            {`How does LazyApply-X work?`}
          </p>
          <div className="targetedSearchInfoList">
            <ul>
              <li>
                <span>Create a list of companies based -</span> You choose the
                type of company with filters like company location, the funding
                round, Public/Private, Number of Employees, and many more, and
                upload your resume on our portal. You can also include or
                exclude specific companies from your list. Our AI will find all
                the relevant companies based on your filters.
              </li>
              <li>
                <span>Create a list of contacts -</span> We will find the
                relevant contact in these companies with their email address.
              </li>
              <li>
                <span>Email contacts -</span> LazyApply-X AI will write a
                beautiful email for you and email it to all of these contacts
                with your resume attached to your email. All the emails will be
                sent from your mail address. Recruiters won’t know that someone
                else sent an email on your behalf.
              </li>
            </ul>
          </div>
          <div>
            <button
              className="targetedSearchInfoButton"
              onClick={() => {
                mixpanel.track("Dashboard - targetsearch start now clicked");
                setstep(1);
              }}
            >
              Start Now
            </button>
          </div>
        </div>
      </>
    );
  };
  ///getCompanyPeople
  const [companyPeople, setCompanyPeople] = useState("");
  const [peopleArray, setPeopleArray] = useState([]);
  useEffect(() => {
    if (companyPeople != "") {
      axios
        .post(
          "https://backend.lazyapply.com/getCompanyPeople",
          {
            domain: companyPeople.value,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setHeightChange(1);
          setPeopleArray(response.data);
          console.log("res", response.data);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [companyPeople]);

  const TryEmail = () => {
    return (
      <>
        <div className="targetedSearchInfo targetedTry">
          <div style={{ marginBottom: "10px" }}>
            Find Emails
            {/* <span className="targetedSearchTry">TRY IT HERE</span> */}
          </div>
          <div className="">
            <Form.Group>
              <button
                className="targetSearchReset"
                onClick={() => {
                  setHeightChange(0);
                  setCompanyPeople("");
                  setPeopleArray([]);
                }}
              >
                Reset
              </button>
              <Form.Label>
                Find People ( Senior HR, Junior Hr, Recruiter, Talent
                Acquisition Manager ) From Selected Company
              </Form.Label>
              <AsyncSelect
                isMulti={false}
                cacheOptions
                value={companyPeople}
                loadOptions={promiseOptionsPeople}
                id="companiesisanyof"
                onChange={(value) => {
                  setCompanyPeople(value);
                }}
                placeholder="Search for a company"
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Form.Group>
          </div>
          <div
            className=""
            style={{
              height: peopleArray.length == 0 ? "0px" : "500px",
              overflow: "scroll",
            }}
          >
            {peopleArray.map((people) => {
              if (people.email) {
                return (
                  <>
                    <div className="cardPeopleContainer">
                      <div className="cardPeopleTop">
                        <div>{people.title}</div>
                      </div>
                      <div className="cardPeople">
                        <div className="cardPeopleImg">
                          <img
                            style={{ width: "50px" }}
                            src={people.photo_url}
                          ></img>
                        </div>
                        <div className="cardPeopleInfo">
                          <div>Email - {people.email}</div>
                          <div>Name - {people.name}</div>
                        </div>
                        <div className="cardPeopleLink">
                          <div
                            onClick={() =>
                              window.open(people.linkedin_url, "_blank")
                            }
                          >
                            Linkedin Profile
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="loading loadingresume" ref={loading}>
        <div className="boxLoading"></div>
      </div>
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      {/* {step == 1 ? TargetedForm() : <TargetedInfo></TargetedInfo>} */}
      {/* {step == 1 ? <></> : TryEmail()} */}
      {TryEmail()}
    </>
  );
}

export default TargetedSearch;
