import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useStateStore } from "../store/index";
import axios from "axios";
import "./feedback.css";
function FeedbackReferal({ hideFeedback, showFeedback }) {
  const globalLogout = useStateStore((state) => state.globalLogout);
  const [submitted, setSubmitted] = useState(false);
  const token = useStateStore((state) => state.token);
  const [problemdesc, setProblemDesc] = useState("");
  const [number, setNumber] = useState(-1);

  const setNumberWithTimeout = (n) => {
    handleSubmitFeedback1(n);
    setTimeout(() => {
      setNumber(n);
    }, 200);
  };

  const handleSubmitFeedback1 = (n) => {
    const feedback = {
      rating: n,
      desc: problemdesc,
    };
    setSubmitted(true);
    axios
      .post(
        "https://backend.lazyapply.com/saveFeedbackReferal",
        {
          feedback,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setSubmitted(false);
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 403") {
          console.log("reauthenticate");
          alert("Unauthorised");
          hideFeedback();
          setTimeout(() => {
            globalLogout();
          }, 1500);
        }
      });
  };

  const handleSubmitFeedback = () => {
    const feedback = {
      rating: number,
      desc: problemdesc,
    };
    setSubmitted(true);
    axios
      .post(
        "https://backend.lazyapply.com/saveFeedbackReferal",
        {
          feedback,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("feedback", response.data);
        setSubmitted(false);
        hideFeedback();
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 403") {
          console.log("reauthenticate");
          alert("Unauthorised");
          hideFeedback();
          setTimeout(() => {
            globalLogout();
          }, 1500);
        }
        console.log("some error occured");
      });
    console.log("submitted feedback");
  };

  const generateArray = (start, end) => {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  };

  return (
    <>
      {showFeedback && (
        <div className="feedbackform feedbackform_referal">
          <div className="feedbackform_body">
            <div className="feedbackform_hide" onClick={() => hideFeedback()}>
              X
            </div>
            {number === -1 ? (
              <>
                <div className="feedbackform_header_number">
                  How likely are you to recommend <strong>LAZYAPPLY</strong> to
                  someone ?
                </div>
                <div className="feedbackform_rating">
                  {generateArray(1, 10).map((i) => {
                    return (
                      <div
                        className={
                          "feedback_ratingnumber " +
                          (i <= 6
                            ? "ratingnumber_red"
                            : i <= 8
                            ? "ratingnumber_yellow"
                            : "ratingnumber_green")
                        }
                        onClick={() => setNumberWithTimeout(i)}
                      >
                        {i}
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <div className="feedbackform_desc feedbackform_desc_referal">
                <Form.Label style={{ textAlign: "center" }}>
                  <div className="feedbackform_header_number">
                    Would you like to say something about{" "}
                    <strong>LAZYAPPLY</strong>?
                  </div>
                </Form.Label>
                <Form.Control
                  value={problemdesc}
                  as="textarea"
                  placeholder="Your feedback is precious, type here.."
                  rows={2}
                  onChange={(e) => setProblemDesc(e.target.value)}
                />
                <div className="feedbackform_submit">
                  <button
                    onClick={handleSubmitFeedback}
                    disabled={submitted ? true : false}
                    type="button"
                    className="feedbackform_submitbutton_referal"
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default FeedbackReferal;
