import React, { useState, useEffect, useRef, useCallback } from "react";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import FeedbackModal from "../resumeScoreFeedback/FeedbackModal";

export default function ResumeJobScore({ form, setField }) {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const handleFeedbackModal = () => {
    setShowFeedbackModal(true);
  };

  const customResumeJobScores = [
    {
      value: 20,
      label: 20,
    },
    {
      value: 40,
      label: 40,
    },
    {
      value: 60,
      label: 60,
    },
    {
      value: 80,
      label: 80,
    },
  ];

  function resumeJobScoreValues(value) {
    return `${value}`;
  }

  return (
    <div>
      <FeedbackModal
        showFeedbackModal={showFeedbackModal}
        setShowFeedbackModal={setShowFeedbackModal}
      />

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        marginTop={5}
        marginBottom={2}
      >
        <p
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            // marginTop: "25px",
            margin: 0,
          }}
        >
          Job Match Score
        </p>
        <Button
          variant="contained"
          onClick={handleFeedbackModal}
          style={{
            fontSize: "1.2rem",
            backgroundColor: "#1976d2",
            color: "#fff",
            padding: "10px 20px",
            borderRadius: 20,
            boxShadow: "0 3px 5px 2px rgba(0, 0, 0, 0.2)",
            textTransform: "none",
            fontWeight: 600,
            // marginRight: "10px",
            // marginTop: "20px",
            margin: 0,
          }}
        >
          Feedback Job Match Score
        </Button>
      </Box>

      <p className="makesure" style={{ marginBottom: "40px" }}>
        Set the minimum match score using the slider below. We'll only apply to
        jobs that meet or exceed this score based on how well your resume
        matches the job description and other job parameters.
      </p>

      <Box
        border={1}
        borderColor="rgba(0, 0, 0, 0.12)"
        borderRadius={8}
        bgcolor="steelblue"
        p={3} // Padding of 3 spacing units
        m={2} // Margin of 2 spacing units
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box width="20%" textAlign="center">
          <Typography
            variant="h6"
            style={{ color: "#fff", fontWeight: 600 }}
            gutterBottom
          >
            Job Match Score
          </Typography>
          <Typography
            variant="h3"
            color="textSecondary"
            style={{ fontWeight: "bold", color: "papayawhip" }}
          >
            {form.resumeJobScore}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ marginTop: 8, color: "#fff" }}
          >
            Will only apply to jobs with a score greater than{" "}
            {form.resumeJobScore}
          </Typography>
        </Box>
        <Box width="80%" ml={10} mr={3}>
          <Slider
            aria-label="Resume Job Score"
            value={form.resumeJobScore}
            onChange={(e) => setField("resumeJobScore", e.target.value)}
            defaultValue={40}
            getAriaValueText={resumeJobScoreValues}
            step={1}
            id="sliderResumeJobScore"
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => {
              return "You";
            }}
            marks={customResumeJobScores}
            min={0}
            max={100}
            sx={{
              color: "#79c6fc",
              height: 14,
              "& .MuiSlider-thumb": {
                height: 40,
                width: 40,
                backgroundColor: "#fff",
                "&:hover": {
                  boxShadow: "none",
                },
              },
              "& .MuiSlider-track": {
                border: "none",
              },
              "& .MuiSlider-rail": {
                opacity: 1.0,
                backgroundColor: "silver",
              },
              "& .MuiSlider-markLabel": {
                color: "white",
                fontSize: "1.4rem",
                fontWeight: 800,
                marginTop: "8px",
                maxWidth: "120px",
                whiteSpace: "normal",
              },
              "& .MuiSlider-mark": {
                backgroundColor: "#fff",
                height: 14,
                width: 5,
              },
              "& .MuiSlider-valueLabel": {
                color: "#fff",
                fontWeight: "800",
                fontSize: "1.4rem",
                // backgroundColor: "#2a196e", // Change the background color of the box
                backgroundColor: "steelblue",
              },
            }}
          />
        </Box>
      </Box>
    </div>
  );
}
