import React, { useState, useMemo } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Form, Row, Col, Spinner } from "react-bootstrap";
import axios from "axios";
// import imgstripe from "../../powered-by-stripe2.png";
const useOptions = () => {
  const fontSize = "18px";
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const CardForm = ({ planName }) => {
  // const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [data, setdata] = useState({
    address: {
      city: "",
      country: "",
      state: "",
      line1: "",
      postal_code: "",
      line2: null,
    },
    email: "",
    name: "",
    phone: "",
  });

  const setAddressField = (key, value) => {
    setdata({
      ...data,
      address: {
        ...data.address,
        [key]: value,
      },
    });
  };

  const setField = (key, value) => {
    setdata({
      ...data,
      [key]: value,
    });
  };
  const [error, seterror] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();
    setprocessing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setprocessing(false);
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: data,
    });

    // console.log("[PaymentMethod]", payload);
    if ("error" in payload) {
      setprocessing(false);
      seterror(payload.error.message);
      window.analytics.track(
        "BNPL",
        {
          label: "buy now pay later",
          planName: planName,
          email: data.email,
          text: "failure",
        },
        {}
      );
    } else {
      if ("paymentMethod" in payload) {
        if (payload.paymentMethod.id != "") {
          //send to backend
          axios
            .post("https://backend.lazyapply.com/linkwithbnpl", {
              paymentid: payload.paymentMethod.id,
              planName: planName,
              paymentData: data,
            })
            .then((res) => {
              setprocessing(false);
              window.analytics.track(
                "BNPL",
                {
                  label: "buy now pay later",
                  planName: planName,
                  email: data.email,
                  text: "success",
                },
                {}
              );
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              console.log("res bnpl", res);
            })
            .catch((err) => {
              setprocessing(false);
              window.analytics.track(
                "BNPL",
                {
                  label: "buy now pay later",
                  planName: planName,
                  email: data.email,
                  text: "failure",
                },
                {}
              );
              alert("Some error occured, try again in some time");
              console.log(err, "some error occured on bnpl");
            });
        } else {
          setprocessing(false);
        }
      } else {
        alert("Some error occured, try again in some time");
        setprocessing(false);
      }
      seterror("");
    }
  };

  const [processing, setprocessing] = useState(false);

  return (
    <>
      <form onSubmit={handleSubmit} className="stripeform">
        <Row>
          <Col md={12}>
            <Form.Label htmlFor="inputname">Name</Form.Label>
            <Form.Control
              required
              type="text"
              id="inputname"
              placeholder="Jane Doe"
              onChange={(e) => setField("name", e.target.value)}
            />
          </Col>
          <Col md={6}>
            <Form.Label htmlFor="inputemail">Email</Form.Label>
            <Form.Control
              required
              type="email"
              id="inputemail"
              placeholder="janedoe@gmail.com"
              onChange={(e) => setField("email", e.target.value)}
            />
          </Col>
          <Col md={6}>
            <Form.Label htmlFor="inputphone">Phone</Form.Label>
            <Form.Control
              required
              type="number"
              id="inputphone"
              placeholder="(941)555-0123"
              onChange={(e) => setField("phone", e.target.value)}
            />
          </Col>
          <Col md={6}>
            <Form.Label htmlFor="inputaddress">Address</Form.Label>
            <Form.Control
              required
              type="text"
              id="inputaddress"
              placeholder="185 Berry St"
              onChange={(e) => setAddressField("line1", e.target.value)}
            />
          </Col>
          <Col md={6}>
            <Form.Label htmlFor="inputcity">City</Form.Label>
            <Form.Control
              required
              type="text"
              id="inputcity"
              placeholder="San Francisco"
              onChange={(e) => setAddressField("city", e.target.value)}
            />
          </Col>
          <Col md={4}>
            <Form.Label htmlFor="inputstate">State</Form.Label>
            <Form.Control
              required
              type="text"
              id="inputstate"
              placeholder="CA"
              onChange={(e) => setAddressField("state", e.target.value)}
            />
          </Col>
          <Col md={4}>
            <Form.Label htmlFor="inputcountry">Country</Form.Label>
            <Form.Control
              required
              type="text"
              id="inputcountry"
              placeholder="US"
              onChange={(e) => setAddressField("country", e.target.value)}
            />
          </Col>
          <Col md={4}>
            <Form.Label htmlFor="inputzip">ZIP</Form.Label>
            <Form.Control
              required
              type="number"
              id="inputzip"
              placeholder="94107"
              onChange={(e) => setAddressField("postal_code", e.target.value)}
            />
          </Col>
        </Row>

        <label className="stripelabel">Card details</label>
        <CardElement
          options={options}
          onReady={() => {
            console.log("CardElement [ready]");
          }}
          onChange={(event) => {
            console.log("CardElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardElement [blur]");
          }}
          onFocus={() => {
            console.log("CardElement [focus]");
          }}
        />
        <p className="errorstripe">{error}</p>
        <button
          type="submit"
          disabled={!stripe || processing}
          className={processing ? "stripebuttondisable" : ""}
        >
          Buy Now And Pay Later
          {processing ? (
            <Spinner
              animation="border"
              size="sm"
              style={{ marginLeft: "5px" }}
            />
          ) : (
            <></>
          )}
        </button>
        <img
          className="stripeimg"
          src={"/assests/powered-by-stripe2.png"}
        ></img>
      </form>
    </>
  );
};

export default CardForm;
