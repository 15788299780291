/*global chrome*/
import React, { useEffect, useState } from "react";
import { mixpanel } from "../../index";
import { Form, FormControl } from "react-bootstrap";
import axios from "axios";
import "./coverLetter.css";

function CoverLetter() {
  useEffect(() => {
    mixpanel.track("Dashboard - cover letter page visit");
  }, []);

  const [loading, setloading] = useState(false);
  const replaceWithBr = (text) => {
    return text.replace(/\n/g, "<br />");
  };

  const [optional, setoptional] = useState(false);
  const [finaltext, setfinaltext] = useState("");
  const submitGetStarted = (e) => {
    e.preventDefault();
    setloading(true);
    setshowresume(false);
    mixpanel.track("Dashboard - cover letter generated  successfully", {
      ...data,
    });
    axios
      .post("https://backend.lazyapply.com/coverletter", data)
      .then((response) => {
        console.log("response", response);
        if (response.data?.finalcoverletter) {
          if (
            response.data.finalcoverletter?.choices &&
            response.data.finalcoverletter?.choices.length > 0
          ) {
            const finaltext = response.data.finalcoverletter?.choices[0].text;
            setfinaltext(finaltext);
            setshowresume(true);
            setloading(false);
          }
        }
      })
      .catch((error) => {
        setshowresume(false);
        setloading(false);
        console.log("some error occured", error);
      });
  };

  const [textFinal, setTextFinal] = useState("");
  useEffect(() => {
    const setFinal = async () => {
      if (finaltext != "") {
        const words = finaltext.split(" ");
        let previous = "";
        for (let index = 0; index < words.length; index++) {
          const word = words[index];
          setTextFinal(previous + " " + word);
          previous = previous + " " + word;
          await new Promise((res, _) => {
            setTimeout(() => {
              res();
            }, 100);
          });
        }
      }
    };
    setFinal();
  }, [finaltext]);

  const [data, setdata] = useState({
    position: "",
    myname: "",
    companyname: "",
    toname: "",
    skills: "",
    roletype: "",
    location: "",
  });
  const [showresume, setshowresume] = useState(false);
  const setCoverLetterData = (key, value) => {
    setdata({ ...data, [key]: value });
  };

  function copyContent(text) {
    if (window.clipboardData && window.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return window.clipboardData.setData("Text", text);
    } else if (
      document.queryCommandSupported &&
      document.queryCommandSupported("copy")
    ) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand("copy"); // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        return prompt("Copy to clipboard: Ctrl+C, Enter", text);
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }

  return (
    <>
      <div className="coverletterbox" style={{ position: "relative" }}>
        {!loading && !showresume && (
          <p className="featureHeading featureheadingcover">
            Cover letter in seconds by AI
          </p>
        )}
        {showresume && (
          <>
            <button className="resetcover tryagain" onClick={submitGetStarted}>
              Try Again
            </button>
            <button
              className="resetcover copycover"
              onClick={() => {
                copyContent(finaltext);
                alert("Copied successfully!");
              }}
            >
              <i className="fas fa-copy"></i>
            </button>
            <button
              className="resetcover"
              onClick={() => {
                setshowresume(false);
                setloading(false);
              }}
            >
              Reset
            </button>
          </>
        )}
        {!loading && !showresume && (
          <div>
            <p className="coverletterenterdetails">
              Enter your details to generate a cover letter
            </p>
            <div className="formcoverletter">
              <Form onSubmit={submitGetStarted} className="formcover">
                <div className="coverletterformdiv">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="coverletterinputblock">
                        <Form.Label className="coverletterlabel">
                          Position Name*
                        </Form.Label>
                        <FormControl
                          required={true}
                          id="position"
                          placeholder="Ex. software developer"
                          type="text"
                          value={data.position}
                          onChange={(e) => {
                            setCoverLetterData("position", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="coverletterinputblock">
                        <Form.Label className="coverletterlabel">
                          Sender Name*
                        </Form.Label>
                        <FormControl
                          required={true}
                          id="name"
                          placeholder="Ex. mehul"
                          type="text"
                          value={data.myname}
                          onChange={(e) => {
                            setCoverLetterData("myname", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="coverletterinputblock">
                        <Form.Label className="coverletterlabel">
                          Company Name*
                        </Form.Label>
                        <FormControl
                          required={true}
                          id="name"
                          placeholder="Ex. google"
                          type="text"
                          value={data.companyname}
                          onChange={(e) => {
                            setCoverLetterData("companyname", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="coverletterinputblock">
                        <Form.Label className="coverletterlabel">
                          Receiver Name*
                        </Form.Label>
                        <FormControl
                          required={true}
                          id="name"
                          placeholder="Ex. Lavish"
                          type="text"
                          value={data.toname}
                          onChange={(e) => {
                            setCoverLetterData("toname", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {optional && (
                      <>
                        <div className="col-lg-3">
                          <div className="coverletterinputblock">
                            <Form.Label className="coverletterlabel">
                              Skills (comma seperated)
                            </Form.Label>
                            <FormControl
                              id="skills"
                              placeholder="Ex. python,js,c++"
                              type="text"
                              value={data.skills}
                              onChange={(e) => {
                                setCoverLetterData("skills", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="coverletterinputblock">
                            <Form.Label className="coverletterlabel">
                              Role Type
                            </Form.Label>
                            <FormControl
                              id="roletype"
                              placeholder="Ex. fulltime, intership"
                              type="text"
                              value={data.roletype}
                              onChange={(e) => {
                                setCoverLetterData("roletype", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="coverletterinputblock">
                            <Form.Label className="coverletterlabel">
                              Job Location
                            </Form.Label>
                            <FormControl
                              id="location"
                              placeholder="Ex. bangalore, remote etc"
                              type="text"
                              value={data.location}
                              onChange={(e) => {
                                setCoverLetterData("location", e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div style={{ marginBottom: "100px" }}>
                    <button
                      onClick={() => setoptional(!optional)}
                      className="optionalcover"
                      type="button"
                    >
                      {!optional ? "Show" : "Hide"} Optional Parameters
                    </button>
                  </div>
                  <div className="covergeneratorbutton">
                    <button type="submit">Generate Cover Letter</button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}
        {loading && !showresume && (
          <>
            <div className="formcoverletter">
              <img
                className="loadingcover"
                alt={"ai-coverletter-loading"}
                src={"https://content.lazyapply.com/ai1.gif"}
              />
            </div>
            <p
              style={{
                display: "block",
                width: "100%",
                fontWeight: "bold",
                textAlign: "center",
                color: "black",
                marginTop: "25px",
              }}
            >
              Generating cover letter for you......
            </p>
          </>
        )}
        {showresume && (
          <div
            className="coverletteroutput"
            dangerouslySetInnerHTML={{
              __html: replaceWithBr(textFinal),
            }}
          />
        )}
      </div>
    </>
  );
}

export default CoverLetter;
