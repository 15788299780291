import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import LazyapplyXFirst from "./LazyapplyXFirst";
import LazyapplyXFirst1 from "./LazyapplyXFirst1";
import LazyapplyXSecond from "./LazyapplyXSecond";
import axios from "axios";
import { useStateStore } from "../../store";
import "./lazyapplyx.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

function LazyapplyX() {
  const CLIENT_ID =
    "715818145189-7geg3j3td9u21nc9qe8eujb2l4dcbt88.apps.googleusercontent.com";
  const lazyapplyXData = useStateStore((state) => state.lazyapplyXData);
  const setLazyapplyXData = useStateStore((state) => state.setLazyapplyXData);
  const setGeneratedSampleEmail = useStateStore(
    (state) => state.setGeneratedSampleEmail
  );
  const userData = useStateStore((state) => state.userData);
  const settingsClick = useStateStore((state) => state.settingsClick);
  const token = useStateStore((state) => state.token);
  const getData = () => {
    axios
      .get(`https://backend.lazyapply.com/targetSearchData`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("data", response.data);
        setLazyapplyXData(response.data);
        if (response.data.sampleEmailGenerated) {
          setGeneratedSampleEmail(response.data.sampleEmailGenerated);
        }
      })
      .catch((err) => {
        console.log("some error occured");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const emails = [
    "prakhar@lazyapply.com",
    "oauthtest222@gmail.com",
    "oauthtest121@gmail.com",
    "gupta.19@iitj.ac.in",
  ];

  return (
    <>
      {lazyapplyXData.stepNo == 0 && (
        <GoogleOAuthProvider clientId={CLIENT_ID}>
          {userData?.email && emails.includes(userData.email) ? (
            <LazyapplyXFirst></LazyapplyXFirst>
          ) : (
            <LazyapplyXFirst1></LazyapplyXFirst1>
          )}
        </GoogleOAuthProvider>
      )}
      {settingsClick == true ? (
        <>
          <LazyapplyXSecond></LazyapplyXSecond>
        </>
      ) : (
        <>
          {lazyapplyXData.stepNo == 1 && (
            <>
              <LazyapplyXSecond></LazyapplyXSecond>
            </>
          )}
          {lazyapplyXData.stepNo == 2 && (
            <>
              <Redirect to="/dashboard/lazyapply-x/sessions" />
            </>
          )}
        </>
      )}
    </>
  );
}

export default LazyapplyX;
