/*global chrome*/
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Form, Col, Row, FormCheck } from "react-bootstrap";
import "./jobplatform.css";
import { useHistory } from "react-router-dom";
import Progress from "./progress";
import axios from "axios";
import { useStateStore } from "../../store/index";
import CustomAlert from "../modal";
import { mixpanel } from "../../index";
import AdvanceSearchModal from "./AdvanceSearchModal";
// import Select from "react-select";
import Feedback from "../feedback";
import { v1 as uuidv1 } from "uuid";
// import StepsWithCut from "../stepswithcut";
import MoreFiltersHelp from "../morefiltershelp";
// import { isEqual } from "lodash";
import { MESSAGETYPE, messages, MESSAGES } from "./slackMessages";
import { GrantAccess } from "./grantAccess";

import SaveSearchModal from "./saveSearchModal";
import GetSavedSearches from "./getSearches";

import faqObj from "./faqJson";
import IndeedArticle from "./indeedArticle";
import ExtensionInstallPopup from "./ExtensionInstallPopup";
import MainWatchDemoModal from "./MainWatchDemoModal";
import mainFilters from "../filters.json";
import ResumeJobScore from "./resumeJobScore/resumeJobScore";

function Ziprecruiter() {
  const setScriptInjectedGlobal = useStateStore(
    (state) => state.setScriptInjectedGlobal
  );
  const scriptInjectedGlobal = useStateStore(
    (state) => state.scriptInjectedGlobal
  );
  const setExtensionPresent = useStateStore(
    (state) => state.setExtensionPresent
  );
  const setUserResumeV3 = useStateStore((state) => state.setUserResumeV3);
  const [showPlanRequired, setShowPlanRequired] = useState(false);
  const { availableChromeVersion } = useStateStore.getState();
  const [showPlanPurchase, setShowPlanPurchase] = useState(false);
  const [showDemoPopup, setShowDemoPopup] = useState(false);
  const [showExtensionInstallPopup, setShowExtensionInstallPopup] =
    useState(false);
  const [showUpdateExtension, setShowUpdateExtension] = useState(false);
  const closeModal = () => {
    setShowIndeedModal(false);
  };

  const setStreakAnalytics = useStateStore((state) => state.setStreakAnalytics);
  const streakAnalytics = useStateStore((state) => state.streakAnalytics);
  const yourExtensionId = useStateStore((state) => state.yourExtensionId);
  const setGlobalTabId = useStateStore((state) => state.setGlobalTabId);
  const [resumeDisable, setResumeDisable] = useState(false);
  const [jobPostedTime, setJobPostedTimeOptions] = useState([]);
  const [jobDistance, setJobDistanceOptions] = useState([]);
  const [jobSalaryRange, setJobSalaryRangeOptions] = useState([]);
  const [jobEmploymentType, setJobEmploymentTypeOptions] = useState([]);
  const [jobTitle, setJobTitleOptions] = useState([]);
  const [remoteOptions, setRemoteOptions] = useState([]);
  const formMain1 = useRef(null);
  const selectResumeRef = useRef(null);
  const chooseParaText = useRef(null);
  const [formDisable, setformDisable] = useState(false);
  const spinner = useRef(null);
  const fetchbutton = useRef(null);
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);
  const [selectedSearchData, setSelectedSearchData] = useState({
    searchId: "",
    searchName: "",
  });
  const [searchInputValue, setSearchInputValue] = useState({
    value: "",
    label: "Select a saved search setting",
  });
  const [showSavedSearchButton, setShowSavedSearchButton] = useState(true);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [showSavedSearchModal, setShowSavedSearchModal] = useState(false);
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [excludeString, setExcludeString] = useState("");
  const [mayIncludeString, setMayIncludeString] = useState("");
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const button1 = useRef(null);
  const [disable2, setdisable2] = useState(0);
  const saveSessionState = useRef(0);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const globalLogout = useStateStore((state) => state.globalLogout);
  const history = useHistory();
  const userData = useStateStore((state) => state.userData);
  const userLinksUpdate = useStateStore((state) => state.userLinksUpdate);
  const configDummy = useStateStore((state) => state.configDummy);
  const configDummyUpdate = useStateStore((state) => state.configDummyUpdate);
  const [startApplying, setstartApplying] = useState(false);
  const sessionLinks = useRef([]);
  const [progressPercent, setProgressPercent] = useState(15);
  const [count, setCount] = useState(0);
  const totalCountUpdate = useStateStore((state) => state.totalCountUpdate);
  const formRef = useRef({});
  const c = useRef(0);
  const [finalJobCount, setFinalJobCount] = useState(0);
  const pp = useRef(15);
  const processing = useRef(null);
  const initprocessingFinal = useRef(null);
  const initprocessing = useRef(null);
  const formMain = useRef(null);
  const buttonFetch = useRef(null);
  const setheightMain = useStateStore((state) => state.setheightMain);
  const mainheight = useRef(null);
  const setZiprecruiterRunning = useStateStore(
    (state) => state.setZiprecruiterRunning
  );
  const questionRefs = useRef([]);
  const [showIndeedModal, setShowIndeedModal] = useState(null);
  const [modalTitle, setModalTitle] = useState("Issue");
  const [form, setForm] = useState({
    skill: "",
    location: "",
    numberOfJobs: 0,
    radiusOptions: "",
    orgOptions: "",
    daysOptions: "",
    titleOptions: "",
    tagsOptions: "",
    salaryOptions: "",
    remote: "",
    resumeJobScore: 40,
  });
  const token = useStateStore((state) => state.token);
  const [errors, setErrors] = useState({});
  const [filter, setFilter] = useState(0);
  const [openHelper, setOpenHelper] = useState(false);
  const userResumeV3 = useStateStore((state) => state.userResumeV3);
  const [resumeId, setResumeId] = useState("");
  const resumeData = useRef({});
  const [resumeChanged, setResumeChanged] = useState(false);
  const [urlMain, setUrlMain] = useState("");
  const sessionId = useRef(null);

  const styleform = {
    padding: "20px",
    paddingTop: "0px",
  };

  const filtersStyle = {
    padding: "20px",
    boxShadow: "0px 2px 4px 2px lightgrey, 0px 2px 4px 2px #efefef",
    margin: "5px",
    position: "relative",
  };

  let buttonMain1 = {
    backgroundColor: "#35AD46",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "10px 10px",
    textAlign: "center",
    borderRadius: "10px",
    outline: "none",
    border: "none",
  };

  let buttonMain = {
    backgroundColor: "grey",
    color: "white",
    cursor: "default",
    fontSize: "16px",
    padding: "10px 10px",
    textAlign: "center",
    borderRadius: "10px",
    outline: "none",
    border: "none",
  };

  function smoothScrollToTop() {
    let start = null;
    const initialScrollTop = window.scrollY;

    function easeOutCubic(t) {
      return 1 - Math.pow(1 - t, 3);
    }

    function step(timestamp) {
      if (start === null) start = timestamp;
      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / 1000, 1); // take 2 seconds to scroll

      const easedProgress = easeOutCubic(progress);
      const newScrollTop = initialScrollTop * (1 - easedProgress);

      window.scrollTo(0, newScrollTop);

      if (newScrollTop > 0) {
        window.requestAnimationFrame(step);
      }
    }

    window.requestAnimationFrame(step);
  }

  const handleCloseSearch = () => {
    setShowAdvanceSearch(false);
  };

  const setField = (field, value) => {
    console.log(field, value);
    setForm({
      ...form,
      [field]: value,
    });

    // Check and see if errors exist, and remove them from the error object:
    console.log(form);

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const closeExtensionInstallPopup = () => {
    setShowExtensionInstallPopup(false);
  };

  const onClickWatchDemo = () => {
    setShowExtensionInstallPopup(false);
    setShowDemoPopup(true);
  };

  let chromeExtensionPresent = async () => {
    let extensionPresentMain = 0;
    if (typeof chrome !== "undefined" && chrome && chrome.runtime) {
      console.log("check1");
      extensionPresentMain = await new Promise((resolve, reject) => {
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            lazyapply: "lazyapply",
            message: "check",
            injected: scriptInjectedGlobal,
          },
          function (response) {
            // Do whatever you want, background script is ready now
            var lastError = chrome.runtime.lastError;
            if (lastError) {
              console.log("error", lastError.message);

              // 'Could not establish connection. Receiving end does not exist.'
              resolve(0);
            }
            console.log("check0", response);
            if ("status" in response && response.status == "installed") {
              setScriptInjectedGlobal(true);
              setExtensionPresent(true);
              console.log("check2");

              resolve(1);
            }
          }
        );
      });
      console.log("extensionpresent", extensionPresentMain);
      return extensionPresentMain;
    } else {
      extensionPresentMain = 0;
      return extensionPresentMain;
    }
  };

  const fetchFilterUserHandler = async () => {
    if (userData.planDetails.planStarted) {
      const response = await GrantAccess("linkedin");
      if (!response.access) {
        if (response.resultObj.extensionNotPresent) {
          setShowExtensionInstallPopup(true);
        } else if (response.resultObj.updateExtension) {
          setShowUpdateExtension(true);
          setShowExtensionInstallPopup(true);
        } else {
          setmessage(response.resultObj.message);
          settitle(response.resultObj.title);
          setmodalshow(true);
          if (response?.resultObj?.gotolinknew) {
            window.open(response.resultObj.gotolinknew, "_blank");
          }
          if (response?.resultObj?.gotolink) {
            history.push(response.resultObj.gotolink);
          }
        }
        return 0;
      } else {
        return 1;
      }
    } else {
      if (resumeId == "") setResumeId("demo_id_lazyapply");
      const extensionPresent = await chromeExtensionPresent();
      console.log("heree111", extensionPresent);
      if (!extensionPresent) {
        setShowExtensionInstallPopup(true);
        return 0;
      } else if (
        chromeVersion != availableChromeVersion &&
        chromeVersion != -1 &&
        availableChromeVersion != -1
      ) {
        setShowExtensionInstallPopup(true);
        setShowUpdateExtension(true);
        return 0;
      } else {
        return 1;
      }
    }
  };

  const handleSubmit = async (e) => {
    console.log("handle submit");
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    console.log(newErrors);
    // Conditional logic:
    if (userData?.planDetails?.v2Id) {
      //v2
      if (resumeId === "" && Object.keys(userResumeV3).length > 0) {
        setResumeChanged(true);
      } else if (Object.keys(newErrors).length > 0) {
        // We got errors!
        setErrors(newErrors);
      } else {
        // No errors! Put any logic here for the form submission!
        if (await fetchFilterUserHandler()) {
          startLinkedinAutomation1();
        }
        // startLinkedinAutomation();
      }
    } else {
      if (Object.keys(newErrors).length > 0) {
        // We got errors!
        setErrors(newErrors);
      } else {
        // No errors! Put any logic here for the form submission!
        if (await fetchFilterUserHandler()) {
          startLinkedinAutomation1();
        }
        // startLinkedinAutomation();
      }
    }
  };

  const findFormErrors = () => {
    const { skill, numberOfJobs } = form;
    const newErrors = {};
    // name errors
    if (!skill || skill === "") newErrors.skill = "Please enter a skill!";
    if (numberOfJobs <= 0)
      newErrors.numberOfJobs = "Please enter the no. of jobs to apply";

    if (numberOfJobs < 20 && configDummy.planName != "free") {
      if (configDummy.dailyLimit - configDummy.todaysessioncount <= 20) {
        console.log("go for it");
      } else {
        newErrors.numberOfJobs = "Please enter a value more than 35";
      }
    } else if (numberOfJobs < 35 && configDummy.planName == "free") {
      if (configDummy.dailyLimit - configDummy.todaysessioncount < 35) {
        console.log("go for it");
      } else {
        newErrors.numberOfJobs = "Please enter a value more than 35";
      }
    }

    return newErrors;
  };

  function goBack() {
    console.log("go back");
    mixpanel.identify(userData.email);
    mixpanel.people.increment("go_back_button ziprecruiter_page dashboard");
    mixpanel.track("go_back_button dashboard", {
      href: window.location.href,
      email: userData?.email,
      platformName: "ziprecruiter",
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    history.goBack();
  }

  const stringToArray = (string) => {
    return string
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s.length > 0);
  };

  function startLinkedinAutomation1() {
    spinner.current.style.display = "inline-block";

    if (
      configDummy.dailyLimit - configDummy.todaysessioncount >=
        form.numberOfJobs ||
      !userData.planDetails.planStarted
    ) {
      fetchbutton.current.disabled = true;
      formRef.current = {
        ...form,
      };

      console.log(form.skill + "skill");
      console.log(form.location + "location");
      const skills = form.skill.split(" ");
      let string1 = "";
      for (let index = 0; index < skills.length; index++) {
        const element = skills[index];
        if (index == 0) {
          string1 += element;
        } else {
          string1 += "+" + element;
        }
      }
      let url = "";
      if (form.location != "")
        url = ` https://www.ziprecruiter.com/candidate/search?search=${string1}&location=${form.location.replace(
          /[^a-zA-Z\s]/g,
          ""
        )}`;
      else {
        url = `https://www.ziprecruiter.com/candidate/search?search=${string1}`;
      }
      setUrlMain(url);
      setZiprecruiterRunning(1);

      mixpanel.identify(userData?.email);
      mixpanel.people.increment(
        "click_to_fetch_filter_button ziprecruiter_page dashboard"
      );
      mixpanel.track(
        "click_to_fetch_filter_button ziprecruiter_page dashboard",
        {
          href: window.location.href,
          email: userData?.email,
          skill: form.skill,
          location: form.location,
          numberOfJobs: form.numberOfJobs,
          excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
          mayIncludeKeywords: advanceSearch
            ? stringToArray(mayIncludeString)
            : [],
          plan_details: userData?.planDetails || {},
          v2Id: userData?.planDetails?.v2Id || "",
        }
      );
      if (typeof chrome !== "undefined" && chrome && chrome.runtime)
        chrome.runtime.sendMessage(yourExtensionId, {
          dashboard: true,
          saveValues: {
            excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
            mayIncludeKeywords: advanceSearch
              ? stringToArray(mayIncludeString)
              : [],
            ziprecruiterFetchFilters: 0,
            ziprecruiterLimit: Math.min(form.numberOfJobs, 200),
            ziprecruiterJobLinks: [],
            resumeJobScore: form.resumeJobScore,
            userProvidedJobTitle: form.skill,
            unappliedJobsDueToScore: 0,
          },
          ziprecruiter: true,
          message: "ziprecruiterFetchFilters",
          data: {
            url: `${url}`,
            selected: true,
            ziprecruiter: true,
            message: "tabid",
          },
        });
    } else {
      spinner.current.style.display = "none";
      if (configDummy.dailyLimit - configDummy.todaysessioncount == 0) {
        setmessage("You have used your daily limit! Come back tomorrow :)");
        settitle("Daily limit exceeded!");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 2500);
        setTimeout(() => {
          history.push("/dashboard");
        }, 3000);
      } else {
        setmessage(
          `You have applied to ${
            configDummy.todaysessioncount
          } jobs today. Please enter a value less than ${
            configDummy.dailyLimit - configDummy.todaysessioncount
          }`
        );
        settitle("Daily limit update!");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 2500);
      }
    }
  }

  function startLinkedinAutomation() {
    setZiprecruiterRunning(1);
    setstartApplying(true);
    formRef.current = {
      ...form,
    };
    setResumeDisable(true);
    saveSessionState.current = 0;
    button1.current.style.backgroundColor = "grey";
    button1.current.style.color = "black";
    button1.current.innerText = "Process Started!";
    console.log(form.skill + "skill");
    console.log(form.location + "location");
    console.log("Filters selected according to which  now everything will run");
    console.log("Run the main process again", form);

    mixpanel.identify(userData?.email);
    mixpanel.people.increment(
      "start_applying_button ziprecruiter_page dashboard"
    );
    mixpanel.track("start_applying_button ziprecruiter_page dashboard", {
      href: window.location.href,
      email: userData?.email,
      skill: form.skill,
      location: form.location,
      numberOfJobs: form.numberOfJobs,
      excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
      mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
      formData: formRef.current,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });

    let url = urlMain;
    if (form.daysOptions != "") {
      url += "&days=" + form.daysOptions;
    }
    if (form.titleOptions != "") {
      url += "&refine_by_title=" + form.titleOptions;
    }
    if (form.salaryOptions != "") {
      url += "&refine_by_salary=" + form.salaryOptions;
    }
    if (form.remote != "") {
      url += "&refine_by_location_type=" + form.remote;
    }
    if (form.tagsOptions != "") {
      // url += "&refine_by_tags=" + form.tagsOptions;
      url += "&refine_by_employment=" + form.tagsOptions;
    }
    if (form.radiusOptions != "") {
      url += "&radius=" + form.radiusOptions;
    }

    console.log(url, "final url --------------------------------ziprecruiter");
    initprocessing.current.style.display = "flex";
    smoothScrollToBottom();
    sessionId.current = uuidv1();
    console.log("current sessionId", sessionId.current);
    if (typeof chrome !== "undefined" && chrome && chrome.runtime)
      chrome.runtime.sendMessage(yourExtensionId, {
        dashboard: true,
        saveValues: {
          ziprecruiterSessionId: sessionId.current,
          excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
          mayIncludeKeywords: advanceSearch
            ? stringToArray(mayIncludeString)
            : [],
          ziprecruiterBaseUrl: url,
          ziprecruiterLimit: Math.min(form.numberOfJobs, 200),
          ziprecruiterJobLinks: [],
          ziprecruiterResumeId: resumeId,
          session: {
            platformName: "ziprecruiter",
            formData: formRef.current,
          },
          resumeData: resumeData.current,
        },
        ziprecruiter: true,
        message: "ziprecruiterStartApplying",
        data: {
          url: `${url}`,
          selected: true,
          ziprecruiter: "true",
          message: "setBaseUrl",
          ziprecruiterBaseUrl: url,
        },
      });
  }

  const handleSubmit1 = (e) => {
    e.preventDefault();
    console.log("Filter Submit", form);
    startLinkedinAutomation();
  };

  function filterReset() {
    setForm({
      ...form,
      radiusOptions: "",
      orgOptions: "",
      daysOptions: "",
      titleOptions: "",
      salaryOptions: "",
      remote: "",
      tagsOptions: "",
    });
  }

  function ziprecruiterReset() {
    console.log("Linkein Reset");
    if (typeof chrome !== "undefined" && chrome && chrome.runtime)
      chrome.runtime.sendMessage(yourExtensionId, {
        dashboard: true,
        ziprecruiter: true,
        message: "resetziprecruiter",
      });
  }

  function getUnappliedJobsDueToResumeScore() {
    console.log("Unapplied jobs due to resume score");

    if (typeof chrome !== "undefined" && chrome && chrome.runtime)
      chrome.runtime.sendMessage(
        yourExtensionId,
        {
          dashboard: true,
          ziprecruiter: true,
          message: "totalUnappliedJobsDueToResumeScore",
        },
        function (response) {
          if (chrome.runtime.lastError) {
            console.error(chrome.runtime.lastError);
          } else {
            console.log("Received response:", response);
          }
        }
      );
  }

  function resetFull() {
    mixpanel.identify(userData?.email);
    mixpanel.people.increment("reset_button ziprecruiter_page dashboard");
    mixpanel.track("reset_button dashboard", {
      href: window.location.href,
      email: userData?.email,
      platformName: "ziprecruiter",
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    if (saveSessionState.current == 0) {
      saveSession(c.current, "ziprecruiter");
    }
    resumeData.current = {};
    window.wasRun = false;
    setResumeId("");
    setShowPlanPurchase(false);
    setResumeDisable(false);
    if (selectResumeRef.current)
      selectResumeRef.current.style.display = "block";
    chooseParaText.current.style.display = "none";
    setformDisable(false);
    setZiprecruiterRunning(0);
    formMain1.current.style.display = "none";
    sessionLinks.current = [];
    fetchbutton.current.disabled = false;
    buttonFetch.current.style.display = "block";
    setShowSavedSearchButton(true);
    setMayIncludeString("");
    setExcludeString("");
    setSelectedSearchData({
      searchId: "",
      searchName: "",
    });
    setSearchInputValue({
      value: "",
      label: "Select a saved search setting",
    });
    setForm({
      skill: "",
      location: "",
      numberOfJobs: 0,
      radiusOptions: "",
      orgOptions: "",
      daysOptions: "",
      titleOptions: "",
      salaryOptions: "",
      tagsOptions: "",
      remote: "",
      resumeJobScore: 40,
    });
    ziprecruiterReset();
    filterReset();
    formRef.current = {};
    document.getElementById("inputskill").value = "";
    document.getElementById("inputlocation").value = "";
    document.getElementById("inputnumber").value = "";
    document.getElementById("sliderResumeJobScore").value = 40;
    c.current = 0;
    pp.current = 15;
    button1.current.style.backgroundColor = "#F8F9FA";
    button1.current.style.color = "#007bff";
    button1.current.innerText = "Start Applying";
    setProgressPercent(15);
    initprocessingFinal.current.style.display = "none";
    initprocessing.current.style.display = "none";
    formMain.current.reset();
    setstartApplying(false);
    setCount(0);
  }

  const messageFn = useCallback((event) => {
    console.log("zipPreviousMessages", event.data);
    if (
      typeof event.data === "object" &&
      !Array.isArray(event.data) &&
      event.data !== null
    ) {
      h(event.data);
      event.source.postMessage("finished", event.origin);
    }
  }, []);

  function saveSession(count, platformName) {
    console.log(formRef.current);
    console.log(saveSessionState.current, "savesessionstate0");
    if (count > 0 && saveSessionState.current == 0) {
      getUnappliedJobsDueToResumeScore();
      console.log(saveSessionState.current, "savesessionstate1");
      let todaysessioncount = configDummy.todaysessioncount + count;
      let totalJobs = configDummy.totalJobs + count;
      let ziprecruiterJobsApplied =
        configDummy.jobsByPlatform["ziprecruiter"] + count;
      console.log(configDummy.totalJobs, count, "yooooo");
      let totalsessions = configDummy.totalsessions + 1;
      let totalsessionstoday = configDummy.totalsessionstoday + 1;
      // totalCountUpdate({
      //   ziprecruiterJobsApplied: ziprecruiterJobsApplied,
      //   todaysessioncount: todaysessioncount,
      //   totalJobs: totalJobs,
      //   totalsessions: totalsessions,
      //   totalsessionstoday: totalsessionstoday,
      // });
      let ziprecruiterJobApplicationLinksFinal = [...sessionLinks.current];
      if ("ziprecruiterJobApplicationLinksFinal" in userData) {
        ziprecruiterJobApplicationLinksFinal = [
          ...userData.ziprecruiterJobApplicationLinksFinal,
          ...ziprecruiterJobApplicationLinksFinal,
        ];
      }
      let totalsession = configDummy.totalsession;
      // setStreakAnalytics({
      //   ...streakAnalytics,
      //   ziprecruiter: streakAnalytics.ziprecruiter + count,
      // });
      configDummyUpdate({
        ...configDummy,
        jobsByPlatform: {
          ...configDummy.jobsByPlatform,
          ziprecruiter: ziprecruiterJobsApplied,
        },
        todaysessioncount: todaysessioncount,
        totalJobs: totalJobs,
        totalsessions: totalsessions,
        totalsessionstoday: totalsessionstoday,
        totalsession: [
          ...totalsession,
          {
            resumeData: resumeData.current,
            sessionLinks: sessionLinks.current,
            sessionLinksBefore: [],
            platformName: "ziprecruiter",
            form: formRef.current,
            count: count,
            email: userData.email,
          },
        ],
      });
      userLinksUpdate({
        ziprecruiterJobApplicationLinksFinal:
          ziprecruiterJobApplicationLinksFinal,
      });
      console.log(sessionLinks.current, "current------------");
      // setSaveSession(1);
      saveSessionState.current = 1;
      const v2Id = userData?.planDetails?.v2Id;
      const url = v2Id
        ? `https://backend.lazyapply.com/v2/saveSession/${v2Id}`
        : "http://backend.lazyapply.com/savesessionv2";

      axios
        .post(
          url,
          {
            sessionLinks: sessionLinks.current,
            sessionLinksBefore: [],
            resumeData: resumeData.current,
            session: {
              count: count,
              platformName: platformName,
              form: formRef.current,
            },
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          ziprecruiterReset();
          console.log(response);
          setmessage("Process completed!");
          settitle("Success");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2500);
        })
        .catch((error) => {
          ziprecruiterReset();
          console.log(error);
          if (error.message == "Request failed with status code 403") {
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
              history.push("/");
            }, 1500);
          } else {
            console.log("error");
            setmessage("Some error occured, please try again in some time!");
            settitle("Error");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2500);
          }
        });
    } else {
      getUnappliedJobsDueToResumeScore();
      saveSessionState.current = 1;
      ziprecruiterReset();
    }
  }

  const checkIfPresent = () => {
    if (selectedSearchData.searchName != "") {
      return true;
    } else {
      return false;
    }
  };

  const hideSavedSearchModal = () => {
    setShowSavedSearchModal(false);
  };

  function smoothScrollToBottom() {
    let start = null;
    const initialScrollTop = window.scrollY;
    const targetScrollTop = document.body.scrollHeight - window.innerHeight;

    function easeOutCubic(t) {
      return 1 - Math.pow(1 - t, 3);
    }

    function step(timestamp) {
      if (start === null) start = timestamp;
      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / 500, 1); // take 2 seconds to scroll

      const easedProgress = easeOutCubic(progress);
      const newScrollTop =
        initialScrollTop + (targetScrollTop - initialScrollTop) * easedProgress;

      window.scrollTo(0, newScrollTop);

      if (newScrollTop < targetScrollTop) {
        window.requestAnimationFrame(step);
      }
    }

    window.requestAnimationFrame(step);
  }

  useEffect(() => {
    questionRefs.current.forEach((question) => {
      const clickHandler = (event) => {
        const active = document.querySelector(".question.active");
        if (active && active !== question) {
          active.classList.toggle("active");
          active.nextElementSibling.style.maxHeight = 0;
        }
        question.classList.toggle("active");
        const answer = question.nextElementSibling;
        if (question.classList.contains("active")) {
          answer.style.maxHeight = answer.scrollHeight + "px";
        } else {
          answer.style.maxHeight = 0;
        }
      };

      if (question) {
        question.addEventListener("click", clickHandler);
      }

      return () => {
        question.removeEventListener("click", clickHandler);
      };
    });
    if (c.current == 0) {
      smoothScrollToBottom();
    }
  }, [pp.current]);
  const chromeVersion = useStateStore((state) => state.chromeVersion);

  function h(request) {
    if ("ziprecruiter" in request) {
      if ("message" in request) {
        if (request.message == "Applied successfully") {
          console.log("hello world from applied successfully");
          // let countlinkedin = count;
          // countlinkedin++;
          // console.log(countlinkedin)
          console.log(request.sessionLink, "link------------");
          sessionLinks.current.push(request.sessionLink);
          ++c.current;
          pp.current = Math.floor(
            (c.current / formRef.current.numberOfJobs) * 100
          );
          setCount(c.current);
          setProgressPercent(pp.current);
          console.log("update count");
        } else if (request.message == "filterValues") {
          if (Object.keys(request.filterValues).length == 0) {
            fetchbutton.current.disabled = false;
            spinner.current.style.display = "none";
            setZiprecruiterRunning(0);
            // console.log("Try again for better input details");

            resetFull();
            if (request.message1 == "server busy") {
              setmessage("No jobs found due to busy server. Try again later!");
              settitle("Server Busy");
              setmodalshow(true);
              setTimeout(() => {
                setmodalshow(false);
              }, 2500);
            } else {
              if (request.message2 == "signInRequired") {
                setmessage(
                  "You're currently logged out of ZipRecruiter. Try again after logging in!"
                );
              } else {
                setmessage("Try again with better input details!");
              }
              settitle("No results found");
              setmodalshow(true);
              setTimeout(() => {
                setmodalshow(false);
                if (request.message2 == "signInRequired") {
                  window.open(
                    "https://www.ziprecruiter.com/authn/login",
                    "_blank"
                  );
                  window.location.reload();
                }
              }, 2500);
            }
          } else {
            setZiprecruiterRunning(0);
            setformDisable(true);
            //perform all the filter operations
            const x = request.filterValues;
            console.log("mainfilters", x);
            // setRemoteOptions(x.remote_wfh_toggle);
            setRemoteOptions(x.refine_by_location_type);
            // setJobTitleOptions(x.refine_by_title);
            setJobPostedTimeOptions(x.days);
            setJobDistanceOptions(x.radius);
            setJobSalaryRangeOptions(x.refine_by_salary);
            // setJobEmploymentTypeOptions(x.refine_by_tags);
            setJobEmploymentTypeOptions(x.refine_by_employment);
            console.log("allfilterslinkedin", request.filterValues);
            chooseParaText.current.style.display = "block";
            spinner.current.style.display = "none";
            formMain1.current.style.display = "block";
            buttonFetch.current.style.display = "none";
            setShowSavedSearchButton(false);
            if (typeof chrome !== "undefined" && chrome && chrome.runtime)
              chrome.runtime.sendMessage(yourExtensionId, {
                dashboard: true,
                saveValues: {
                  ziprecruiterFetchFilters: 1,
                },
                ziprecruiter: true,
                message: "ziprecruiterFiltersFetched",
              });
            smoothScrollToBottom();
          }
        }
        if (request.message == "debugSession") {
          fetchbutton.current.disabled = false;
          spinner.current.style.display = "none";
          setZiprecruiterRunning(0);
          resetFull();
          setmessage(
            "We have detected some issue in LazyApply. Our team has been notified and we will fix this issue within next 1 hour. You have to do nothing in your part, this will be automatically fixed. \n\nThank you for being a wonderful customer."
          );
          settitle("Some problem occured");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 20000);
        }
        if (request.message == "applypage") {
          if ("message2" in request) {
            console.log("Request.message2------>", request.message2);
            if (request.message2 == "planRequired") {
              setShowPlanPurchase(true);
              setZiprecruiterRunning(0);
              button1.current.innerText = "Completed";
              processing.current.style.display = "none";
              smoothScrollToTop();
              initprocessingFinal.current.style.display = "flex";
              initprocessing.current.style.display = "none";
              if (selectResumeRef.current)
                selectResumeRef.current.style.display = "none";
              ziprecruiterReset();
            } else if (request.message2 == "completed") {
              console.log("completed full");
              // ++c.current;
              // setCount(c.current);
              setZiprecruiterRunning(0);
              if (selectResumeRef.current)
                selectResumeRef.current.style.display = "none";
              button1.current.innerText = "Completed";
              processing.current.style.display = "none";
              smoothScrollToTop();
              const v2Id = userData?.planDetails?.v2Id;
              axios
                .post(
                  `https://backend.lazyapply.com/v2/jobCountInLastSession/${v2Id}`,
                  { sessionId: sessionId.current },
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  }
                )
                .then((response) => {
                  console.log("first response", response.data);
                  setFinalJobCount(response.data.jobCount || 0);
                  initprocessingFinal.current.style.display = "flex";
                  initprocessing.current.style.display = "none";
                })
                .catch((err) => {
                  console.log("error in request", err);
                });
              pp.current = 100;
              setProgressPercent(pp.current);
              console.log(form);
              console.log(formMain.current.children);
              if (!window.wasRun) {
                messages()[MESSAGES.ZEROJOBSAPPLIED](
                  MESSAGETYPE.USER_REPORT_FRONTEND_ZIPRECRUITER,
                  MESSAGES.TOTALJOBSAPPLIED,
                  c.current,
                  JSON.stringify(formRef.current),
                  `${userData.email},total jobs applied this session ${c.current},${userData.name},${userData.planDetails.planName}`,
                  chromeVersion
                );
                saveSession(c.current, "ziprecruiter");
                window.wasRun = true;
              }
            } else if (request.message2 === "testing") {
              console.log("hello 12345");
              // event.source.postMessage("hello from here", event.origin);
            } else if (
              request.message2 ==
              "No results found, try changing the filter values"
            ) {
              console.log("no results found");
              setZiprecruiterRunning(0);
              setmessage("Try again for better input details!");
              settitle("No results found");
              setmodalshow(true);
              setTimeout(() => {
                setmodalshow(false);
              }, 2500);
              resetFull();
            } else if (request.message2 == "unauthorized") {
              //  alert("unauthorised");
              fetchbutton.current.disabled = false;
              setZiprecruiterRunning(0);
              console.log("reauthenticate");
              setmessage("Please relogin again!");
              settitle("Not authorized");
              setmodalshow(true);
              setTimeout(() => {
                globalLogout();
                history.push("/");
              }, 1500);
              resetFull();
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    if (form.skill != "" && form.numberOfJobs > 0 && resumeId != "") {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
  }, [form, resumeId]);

  const [resumefetch, setresumefetch] = useState(false);
  const fetchAllResumes = (v2Id) => {
    axios
      .get(
        `https://backend.lazyapply.com/v2/resumesV3/checkResumesValidation/${v2Id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setresumefetch(true);
        setUserResumeV3(response.data.resumes);
      })
      .catch((err) => {
        setresumefetch(true);
        console.log("some error occured");
      });
  };

  useEffect(() => {
    const v2Id = userData?.planDetails?.v2Id;
    if (v2Id) fetchAllResumes(v2Id);
  }, [userData]);

  useEffect(() => {
    console.log(
      "selected search data --> do processing here",
      selectedSearchData
    );
    if (selectedSearchData.form) {
      setForm((formData) => {
        let newFormData = {
          ...formData,
          numberOfJobs: selectedSearchData.form.numberOfJobs,
          skill: selectedSearchData.form.skill,
          location: selectedSearchData.form.location,
        };
        return newFormData;
      });
    } else {
      setForm((formData) => {
        let newFormData = {
          ...formData,
          numberOfJobs: 0,
          skill: "",
          location: "",
        };
        return newFormData;
      });
    }
    if (selectedSearchData.advanceSearch) {
      setAdvanceSearch(selectedSearchData.advanceSearch.advanceSearch);
      setExcludeString(selectedSearchData.advanceSearch.excludeString);
      setMayIncludeString(selectedSearchData.advanceSearch.mayIncludeString);
    } else {
      setAdvanceSearch(false);
      setExcludeString("");
      setMayIncludeString("");
    }
    if (selectedSearchData.extraConfig) {
      if (selectedSearchData.extraConfig.resumeId) {
        const found = userResumeV3.filter((key) => {
          return key.resumeId == selectedSearchData.extraConfig.resumeId;
        });
        if (found.length > 0) {
          userResumeV3.map((key) => {
            const item = key;
            if (item.resumeId === selectedSearchData.extraConfig.resumeId) {
              resumeData.current = {
                resumeId: item.resumeId,
                resumeName: item.resumename,
              };
            }
          });
          setResumeId(selectedSearchData.extraConfig.resumeId);
        } else {
          setResumeId("");
        }
      }
    } else {
      setResumeId("");
    }
  }, [selectedSearchData]);

  useEffect(() => {
    console.log("added");
    window.addEventListener("message", messageFn);
    if (initprocessingFinal.current)
      initprocessingFinal.current.style.display = "none";
    if (initprocessing.current) initprocessing.current.style.display = "none";
    if (formMain1.current) formMain1.current.style.display = "none";
    if (chooseParaText.current) chooseParaText.current.style.display = "none";
    if (spinner.current) spinner.current.style.display = "none";
    ziprecruiterReset();
    return () => {
      console.log("removed");
      window.removeEventListener("message", messageFn);
    };
  }, []);

  useEffect(() => {
    console.log("Sending initial request to extension");
    const usermain = JSON.parse(window.localStorage.getItem("user") || "{}");
    const tokenmain = window.localStorage.getItem("token");
    if (
      tokenmain != "" &&
      usermain != undefined &&
      Object.keys(usermain).length > 0
    ) {
      if (typeof chrome !== "undefined" && chrome && chrome.runtime)
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            dashboard: true,
            saveValues: {
              token: tokenmain,
              user: usermain,
            },
            injected: scriptInjectedGlobal,
            global: true,
            message: "loginToken",
          },
          (response) => {
            console.log(
              "response from extension",
              response,
              chrome.runtime.lastError
            );
            var lastError = chrome.runtime?.lastError;
            if (lastError) {
              setScriptInjectedGlobal(false);
              setExtensionPresent(false);
              history.push("/dashboard");
              return;
            } else {
              console.log("response of initial request", response);
              setScriptInjectedGlobal(true);
              setGlobalTabId(response.globalTabId);
            }
          }
        );
    }
  }, []);

  useEffect(() => {
    let height = 0;
    // const checking = localStorage.getItem("stepcheckziprecruiter");
    // if (checking && checking == 'true') {
    //   setChecks(true);
    // }
    if (mainheight) height = mainheight.current.scrollHeight;
    // if (document.querySelector(".dashboardRightEnd")) {
    //   document.querySelector(".dashboardRightEnd").style.height = height + "px";
    //   // if (!checks) {
    //   //   document.querySelector(".dashboardRightEnd").style.height = "inherit";
    //   // }
    // }
    if (document.querySelector(".dashboardLeft"))
      document.querySelector(".dashboardLeft").style.height = height + "px";
    setheightMain(height);
    mixpanel.identify(userData?.email);
    mixpanel.people.increment("ziprecruiter_page dashboard");
    mixpanel.track("ziprecruiter_page dashboard", {
      href: window.location.href,
      email: userData?.email,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    console.log("config", configDummy);
    initprocessing.current.style.display = "none";
    formMain1.current.style.display = "none";
    chooseParaText.current.style.display = "none";
    spinner.current.style.display = "none";
    ziprecruiterReset();
  }, []);

  useEffect(() => {
    if (advanceSearch) {
      setShowAdvanceSearch(true);
    }
  }, [advanceSearch]);

  useEffect(() => {
    let fc = 0;
    Object.keys(form).forEach((k) => {
      if (
        k === "daysOption" ||
        k === "orgOptions" ||
        k === "radiusOptions" ||
        k === "remote" ||
        k === "salaryOptions" ||
        k === "tagsOptions" ||
        k === "titleOptions"
      ) {
        form[k] != "" ? fc++ : console.log("do nothing");
      } else {
        console.log("do nothing");
      }
    });
    if (fc > 1) {
      setOpenHelper(true);
    }
  }, [form]);

  useEffect(() => {
    console.log("ppcurrent", pp.current);
    if (pp.current === 100) {
      formMain.current.style.display = "none";
      formMain1.current.style.display = "none";
      button1.current.style.display = "none";
      chooseParaText.current.style.display = "none";
    } else {
      formMain.current.style.display = "block";
      button1.current.style.display = "block";
    }
  }, [pp.current]);
  // const [checks, setChecks] = useState(false);

  const demoSubmit = () => {
    mixpanel.track("start_auto_apply_button dashboard", {
      href: window.location.href,
      email: userData?.email,
      platformName: "ziprecruiter",
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    setTimeout(() => {
      history.push("/pricing");
    }, 200);
  };

  const showFilters = () => {
    setZiprecruiterRunning(0);
    const x = mainFilters.data.ziprecruiter;
    setRemoteOptions(x.refine_by_location_type);
    setJobPostedTimeOptions(x.days);
    setJobDistanceOptions(x.radius);
    setJobSalaryRangeOptions(x.refine_by_salary);
    setJobEmploymentTypeOptions(x.refine_by_employment);
    chooseParaText.current.style.display = "block";
    spinner.current.style.display = "none";
    formMain1.current.style.display = "block";
    buttonFetch.current.style.display = "none";
    setShowSavedSearchButton(false);
    smoothScrollToBottom();
  };

  const checkIfDemoAndNoPlan = () => {
    return !userData.planDetails.planStarted && showDemo;
  };

  const showDemo = useStateStore((state) => state.showDemo);
  useEffect(() => {
    const fn = async () => {
      const result = await GrantAccess("ziprecruiter");
      console.log("grantAccess", result);
      if (!result.access && !showDemo) {
        history.push("/dashboard");
      } else {
        if (checkIfDemoAndNoPlan()) {
          showFilters();
        }
      }
    };
    fn();
  }, [userData]);

  return (
    <div style={{ position: "relative" }} ref={mainheight}>
      {/* {!checks && (
        <>
          <button
            className="buttonGoBack btn buttonSetup"
            onClick={() => {
              localStorage.setItem("stepcheckziprecruiter", true);
              setChecks(true);
            }}
          >
            Skip Setup Steps
          </button>
          <button className="buttonGoBack btn" onClick={goBack}>
            <i className="fas fa-arrow-left"></i> Go Back
          </button>
          <div className="stepswithcutmain">
            <p className="stepswithcutmainheading">
              One time setup steps for ZipRecruiter
            </p>
            <StepsWithCut
              platform="ziprecruiter"
              setComplete={(value) => {
                if (value) {
                  localStorage.setItem("stepcheckziprecruiter", true);
                }
                setChecks(value);
              }}
            />
          </div>
        </>
      )} */}
      <div>
        <MainWatchDemoModal
          platform="Ziprecruiter"
          showDemoPopup={showDemoPopup}
          customFn={() => {
            setShowDemoPopup(false);
          }}
        ></MainWatchDemoModal>
        <ExtensionInstallPopup
          title={"Ziprecruiter"}
          onClickWatchDemo={onClickWatchDemo}
          s={showExtensionInstallPopup}
          closeFn={closeExtensionInstallPopup}
          showUpdateExtension={showUpdateExtension}
        />
        <MoreFiltersHelp
          openHelper={openHelper}
          setOpenHelper={setOpenHelper}
        />
        <CustomAlert
          message={message}
          s={modalshow}
          title={title}
        ></CustomAlert>
        <p className="startApplying">Start Applying on ZipRecruiter</p>
        {/* <button
          className="buttonGoBack btn buttonSetup"
          onClick={() => setChecks(false)}
        >
          <i className="fas fa-wrench"></i> One Time Setup Steps
        </button> */}
        <button className="buttonGoBack btn" onClick={goBack}>
          <i className="fas fa-arrow-left"></i> Go Back
        </button>
        {userData?.planDetails?.v2Id && (
          <div className="v2resumeSelect" ref={selectResumeRef}>
            <p className="makesure" style={{ marginBottom: "40px" }}>
              Make sure to login in the ziprecruiter account of the person with
              the selected resume and his/her ziprecruiter profile should be
              completed before applying
            </p>
            <SaveSearchModal
              platformName="ziprecruiter"
              showProp={showSavedSearchModal}
              hideShow={hideSavedSearchModal}
              form={{ ...form }}
              advanceSearch={{
                advanceSearch: advanceSearch,
                excludeString,
                mayIncludeString,
              }}
              extraConfig={{
                resumeId: resumeId,
              }}
              setSelectedSearchData={setSelectedSearchData}
              setSavedSuccessfully={setSavedSuccessfully}
              selectedSearchData={selectedSearchData}
            />
            <GetSavedSearches
              platformName="ziprecruiter"
              savedSuccessfully={savedSuccessfully}
              setSelectedSearchData={setSelectedSearchData}
              searchInputValue={searchInputValue}
              setSearchInputValue={setSearchInputValue}
            />
            <Form.Group>
              <Form.Label>Choose a Profile to apply jobs for</Form.Label>

              {Object.keys(userResumeV3).length == 0 ||
              !userResumeV3.some((resume) => resume.validation === 1) ? (
                <>
                  <Form.Control
                    as="select"
                    id="resumeId"
                    value={"demo_id_lazyapply"}
                    onChange={(e) => {
                      if (e.target.value == "create_own_profile") {
                        mixpanel.track(
                          "create_your_own_profile_select dashboard",
                          {
                            href: window.location.href,
                            email: userData?.email,
                            platformName: "ziprecruiter",
                            plan_details: userData?.planDetails || {},
                            v2Id: userData?.planDetails?.v2Id || "",
                          }
                        );
                        history.push("/dashboard/editresumesv2");
                      }
                    }}
                  >
                    <option value="demo_id_lazyapply">
                      Demo Profile Selected
                    </option>
                    <option value="create_own_profile">
                      Create Your Own Profile
                    </option>
                  </Form.Control>
                </>
              ) : (
                <>
                  <Form.Control
                    as="select"
                    id="resumeId"
                    disabled={resumeDisable}
                    isInvalid={resumeChanged}
                    value={resumeId}
                    onChange={(e) => {
                      if (e.target.value != "") {
                        setResumeChanged(false);
                      }

                      userResumeV3.map((key) => {
                        const item = key;
                        if (item.resumeId === e.target.value) {
                          resumeData.current = {
                            resumeId: item.resumeId,
                            resumeName: item.resumename,
                          };
                        }
                      });
                      setResumeId(e.target.value);
                    }}
                  >
                    <option value="">Select a resume</option>
                    {userResumeV3.map((key) => {
                      const item = key;
                      if (key.validation == 1)
                        return (
                          <option value={item.resumeId}>
                            {item.resumename}
                          </option>
                        );
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select a resume/cv
                  </Form.Control.Feedback>
                </>
              )}
            </Form.Group>
          </div>
        )}
        <Form onSubmit={handleSubmit} ref={formMain}>
          <Row style={styleform}>
            <Col md={4}>
              <Form.Label htmlFor="inputskill">
                Enter skill / job title*
              </Form.Label>
              <Form.Control
                type="text"
                disabled={formDisable}
                id="inputskill"
                value={form.skill}
                aria-describedby="passwordHelpBlock"
                onChange={(e) => setField("skill", e.target.value)}
                isInvalid={!!errors.skill}
              />
              <Form.Control.Feedback type="invalid">
                {errors.skill}
              </Form.Control.Feedback>
              <Form.Text id="passwordHelpBlock" muted>
                For example - Enter only one single skill or job title like
                react or data analyst or marketing manager etc
              </Form.Text>
            </Col>
            <Col md={4}>
              <Form.Label htmlFor="inputlocation">
                Enter the job location (optional)
              </Form.Label>
              <Form.Control
                disabled={formDisable}
                type="text"
                id="inputlocation"
                value={form.location}
                aria-describedby="passwordHelpBlock"
                onChange={(e) => setField("location", e.target.value)}
              />
              <Form.Text id="passwordHelpBlock" muted>
                For example - you can enter your job location like - usa or
                canada or any other country/location etc.
              </Form.Text>
            </Col>
            <Col md={4}>
              <Form.Label htmlFor="inputnumber">
                Number of jobs to apply*
              </Form.Label>
              <Form.Control
                disabled={formDisable}
                type="number"
                id="inputnumber"
                value={form.numberOfJobs}
                aria-describedby="passwordHelpBlock"
                onChange={(e) => setField("numberOfJobs", e.target.value)}
                isInvalid={!!errors.numberOfJobs}
              />
              <Form.Control.Feedback type="invalid">
                {errors.numberOfJobs}
              </Form.Control.Feedback>
              <Form.Text id="passwordHelpBlock" muted>
                For example - 50 or 100 etc.
              </Form.Text>
            </Col>

            <Col md={12}>
              {" "}
              <ResumeJobScore form={form} setField={setField} />{" "}
            </Col>

            <div className="advanceSearchContainer">
              <div className="advanceSearchContainerLeft">
                Job Title Filters - Advance Search
              </div>
              <div className="advanceSearchContainerRight">
                <div className="exactsearch">
                  <div className="exactsearchchild">
                    <p
                      style={{
                        color: "#007bff",
                        marginBottom: "0px",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        cursor: "pointer",
                      }}
                    >
                      {advanceSearch ? (
                        <span onClick={() => setShowAdvanceSearch(true)}>
                          Configure
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                    <label
                      className={
                        "switch3" + (advanceSearch ? " switch3-checked" : "")
                      }
                    >
                      <input
                        type="checkbox"
                        checked={advanceSearch}
                        onChange={(e) => {
                          setAdvanceSearch(!advanceSearch);
                        }}
                      />
                      <div></div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <AdvanceSearchModal
              show={showAdvanceSearch}
              excludeString={excludeString}
              setExcludeString={setExcludeString}
              mayIncludeString={mayIncludeString}
              setMayIncludeString={setMayIncludeString}
              handleClose={handleCloseSearch}
            />
            <Col md={8} ref={buttonFetch}>
              <button
                type="submit"
                className="btn linkedinSubmitButton"
                ref={fetchbutton}
              >
                Click to fetch filters first
                <i className="fas fa-arrow-right"></i>{" "}
                <span
                  className="spinner-border spinner-border-sm"
                  style={{ marginLeft: "5px" }}
                  role="status"
                  aria-hidden="true"
                  ref={spinner}
                ></span>
              </button>
            </Col>
            {showSavedSearchButton && (
              <Col
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {configDummy?.totalJobs && configDummy.totalJobs >= 200 && (
                  <button
                    type="button"
                    style={saveButtonDisabled ? buttonMain : buttonMain1}
                    disabled={saveButtonDisabled}
                    onClick={() => {
                      setShowSavedSearchModal(true);
                    }}
                  >
                    {checkIfPresent()
                      ? "Update Search Configuration"
                      : "Save Search Configuration"}
                  </button>
                )}
              </Col>
            )}
          </Row>
        </Form>
        <p ref={chooseParaText} style={{ marginLeft: "20px", color: "gray" }}>
          Choose one or more filters (optional)
        </p>
        <Form onSubmit={handleSubmit1} ref={formMain1}>
          <Row style={filtersStyle}>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Posted Time</Form.Label>
                <Form.Control
                  as="select"
                  id="JobPostedTimeOptions"
                  value={form.daysOptions}
                  onChange={(e) => setField("daysOptions", e.target.value)}
                >
                  {jobPostedTime?.map((v) => {
                    return <option value={v.value}>{v.text}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <button
                className="btn filterReset"
                type="button"
                onClick={filterReset}
              >
                Reset
              </button>
              <Form.Group>
                <Form.Label>Distance</Form.Label>
                <Form.Control
                  as="select"
                  id="JobDistanceOptions"
                  value={form.radiusOptions}
                  onChange={(e) => setField("radiusOptions", e.target.value)}
                >
                  {jobDistance?.map((v) => {
                    return <option value={v.value}>{v.text}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            {/* f_TPR=r604800 */}
            {/* <Col md={6}>
              <Form.Group>
                <Form.Label>Salary Range</Form.Label>
                <Form.Control
                  as="select"
                  value={form.salaryOptions}
                  id="JobSalaryRangeOptions"
                  onChange={(e) => setField("salaryOptions", e.target.value)}
                >
                  {jobSalaryRange?.map((v) => {
                    return <option value={v.value}>{v.text}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            </Col> */}
            {/* f_I=45%2C104 */}
            <Col md={6}>
              <Form.Group>
                <Form.Label>Employment Type</Form.Label>
                <Form.Control
                  as="select"
                  value={form.tagsOptions}
                  id="JobEmploymentTypeOptions"
                  onChange={(e) => setField("tagsOptions", e.target.value)}
                >
                  {jobEmploymentType?.map((v) => {
                    return <option value={v.value}>{v.text}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            {/* f_F=qa%2Cbd */}
            {/* <Col md={6}>
              <Form.Group>
                <Form.Label>Job Title</Form.Label>
                <Form.Control
                  as="select"
                  value={form.titleOptions}
                  id="JobTitleOptions"
                  onChange={(e) => setField("titleOptions", e.target.value)}
                >
                  {jobTitle.map((v) => {
                    return <option value={v.value}>{v.text}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            </Col> */}
            <Col md={6}>
              <Form.Group>
                <Form.Label>Location Type ( Remote/ In Person)</Form.Label>
                <Form.Control
                  as="select"
                  value={form.remote}
                  id="JobRemoteOptions"
                  onChange={(e) => setField("remote", e.target.value)}
                >
                  {remoteOptions?.map((v) => {
                    return <option value={v.value}>{v.text}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            {/* <Col md={6} className="under10 mt-2">
              <FormCheck
                id="switchEnabled"
                type="switch"
                checked={form.remote === "" ? false : true}
                onChange={(e) =>
                  setField("remote", e.target.checked ? "on" : "")
                }
                label="Remote Job"
              />
            </Col> */}
          </Row>
          <Row>
            <Col md={12}>
              {checkIfDemoAndNoPlan() ? (
                <button
                  type="button"
                  ref={button1}
                  onClick={() => demoSubmit()}
                  className="btn linkedinSubmitButton"
                >
                  Start Auto Applying{" "}
                  {form.numberOfJobs != 0 ? `To ${form.numberOfJobs} Jobs` : ""}
                  <i className="fas fa-arrow-right"></i>
                </button>
              ) : (
                <button
                  type="submit"
                  ref={button1}
                  disabled={startApplying}
                  className="btn linkedinSubmitButton"
                >
                  Start Applying <i className="fas fa-arrow-right"></i>
                </button>
              )}
            </Col>
          </Row>
        </Form>
        <Row style={{ margin: "10px auto", padding: "0 5px" }}>
          {pp.current === 100 ? (
            <Col md={5} style={{ marginBottom: "20px" }}>
              <Feedback
                platformName="ziprecruiter"
                totalJobsApplied={c.current}
              />
            </Col>
          ) : (
            <Col md={5}></Col>
          )}
          <Col md={7} ref={initprocessingFinal} className="linkedinInit">
            <button
              type="button"
              className="linkedinReset btn"
              onClick={resetFull}
            >
              Reset
            </button>
            {showPlanPurchase ? (
              <>
                <div className="planPurchaseRequiredMainDiv">
                  <p className="planPurchaseRequired">
                    You don't have a plan, please purchase a plan to continue
                  </p>
                  <button
                    onClick={() => {
                      mixpanel.track("purchase_plan_button dashboard", {
                        href: window.location.href,
                        email: userData?.email,
                        platformName: "ziprecruiter",
                        plan_details: userData?.planDetails || {},
                        v2Id: userData?.planDetails?.v2Id || "",
                      });
                      setTimeout(() => {
                        history.push("/pricing");
                      }, 500);
                    }}
                    className="button1"
                  >
                    Purchase Plan
                  </button>
                </div>
              </>
            ) : (
              <div className="linkedinInitTop">
                <div>Percentage complete ({100} %)</div>
                <div>Total jobs applied ({finalJobCount})</div>
              </div>
            )}
            {showPlanPurchase ? (
              <></>
            ) : (
              <div className="linkedinInitTop">
                <div>
                  <Progress done={100} />
                </div>
                <div className="jobsApplied">{finalJobCount}</div>
              </div>
            )}
          </Col>
          <Col md={7} ref={initprocessing} className="linkedinInit">
            <button
              type="button"
              className="linkedinReset btn"
              onClick={resetFull}
            >
              Reset
            </button>
            <div className="linkedinInitTop1">
              <div ref={processing} className="processing">
                Automation in Process...
              </div>
            </div>
            <div className="linkedinInitTop1">
              <div>
                Please do not switch tabs till this session's completion.
              </div>
            </div>
          </Col>
        </Row>
        {/* <Row>
          {pp.current == 100 && (
            <>
              <div class="col-10 wrapper">
                <h3 class="feedbackform_header issuesHeader">Common Issues</h3>
                {(() => {
                  let allIssues = [
                    ...faqObj.glassdoor.issues,
                    ...faqObj.common.issues,
                  ];
                  return allIssues.map((x, index) => (
                    <div className="container" key={index}>
                      <div
                        className="question"
                        ref={(el) => (questionRefs.current[index] = el)}
                      >
                        {x.question}
                      </div>
                      <div class="answercont">
                        <div class="answer">
                          {x.answer}

                          {x.question && (
                            <a
                              href="#"
                              className="text-primary"
                              style={{
                                marginLeft: "0px",
                                marginTop: "20px",
                                marginBottom: "20px",
                                display: "inline-block",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setModalTitle(x.question);
                                setShowIndeedModal(true);
                              }}
                            >
                              {x.anchorText}
                            </a>
                          )}

                          <div>
                            <video
                              loop={true}
                              autoplay={true}
                              controls={true}
                              id="vid"
                              className="featuresImage"
                              style={{ boxShadow: "none" }}
                            >
                              <source
                                src="https://content.lazyapply.com/addtochrome.webm"
                                type="video/webm"
                              />
                            </video>
                            // {<img
                            //           src={"/assets/screensht.png"}
                            //           alt="Image"
                            //         /> }
                          </div>
                        </div>
                      </div>
                    </div>
                  ));
                })()}
              </div>
              <div class="col-12" style={{ height: "100px" }}>
                {" "}
              </div>
              <IndeedArticle
                showProp={showIndeedModal}
                hideShow={closeModal}
                title={modalTitle}
              />
            </>
          )}
        </Row> */}
      </div>
    </div>
  );
}

export default Ziprecruiter;
