import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import SuccessModal from "./SuccessModal";
import { useStateStore } from "../../../store/index";

function FeedbackModal({ showFeedbackModal, setShowFeedbackModal }) {
  const userData = useStateStore((state) => state.userData);

  const [feedback, setFeedback] = useState("");

  const [successModal, setSuccessModal] = useState(false);

  const [showLoading, setShowLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const token = useStateStore((state) => state.token);
  const v2Id = userData?.planDetails?.v2Id || "";

  const handleClose = () => {
    setShowFeedbackModal(false);

    setFeedback("");

    setErrorMessage("");
  };

  const sendFeedbackData = async () => {
    const response = await axios.post(
      "https://backend.lazyapply.com/products/feedback-job-resume-score",

      { feedback: feedback, v2Id: v2Id },

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setShowFeedbackModal(false);
    setSuccessModal(true);
    setShowLoading(false);
    setFeedback("");
  };

  function handleSubmit() {
    if (feedback.trim()) {
      setErrorMessage("");
      setShowLoading(true);
      sendFeedbackData();
    } else {
      setErrorMessage("Please provide your feedback before submitting");
    }
  }

  function handleFeedbackChange(event) {
    setFeedback(event.target.value);
  }

  if (showFeedbackModal) {
    return (
      <Modal
        show={showFeedbackModal}
        onHide={handleClose}
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title> Help Us Improve! </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!showLoading ? (
            <>
              {" "}
              <div
                style={{
                  marginBottom: "10%",
                }}
              >
                <span
                  style={{
                    color: "#1976d2",
                    fontSize: "1.2rem",
                    fontWeight: 500,
                  }}
                >
                  Your feedback is important to us. Please share your thoughts,
                  questions, or any concerns you have for this new job match
                  score feature in the space below. We appreciate your time and
                  input!
                </span>
              </div>
              <div
                style={{
                  marginBottom: "2%",
                }}
              >
                <textarea
                  placeholder="Share Your Feedback"
                  required
                  value={feedback}
                  onChange={handleFeedbackChange}
                  style={{
                    width: "100%",
                    borderRadius: 12,
                    color: "#333",
                    padding: "12px",
                    fontSize: "1.2rem",
                    fontWeight: 500,
                  }}
                ></textarea>
              </div>
              {errorMessage && (
                <p style={{ color: "red", fontWeight: 600 }}>{errorMessage}</p>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  style={{
                    fontSize: "1.2rem",
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    padding: "10px 20px",
                    borderRadius: 20,
                    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, 0.2)",

                    textTransform: "none",
                    fontWeight: 600,
                    marginRight: "0",
                  }}
                >
                  Submit
                </Button>
              </div>{" "}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={50} thickness={5} />
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }

  if (successModal) {
    return (
      <SuccessModal
        successFeedbackModal={successModal}
        setSuccessFeedbackModal={setSuccessModal}
      />
    );
  }
}

export default FeedbackModal;
