/*global chrome*/
import React, { useEffect, useRef, useState } from "react";
import "./header.css";
import { googleLogout } from "@react-oauth/google";

// import logo from "../panda1.png";
// import GoogleLogin from "react-google-login";
import {
  Navbar,
  Container,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import axios from "axios";
// import Image from "react-bootstrap/Image";
import { Link, useHistory, NavLink } from "react-router-dom";
import { useStateStore } from "../store/index";
import CustomAlert from "./modal";
import { mixpanel } from "../index";
import FeatureRequest from "./featureRequest";
import { GoogleLogin } from "@react-oauth/google";
function Header() {
  const loading = useRef(null);
  const setAvailableChromeVersion = useStateStore(
    (state) => state.setAvailableChromeVersion
  );
  const selectedPath = useStateStore((state) => state.selectedPath);
  const isOpenHamburger = useStateStore((state) => state.isOpenHamburger);
  const setIsOpenHamburger = useStateStore((state) => state.setIsOpenHamburger);
  const [open, setopen] = useState(0);
  const streakValue = useStateStore((state) => state.streakValue);
  const chromeVersion = useStateStore((state) => state.chromeVersion);
  const setInvites = useStateStore((state) => state.setInvites);
  const CLIENT_ID = encodeURIComponent(
    "715818145189-7geg3j3td9u21nc9qe8eujb2l4dcbt88.apps.googleusercontent.com"
  );
  const yourExtensionId = useStateStore((state) => state.yourExtensionId);
  const extensionPresent = useStateStore((state) => state.extensionPresent);
  const token = useStateStore((state) => state.token);
  const [userInvitesPresent, setUserInvitesPresent] = useState(false);
  const userData = useStateStore((state) => state.userData);
  const isUserAuthenticated = useStateStore(
    (state) => state.isUserAuthenticated
  );
  const [navbarWidth, setnavbarWidth] = useState(0);
  const configDummy = useStateStore((state) => state.configDummy);
  const userAuthentication = useStateStore((state) => state.userAuthentication);
  const updateToken = useStateStore((state) => state.updateToken);
  const imageSrc = useStateStore((state) => state.imageSrc);
  const userImageSrc = useStateStore((state) => state.userImageSrc);
  const history = useHistory();
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  function handleLogin(googleData) {
    console.log("googledata", googleData);
    if (googleData) {
      axios
        .post(
          "https://backend.lazyapply.com/api/v0/auth/google",
          {
            token: googleData,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          console.log(response);
          userAuthentication(1);
          userImageSrc(response.data.user.picture);
          updateToken(response.data.token);
          window.localStorage.setItem("token", response.data.token);
          window.localStorage.setItem(
            "user",
            JSON.stringify(response.data.user)
          );
          history.push("/dashboard");
        })
        .catch(function (error) {
          loading.current.style.display = "none";
          //console.log(error);
          setmessage("Some error occured, please try again in some time!");
          settitle("Error");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2500);
        });
    }
  }

  function logout() {
    loading.current.style.display = "block";
    mixpanel.identify(userData.email);
    mixpanel.people.increment("logout_button header dashboard");
    mixpanel.track("logout_button header dashboard", {
      email: userData?.email,
      href: window.location.href,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    window.localStorage.setItem("token", "");
    window.localStorage.setItem("user", JSON.stringify({}));
    loading.current.style.display = "none";
    updateToken("");
    userImageSrc(null);
    userAuthentication(0);
    googleLogout();
    history.push("/");
    if (
      extensionPresent &&
      typeof chrome !== "undefined" &&
      chrome &&
      chrome.runtime
    ) {
      chrome.runtime.sendMessage(
        yourExtensionId,
        {
          dashboard: true,
          saveValues: {
            token: "",
            user: {},
          },
          global: true,
          message: "loginToken",
        },
        () => {
          window.localStorage.setItem("token", "");
          window.localStorage.setItem("user", JSON.stringify({}));
          loading.current.style.display = "none";
          updateToken("");
          userImageSrc(null);
          userAuthentication(0);
          history.push("/");
        }
      );
    }
  }

  useEffect(() => {
    if (loading) loading.current.style.display = "none";
  }, []);

  useEffect(() => {
    if (
      token != "" &&
      isUserAuthenticated == 1 &&
      userData.email &&
      userData.email != ""
    ) {
      axios
        .get(
          `https://backend.lazyapply.com/v2/invites?email=${userData.email}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log("invites member", response);
          if (
            "invites" in response.data &&
            Object.keys(response.data.invites).length > 0
          ) {
            setUserInvitesPresent(true);
            setInvites(response.data.invites);
          }
        })
        .catch((err) => {
          console.log("some error occured");
        });
    }
  }, [userData, userAuthentication]);

  function goToHome() {
    mixpanel.identify(userData?.email);
    mixpanel.people.increment("lazyapply_icon header dashboard");
    mixpanel.track("lazyapply_icon header dashboard", {
      email: userData?.email,
      href: window.location.href,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    history.push("/");
  }

  const [showButton, setShowButton] = useState(false);
  const [buttonText, setButtonText] = useState("");
  useEffect(() => {
    const chromeVersionFn = async () => {
      try {
        const response = await axios.get(
          "https://backend.lazyapply.com/getChromeVersion"
        );
        if (response.data.found) {
          console.log("version", chromeVersion, response.data.version);
          setAvailableChromeVersion(response.data.version);
          if (chromeVersion === response.data.version) {
            setButtonText("Already on latest version " + chromeVersion);
          } else {
            setShowButton(true);
            setButtonText("Upgrade Chrome Extension");
          }
        } else {
          console.log("not found version");
          setShowButton(false);
        }
      } catch (err) {
        console.log("err", err);
        setShowButton(false);
      }
    };

    if (chromeVersion != -1) chromeVersionFn();
  }, [chromeVersion]);

  function getInitials(name) {
    let initials = "";
    const words = name.split(" ");

    for (let i = 0; i < words.length; i++) {
      if (words[i].length > 0) {
        initials += words[i][0].toUpperCase();
      }
    }

    return initials;
  }

  const options = [
    { name: "Home", route: "/dashboard", fn: () => {} },
    // { name: "Email Automation", route: "/dashboard/main", fn: () => {} },
    { name: "Edit Profile", route: "/dashboard/editresumesv2", fn: () => {} },
    {
      name: "Analytics",
      route: "/dashboard/appliedjobs",
      fn: () => {},
    },
    {
      name: "Pricing",
      route: "/pricing",
      fn: () => {},
    },
    {
      name: "Faq",
      route: "/faq",
      fn: () => {},
    },
    {
      name: "Logout",
      fn: logout,
    },
  ];

  const tooltip = <Tooltip id="tooltip">Total Jobs Applied</Tooltip>;

  const ProfileDropdown = ({ mobile = false }) => {
    return (
      <>
        {configDummy?.totalJobs ? (
          !mobile && (
            <OverlayTrigger placement="left" overlay={tooltip}>
              <div className="profile-totaljobs">
                {configDummy?.totalJobs || 0}
              </div>
            </OverlayTrigger>
          )
        ) : (
          <>
            <OverlayTrigger placement="left" overlay={tooltip}>
              <div className="profile-totaljobs">{0}</div>
            </OverlayTrigger>
          </>
        )}
        <Dropdown className="profile-dropdown">
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            <div className="roundedDiv">
              {getInitials(userData?.name || "")}
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {options.map((option, index) => (
              <Dropdown.Item
                key={index}
                onClick={() => {
                  mixpanel.identify(userData?.email);
                  mixpanel.people.increment("dropdown_option header dashboard");
                  mixpanel.track("dropdown_option header dashboard", {
                    email: userData?.email,
                    href: window.location.href,
                    option_name: option.name,
                    plan_details: userData?.planDetails || {},
                    v2Id: userData?.planDetails?.v2Id || "",
                  });
                  if ("route" in option) history.push(option.route);
                  option.fn();
                }}
              >
                {option.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  useEffect(() => {
    setnavbarWidth(window.innerWidth);
    loading.current.style.display = "none";
  }, []);

  return (
    <>
      <div className="loading" ref={loading}>
        <div className="boxLoading"></div>
      </div>
      {/* <FeatureRequest s={show} hideFn={hideFn}></FeatureRequest> */}
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      <Navbar bg="light" className="navbarMainContainer">
        <Container className="navbarContainer">
          {navbarWidth >= 768 && (
            <Navbar.Brand>
              {" "}
              <Link to="/">
                <img src={"/assests/panda1.png"} className="navbarImg"></img>
              </Link>
            </Navbar.Brand>
          )}
          <Container className="navbarChildContainer">
            {navbarWidth <= 768 && (
              <>
                <div className="navbarMobileContainer">
                  <div className="nav-icon3-container">
                    <div
                      id="nav-icon3"
                      className={isOpenHamburger ? " open" : ""}
                      onClick={() => {
                        setIsOpenHamburger(!isOpenHamburger);
                        setopen(!open);
                      }}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <div className="navbarMobileMainText">{selectedPath}</div>
                  {/* <div className="navbarMobileAvataar">
                    <img src={"/assests/avataar-mobile.png"}></img>
                  </div> */}
                  {isUserAuthenticated == 1 && (
                    <ProfileDropdown mobile={true}></ProfileDropdown>
                  )}
                </div>
              </>
            )}
            {navbarWidth >= 768 && (
              <>
                <div className="navbarChildContainerLeft" onClick={goToHome}>
                  LazyApply
                </div>
                <div className="navbarChildContainerRight">
                  {showButton && (
                    <button
                      className={
                        "navbarLink chromeExtensionUpdate" +
                        (buttonText.includes("Upgrade")
                          ? " chromeExtensionUpgradeRequired"
                          : " chromeExtensionStable")
                      }
                      onClick={() => {
                        if (buttonText.includes("Upgrade"))
                          window.open(
                            "https://chrome.google.com/webstore/detail/lazyapply-job-application/pgnfaifdbfoiehcndkoeemaifhhbgkmm",
                            "_blank"
                          );
                      }}
                    >
                      {buttonText}
                    </button>
                  )}
                  {/* {userInvitesPresent && (
                    <button
                      className="navbarLink featureRequestBtn featureRequestBtnInvites"
                      onClick={() => {
                        history.push("/dashboard/userinvites");
                        mixpanel.identify(userData?.email);
                        mixpanel.people.increment(
                          "user_invitation_button header dashboard"
                        );
                        mixpanel.track(
                          "user_invitation_button header dashboard",
                          {
                            email: userData?.email,
                            href: window.location.href,
                            plan_details: userData?.planDetails || {},
                            v2Id: userData?.planDetails?.v2Id || "",
                          }
                        );
                      }}
                    >
                      User Invitations
                    </button>
                  )} */}
                  <div
                    style={{
                      marginRight: "20px",
                      fontWeight: "bold",
                      cursor: "default",
                    }}
                  >
                    {streakValue ? <> ✨ {streakValue} day streak</> : <></>}
                  </div>
                  {/* <NavLink
                    className="navbarLink"
                    to="/pricing"
                    onClick={() => {
                      mixpanel.identify(userData?.email);
                      mixpanel.people.increment(
                        "pricing_link header dashboard"
                      );
                      mixpanel.track("pricing_link header dashboard", {
                        email: userData?.email,
                        href: window.location.href,
                        plan_details: userData?.planDetails || {},
                        v2Id: userData?.planDetails?.v2Id || "",
                      });
                    }}
                    activeClassName="navbarlinkActive"
                  >
                    Pricing
                  </NavLink> */}
                  {/* <NavLink
                    className="navbarLink"
                    to="/faq"
                    activeClassName="navbarlinkActive"
                    onClick={() => {
                      mixpanel.identify(userData?.email);
                      mixpanel.people.increment("faq_link header dashboard");
                      mixpanel.track("faq_link header dashboard", {
                        email: userData?.email,
                        href: window.location.href,
                        plan_details: userData?.planDetails || {},
                        v2Id: userData?.planDetails?.v2Id || "",
                      });
                    }}
                  >
                    FAQ
                  </NavLink> */}

                  {isUserAuthenticated == 1 && (
                    <ProfileDropdown></ProfileDropdown>
                  )}
                  {isUserAuthenticated == 0 && (
                    <GoogleLogin
                      onSuccess={(credentialResponse) => {
                        console.log("credential", credentialResponse);
                        handleLogin(credentialResponse.credential);
                      }}
                      onError={() => {
                        console.log("Login Failed");
                      }}
                    />
                  )}
                </div>
              </>
            )}
          </Container>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
