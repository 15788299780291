import React from "react";
import "./NotFound.css";

function NotFound({
  notFoundText = "No Results Found, Try Changing the Search",
  clearSearch,
  showPic = true,
  buttonClick,
  onFind,
}) {
  return (
    <div className="main-referral-not-found">
      {buttonClick ? (
        <>
          {showPic && (
            <img src={"/assests/no_results.webp"} className="navbarImg"></img>
          )}
          {notFoundText}
        </>
      ) : (
        <>
          <button
            className="main-referral-find-employees-init-button"
            onClick={() => onFind()}
          >
            <i className="fas fa-address-book"></i>
            Find Employees..
          </button>
        </>
      )}
    </div>
  );
}

export default NotFound;
