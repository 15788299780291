import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./InitBlock.css";
import { useGoogleLogin } from "@react-oauth/google";
import { useStateStore } from "../../store";
import axios from "axios";
import CustomAlert from "../modal";

function InitBlock({ closeFn, s }) {
  const CLIENT_ID =
    "715818145189-7geg3j3td9u21nc9qe8eujb2l4dcbt88.apps.googleusercontent.com";
  const referralInitData = useStateStore((state) => state.referralInitData);
  const setReferralInitData = useStateStore(
    (state) => state.setReferralInitData
  );
  const setSendEmail = useStateStore((state) => state.setSendEmail);
  const userData = useStateStore((state) => state.userData);
  const token = useStateStore((state) => state.token);
  const [show, setShow] = useState(false);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);

  const handleClose = () => {
    setShow(false);
    closeFn();
  };

  useEffect(() => {
    setShow(s);
  }, [s]);

  const saveTokensAndConnectEmail = (code) => {
    console.log("code", code);
    axios
      .post(
        "https://backend.lazyapply.com/lazyapplyx/saveemailpermission",
        {
          code,
          v2Id: userData?.planDetails?.v2Id || "",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("response", response.data);
        if (
          response?.data?.message &&
          response?.data?.message === "Successfully Linked"
        ) {
          setReferralInitData({
            ...referralInitData,
            emailConnected: true,
          });
          closeFn();
          setSendEmail(true);
          // setmessage("Permission given successfully");
          // settitle("Success!");
          // setmodalshow(true);
          // setTimeout(() => {
          //   setmodalshow(false);
          // }, 2000);
        } else if (
          response?.data?.message &&
          response?.data?.message === "Access not given to Lazyapply"
        ) {
          setmessage("Access not given to Lazyapply");
          settitle("Relogin again!");
          setmodalshow(true);
          setTimeout(() => {
            closeFn();
            setmodalshow(false);
          }, 2000);
        } else {
          console.log("something went wrong");
          setmessage("Some error occured");
          settitle("Error!");
          setmodalshow(true);
          setTimeout(() => {
            closeFn();
            setmodalshow(false);
          }, 2000);
        }
      })
      .catch((err) => {
        console.log("errorhai", err);
      });
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      saveTokensAndConnectEmail(codeResponse.code);
      console.log("code", codeResponse.code);
    },
    onError: (err) => {
      console.log("code err", err.error);
      setmessage("Access not given to Lazyapply");
      settitle("Relogin again!");
      setmodalshow(true);
      setTimeout(() => {
        setmodalshow(false);
      }, 2000);
    },
    clientId: CLIENT_ID,
    access_type: "offline",
    flow: "auth-code",
    scope:
      "https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly",
    uxMode: "popup",
  });

  const customFn = () => {
    setmodalshow(false);
  };

  return (
    <>
      <CustomAlert
        message={message}
        s={modalshow}
        title={title}
        customFn={customFn}
      ></CustomAlert>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Connect Your Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="targetedSearchInfoDesc1">
              To send emails through our secret network, you need to connect
              your email in order to send emails
            </p>
            <button onClick={() => login()} className={"login-with-google-btn"}>
              Give Permissions To Send Email
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default InitBlock;
