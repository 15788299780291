import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardForm from "./CardForm";

const stripePromise = loadStripe("pk_live_51JByccSBv8rKlDgOzZa0eY0LYsInEz9jqYF73kEItWl4HlXXLozL4VcLoynkBrSrL5t3cg5cbC1k8Hz5KQKMr7qh00EDqLU6jM");

const StripeComponent = ({planName}) => {
  return (
    <Elements stripe={stripePromise}>
      <div className="Demo">
        <CardForm  planName={planName}/>
      </div>
    </Elements>
  );
};

export default StripeComponent;
