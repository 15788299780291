import React, { useState, useRef, useEffect } from "react";
import { Form, Row, Button, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import "./editresumedash.css";
// import Autocomplete from "react-autocomplete";
// import skilljson from "../skills.json";
import axios from "axios";
import { useStateStore } from "../../store/index";
import CustomAlert from "../modal";
import { mixpanel } from "../../index";

function Editresumedash() {
  const userResumeV2 = useStateStore((state) => state.userResumeV2);
  const params = useParams();
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const globalLogout = useStateStore((state) => state.globalLogout);
  const token = useStateStore((state) => state.token);
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});
  const enddates = useRef(null);
  const [skills, setskills] = useState({});
  const [skillName, setSkillName] = useState("");
  const [companydetails, setcompanydetails] = useState(false);
  const [skillYear, setSkillYear] = useState(0);
  const userData = useStateStore((state) => state.userData);
  const userDataResume = useStateStore((state) => state.userDataResume);
  const setResumeModifiedV2 = useStateStore(
    (state) => state.setResumeModifiedV2
  );
  const setSkills = (field, value) => {
    setSkillName(value);
  };

  const setYears = (field, value) => {
    setSkillYear(value);
  };
  const [mainAutocompleteArray, setMainAutocompleteArray] = useState([]);
  useEffect(() => {
    axios
      .get(
        ` https://autocomplete.indeed.com/api/v0/suggestions/what?country=IN&language=en&count=100&formatted=1&query=${skillName}&useEachWord=false&showAlternateSuggestions=false&sessionId=FOxVkO5MEeux2x1RC6FoNQ&seqId=5&page=homepage&ctk=1f33en2g1sspp802&merged=true&rich=true
    `
      )
      .then((response) => {
        console.log("indeedddd", response.data);
        setMainAutocompleteArray(response.data);
      })
      .catch((e) => {
        console.log("error");
      });
  }, [skillName]);

  function addSkill(e) {
    e.preventDefault();
    document.querySelector("#inputskillyears").value = 0;
    // document.querySelector("#inputskillname").value = "";
    const sN = skillName;
    const sY = skillYear;
    setSkillName("");
    setSkillYear(0);
    console.log("add");
    console.log(sN, sY);
    if (sN != "") setskills({ ...skills, [sN]: sY });
  }

  const setField = (field, value) => {
    console.log(field + value);

    setForm({
      ...form,
      [field]: value,
    });

    // Check and see if errors exist, and remove them from the error object:
    console.log(form);

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const findFormErrors = () => {
    const {
      firstname,
      lastname,
      phone,
      email,
      city,
      state,
      zip,
      educationlevel,
      totalexperience,
      country,
      gender,
      startdate,
      companyname,
      currentlyworkhere,
      jobtitle,
      enddate,
    } = form;
    const newErrors = {};
    // name errors
    if (!firstname || firstname === "")
      newErrors.firstname = "Enter first name!";
    if (!lastname || lastname === "") newErrors.lastname = "Enter last name!";
    if (!city || city === "") newErrors.city = "Enter your city!";
    if (!state || state === "") newErrors.state = "Enter your state!";
    if (!zip || zip === "") newErrors.zip = "Enter your zip code!";
    if (!phone || phone.length < 6) newErrors.phone = "Enter your phone no!";
    if (!email || email === "") newErrors.email = "Enter your email!";

    if (currentlyworkhere == true) {
      console.log("true");
    } else {
      if (companydetails) {
        if (!enddate || enddate === "")
          newErrors.enddate = "Enter your end date!";
      } else {
        console.log("done");
      }
    }
    if (!country || country === "") newErrors.country = "Enter your country!";
    if (!gender || gender == "Select a option")
      newErrors.gender = "Enter your gender";
    if (
      !totalexperience ||
      totalexperience === "" ||
      totalexperience.length == 0
    )
      newErrors.totalexperience = "Enter your years of experience";
    if (!educationlevel || educationlevel == "None")
      newErrors.educationlevel = "Choose a option!";
    return newErrors;
  };

  useEffect(() => {
    if (
      ("companyname" in form && form.companyname != "") ||
      ("jobtitle" in form && form.jobtitle != "") ||
      ("currentlyworkhere" in form && form.currentlyworkhere == true) ||
      ("description" in form && form.description != "")
    ) {
      setcompanydetails(true);
    } else {
      setcompanydetails(false);
    }
  }, [
    form.companyname,
    form.jobtitle,
    form.currentlyworkhere,
    form.description,
  ]);

  useEffect(() => {
    if (form.currentlyworkhere == true) {
      enddates.current.style.display = "none";
    } else {
      enddates.current.style.display = "block";
    }
  }, [form.currentlyworkhere]);

  const handleSubmit = (e) => {
    console.log("handle submit");
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    console.log(newErrors);
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else if (Object.keys(skills).length < 4) {
      setmessage("Add atleast 4 skills");
      settitle("Required");
      setmodalshow(true);
      setTimeout(() => {
        setmodalshow(false);
      }, 2500);
    } else {
      // No errors! Put any logic here for the form submission
      console.log("form submit");

      if (params.id) {
        const v2Id = userData?.planDetails?.v2Id;
        const resumeId = params.id;
        let resumename = "";
        userResumeV2.forEach((item) => {
          if (item.resumeId === resumeId) {
            resumename = item.resumename;
          }
        });
        axios
          .post(
            `https://backend.lazyapply.com/v2/updateResume/${v2Id}?id=${resumeId}`,
            {
              resume: form,
              skills: skills,
              resumename: resumename,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            console.log(response);
            mixpanel.identify(userData.email);
            mixpanel.people.increment(
              "Dashboard - Resume updated successfully"
            );
            mixpanel.track("Dashboard - Resume updated successfully", {
              email: userData.email,
            });
            setResumeModifiedV2(1);
            setmessage("Successfully updated");
            settitle("Success");
            setmodalshow(true);
            setTimeout(() => {
              history.push(`/dashboard/editresume/additionalInfo/${params.id}`);
            }, 2500);
          })
          .catch((error) => {
            console.log(error);
            if (error.message == "Request failed with status code 403") {
              console.log("reauthenticate");
              setmessage("Please relogin again!");
              settitle("Not authorized");
              setmodalshow(true);
              setTimeout(() => {
                globalLogout();
                history.push("/");
              }, 1500);
            } else {
              console.log("error");
              setmessage("Some error occured, please try again in some time!");
              settitle("Error");
              setmodalshow(true);
              setTimeout(() => {
                setmodalshow(false);
              }, 2500);
            }
          });
      } else {
        axios
          .post(
            "https://backend.lazyapply.com/updateresume",
            {
              resume: form,
              skills: skills,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            console.log(response);
            userDataResume({
              resume: form,
              skills: skills,
              resumevalidation: 1,
            });

            mixpanel.identify(userData.email);
            mixpanel.people.increment(
              "Dashboard - Resume updated successfully"
            );
            mixpanel.track("Dashboard - Resume updated successfully", {
              email: userData.email,
            });

            setmessage("Successfully updated");
            settitle("Success");
            setmodalshow(true);
            setTimeout(() => {
              history.push("/dashboard/editresume/additionalInfo");
            }, 2500);
          })
          .catch((error) => {
            console.log(error);
            if (error.message == "Request failed with status code 403") {
              console.log("reauthenticate");
              setmessage("Please relogin again!");
              settitle("Not authorized");
              setmodalshow(true);
              setTimeout(() => {
                globalLogout();
                history.push("/");
              }, 1500);
            } else {
              console.log("error");
              setmessage("Some error occured, please try again in some time!");
              settitle("Error");
              setmodalshow(true);
              setTimeout(() => {
                setmodalshow(false);
              }, 2500);
            }
          });
      }
    }
  };

  function deleteSkill(key) {
    let allSkills = { ...skills };
    delete allSkills[key];
    setskills(allSkills);
  }
  useEffect(() => {
    loading.current.style.display = "none";
    mixpanel.identify(userData.email);
    mixpanel.people.increment("Dashboard - resume edit page visit");
    mixpanel.track("Dashboard - resume edit page visit", {
      email: userData.email,
    });
    if (params.id) {
      // v2 version
      loading.current.style.display = "block";
      const resumeId = params.id;
      const v2Id = userData?.planDetails?.v2Id;
      //fetch here
      axios
        .get(
          `https://backend.lazyapply.com/v2/resumes/${v2Id}?id=${resumeId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log("res", response);
          loading.current.style.display = "none";
          if (
            "resume" in response.data &&
            Object.keys(response.data.resume).length > 0
          ) {
            if ("firstname" in response.data.resume) {
              console.log(response.data.resume);
              console.log(response.data.skills);
              setskills(response.data.skills);
              setForm(response.data.resume);
            }
          }
        })
        .catch((err) => {
          loading.current.style.display = "none";
          console.log("err", err);
        });
    } else {
      if ("resume" in userData) {
        if ("firstname" in userData.resume) {
          console.log(userData.resume);
          console.log(userData.skills);
          setskills(userData.skills);
          setForm(userData.resume);
        }
      }
    }
  }, []);

  const options = [
    "None",
    "Secondary(10th Pass)",
    "Higher Secondary(12th Pass)",
    "Diploma",
    "Bachelor's",
    "Master's",
    "Doctorate",
  ];

  const options1 = ["Select a option", "Male", "Female", "Not To Say"];

  const styleform = {
    padding: "20px",
  };

  const styleform1 = {
    padding: "5px 20px",
  };

  const autoCompleteStyle = {
    borderRadius: "3px",
    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
    background: "rgba(255, 255, 255, 0.9)",
    padding: "2px 0",
    fontSize: "90%",
    position: "fixed",
    zIndex: "10000",
    color: "black",
    overflow: "auto",
    width: "90px",
    maxHeight: "250px", // TODO: don't cheat, let it flow to the bottom
  };

  const wrapperStyle = {
    position: "relative",
    display: "block",
    width: "100%",
    padding: "5px",
    color: "#495057",
    border: ".1px solid #ced4da",
    borderRadius: ".25rem",
  };

  const wrapperStyle1 = {
    display: "block",
    width: "100%",
    border: "none",
  };

  const [showProp, setShowProp] = useState(false);

  function goBack() {
    history.goBack();
  }

  function goToAdditional() {
    if (params.id) {
      history.push(`/dashboard/editresume/additionalInfo/${params.id}`);
    } else {
      history.push("/dashboard/editresume/additionalInfo");
    }
  }
  const loading = useRef(null);
  return (
    <div style={{ position: "relative" }}>
      <div className="loading loadingresume" ref={loading}>
        <div className="boxLoading"></div>
      </div>
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      <div className="makeSure">
        Make sure to upload your resume on respective platforms! <br></br>
        <div>
          For linkedin -{" "}
          <a
            href="https://www.linkedin.com/jobs/application-settings/"
            target="_blank"
          >
            Click here
          </a>
          , For indeed -{" "}
          <a href="https://my.indeed.com/resume" target="_blank">
            Click here
          </a>
        </div>
      </div>

      <button className="additionalInfo btn" onClick={goToAdditional}>
        Change Additional Info
      </button>
      <button className="buttonGoBack btn" onClick={goBack}>
        <i className="fas fa-arrow-left"></i> Go Back
      </button>
      <Form onSubmit={handleSubmit} style={{ marginTop: "50px" }}>
        <Row className="row1" style={styleform}>
          <Col md={3}>
            <Form.Label htmlFor="inputfirstname">First Name*</Form.Label>
            <Form.Control
              required
              type="text"
              id="inputfirstname"
              value={form.firstname}
              placeholder="First Name.."
              aria-describedby="passwordHelpBlock"
              onChange={(e) => setField("firstname", e.target.value)}
              isInvalid={!!errors.firstname}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstname}
            </Form.Control.Feedback>
          </Col>
          <Col md={3}>
            <Form.Label htmlFor="inputlastname">Last Name*</Form.Label>
            <Form.Control
              required
              type="text"
              id="inputlastname"
              value={form.lastname}
              placeholder="Last Name.."
              aria-describedby="passwordHelpBlock"
              onChange={(e) => setField("lastname", e.target.value)}
              isInvalid={!!errors.lastname}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastname}
            </Form.Control.Feedback>
          </Col>
          <Col md={3}>
            <Form.Label htmlFor="inputemail">Enter your email*</Form.Label>
            <Form.Control
              required
              type="email"
              value={form.email}
              id="inputemail"
              aria-describedby="passwordHelpBlock"
              onChange={(e) => setField("email", e.target.value)}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Col>
          <Col md={3}>
            <Form.Label htmlFor="inputphone">Enter your phone no*</Form.Label>
            <Form.Control
              required
              type="number"
              id="inputphone"
              value={form.phone}
              aria-describedby="passwordHelpBlock"
              onChange={(e) => setField("phone", e.target.value)}
              isInvalid={!!errors.phone}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phone}
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row style={styleform1}>
          <Col md={4}>
            <Form.Label htmlFor="inputcity">Enter your city name*</Form.Label>
            <Form.Control
              required
              type="text"
              id="inputcity"
              value={form.city}
              aria-describedby="passwordHelpBlock"
              onChange={(e) => setField("city", e.target.value)}
              isInvalid={!!errors.city}
            />
            <Form.Control.Feedback type="invalid">
              {errors.city}
            </Form.Control.Feedback>
          </Col>
          <Col md={4}>
            <Form.Label htmlFor="inputstate">Enter your state*</Form.Label>
            <Form.Control
              required
              type="text"
              id="inputstate"
              value={form.state}
              aria-describedby="passwordHelpBlock"
              onChange={(e) => setField("state", e.target.value)}
              isInvalid={!!errors.state}
            />
            <Form.Control.Feedback type="invalid">
              {errors.state}
            </Form.Control.Feedback>
          </Col>
          <Col md={4}>
            <Form.Label htmlFor="inputzip">Enter your zip code*</Form.Label>
            <Form.Control
              required
              type="text"
              id="inputzip"
              value={form.zip}
              aria-describedby="passwordHelpBlock"
              onChange={(e) => setField("zip", e.target.value)}
              isInvalid={!!errors.zip}
            />
            <Form.Control.Feedback type="invalid">
              {errors.zip}
            </Form.Control.Feedback>
          </Col>
        </Row>
        <Row style={styleform1}>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="inputeducationlevel">
                What is the highest level of education you have completed?*
              </Form.Label>
              <Form.Control
                as="select"
                value={form.educationlevel}
                id="inputeducationlevel"
                isInvalid={!!errors.educationlevel}
                onChange={(e) => setField("educationlevel", e.target.value)}
              >
                {options.map((v) => {
                  return <option value={v}>{v}</option>;
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.educationlevel}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="inputtotalexperience">
                Companies to exclude from sending application (comma seperated)
              </Form.Label>
              <Form.Control
                type="text"
                value={form.toexclude}
                id="inputcountry"
                onChange={(e) => setField("toexclude", e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label htmlFor="inputtotalexperience">Country*</Form.Label>
              <Form.Control
                required
                type="text"
                value={form.country}
                id="inputcountry"
                isInvalid={!!errors.country}
                onChange={(e) => setField("country", e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.country}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label htmlFor="inputgender">Enter your Gender?*</Form.Label>
              <Form.Control
                as="select"
                id="inputgender"
                isInvalid={!!errors.gender}
                value={form.gender}
                onChange={(e) => setField("gender", e.target.value)}
              >
                {options1.map((v) => {
                  return <option value={v}>{v}</option>;
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.gender}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label htmlFor="inputtotalexperience">
                Total Experience? (In years)*
              </Form.Label>
              <Form.Control
                required
                type="number"
                value={form.totalexperience}
                id="inputtotalexperience"
                isInvalid={!!errors.totalexperience}
                onChange={(e) => setField("totalexperience", e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.totalexperience}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="jobDetails">
              <div className="jobDEtailsTop0">
                Current / Previous Experience{" "}
                {companydetails ? "*" : " (Optional)"}
              </div>
              <div className="jobDetailsTop">
                <div className="jobDetailsTopChild0">
                  <div>
                    <Form.Group>
                      <Form.Label htmlFor="inputcompany">
                        Company Name
                      </Form.Label>
                      <Form.Control
                        required={companydetails}
                        type="text"
                        value={form.companyname}
                        placeholder="Curr / Prev company name"
                        id="inputcompanyname"
                        onChange={(e) =>
                          setField("companyname", e.target.value)
                        }
                      />
                    </Form.Group>
                  </div>
                  <div>
                    <Form.Group>
                      <Form.Label htmlFor="inputjobtitle">Job title</Form.Label>
                      <Form.Control
                        required={companydetails}
                        type="text"
                        value={form.jobtitle}
                        id="inputjobtitle"
                        onChange={(e) => setField("jobtitle", e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="jobDetailsTopChild1">
                  <div className="currentlyWorkHere0">
                    <Form.Group>
                      <Form.Check
                        label="Do you currently work here?"
                        type="checkbox"
                        id="inputcheckbox"
                        checked={form.currentlyworkhere}
                        onChange={(e) =>
                          setField("currentlyworkhere", e.target.checked)
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="currentlyWorkHere1">
                    <Form.Group>
                      <Form.Label htmlFor="inputctc">Annual Salary</Form.Label>
                      <Form.Control
                        placeholder="In your currency"
                        type="number"
                        value={form.ctc}
                        id="inputctc"
                        onChange={(e) => setField("ctc", e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="jobDetailsMiddle">
                <div>
                  <Form.Group>
                    <Form.Label>Select start date</Form.Label>
                    <Form.Control
                      required={companydetails}
                      type="date"
                      name="startdate"
                      value={form.startdate}
                      placeholder="Start date"
                      onChange={(e) => setField("startdate", e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div ref={enddates}>
                  <Form.Group>
                    <Form.Label>Select end date</Form.Label>
                    <Form.Control
                      type="date"
                      name="enddate"
                      placeholder="End date"
                      value={form.enddate}
                      isInvalid={!!errors.enddate}
                      onChange={(e) => setField("enddate", e.target.value)}
                    />
                  </Form.Group>
                  <Form.Control.Feedback type="invalid">
                    {errors.enddate}
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="jobDetailsBottom">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  value={form.description}
                  as="textarea"
                  rows={3}
                  onChange={(e) => setField("description", e.target.value)}
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="jobDetails">
              <div className="jobDEtailsTop0">Enter Your Skills</div>
              <div className="jobDetailsSkill">
                <div className="jobDetailsSkillTop">
                  <div>
                    <p>Enter skill name*</p>
                  </div>

                  <div>
                    {/* <Autocomplete
                      inputProps={{
                        style: wrapperStyle,
                        placeholder: "Enter skill name, ex - java",
                      }}
                      wrapperStyle={wrapperStyle1}
                      getItemValue={(item) => item.suggestion}
                      items={mainAutocompleteArray}
                      shouldItemRender={(item, value) =>
                        item.suggestion
                          .toLowerCase()
                          .indexOf(value.toLowerCase()) > -1
                      }
                      renderItem={(item, isHighlighted) => (
                        <div
                          key={item.suggestion}
                          style={{
                            background: isHighlighted ? "lightgray" : "white",
                          }}
                        >
                          {item.suggestion}
                        </div>
                      )}
                      menuStyle={autoCompleteStyle}
                      value={skillName}
                      onChange={(e) => setSkills("skill", e.target.value)}
                      onSelect={(val) => setSkills("skill", val)}
                    /> */}
                  </div>
                </div>
                <div className="jobDetailsSkillTop">
                  <div>
                    <p>Exp. in years*</p>
                  </div>

                  <div>
                    <Form.Control
                      defaultValue="0"
                      type="number"
                      id="inputskillyears"
                      onChange={(e) => setYears("years", e.target.value)}
                    />
                  </div>
                </div>
                <div className="addButton">
                  <button type="button" onClick={addSkill}>
                    Add +
                  </button>
                </div>
              </div>
            </div>
            <div className="jobDetailsSkillValue">
              {Object.keys(skills).map(function (key) {
                return (
                  <span className="skillSpan" onClick={() => deleteSkill(key)}>
                    {key}-{skills[key]} <i className="fas fa-times ml-1"></i>
                  </span>
                );
              })}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="submitForm">
            <Button className="btn linkedinSubmitButton" type="submit">
              Submit Resume<i className="far fa-thumbs-up ml-2"></i>
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Editresumedash;
