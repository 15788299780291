import React from "react";
import Header from "../headerblur";
import Login from "../login";
import Homedash from "./Homedashblur";
import Sidebar from "./SidebarBlur";
import "./loginmain.css";

function LoginMain() {
  return (
    <>
      <div className="loginmainblock">
        <div className="loginmainblurred">
          <Header />
          <div className="dashboard">
            <Sidebar />
            <div className="dashboardRight">
              <Homedash />
            </div>
          </div>
        </div>
        <Login loginMain={true} />
      </div>
    </>
  );
}

export default LoginMain;
