import React from "react";
import "./organisation_details.css";
import { useHistory } from "react-router-dom";
import { useStateStore } from "../../store";

function AccountDetails() {
  const history = useHistory();
  const organization = useStateStore((state) => state.organization);
  const groupByLevels = (usersArray) => {
    const groupedLevels = {
      level1: [],
      level2: [],
      level3: [],
    };

    usersArray.forEach((user) => {
      const role = user.role;

      if (groupedLevels[role]) {
        groupedLevels[role].push(user);
      }
    });

    return groupedLevels;
  };

  const levels = groupByLevels(organization.users);
  console.log("levels", levels);

  return (
    <div
      className="orginasation_details_block"
      style={{ position: "relative" }}
    >
      <button
        className="btn invite_user_main_block_back_button"
        onClick={() => history.goBack()}
      >
        Back
      </button>
      <div className="orginasation_details_heading">Organisation Details</div>
      <div className="orginasation_details_sub_block">
        <div className="orginasation_details_div">
          <div className="orginasation_details_name">
            Name - {organization.organization_details.name}
          </div>
          <div className="orginasation_details_address">
            Address - {organization.organization_details.address}
          </div>
          <div className="orginasation_details_contact">
            Contact - {organization.organization_details.contact}
          </div>
        </div>

        <div className="orginasation_details_heading">Plan Details</div>
        <div className="orginasation_details_plan">
          <div>
            Plan Name - {organization.organization_plandetails.plan_name}
          </div>
          <div>
            User Limit - {organization.organization_plandetails.user_limit}
          </div>
          <div>
            Resume Limit - {organization.organization_plandetails.resume_limit}
          </div>
        </div>
        <div className="orginasation_details_current_users">
          <div className="orginasation_details_heading">
            User's And Invitations
          </div>
          <p
            className="orginasation_details_current_users_link"
            onClick={() => {
              history.push("/dashboard/invite-users?invitations=1");
            }}
          >
            Click here to view all users and invitations{" "}
            <i className="fas fa-external-link-alt ml-2"></i>
          </p>
          {/* {Object.keys(levels).map((key) => {
            return (
              <>
                {levels[key].length > 0 ? (
                  <>
                    <p className="orginasation_details_current_users_heading">
                      {key}
                    </p>
                    <div>
                      Emails :
                      <ul>
                        {levels[key].map((l) => {
                          return (
                            <li className="organisation_li">
                              {l.user_email}{" "}
                              {l.invite_status == -1 || l.role == "level1" ? (
                                <span className="organisation_role">Owner</span>
                              ) : (
                                <span className="organisation_role">
                                  Invite {l.invite_status == 1 ? "" : "Not"}{" "}
                                  Accepted
                                </span>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            );
          })} */}
        </div>
      </div>
    </div>
  );
}

export default AccountDetails;
