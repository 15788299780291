import React, { useEffect } from "react";
import LinkedinSendEmailMain from "./LinkedinSendEmailMain";
import axios from "axios";
import { useStateStore } from "../../store";
import "./lazyapplyx.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

function LazyapplyX() {
  const CLIENT_ID =
    "715818145189-7geg3j3td9u21nc9qe8eujb2l4dcbt88.apps.googleusercontent.com";
  const setLazyapplyXData = useStateStore((state) => state.setLazyapplyXData);
  const setGeneratedSampleEmail = useStateStore(
    (state) => state.setGeneratedSampleEmail
  );
  const userData = useStateStore((state) => state.userData);
  const token = useStateStore((state) => state.token);
  const getData = () => {
    axios
      .get(`https://backend.lazyapply.com/lazyapplyx/targetSearchData`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("data", response.data);
        setLazyapplyXData(response.data);
        if (response.data.sampleEmailGenerated) {
          setGeneratedSampleEmail(response.data.sampleEmailGenerated);
        }
      })
      .catch((err) => {
        console.log("some error occured");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <LinkedinSendEmailMain></LinkedinSendEmailMain>
      </GoogleOAuthProvider>
    </>
  );
}

export default LazyapplyX;
