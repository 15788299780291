import React from "react";
import { useStateStore } from "../../store";

function Pagination({
  activePage,
  count,
  rowsPerPage,
  totalPages,
  setActivePage,
  pageSize,
  setPageSize,
}) {
  const disableNextPage = useStateStore((state) => state.disableNextPage);
  const searchingJobsWithFilters = useStateStore(
    (state) => state.searchingJobsWithFilters
  );
  const setPaginationActivePageNumber = useStateStore(
    (state) => state.setPaginationActivePageNumber
  );
  const setPaginationDirection = useStateStore(
    (state) => state.setPaginationDirection
  );
  const beginning = activePage === 1 ? 1 : rowsPerPage * (activePage - 1) + 1;
  const end = activePage === totalPages ? count : beginning + rowsPerPage - 1;
  console.log("active", activePage, totalPages);
  return (
    <>
      <div className="pagination">
        {/* <button disabled={activePage === 1} onClick={() => setActivePage(1)}>
          {"<<"}
        </button> */}
        <button
          disabled={activePage === 1}
          onClick={() => {
            setPaginationDirection(-1);
            setPaginationActivePageNumber(activePage - 1);
            setActivePage(activePage - 1);
          }}
        >
          {"<"}
        </button>
        <button
          disabled={activePage === totalPages || disableNextPage}
          onClick={() => {
            setPaginationDirection(1);
            setPaginationActivePageNumber(activePage + 1);
            setActivePage(activePage + 1);
          }}
        >
          {">"}
        </button>
        {!searchingJobsWithFilters && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            Page {activePage} of {totalPages} |
          </div>
        )}

        {!searchingJobsWithFilters && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            Rows: {beginning === end ? end : `${beginning} - ${end}`} of {count}{" "}
            |
          </div>
        )}
      </div>
    </>
  );
}

export default Pagination;
