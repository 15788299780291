import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { useStateStore } from "../../store/index";
import axios from "axios";
import { Spinner } from "react-bootstrap";

function ModalResumeTracker({
  show,
  handleClose,
  resumeId,
  mainfileDetails,
  userData,
  setResumeTrackerChanged,
  resumeMetaDetails,
}) {
  console.log(
    "show",
    resumeId,
    mainfileDetails,
    userData?.email,
    resumeMetaDetails
  );
  const token = useStateStore((state) => state.token);
  const [userId, setUserId] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [resumeTrackerDetails, setResumeTrackerDetails] = useState({});
  const [disable, setDisable] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [userIdIncorrectMessage, setUserIdIncorrectMessage] = useState("");
  const globalLogout = useStateStore((state) => state.globalLogout);

  let showr = {
    textAlign: "center",
    fontWeight: "bold",
    color: "black",
    fontSize: "16.5px",
    marginTop: "10px",
  };

  let buttonSave = {
    backgroundColor: "#35AD46",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "10px 20px",
    textAlign: "center",
    borderRadius: "10px",
    width: "100%",
    outline: "none",
    border: "none",
    marginTop: "30px",
  };

  const showCustomMessage = (message) => {
    setShowMessage(message);
    setTimeout(() => {
      setShowMessage("");
    }, 3000);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (changeUserId()) {
      setDisable(true);
      setSpinner(true);
      let body = {
        resumeKey: mainfileDetails.key,
        email: userData.email,
        v2Id: userData.planDetails.v2Id,
        userId: userId,
        resumeId: resumeId,
      };
      try {
        const response = await axios.post(
          "https://backend.lazyapply.com/createResumeTrackerDataDashboard",
          body,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSpinner(false);
        const details = response.data;
        console.log("details", details);
        if (details.error) {
          setDisable(false);
          showCustomMessage(details.message);
        } else {
          setTimeout(() => {
            setResumeTrackerChanged(true);
          }, 3000);
          const codeVerified = details.data.codeVerified;
          if (codeVerified == 0) {
            showCustomMessage(
              "We have sent a verification email to your email address. Please verify your email to enable tracking of views on your resume. "
            );
          } else {
            showCustomMessage(
              `Successfully Created And Started Tracking on https://${userId}.rsme.io.`
            );
          }
        }
      } catch (error) {
        setSpinner(false);
        setDisable(false);
        console.error(error);
        if (error.message == "Request failed with status code 403") {
          console.log("reauthenticate");
          setTimeout(() => {
            globalLogout();
          }, 1500);
        } else {
          console.log("error");
          showCustomMessage("Some error occured please try again");
        }
        // Handle the error or display an appropriate error message to the user
      }
    }
  };

  function isValidSubdomain(username) {
    // A valid subdomain must be between 1 and 63 characters long
    if (username.length < 3 || username.length > 63) {
      return {
        isValid: false,
        message: "Username must be between 1 and 63 characters long.",
      };
    }

    if (username.includes(" ")) {
      return {
        isValid: false,
        message: "Spaces are not allowed",
      };
    }

    // A valid subdomain must not contain any special characters, except for hyphens
    const regex = /^[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9])?$/;
    if (!regex.test(username)) {
      return {
        isValid: false,
        message:
          "Username must start and end with a lowercase letter or number. It can contain lowercase letters, numbers, and hyphens in between, but cannot end with a hyphen.",
      };
    }

    // A valid subdomain must not begin or end with a hyphen
    if (username.startsWith("-") || username.endsWith("-")) {
      return {
        isValid: false,
        message: "Username cannot begin or end with a hyphen.",
      };
    }

    // If the username passes all checks, it can be used as a subdomain
    return { isValid: true, message: "Username is valid." };
  }

  function isValidFirestoreUsername(username) {
    const reservedTerms = [
      "__",
      ".write",
      ".read",
      ".indexOn",
      ".validate",
      ".rules",
      ".rule",
    ];

    // Check if username is no longer than 1500 bytes
    if (new Blob([username]).size > 1500) {
      return {
        isValid: false,
        message: "Your username is too long. Please choose a shorter username.",
      };
    }

    if (username.length < 3) {
      return {
        isValid: false,
        message: "Your username should contain atleast 3 characters",
      };
    }

    // Check if username contains a forward slash
    if (username.includes("/")) {
      return {
        isValid: false,
        message:
          "Your username cannot contain a forward slash (/). Please choose a different username.",
      };
    }

    // Check if username solely consists of a single period or double periods
    if (username === "." || username === "..") {
      return {
        isValid: false,
        message:
          "Your username cannot be a single period (.) or double periods (..). Please choose a different username.",
      };
    }

    // Check if username matches any reserved terms
    if (reservedTerms.includes(username)) {
      return {
        isValid: false,
        message:
          "Your username contains a reserved term. Please choose a different username.",
      };
    }

    return { isValid: true, message: "Your username is valid!" };
  }

  const changeUserId = () => {
    const response = isValidSubdomain(userId);
    const response1 = isValidFirestoreUsername(userId);
    if (response.isValid && response1.isValid) {
      setUserIdIncorrectMessage("");
      return true;
    } else {
      if (!response.isValid) setUserIdIncorrectMessage(response.message);
      if (!response1.isValid) setUserIdIncorrectMessage(response1.message);
      setTimeout(() => {
        setUserIdIncorrectMessage("");
      }, 3000);
      return false;
    }
  };

  useEffect(() => {
    setShowMessage("");
  }, []);

  useEffect(() => {
    if (resumeMetaDetails.resumeTrackerUserId) {
      console.log("resumemetadetails", resumeMetaDetails.resumeTrackerUserId);
      axios
        .post(
          "https://backend.lazyapply.com/getResumeTrackerDetails",
          { userId: resumeMetaDetails.resumeTrackerUserId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          console.log("response", response.data);
          if (response.data.found) {
            setResumeTrackerDetails(response.data.searchResponse.hits[0]);
          } else {
            showCustomMessage("Resume tracker data not found.");
          }
        })
        .catch((err) => {
          console.log("err", err);
          showCustomMessage("Some error occured please try again");
        });
    }
  }, [resumeMetaDetails]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="md"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Track Your Resume</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: "20px" }}>
          <p style={{ fontWeight: "bold", color: "#007bff" }}>
            It is a simple tool that counts how many times your resume has been
            viewed on your unique URL created by you.
          </p>
          {!resumeMetaDetails.resumeTrackerUserId ? (
            <>
              <Form onSubmit={submit} style={{ marginTop: "20px" }}>
                <Form.Control
                  required={true}
                  type="text"
                  id="userId"
                  value={userId}
                  disabled={disable}
                  aria-describedby="passwordHelpBlock"
                  placeholder="Enter user name"
                  onChange={(e) => setUserId(e.target.value)}
                />
                <Form.Text id="passwordHelpBlock" muted>
                  For example - JohnDoeResume , JaneSmithCV ,Michael12
                  <br></br>
                  Your Domain will be https://
                  {userId == "" ? "[username]" : userId}
                  .rsme.io
                </Form.Text>
                <button style={buttonSave} disabled={disable}>
                  Track Resume Now{" "}
                  {spinner === true ? (
                    <Spinner
                      animation="border"
                      size="sm"
                      style={{ marginLeft: "5px" }}
                    />
                  ) : (
                    <></>
                  )}
                </button>
                <p style={{ marginTop: "20px" }}>
                  <span style={{ color: "#007bff" }}>{userData?.email}</span>{" "}
                  will be used to track views on your resume. You will get an
                  update whenever someone view your resume.
                </p>
              </Form>
            </>
          ) : (
            <>
              <p>You have already created the resume tracker.</p>
              <p>
                Your Resume Tracker Url -{" "}
                <a
                  target="_blank"
                  href={`https://${resumeMetaDetails.resumeTrackerUserId}.rsme.io`}
                >
                  https://{resumeMetaDetails.resumeTrackerUserId}.rsme.io
                </a>
              </p>
              <p>
                If you have newly created the resume tracker url, It will take
                1-2 minutes for your url/link to be activated!
              </p>
              <p>
                {resumeTrackerDetails?.document?.email && (
                  <>
                    Email used for tracking -{" "}
                    {resumeTrackerDetails?.document?.email}
                  </>
                )}
              </p>
              <p>
                {resumeTrackerDetails?.document?.codeverified &&
                resumeTrackerDetails.document.codeverified == 0 ? (
                  "We have sent a verification email to your email address. Please verify your email to enable tracking of views on your resume."
                ) : (
                  <></>
                )}
              </p>
            </>
          )}
          {userIdIncorrectMessage != "" && (
            <p style={showr}>{userIdIncorrectMessage}</p>
          )}
          <p style={showr}>{showMessage}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalResumeTracker;
