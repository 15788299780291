import React, { useEffect, useState } from "react";
import UploadResumeModalV2 from "./uploadResumeModalV2";
import { useStateStore } from "../../store/index";
import axios from "axios";
import "./uploadresume.css";
import { Form } from "react-bootstrap";

function UploadResume({
  resumeUrlId,
  setResumeUrlId,
  resumeUrlChanged,
  setResumeUrlChanged,
}) {
  const setUploadResumeUrlAll = useStateStore(
    (state) => state.setUploadResumeUrlAll
  );
  const [showUploadResumeModalV2, setShowUploadResumeModalV2] = useState(false);
  const uploadResumeUrlData = useStateStore(
    (state) => state.uploadResumeUrlData
  );
  const userData = useStateStore((state) => state.userData);
  const token = useStateStore((state) => state.token);

  const closeModal = () => {
    setShowUploadResumeModalV2(false);
  };

  function removeObjectById(array, id) {
    const index = array.findIndex((obj) => obj.resumeId === id);
    if (index !== -1) {
      array.splice(index, 1);
    }
    return array;
  }

  useEffect(() => {
    if (uploadResumeUrlData.length == 1) {
      setResumeUrlId(uploadResumeUrlData[0].resumeId);
    }
  }, [uploadResumeUrlData]);

  const remove = (id) => {
    const v2Id = userData?.planDetails?.v2Id;
    const filteredArray = removeObjectById(uploadResumeUrlData, id);
    console.log("filtered array", filteredArray);
    axios
      .post(
        `https://backend.lazyapply.com/v2/deleteResume/${v2Id}`,
        {
          resumeUrls: filteredArray,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("response", response?.data);
        setUploadResumeUrlAll(filteredArray);
      })
      .catch((error) => {
        console.log("err", error);
      });
  };

  return (
    <>
      <UploadResumeModalV2
        showProp={showUploadResumeModalV2}
        hideShow={closeModal}
      />
      {/* <div className="mainResumeViewer">
        <Form.Group>
          <Form.Label>Choose a Uploaded Resume</Form.Label>
          <Form.Control
            as="select"
            id="resumeId"
            isInvalid={resumeUrlChanged}
            value={resumeUrlId}
            onChange={(e) => {
              if (e.target.value != "") {
                setResumeUrlChanged(false);
              }
              setResumeUrlId(e.target.value);
            }}
          >
            <option value="">Select a uploaded resume</option>

            {uploadResumeUrlData.map((x) => {
              return (
                <>
                  <option value={x.resumeId}>
                    {x.originalname} , ( {x.resumename} ) 
                  </option>
                </>
              );
            })}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Please select a uploaded resume, if no uploaded resume is present
            upload from the button below
          </Form.Control.Feedback>
        </Form.Group>
      
        <p>
          {uploadResumeUrlData.length == 0
            ? "Please ensure that you upload your resume in PDF format (a requirement for Glassdoor) *"
            : ""}
        </p>
        <div
          className="mainResumeViewerUploadButton"
          onClick={() => setShowUploadResumeModalV2(true)}
        >
          {uploadResumeUrlData.length > 0
            ? "Upload Another Resume"
            : "Upload Resume"}
        </div>
      </div> */}
    </>
  );
}

export default UploadResume;
