import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import SuccessModal from "./SuccessModal";
import { useStateStore } from "../../../store/index";
import { mixpanel } from "../../../index";

function ReportModal({
  reportedData,
  setReportedData,
  showModal,
  setShowReportModal,
  setQuestion,
  setAnswer,
}) {
  const userData = useStateStore((state) => state.userData);
  const [reportConcernValue, setReportConcernValue] = useState("");
  const [successReportModal, setSuccessReportModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const token = useStateStore((state) => state.token);

  const handleClose = () => {
    mixpanel.track("report_question_popup queries_page dashboard", {
      email: userData?.email,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
      popup_event: false,
    });
    setShowReportModal(false);
    setReportConcernValue("");
    setErrorMessage("");
    setReportedData((prevState) => ({
      ...prevState,
      context: "",
    }));
  };

  const sendReportedData = async () => {
    const response = await axios.post(
      "https://backend.lazyapply.com/v2/reportedQuestions",
      reportedData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    mixpanel.track(
      "submit_button report_question_popup queries_page dashboard",
      {
        email: userData?.email,
        plan_details: userData?.planDetails || {},
        v2Id: userData?.planDetails?.v2Id || "",
        reported_data: reportedData,
      }
    );

    setShowReportModal(false);
    setSuccessReportModal(true);
    setShowLoading(false);
    setQuestion(false);
    setAnswer(false);
    setReportConcernValue("");
  };

  function handleSubmit() {
    if (reportConcernValue.trim()) {
      setErrorMessage("");
      setShowLoading(true);
      sendReportedData();
    } else {
      setErrorMessage("Please provide your concerns before submitting");
    }
  }

  function handleReportConcernValueChange(event) {
    setReportConcernValue(event.target.value);
    setReportedData((prevState) => ({
      ...prevState,
      context: event.target.value,
    }));
  }

  if (showModal) {
    return (
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title> Report question </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!showLoading ? (
            <>
              {" "}
              <div
                style={{
                  marginBottom: "10%",
                }}
              >
                <span
                  style={{
                    color: "#1976d2",
                    fontSize: "1.2rem",
                    fontWeight: 500,
                  }}
                >
                  Please provide us your concerns about this question and what
                  should be the correct answer to this question.
                </span>
              </div>
              <div
                style={{
                  marginBottom: "2%",
                }}
              >
                <textarea
                  placeholder="Report here!"
                  required
                  value={reportConcernValue}
                  onChange={handleReportConcernValueChange}
                  style={{
                    width: "100%",
                    borderRadius: 12,
                    color: "#333",
                    padding: "12px",
                    fontSize: "1.2rem",
                    fontWeight: 500,
                  }}
                ></textarea>
              </div>
              {errorMessage && (
                <p style={{ color: "red", fontWeight: 600 }}>{errorMessage}</p>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  style={{
                    fontSize: "1.2rem",
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    padding: "10px 20px",
                    borderRadius: 20,
                    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, 0.2)",

                    textTransform: "none",
                    fontWeight: 600,
                    marginRight: "0",
                  }}
                >
                  Submit
                </Button>
              </div>{" "}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={50} thickness={5} />
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }

  if (successReportModal) {
    return (
      <SuccessModal
        successReportModal={successReportModal}
        setSuccessReportModal={setSuccessReportModal}
      />
    );
  }
}

export default ReportModal;
