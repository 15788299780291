import React, { useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useStateStore } from "../../store";
import axios from "axios";
import { mixpanel } from "../../index";
import CustomAlert from "../modal";
import LazyapplyXSurveyModal from "./lazyapplySurvey";
import { useHistory } from "react-router-dom";

function LazyapplyXFirst() {
  const history = useHistory();
  const CLIENT_ID =
    "715818145189-7geg3j3td9u21nc9qe8eujb2l4dcbt88.apps.googleusercontent.com";
  const token = useStateStore((state) => state.token);
  const [lazyapplylink, setlazyapplylink] = useState("");
  const lazyapplyXData = useStateStore((state) => state.lazyapplyXData);
  const setLazyapplyXData = useStateStore((state) => state.setLazyapplyXData);
  const userData = useStateStore((state) => state.userData);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const [userTerms, setUserTerms] = useState(false);
  const [userTerms1, setUserTerms1] = useState(false);
  const [surveyModalShow, setSurveyModalShow] = useState(false);
  const saveTokensAndConnectEmail = (code) => {
    console.log("code", code);
    mixpanel.identify(userData.email);
    mixpanel.people.increment(
      "Dashboard - LaxyapplyX connect email button clicked"
    );
    mixpanel.track("Dashboard - LaxyapplyX connect email button clicked", {
      email: userData.email,
    });

    axios
      .post(
        "https://backend.lazyapply.com/saveTokenAndConnectEmail2",
        {
          code,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("response", response.data);
        if (
          response?.data?.message &&
          response?.data?.message === "Successfully Linked"
        ) {
          setLazyapplyXData({
            ...lazyapplyXData,
            stepNo: 1,
          });
          mixpanel.identify(userData?.email);
          mixpanel.people.increment(
            "Dashboard - LaxyapplyX email connected successfully"
          );
          mixpanel.track(
            "Dashboard - LaxyapplyX email connected successfully",
            {
              email: userData?.email,
            }
          );
        } else if (
          response?.data?.message &&
          response?.data?.message === "Access not given to Lazyapply"
        ) {
          setmessage("Access not given to Lazyapply");
          settitle("Relogin again!");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2000);
        } else {
          console.log("something went wrong");
          setmessage("Some error occured");
          settitle("Error!");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2000);
        }
      })
      .catch((err) => {
        console.log("errorhai", err);
      });
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      saveTokensAndConnectEmail(codeResponse.code);
      console.log("code", codeResponse.code);
    },
    onError: (err) => {
      //access_denied
      console.log("code err", err.error);
      setmessage("Access not given to Lazyapply");
      settitle("Relogin again!");
      setmodalshow(true);
      setTimeout(() => {
        setmodalshow(false);
      }, 2000);
    },
    clientId: CLIENT_ID,
    access_type: "offline",
    flow: "auth-code",
    scope:
      "https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly",
    uxMode: "popup",
  });

  const openBetaModal = () => {
    // setlazyapplylink("https://forms.gle/YZjxgQfq4VWeoJch7");
    // setmessage(
    //   "Due to verification issue from google, We are giving beta access to certain user currently, fill this form if you are interested"
    // );
    // settitle("Lazyapply-X Beta Access");
    // setmodalshow(true);
    setSurveyModalShow(true);
  };

  const customFn = () => {
    setmodalshow(false);
    setlazyapplylink("");
  };

  const surveyCustomFn = () => {
    setSurveyModalShow(false);
  };

  const getAuthorizationCode = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");

    if (code) {
      // You have the authorization code here, you can use it for further processing
      console.log("Authorization code:", code);
      saveTokensAndConnectEmail(code);

      // Now you can perform any additional logic, like exchanging the code for access tokens
      // or handling the sign-in process on the server-side.
    }
  };

  useEffect(() => {
    getAuthorizationCode();
  }, []);

  const checkBetaUser = () => {
    axios
      .get("https://backend.lazyapply.com/betaListLazyapplyX", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.data) {
          if (response.data.accessGranted) {
            // login();
            // const currentURL = window.location.href;
            // const redirectURI = encodeURIComponent(currentURL);
            // const href = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${redirectURI}&scope=https://www.googleapis.com/auth/gmail.send&access_type=offline`;
            window.open(
              `https://lazyapplyx.lazyapply.com?token=${token}`,
              "_blank"
            );
            setTimeout(() => {
              window.close();
            }, 1000);
          } else {
            setmessage("Access not given to Lazyapply");
            settitle("Not a beta user!");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2000);
          }
        } else {
          setmessage("Access not given to Lazyapply");
          settitle("Not a beta user!");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2000);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      <CustomAlert
        message={message}
        s={modalshow}
        title={title}
        lazyapplyxlink={lazyapplylink}
        customFn={customFn}
      ></CustomAlert>
      <LazyapplyXSurveyModal s={surveyModalShow} customFn={surveyCustomFn} />
      <div className="targetedSearchInfo">
        <p className="targetedSearchInfoTitle">
          LazyApply-X ( Targeted Search )
        </p>
        <p className="targetedSearchInfoDesc">
          {`Choose the type of company where you want to work and LazyApplyX will automatically email your resume to Senior HR, Junior Hr, Recruiter, and other relevant persons in the company`}
        </p>
        <p className="targetedSearchInfoDesc">{`How does LazyApply-X work?`}</p>
        <div className="targetedSearchInfoList">
          <ul>
            <li>
              <span>Create a list of companies based -</span> You choose the
              type of company with filters like company location, the funding
              round, Public/Private, Number of Employees, and many more, and
              upload your resume on our portal. You can also include or exclude
              specific companies from your list. Our AI will find all the
              relevant companies based on your filters.
            </li>
            <li>
              <span>Create a list of contacts -</span> We will find the relevant
              contact in these companies with their email address.
            </li>
            <li>
              <span>Email contacts -</span> LazyApply-X AI will write a
              beautiful email for you and email it to all of these contacts with
              your resume attached to your email. All the emails will be sent
              from your mail address. Recruiters won’t know that someone else
              sent an email on your behalf.
            </li>
          </ul>
        </div>
        <div
          style={{
            margin: "10px",
          }}
        >
          <button
            // onClick={() => openBetaModal()}
            onClick={() => login()}
            disabled={!userTerms || !userTerms1}
            className={
              "login-with-google-btn" + (userTerms ? " login-disabled" : "")
            }
          >
            Sign in with google
          </button>
          {/* <p style={{ marginTop: "20px" }}>
            Already a beta user,{" "}
            <span
              style={{ color: "#007bff", cursor: "pointer" }}
              onClick={() => {
                checkBetaUser();
              }}
            >
              click here
            </span>
          </p> */}
          <div style={{ display: "block", margin: "15px" }}>
            <p style={{ fontWeight: "bold", color: "black" }}>
              Data Use Compliance
            </p>
            <p style={{ color: "#007bff" }}>
              "LazyApply confirms that it does not use data obtained through
              Google APIs for the purpose of developing, improving, or training
              generalized artificial intelligence or machine learning models. We
              are committed to complying with Google's API Services User Data
              Policy, including the Limited Use requirements."
            </p>
          </div>
          <div className="userTerms">
            <div className="userTermsCheckbox">
              <input
                type="checkbox"
                checked={userTerms}
                onChange={(e) => {
                  setUserTerms(!userTerms);
                }}
              />
            </div>
            <div>
              Lazyapply's use and transfer of information received from Google
              APIs to any other app will adhere to{" "}
              <a
                style={{ textDecoration: "underline" }}
                href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                target="_blank"
                rel="noreferrer"
              >
                Google API Services User Data Policy
              </a>{" "}
              , including the Limited Use requirements.
            </div>{" "}
          </div>

          <div className="userTerms">
            <div className="userTermsCheckbox">
              <input
                type="checkbox"
                checked={userTerms1}
                onChange={(e) => {
                  setUserTerms1(!userTerms1);
                }}
              />
            </div>
            <div>
              Lazyapply utilizes a third-party AI model to process job and
              resume details provided by the user, with the sole purpose of
              generating personalized emails for HR outreach. Rest assured, this
              data remains confidential and is not shared with any external
              entity, apart from the AI model processing. For a detailed
              understanding, please refer to our{" "}
              <span
                onClick={() => history.push("/dashboard/lazyapplyx-privacy")}
                style={{
                  textDecoration: "underline",
                  color: "#007bff",
                  cursor: "pointer",
                }}
              >
                Data Usage and Privacy Explanation
              </span>
            </div>
          </div>

          {/* <button className="targetedSearchInfoButton">Check Demo Video</button> */}
        </div>
      </div>
    </>
  );
}

export default LazyapplyXFirst;
