import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useStateStore } from "../../store/index";
import { v1 as uuidv1 } from "uuid";
import FileUpload from "./FileUploader";
function UploadResumeModalV2({ showProp, hideShow }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    hideShow();
  };
  const uploadResumeUrlData = useStateStore(
    (state) => state.uploadResumeUrlData
  );
  const setUploadResumeUrlData = useStateStore(
    (state) => state.setUploadResumeUrlData
  );
  const [showSpinner, setShowSpinner] = useState(false);
  const userData = useStateStore((state) => state.userData);
  const [value, setValue] = useState("");
  const token = useStateStore((state) => state.token);
  const [file, setFile] = useState(null);

  let buttonSave = {
    backgroundColor: "#35AD46",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "10px 20px",
    textAlign: "center",
    borderRadius: "10px",
    width: "100%",
    outline: "none",
    border: "none",
    marginTop: "10px",
  };

  let showr = {
    textAlign: "center",
    fontWeight: "bold",
    color: "black",
    fontSize: "16.5px",
    marginTop: "10px",
  };
  const [showMessage, setShowMessage] = useState("");

  const showCustomMessage = (message) => {
    setShowMessage(message);
    setTimeout(() => {
      setShowMessage("");
    }, 2500);
  };

  const createNewResume = () => {
    setDisable(true);
    const v2Id = userData?.planDetails?.v2Id;
    const newResumeId = uuidv1();
    if (value != "" && file) {
      const formData = new FormData();
      console.log("file");
      formData.append("resumename", value);
      formData.append("resumeId", newResumeId);
      formData.append("file", file);
      console.log("formData", formData);
      setShowSpinner(true);
      axios
        .post(
          `https://backend.lazyapply.com/v2/uploadResume/${v2Id}`,
          formData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setShowSpinner(false);
          console.log(response, "response submitcode");
          if ("resumeJson" in response.data) {
            showCustomMessage("Successfully Uploaded");
            setUploadResumeUrlData(response.data.resumeJson);
            setDisable(false);
            handleClose();
          } else {
            showCustomMessage("Uploading Failed!");
            setDisable(false);
            handleClose();
          }
        })
        .catch((error) => {
          setShowSpinner(false);
          setDisable(false);
          showCustomMessage("Some error occured, please try again!");
          console.log(error, "error submitcode");
        });
    } else {
      setDisable(false);
      showCustomMessage("Resume name cannot be empty");
    }
  };

  function submitCoupanCode(e) {
    e.preventDefault();
    const resumev2 = uploadResumeUrlData;
    if (resumev2.length > 0) {
      let present = false;
      for (let index = 0; index < resumev2.length; index++) {
        const resume = resumev2[index];
        if (resume.resumename === value) {
          present = true;
          break;
        }
      }
      if (present) {
        showCustomMessage("Resume with this name already present!");
      } else {
        createNewResume();
      }
    } else {
      createNewResume();
    }
  }

  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="md"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Upload Resume/CV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: "20px" }}>
          <p>
            The Resume/CV you upload here will be used in Glassdoor when it ask
            for your Resume/CV.
          </p>
          <Form onSubmit={submitCoupanCode}>
            <Form.Control
              required={true}
              type="text"
              id="resumename"
              value={value}
              placeholder="Enter resume name example -> aman_glassdoor"
              onChange={(e) => setValue(e.target.value)}
            />
            <p style={{ color: "grey", fontSize: "14px", marginTop: "10px" }}>
              Name is required so that you can select from multiple Resume/CV
              you upload later on.{" "}
            </p>

            <div className="resumeTrackerUpload">
              <p style={{ marginTop: "20px" }}>Upload your Resume/ CV</p>
              <FileUpload file={file} setFile={setFile}></FileUpload>
            </div>

            <button style={buttonSave} disabled={disable}>
              Upload Now{" "}
              {showSpinner && (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ marginLeft: "5px" }}
                />
              )}
            </button>
          </Form>
          <p style={showr}>{showMessage}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UploadResumeModalV2;
