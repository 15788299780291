import React, { useState, useRef, useEffect } from "react";
import { Form, Row, Button, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import "./editresumedash.css";
import axios from "axios";
import { useStateStore } from "../../store/index";
import CustomAlert from "../modal";
import { mixpanel } from "../../index";
import countries from "../country_codes.json";
function Editresumedash() {
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const params = useParams();
  const [modalshow, setmodalshow] = useState(false);
  const globalLogout = useStateStore((state) => state.globalLogout);
  const token = useStateStore((state) => state.token);
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    website_blog_portfolio: "-",
    noticeperiod: "0",
    languages_you_know: {},
  });
  const userData = useStateStore((state) => state.userData);
  const userDataResumeUpdate = useStateStore(
    (state) => state.userDataResumeUpdate
  );
  const setResumeModifiedV2 = useStateStore(
    (state) => state.setResumeModifiedV2
  );

  const setField = (field, value) => {
    console.log(field, value, "field,value");

    setForm({
      ...form,
      [field]: value,
    });

    // Check and see if errors exist, and remove them from the error object:
    console.log(form, "form");

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const findFormErrors = () => {
    const {
      veteran,
      disability,
      race_ethnicity,
      expectedsalary,
      address,
      valid_driver_license,
      message_to_the_hiring_manager,
      coverletter,
      what_makes_you_unique,
    } = form;
    const newErrors = {};
    // name errors
    if (!address || address === "") newErrors.address = "Enter your address";

    if (!message_to_the_hiring_manager || message_to_the_hiring_manager === "")
      newErrors.message_to_the_hiring_manager =
        "Enter your message to hiring manager value";
    if (!coverletter || coverletter === "")
      newErrors.coverletter = "Enter your cover letter value";
    if (!what_makes_you_unique || what_makes_you_unique === "")
      newErrors.what_makes_you_unique =
        "Enter your what makes you unique value";
    if (!expectedsalary || expectedsalary === "")
      newErrors.expectedsalary = "Enter your address";
    if (!veteran || veteran == "Select an option")
      newErrors.veteran = "Enter your veteran status option";
    if (!disability || disability == "Select an option")
      newErrors.disability = "Enter your disability option";
    if (!race_ethnicity || race_ethnicity == "Select an option")
      newErrors.race_ethnicity = "Enter your race and ethnicity option";
    if (!valid_driver_license || valid_driver_license == "Select an option")
      newErrors.valid_driver_license = "Enter a driver license option";
    return newErrors;
  };

  const handleSubmit = (e) => {
    console.log("handle submit");
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    console.log(newErrors);
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      // No errors! Put any logic here for the form submission
      console.log("form submit", form);
      if (params.id) {
        const v2Id = userData?.planDetails?.v2Id;
        const resumeId = params.id;
        axios
          .post(
            `https://backend.lazyapply.com/v2/updateResumeNew/${v2Id}?id=${resumeId}`,
            {
              resume: form,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            console.log(response);
            mixpanel.identify(userData.email);
            mixpanel.people.increment(
              "Dashboard - Additional Resume updated successfully"
            );
            mixpanel.track(
              "Dashboard - Additional Resume updated successfully",
              {
                email: userData.email,
              }
            );
            setResumeModifiedV2(1);
            setmessage("Successfully updated");
            settitle("Success");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2500);
          })
          .catch((error) => {
            console.log(error);
            if (error.message == "Request failed with status code 403") {
              console.log("reauthenticate");
              setmessage("Please relogin again!");
              settitle("Not authorized");
              setmodalshow(true);
              setTimeout(() => {
                globalLogout();
                history.push("/");
              }, 1500);
            } else {
              console.log("error");
              setmessage("Some error occured, please try again in some time!");
              settitle("Error");
              setmodalshow(true);
              setTimeout(() => {
                setmodalshow(false);
              }, 2500);
            }
          });
      } else {
        axios
          .post(
            "https://backend.lazyapply.com/updateresumenew",
            {
              resume: form,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            console.log(response);
            userDataResumeUpdate({
              additionalResume: form,
              additionalresumevalidation: 1,
            });
            mixpanel.identify(userData.email);
            mixpanel.people.increment(
              "Dashboard - Additional Resume updated successfully"
            );
            mixpanel.track(
              "Dashboard - Additional Resume updated successfully",
              {
                email: userData.email,
              }
            );
            setmessage("Successfully updated");
            settitle("Success");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2500);
          })
          .catch((error) => {
            console.log(error);
            if (error.message == "Request failed with status code 403") {
              console.log("reauthenticate");
              setmessage("Please relogin again!");
              settitle("Not authorized");
              setmodalshow(true);
              setTimeout(() => {
                globalLogout();
                history.push("/");
              }, 1500);
            } else {
              console.log("error");
              setmessage("Some error occured, please try again in some time!");
              settitle("Error");
              setmodalshow(true);
              setTimeout(() => {
                setmodalshow(false);
              }, 2500);
            }
          });
      }
    }
  };

  useEffect(() => {
    loading.current.style.display = "none";
    mixpanel.identify(userData.email);
    mixpanel.people.increment("Dashboard - resume additonal edit page visit");
    mixpanel.track("Dashboard - resume addtional edit page visit", {
      email: userData.email,
    });

    if (params.id) {
      // v2 version
      loading.current.style.display = "block";
      const resumeId = params.id;
      const v2Id = userData?.planDetails?.v2Id;
      //fetch here
      axios
        .get(
          `https://backend.lazyapply.com/v2/resumes/${v2Id}?id=${resumeId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log("res", response);
          loading.current.style.display = "none";
          if (
            "resume" in response.data &&
            Object.keys(response.data.resume).length > 0
          ) {
            if ("additionalInfo" in response.data.resume) {
              const {
                race_ethnicity,
                disability,
                veteran,
                address,
                coverletter,
              } = response.data.resume;
              const f = {
                ...response.data.resume.additionalInfo,
                race_ethnicity,
                disability,
                veteran,
                address,
                coverletter,
              };
              if ("noticeperiod" in response.data.resume) {
                f.noticeperiod = response.data.resume.noticeperiod;
              }
              if ("visa_status" in response.data.resume) {
                f.visa_status = response.data.resume.visa_status;
              }
              if ("phone_country_code" in response.data.resume) {
                f.phone_country_code = response.data.resume.phone_country_code;
              }
              if ("languages_you_know" in response.data.resume) {
                f.languages_you_know = response.data.resume.languages_you_know;
              } else {
                f.languages_you_know = {};
              }

              if ("linkedin_profile_url" in response.data.resume) {
                f.linkedin_profile_url =
                  response.data.resume.linkedin_profile_url;
              }
              console.log("form", f);
              setForm(f);
            }
          }
        })
        .catch((err) => {
          loading.current.style.display = "none";
          console.log("err", err);
        });
    } else {
      if ("resume" in userData) {
        if ("additionalInfo" in userData.resume) {
          const { race_ethnicity, disability, veteran, address, coverletter } =
            userData.resume;
          const f = {
            ...userData.resume.additionalInfo,
            race_ethnicity,
            disability,
            veteran,
            address,
            coverletter,
          };
          if ("noticeperiod" in userData.resume) {
            f.noticeperiod = userData.resume.noticeperiod;
          }
          if ("visa_status" in userData.resume) {
            f.visa_status = userData.resume.visa_status;
          }
          if ("phone_country_code" in userData.resume) {
            f.phone_country_code = userData.resume.phone_country_code;
          }
          if ("languages_you_know" in userData.resume) {
            f.languages_you_know = userData.resume.languages_you_know;
          } else {
            f.languages_you_know = {};
          }
          if ("linkedin_profile_url" in userData.resume) {
            f.linkedin_profile_url = userData.resume.linkedin_profile_url;
          }
          console.log("formnew", f);
          setForm(f);
        }
      }
    }
  }, []);

  const race_ethnicity_options = [
    "Select an option",
    "American Indian or Alaskan Native",
    "Asian",
    "Black or African American",
    "Hispanic or Latino",
    "White",
    "Native Hawaiian or Other Pacific Islander",
    "Two or More Races",
    "Decline To Self Identify",
  ];

  const veteran_options = [
    "Select an option",
    "No",
    "Yes",
    "I don't wish to answer",
  ];

  const visa_status_options = ["Select an option", "No", "Yes"];

  const language_proficiency = [
    "Conversational",
    "Professional",
    "Native or bilingual",
  ];

  const language_options = [
    "English",
    "Mandarin Chinese",
    "Spanish",
    "Hindi",
    "Urdu",
    "Arabic",
    "Bengali",
    "Portuguese",
    "Russian",
    "Japanese",
    "German",
    "Javanese",
    "Punjabi",
    "Wu",
    "French",
    "Telugu",
    "Vietnamese",
    "Marathi",
    "Korean",
    "Tamil",
    "Italian",
    "Turkish",
    "Cantonese/Yue",
  ];

  const valid_driver_license_options = ["Select an option", "No", "Yes"];

  const disability_options = [
    "Select an option",
    "No",
    "Yes",
    "I don't wish to answer",
  ];

  const styleform = {
    padding: "10px",
    borderRadius: "3px",
    boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
    background: "rgba(255, 255, 255, 0.9)",
    margin: "10px",
  };

  const [selectedLangaugeProficiency, setSelectedLangaugeProficiency] =
    useState("Conversational");
  const [selectedLangauge, setSelectedLangauge] = useState("English");

  function goBack() {
    history.goBack();
  }
  const loading = useRef(null);

  const [languages, setlanguages] = useState({});
  const addLanguage = () => {
    console.log("language", selectedLangauge, selectedLangaugeProficiency);
    const updatedlanguage = {
      ...(form.languages_you_know || {}),
      [selectedLangauge]: selectedLangaugeProficiency,
    };
    setField("languages_you_know", updatedlanguage);
  };

  function deleteLanguage(key) {
    let alllanguages = form.languages_you_know || {};
    delete alllanguages[key];
    setField("languages_you_know", alllanguages);
  }

  return (
    <div style={{ position: "relative" }}>
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      <div className="loading loadingresume" ref={loading}>
        <div className="boxLoading"></div>
      </div>
      <div className="makeSure">
        Additional Information for applying jobs optimally!<br></br>
      </div>

      <button className="buttonGoBack btn" onClick={goBack}>
        <i className="fas fa-arrow-left"></i> Go Back
      </button>
      <Form onSubmit={handleSubmit} style={{ marginTop: "70px" }}>
        <Row className="row1" style={styleform}>
          <Col
            md={12}
            style={{
              textAlign: "left",
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            Diversity
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="race_ethnicity">
                Race and Ethnicity*
              </Form.Label>
              <Form.Control
                as="select"
                value={form.race_ethnicity}
                id="race_ethnicity"
                isInvalid={!!errors.race_ethnicity}
                onChange={(e) => setField("race_ethnicity", e.target.value)}
              >
                {race_ethnicity_options.map((v) => {
                  return <option value={v}>{v}</option>;
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.race_ethnicity}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="veteran">Veteran Status*</Form.Label>
              <Form.Control
                as="select"
                value={form.veteran}
                id="veteran"
                isInvalid={!!errors.veteran}
                onChange={(e) => setField("veteran", e.target.value)}
              >
                {veteran_options.map((v) => {
                  return <option value={v}>{v}</option>;
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.veteran}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="disability">Disability Status*</Form.Label>
              <Form.Control
                as="select"
                value={form.disability}
                id="disability"
                isInvalid={!!errors.disability}
                onChange={(e) => setField("disability", e.target.value)}
              >
                {disability_options.map((v) => {
                  return <option value={v}>{v}</option>;
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.disability}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="row1" style={styleform}>
          <Col
            md={12}
            style={{
              textAlign: "left",
              fontWeight: "bold",
              marginBottom: "5px",
            }}
          >
            Some Other Information
          </Col>
          <Col md={6} style={{ marginBottom: "10px" }}>
            <Form.Label htmlFor="address">Address*</Form.Label>
            <Form.Control
              required
              type="text"
              id="address"
              value={form.address}
              placeholder="Your street address.."
              aria-describedby="address"
              onChange={(e) => setField("address", e.target.value)}
              isInvalid={!!errors.address}
            />
            <Form.Control.Feedback type="invalid">
              {errors.address}
            </Form.Control.Feedback>
          </Col>
          <Col md={6} style={{ marginBottom: "10px" }}>
            <Form.Label htmlFor="expectedsalary">Expected Salary*</Form.Label>
            <Form.Control
              required
              type="text"
              value={form.expectedsalary}
              id="expectedsalary"
              placeholder="60000$ or 12 Lakhs etc"
              aria-describedby="expectedsalary"
              onChange={(e) => setField("expectedsalary", e.target.value)}
              isInvalid={!!errors.expectedsalary}
            />
            <Form.Control.Feedback type="invalid">
              {errors.expectedsalary}
            </Form.Control.Feedback>
          </Col>
          <Col md={12} style={{ marginBottom: "10px" }}>
            <Form.Label htmlFor="website_blog_portfolio">
              Personal website,blog or portfolio links (comma seperated)
            </Form.Label>
            <Form.Control
              type="text"
              value={form.website_blog_portfolio}
              id="website_blog_portfolio"
              aria-describedby="website_blog_portfolio"
              placeholder="https://abc.com, https://xyz.com"
              onChange={(e) =>
                setField("website_blog_portfolio", e.target.value)
              }
            />
          </Col>
          <Col md={12} style={{ marginBottom: "10px" }}>
            <Form.Label>Message to the hiring manager*</Form.Label>
            <Form.Control
              required={true}
              value={form.message_to_the_hiring_manager}
              as="textarea"
              placeholder="I would love to begin my career with your company and am confident that I would be a beneficial addition to the Company..... "
              rows={3}
              onChange={(e) =>
                setField("message_to_the_hiring_manager", e.target.value)
              }
            />
          </Col>
          <Col md={12} style={{ marginBottom: "10px" }}>
            <Form.Label>Cover letter*</Form.Label>
            <Form.Control
              required={true}
              value={form.coverletter}
              as="textarea"
              placeholder=""
              rows={3}
              onChange={(e) => setField("coverletter", e.target.value)}
            />
          </Col>
          <Col md={12} style={{ marginBottom: "10px" }}>
            <Form.Label>What makes you unique for the job*</Form.Label>
            <Form.Control
              required={true}
              value={form.what_makes_you_unique}
              as="textarea"
              placeholder="What makes me unique is that I really enjoy working in a fast-paced atmosphere with lots going on......"
              rows={3}
              onChange={(e) =>
                setField("what_makes_you_unique", e.target.value)
              }
            />
          </Col>
          <Col md={6} style={{ marginBottom: "10px" }}>
            <Form.Group>
              <Form.Label htmlFor="valid_driver_license">
                Do you have a valid driver license?*
              </Form.Label>
              <Form.Control
                as="select"
                value={form.valid_driver_license}
                id="valid_driver_license"
                isInvalid={!!errors.valid_driver_license}
                onChange={(e) =>
                  setField("valid_driver_license", e.target.value)
                }
              >
                {valid_driver_license_options.map((v) => {
                  return <option value={v}>{v}</option>;
                })}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.valid_driver_license}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} style={{ marginBottom: "10px" }}>
            <Form.Group>
              <Form.Label>Select expected start date of joining*</Form.Label>
              <Form.Control
                required={true}
                type="date"
                name="earliest_start_date"
                value={form.earliest_start_date}
                placeholder="Earliest start date to join the company"
                onChange={(e) =>
                  setField("earliest_start_date", e.target.value)
                }
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group>
              <Form.Label htmlFor="visa_status">
                Will you now, or in the future, require sponsorship for
                employment visa status (e.g. H-1B visa status)?
              </Form.Label>
              <Form.Control
                as="select"
                value={form.visa_status}
                id="visa_status"
                onChange={(e) => {
                  if (e.target.value != "Select an option")
                    setField("visa_status", e.target.value);
                }}
              >
                {visa_status_options.map((v) => {
                  return <option value={v}>{v}</option>;
                })}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="visa_status">Phone Country Code</Form.Label>
              <Form.Control
                as="select"
                value={form.phone_country_code}
                id="phone_country_code"
                onChange={(e) => {
                  if (e.target.value != "Select an option")
                    setField("phone_country_code", e.target.value);
                }}
              >
                {countries.country_codes.map((v) => {
                  return <option value={v}>{v}</option>;
                })}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label htmlFor="linkedin_profile_url">
                Linkedin Profile Url
              </Form.Label>
              <Form.Control
                type="url"
                value={form.linkedin_profile_url || ""}
                id="linkedin_profile_url"
                placeholder="Enter your linkedin profile url here.."
                onChange={(e) =>
                  setField("linkedin_profile_url", e.target.value)
                }
              />
            </Form.Group>
          </Col>
          <Col md={4} style={{ marginBottom: "10px" }}>
            <Form.Group>
              <Form.Label>Notice period (in days)</Form.Label>
              <Form.Control
                type="number"
                name="noticeperiod"
                value={form.noticeperiod}
                placeholder="Enter your notice period"
                onChange={(e) => setField("noticeperiod", e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={8} className="languagesYouKnow">
            <div className="jobDetails">
              <div className="jobDEtailsTop0">Languages you speak</div>
              <div className="jobDetailsSkill">
                <div className="jobDetailsSkillTop">
                  <div>
                    <p>Select language</p>
                  </div>

                  <div>
                    <Form.Control
                      as="select"
                      value={selectedLangauge}
                      id="language"
                      onChange={(e) => {
                        setSelectedLangauge(e.target.value);
                      }}
                    >
                      {language_options.map((v) => {
                        return <option value={v}>{v}</option>;
                      })}
                    </Form.Control>
                  </div>
                </div>
                <div className="jobDetailsSkillTop">
                  <div>
                    <p>Language proficiency</p>
                  </div>

                  <div>
                    <Form.Control
                      as="select"
                      value={selectedLangaugeProficiency}
                      id="language_proficiency"
                      onChange={(e) => {
                        setSelectedLangaugeProficiency(e.target.value);
                      }}
                    >
                      {language_proficiency.map((v) => {
                        return <option value={v}>{v}</option>;
                      })}
                    </Form.Control>
                  </div>
                </div>
                <div className="addButton">
                  <button type="button" onClick={addLanguage}>
                    Add +
                  </button>
                </div>
              </div>
            </div>
            <div className="jobDetailsSkillValue">
              {Object.keys(form.languages_you_know || {}).map(function (key) {
                return (
                  <span
                    className="skillSpan"
                    onClick={() => deleteLanguage(key)}
                  >
                    {key}-{form.languages_you_know[key]}{" "}
                    <i className="fas fa-times ml-1"></i>
                  </span>
                );
              })}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="submitForm">
            <Button className="btn linkedinSubmitButton" type="submit">
              Submit Additional Details<i className="far fa-thumbs-up ml-2"></i>
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Editresumedash;
