import React,{useState} from 'react'

function Progress({done}) {
    const [style, setStyle] = useState({});

    setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${done}%`,
      };

      setStyle(newStyle);
    }, 200);

    return (
        <div className="progressbars">
          <div className="progress-dones" style={style}>
            {done}%
          </div>
        </div>
    );
}

export default Progress

