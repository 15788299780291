import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { useStateStore } from "../../store";
import "./lazyapplyXAnalytics.css";

function LazyapplyXAnalytics() {
  const { id } = useParams();
  const [message, setmessage] = useState("");
  const [data, setData] = useState(null);
  const token = useStateStore((state) => state.token);

  const history = useHistory();

  const goBack = () => {
    console.log("go back");
    history.goBack();
  };

  const fetchData = async () => {
    console.log("id fetch call 0", id);
    try {
      const response = await axios.get(
        `https://backend.lazyapply.com/lazyapplyx/getGmailReadRefresh/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("response", response.data.message);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData1 = async () => {
    console.log("id fetch call 1", id);
    try {
      const response = await axios.get(
        `https://backend.lazyapply.com/lazyapplyx/getGmailReadData/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("response", response.data.message);
      setmessage(response.data.message);
      if (response.data.message == "success") setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const renderTextWithLineBreaks = (text) => {
    return text.split("\n").map((line, index, array) =>
      // Do not add a <br /> after the last line
      line !== "" && index !== array.length - 1 ? (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ) : (
        <React.Fragment key={index}>{line}</React.Fragment>
      )
    );
  };

  useEffect(() => {
    fetchData();
    fetchData1();
  }, [id]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchData1();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [id]);

  useEffect(() => {
    const intervalId1 = setInterval(() => {
      fetchData();
    }, 20000);
    return () => clearInterval(intervalId1);
  }, [id]);

  return (
    <div style={{ position: "relative" }} id="mainLazyapplyXAnalytics">
      <>
        <button className="buttonGoBack btn" onClick={goBack}>
          <i className="fas fa-arrow-left"></i> Go Back
        </button>

        {message != "success" && (
          <>
            <div
              className="gmailAnalyticsDiv"
              style={{
                padding: "50px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Its currently {message} the mails, it will be available to you in
              some seconds
            </div>
          </>
        )}

        {data &&
          data.message &&
          data.message == "success" &&
          data?.data?.emails && (
            <div className="gmailAnalyticsDiv">
              <p className="gmailAnalyticsDivMainHeading">
                Total emails replied to - {JSON.stringify(data.data.count)}{" "}
              </p>
              {data.data.count > 0 && <p>Below are the details :-</p>}
              <div className="gmailEmailDiv">
                {data.data.emails.map((obj, index) => {
                  return (
                    <>
                      <div className="gmailEmailBlock">
                        <div className="gmailEmailBlock0">
                          <p>{index + 1}.</p>
                        </div>
                        <div>
                          <p>Email - {obj.email}</p>
                          <p>Job Title - {obj.jobTitle}</p>
                          <div className="email-thread-container">
                            <h2>Email Thread</h2>
                            {obj.replies.length > 0 && (
                              <div className="email-original">
                                <h3>Original Email</h3>
                                <p>
                                  {renderTextWithLineBreaks(obj.replies[0])}
                                </p>
                              </div>
                            )}
                            {obj.replies.length > 1 && (
                              <div>
                                <h3
                                  style={{ fontSize: "18px", margin: "10px" }}
                                >
                                  Email Replies
                                </h3>
                                <ul>
                                  {obj.replies.slice(1).map((reply, index) => (
                                    <li key={index} className="email-reply">
                                      {renderTextWithLineBreaks(reply)}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          )}
      </>
    </div>
  );
}

export default LazyapplyXAnalytics;
