/*global chrome*/
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useMultipleRefs } from "./customHooks";
import { Form, Col, Row } from "react-bootstrap";
import "./jobplatform.css";
import { useHistory } from "react-router-dom";
import Progress from "./progress";
import axios from "axios";
import { useStateStore } from "../../store/index";
import CustomAlert from "../modal";
import { mixpanel } from "../../index";
import Feedback from "../feedback";
import Select from "react-select";
import { v1 as uuidv1 } from "uuid";
// import StepsWithCut from "../stepswithcut";
import { MESSAGETYPE, messages, MESSAGES } from "./slackMessages";
import MoreFiltersHelp from "../morefiltershelp";
import AdvanceSearchModal from "./AdvanceSearchModal";
import faqObj from "./faqJson";
import IndeedArticle from "./indeedArticle";
// import { isEqual } from "lodash";
import { GrantAccess } from "./grantAccess";
import SaveSearchModal from "./saveSearchModal";
import GetSavedSearches from "./getSearches";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import FeedbackModal from "./resumeScoreFeedback/FeedbackModal";
import ExtensionInstallPopup from "./ExtensionInstallPopup";
import MainWatchDemoModal from "./MainWatchDemoModal";
import mainFilters from "../filters.json";

function Indeed() {
  const setScriptInjectedGlobal = useStateStore(
    (state) => state.setScriptInjectedGlobal
  );
  const scriptInjectedGlobal = useStateStore(
    (state) => state.scriptInjectedGlobal
  );
  const setExtensionPresent = useStateStore(
    (state) => state.setExtensionPresent
  );
  const setUserResumeV3 = useStateStore((state) => state.setUserResumeV3);
  const [showPlanRequired, setShowPlanRequired] = useState(false);
  const { availableChromeVersion } = useStateStore.getState();
  const [showPlanPurchase, setShowPlanPurchase] = useState(false);
  const [showDemoPopup, setShowDemoPopup] = useState(false);
  const [showExtensionInstallPopup, setShowExtensionInstallPopup] =
    useState(false);
  const [showUpdateExtension, setShowUpdateExtension] = useState(false);
  const setStreakAnalytics = useStateStore((state) => state.setStreakAnalytics);
  const streakAnalytics = useStateStore((state) => state.streakAnalytics);
  const yourExtensionId = useStateStore((state) => state.yourExtensionId);
  const setGlobalTabId = useStateStore((state) => state.setGlobalTabId);
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);
  const [selectedSearchData, setSelectedSearchData] = useState({
    searchId: "",
    searchName: "",
  });
  const [searchInputValue, setSearchInputValue] = useState({
    value: "",
    label: "Select a saved search setting",
  });
  const [showSavedSearchButton, setShowSavedSearchButton] = useState(true);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [showSavedSearchModal, setShowSavedSearchModal] = useState(false);
  const [excludeString, setExcludeString] = useState("");
  const [mayIncludeString, setMayIncludeString] = useState("");
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const setheightMain = useStateStore((state) => state.setheightMain);
  const heightMain = useStateStore((state) => state.heightMain);
  const userResumeV3 = useStateStore((state) => state.userResumeV3);
  const [resumeId, setResumeId] = useState("");
  const resumeData = useRef({});
  const [resumeChanged, setResumeChanged] = useState(false);
  const [resumeDisable, setResumeDisable] = useState(false);
  const [openHelper, setOpenHelper] = useState(false);
  const [country, setCountry] = useState("www");
  const [form1, setForm1] = useState({});
  const [errors, setErrors] = useState({});
  const [countryoption, setCountryOption] = useState("");
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const globalLogout = useStateStore((state) => state.globalLogout);
  const history = useHistory();
  const formMain = useRef(null);
  const sessionLinks = useRef([]);
  const sessionLinksBefore = useRef([]);
  const saveSessionState = useRef(0);
  const selectResumeRef = useRef(null);
  // const [saveSessionState, setSaveSession] = useState(0);
  const userData = useStateStore((state) => state.userData);
  const userLinksUpdate = useStateStore((state) => state.userLinksUpdate);
  const [startApplying, setstartApplying] = useState(false);
  const [formDisable, setformDisable] = useState(false);
  const fetchbutton = useRef(null);
  const token = useStateStore((state) => state.token);
  const totalCountUpdate = useStateStore((state) => state.totalCountUpdate);
  const configDummy = useStateStore((state) => state.configDummy);
  const configDummyUpdate = useStateStore((state) => state.configDummyUpdate);
  //const [progressPercent, setProgressPercent] = useState(15);
  const [urlMain, setUrlMain] = useState("");
  // const [count, setCount] = useState(0);
  const setIndeedRunning = useStateStore((state) => state.setIndeedRunning);
  const count = useRef(0);
  const [finalJobCount, setFinalJobCount] = useState(0);
  const progressPercent = useRef(15);
  const [c, setc] = useState(0);
  const [pp, setpp] = useState(15);
  const button1 = useRef(null);
  const spinner = useRef(null);
  const button2 = useRef(null);
  const chooseParaText = useRef(null);
  const filtersDiv = useRef(null);
  const formMain1 = useRef(null);
  const para = useRef(null);
  const initprocessing = useRef(null);
  const processing = useRef(null);
  const initprocessingFinal = useRef(null);
  const [filters, setFilters] = useState({});
  const formRef = useRef(null);
  const questionRefs = useRef([]);
  const [showIndeedModal, setShowIndeedModal] = useState(null);
  const [modalTitle, setModalTitle] = useState("Issue");
  const [form, setForm] = useState({
    jobtype: "",
    experience: "",
    skill: "",
    location: "",
    numberOfJobs: 0,
    resumeJobScore: 40,
  });

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const sessionId = useRef(null);
  let filterValues = useRef({});
  let filterRefs = useMultipleRefs(12);
  let urlParts = {};

  const handleFeedbackModal = () => {
    setShowFeedbackModal(true);
  };

  const customResumeJobScores = [
    {
      value: 20,
      label: 20,
    },
    {
      value: 40,
      label: 40,
    },
    {
      value: 60,
      label: 60,
    },
    {
      value: 80,
      label: 80,
    },
  ];

  function resumeJobScoreValues(value) {
    return `${value}`;
  }

  function smoothScrollToTop() {
    let start = null;
    const initialScrollTop = window.scrollY;

    function easeOutCubic(t) {
      return 1 - Math.pow(1 - t, 3);
    }

    function step(timestamp) {
      if (start === null) start = timestamp;
      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / 1000, 1); // take 2 seconds to scroll

      const easedProgress = easeOutCubic(progress);
      const newScrollTop = initialScrollTop * (1 - easedProgress);

      window.scrollTo(0, newScrollTop);

      if (newScrollTop > 0) {
        window.requestAnimationFrame(step);
      }
    }

    window.requestAnimationFrame(step);
  }

  const country_options = [
    { name: "Select country", code: "" },
    {
      name: "Argentina",
      code: "ar",
    },
    {
      name: "Australia",
      code: "au",
    },
    {
      name: "Austria",
      code: "at",
    },
    {
      name: "Bahrain",
      code: "bh",
    },
    {
      name: "Belgium",
      code: "be",
    },
    {
      name: "Brazil",
      code: "br",
    },
    {
      name: "Canada",
      code: "ca",
    },
    {
      name: "Chile",
      code: "cl",
    },
    {
      name: "China",
      code: "cn",
    },
    {
      name: "Colombia",
      code: "co",
    },
    {
      name: "Costa Rica",
      code: "cr",
    },
    {
      name: "Czech Republic",
      code: "cz",
    },
    {
      name: "Denmark",
      code: "dk",
    },
    {
      name: "Ecuador",
      code: "ec",
    },
    {
      name: "Egypt",
      code: "eg",
    },
    {
      name: "Finland",
      code: "fi",
    },
    {
      name: "France",
      code: "fr",
    },
    {
      name: "Germany",
      code: "de",
    },
    {
      name: "Greece",
      code: "gr",
    },
    {
      name: "Hong Kong",
      code: "hk",
    },
    {
      name: "Hungary",
      code: "hu",
    },
    {
      name: "India",
      code: "in",
    },
    {
      name: "Indonesia",
      code: "id",
    },
    {
      name: "Ireland",
      code: "ie",
    },
    {
      name: "Israel",
      code: "il",
    },
    {
      name: "Italy",
      code: "it",
    },
    {
      name: "Japan",
      code: "jp",
    },
    {
      name: "Kuwait",
      code: "kw",
    },
    {
      name: "Luxembourg",
      code: "lu",
    },
    {
      name: "Malaysia",
      code: "malaysia",
    },
    {
      name: "Mexico",
      code: "mx",
    },
    {
      name: "Morocco",
      code: "ma",
    },
    {
      name: "Netherlands",
      code: "nl",
    },
    {
      name: "New Zealand",
      code: "nz",
    },
    {
      name: "Nigeria",
      code: "ng",
    },
    {
      name: "Norway",
      code: "no",
    },
    {
      name: "Oman",
      code: "om",
    },
    {
      name: "Pakistan",
      code: "pk",
    },
    {
      name: "Panama",
      code: "pa",
    },
    {
      name: "Peru",
      code: "pe",
    },
    {
      name: "Philippines",
      code: "ph",
    },
    {
      name: "Poland",
      code: "pl",
    },
    {
      name: "Portugal",
      code: "pt",
    },
    {
      name: "Qatar",
      code: "qa",
    },
    {
      name: "Romania",
      code: "ro",
    },
    {
      name: "Russia",
      code: "ru",
    },
    {
      name: "Saudi Arabia",
      code: "sa",
    },
    {
      name: "Singapore",
      code: "sg",
    },
    {
      name: "South Africa",
      code: "za",
    },
    {
      name: "South Korea",
      code: "kr",
    },
    {
      name: "Spain",
      code: "es",
    },
    {
      name: "Sweden",
      code: "se",
    },
    {
      name: "Switzerland",
      code: "ch",
    },
    {
      name: "Taiwan",
      code: "tw",
    },
    {
      name: "Thailand",
      code: "th",
    },
    {
      name: "Turkey",
      code: "tr",
    },
    {
      name: "Ukraine",
      code: "ua",
    },
    {
      name: "United Arab Emirates",
      code: "ae",
    },
    {
      name: "United Kingdom",
      code: "uk",
    },
    {
      name: "United States",
      code: "www",
    },
    {
      name: "Uruguay",
      code: "uy",
    },
    {
      name: "Venezuela",
      code: "ve",
    },
    {
      name: "Vietnam",
      code: "vn",
    },
  ];

  const styleform = {
    padding: "20px",
    paddingTop: "0px",
  };
  const styleform1 = {
    margin: "10px auto",
    padding: "0 5px",
  };

  const filtersStyle = {
    padding: "20px",
    boxShadow: "0px 2px 4px 2px lightgrey, 0px 2px 4px 2px #efefef",
    margin: "5px",
    position: "relative",
  };

  let parastyle = {
    display: "none",
    color: "red",
    marginLeft: "20px",
  };

  let buttonMain1 = {
    backgroundColor: "#35AD46",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "10px 10px",
    textAlign: "center",
    borderRadius: "10px",
    outline: "none",
    border: "none",
  };

  let buttonMain = {
    backgroundColor: "grey",
    color: "white",
    cursor: "default",
    fontSize: "16px",
    padding: "10px 10px",
    textAlign: "center",
    borderRadius: "10px",
    outline: "none",
    border: "none",
  };

  function smoothScrollToBottom() {
    let start = null;
    const initialScrollTop = window.scrollY;
    const targetScrollTop = document.body.scrollHeight - window.innerHeight;

    function easeOutCubic(t) {
      return 1 - Math.pow(1 - t, 3);
    }

    function step(timestamp) {
      if (start === null) start = timestamp;
      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / 500, 1); // take 2 seconds to scroll

      const easedProgress = easeOutCubic(progress);
      const newScrollTop =
        initialScrollTop + (targetScrollTop - initialScrollTop) * easedProgress;

      window.scrollTo(0, newScrollTop);

      if (newScrollTop < targetScrollTop) {
        window.requestAnimationFrame(step);
      }
    }

    window.requestAnimationFrame(step);
  }

  const closeModal = () => {
    setShowIndeedModal(false);
  };

  const handleCloseSearch = () => {
    setShowAdvanceSearch(false);
  };

  const setField1 = (field, value) => {
    setForm1({
      ...form1,
      [field]: value,
    });
  };

  const setField = (field, value) => {
    console.log(field + value);
    setForm({
      ...form,
      [field]: value,
    });

    // Check and see if errors exist, and remove them from the error object:

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    console.log(newErrors);
    // Conditional logic:
    if (userData?.planDetails?.v2Id) {
      //v2
      if (resumeId === "" && Object.keys(userResumeV3).length > 0) {
        setResumeChanged(true);
      } else if (Object.keys(newErrors).length > 0) {
        // We got errors!
        setErrors(newErrors);
      } else {
        // No errors! Put any logic here for the form submission!
        if (await fetchFilterUserHandler()) {
          startLinkedinAutomation();
        }
      }
    } else {
      if (Object.keys(newErrors).length > 0) {
        // We got errors!
        setErrors(newErrors);
      } else {
        // No errors! Put any logic here for the form submission!
        if (await fetchFilterUserHandler()) {
          startLinkedinAutomation();
        }
      }
    }
  };

  const findFormErrors = () => {
    const { skill, numberOfJobs } = form;
    const newErrors = {};
    // name errors
    if (!skill || skill === "") newErrors.skill = "Please enter a skill!";
    if (numberOfJobs <= 0)
      newErrors.numberOfJobs = "Please enter the no. of jobs to apply";

    if (numberOfJobs < 2 && configDummy.planName != "free") {
      if (configDummy.dailyLimit - configDummy.todaysessioncount <= 2) {
        console.log("go for it");
      } else {
        newErrors.numberOfJobs = "Please enter a value more than 20";
      }
    } else if (numberOfJobs < 35 && configDummy.planName == "free") {
      if (configDummy.dailyLimit - configDummy.todaysessioncount < 35) {
        console.log("go for it");
      } else {
        newErrors.numberOfJobs = "Please enter a value more than 35";
      }
    }

    return newErrors;
  };

  function goBack() {
    mixpanel.identify(userData?.email);
    mixpanel.people.increment("go_back_button indeed_page dashboard");
    mixpanel.track("go_back_button dashboard", {
      href: window.location.href,
      email: userData?.email,
      platformName: "indeed",
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    console.log("go back");
    history.goBack();
  }

  async function findIP() {
    const request = await fetch("https://ipinfo.io/json?token=7987a2c0dfe3af");
    const json = await request.json();
    console.log(json, "IP DETAILS");
    if (json.country.toLowerCase() == "us") {
      setCountry("www");
    } else {
      setCountry(json.country.toLowerCase());
    }
  }

  const stringToArray = (string) => {
    return string
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s.length > 0);
  };

  function startLinkedinAutomation() {
    console.log(form.skill);
    console.log(form.location);
    console.log(form.numberOfJobs);
    spinner.current.style.display = "inline-block";
    if (
      configDummy.dailyLimit - configDummy.todaysessioncount >=
        form.numberOfJobs ||
      !userData.planDetails.planStarted
    ) {
      fetchbutton.current.disabled = true;
      setUrlMain(
        `https://${country}.indeed.com/jobs?q=${encodeURIComponent(
          form.skill
        )}&l=${encodeURIComponent(
          form.location.replace(/[^a-zA-Z\s]/g, "")
        )}&iafilter=1`
      );

      mixpanel.identify(userData?.email);
      mixpanel.people.increment(
        "click_to_fetch_filter_button indeed_page dashboard"
      );
      mixpanel.track("click_to_fetch_filter_button indeed_page dashboard", {
        href: window.location.href,
        email: userData?.email,
        skill: form.skill,
        location: form.location,
        numberOfJobs: form.numberOfJobs,
        excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
        mayIncludeKeywords: advanceSearch
          ? stringToArray(mayIncludeString)
          : [],
        plan_details: userData?.planDetails || {},
        v2Id: userData?.planDetails?.v2Id || "",
      });
      if (typeof chrome !== "undefined" && chrome && chrome.runtime)
        chrome.runtime.sendMessage(yourExtensionId, {
          dashboard: true,
          saveValues: {
            excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
            mayIncludeKeywords: advanceSearch
              ? stringToArray(mayIncludeString)
              : [],
            limit: Math.min(form.numberOfJobs, 500),
            jobLinks: [],
            fetchFilters: 0,
            uniquesessionid: uuidv1(),
            resumeJobScore: form.resumeJobScore,
            userProvidedJobTitle: form.skill,
            unappliedJobsDueToScore: 0,
          },
          indeed: true,
          message: "indeedFetchFilters",
          data: {
            url: `https://${country}.indeed.com/jobs?q=${encodeURIComponent(
              form.skill
            )}&l=${encodeURIComponent(
              form.location.replace(/[^a-zA-Z\s]/g, "")
            )}&iafilter=1`,
            indeed: true,
            message: "tabid",
          },
        });
    } else {
      spinner.current.style.display = "none";
      // alert("rgrt");
      if (configDummy.dailyLimit - configDummy.todaysessioncount == 0) {
        setmessage("You have used your daily limit! Come back tomorrow :)");
        settitle("Daily limit exceeded!");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 2500);
        setTimeout(() => {
          history.push("/dashboard");
        }, 3000);
      } else {
        setmessage(
          `You have applied to ${
            configDummy.todaysessioncount
          } jobs today. Please enter a value less than ${
            configDummy.dailyLimit - configDummy.todaysessioncount
          }`
        );
        settitle("Daily limit update!");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 2500);
      }
    }
  }

  function indeedReset() {
    console.log("Reset");
    if (typeof chrome !== "undefined" && chrome && chrome.runtime)
      chrome.runtime.sendMessage(yourExtensionId, {
        dashboard: true,
        indeed: true,
        message: "resetindeed",
      });
  }

  function getUnappliedJobsDueToResumeScore() {
    console.log("Unapplied jobs due to resume score");

    if (typeof chrome !== "undefined" && chrome && chrome.runtime)
      chrome.runtime.sendMessage(
        yourExtensionId,
        {
          dashboard: true,
          indeed: true,
          message: "totalUnappliedJobsDueToResumeScore",
        },
        function (response) {
          if (chrome.runtime.lastError) {
            console.error(chrome.runtime.lastError);
          } else {
            console.log("Received response:", response);
          }
        }
      );
  }

  function filterReset() {
    urlParts = {};
    for (let ref of filterRefs) {
      if (ref.current && ref.current.select) {
        ref.current.select.clearValue();
      }
    }
    filterValues.current = {};
    setForm({
      ...form,
    });
  }

  function handleSubmit1(e) {
    console.log("submit,, -1  ");
    e.preventDefault();
    console.log(form1);

    // let count = 0;
    // Object.keys(form1).forEach((key) => {
    //   if (form1[key] != "") {
    //     count++;
    //   }
    // });
    if (count > 1) {
      //show error
      // para.current.style.display = "block";
    } else {
      //para.current.style.display = "none";
      finalSubmit();
    }
  }

  const closeExtensionInstallPopup = () => {
    setShowExtensionInstallPopup(false);
  };

  const onClickWatchDemo = () => {
    setShowExtensionInstallPopup(false);
    setShowDemoPopup(true);
  };

  let chromeExtensionPresent = async () => {
    let extensionPresentMain = 0;
    if (typeof chrome !== "undefined" && chrome && chrome.runtime) {
      console.log("check1");
      extensionPresentMain = await new Promise((resolve, reject) => {
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            lazyapply: "lazyapply",
            message: "check",
            injected: scriptInjectedGlobal,
          },
          function (response) {
            // Do whatever you want, background script is ready now
            var lastError = chrome.runtime.lastError;
            if (lastError) {
              console.log("error", lastError.message);

              // 'Could not establish connection. Receiving end does not exist.'
              resolve(0);
            }
            console.log("check0", response);
            if ("status" in response && response.status == "installed") {
              setScriptInjectedGlobal(true);
              setExtensionPresent(true);
              console.log("check2");

              resolve(1);
            }
          }
        );
      });
      console.log("extensionpresent", extensionPresentMain);
      return extensionPresentMain;
    } else {
      extensionPresentMain = 0;
      return extensionPresentMain;
    }
  };

  const fetchFilterUserHandler = async () => {
    if (userData.planDetails.planStarted) {
      const response = await GrantAccess("linkedin");
      if (!response.access) {
        if (response.resultObj.extensionNotPresent) {
          setShowExtensionInstallPopup(true);
        } else if (response.resultObj.updateExtension) {
          setShowUpdateExtension(true);
          setShowExtensionInstallPopup(true);
        } else {
          setmessage(response.resultObj.message);
          settitle(response.resultObj.title);
          setmodalshow(true);
          if (response?.resultObj?.gotolinknew) {
            window.open(response.resultObj.gotolinknew, "_blank");
          }
          if (response?.resultObj?.gotolink) {
            history.push(response.resultObj.gotolink);
          }
        }
        return 0;
      } else {
        return 1;
      }
    } else {
      if (resumeId == "") setResumeId("demo_id_lazyapply");
      const extensionPresent = await chromeExtensionPresent();
      console.log("heree111", extensionPresent);
      if (!extensionPresent) {
        setShowExtensionInstallPopup(true);
        return 0;
      } else if (
        chromeVersion != availableChromeVersion &&
        chromeVersion != -1 &&
        availableChromeVersion != -1
      ) {
        setShowExtensionInstallPopup(true);
        setShowUpdateExtension(true);
        return 0;
      } else {
        return 1;
      }
    }
  };

  function finalSubmit() {
    console.log("final submit");
    setstartApplying(true);
    setIndeedRunning(1);
    setResumeDisable(true);
    saveSessionState.current = 0;
    formRef.current = { ...form, ...form1 };
    button2.current.style.backgroundColor = "grey";
    button2.current.style.color = "black";
    button2.current.innerText = "Process Started!";
    console.log("final submit");
    console.log("Filters selected according to which  now everything will run");
    console.log("Run the main process again");
    let url = urlMain;
    console.log(url);
    initprocessing.current.style.display = "flex";
    smoothScrollToBottom();
    sessionId.current = uuidv1();
    console.log("current sessionId", sessionId.current);

    mixpanel.identify(userData?.email);
    mixpanel.people.increment("start_applying_button indeed_page dashboard");
    mixpanel.track("start_applying_button indeed_page dashboard", {
      href: window.location.href,
      email: userData?.email,
      skill: form.skill,
      location: form.location,
      numberOfJobs: form.numberOfJobs,
      excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
      mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
      formData: form1,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });

    if (typeof chrome !== "undefined" && chrome && chrome.runtime)
      chrome.runtime.sendMessage(yourExtensionId, {
        dashboard: true,
        saveValues: {
          indeedSessionId: sessionId.current,
          excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
          mayIncludeKeywords: advanceSearch
            ? stringToArray(mayIncludeString)
            : [],
          baseURL: url,
          indeedResumeId: resumeId,
          session: {
            platformName: "indeed",
            formData: formRef.current,
          },
          resumeData: resumeData.current,
          filters: form1,
          indeedV2FilterSet: 0,
          indeedSetFilters: urlParts,
        },
        indeed: true,
        message: "indeedStartApplying",
        data: {
          message: "setBaseUrl",
          url: url,
        },
      });
  }

  function resetFull() {
    mixpanel.identify(userData.email);
    mixpanel.people.increment("reset_button linkedin_page dashboard");
    mixpanel.track("reset_button dashboard", {
      href: window.location.href,
      email: userData?.email,
      platformName: "indeed",
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    if (saveSessionState.current == 0) {
      saveSession(count.current, "indeed");
    }
    resumeData.current = {};
    window.wasRun = false;
    setShowPlanPurchase(false);
    setResumeId("");
    setResumeDisable(false);
    if (selectResumeRef.current)
      selectResumeRef.current.style.display = "block";
    sessionLinks.current = [];
    sessionLinksBefore.current = [];
    setIndeedRunning(0);
    setForm({
      jobtype: "",
      experience: "",
      skill: "",
      location: "",
      numberOfJobs: 0,
      resumeJobScore: 40,
    });
    setAdvanceSearch(false);
    setMayIncludeString("");
    setExcludeString("");
    setSelectedSearchData({
      searchId: "",
      searchName: "",
    });
    setSearchInputValue({
      value: "",
      label: "Select a saved search setting",
    });
    filterReset();
    formRef.current = {};
    fetchbutton.current.disabled = false;
    document.getElementById("inputskill").value = "";
    document.getElementById("inputlocation").value = "";
    document.getElementById("inputnumber").value = "";
    document.getElementById("sliderResumeJobScore").value = 40;
    formMain1.current.reset();
    indeedReset();
    setpp(15);
    setc(0);
    count.current = 0;
    progressPercent.current = 15;
    console.log("resetFull");
    para.current.style.display = "none";
    chooseParaText.current.style.display = "none";
    formMain1.current.style.display = "none";
    button1.current.style.display = "block";
    setShowSavedSearchButton(true);
    setformDisable(false);
    button2.current.style.backgroundColor = "#F8F9FA";
    button2.current.style.color = "#007bff";
    button2.current.innerText = "Start Applying";
    initprocessing.current.style.display = "none";
    initprocessingFinal.current.style.display = "none";
    setstartApplying(false);
  }

  const messageFn = useCallback((event) => {
    console.log("indeed", event.data);
    if (
      typeof event.data === "object" &&
      !Array.isArray(event.data) &&
      event.data !== null
    ) {
      h(event.data);
      event.source.postMessage("finished", event.origin);
    }
  }, []);

  const checkIfPresent = () => {
    if (selectedSearchData.searchName != "") {
      return true;
    } else {
      return false;
    }
  };

  const hideSavedSearchModal = () => {
    setShowSavedSearchModal(false);
  };

  const chromeVersion = useStateStore((state) => state.chromeVersion);

  function h(request) {
    if (!("linkedin" in request)) {
      if ("message" in request) {
        if (request.message == "Applied successfully") {
          console.log(request.link);
          sessionLinks.current.push(request.link);
          // count increment here
          //let countlinkedin = count;
          //countlinkedin++;
          // setCount(countlinkedin);
          ++count.current;
          console.log(count.current);
          setc(count.current);
          progressPercent.current = Math.floor(
            (count.current / formRef.current.numberOfJobs) * 100
          );
          setpp(progressPercent.current);
          //setProgressPercent((count.current / form.numberOfJobs) * 100);
          console.log("update count");
        } else if (request.message == "Already Applied Before") {
          sessionLinksBefore.current.push(request.link);
        } else if (request.message == "filterValues") {
          console.log(request.filterValues);
          if (Object.keys(request.filterValues).length == 0) {
            fetchbutton.current.disabled = false;
            spinner.current.style.display = "none";
            setIndeedRunning(0);
            // console.log("Try again for better input details");
            if (request.message2 == "signInRequired") {
              setmessage(
                "You're logged out of Indeed. Try again after logging in!"
              );
            } else {
              setmessage("Try again with better input details!");
            }
            settitle("No results found");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
              if (request.message2 == "signInRequired") {
                // window.open("https://secure.indeed.com/auth", "_blank");
                window.location.reload();
              }
            }, 2500);
            resetFull();

            //reset here
          } else {
            filterValues.current = request.filterValues;
            console.log(request.filterValues);
            setformDisable(true);
            console.log("allfilters", request.filterValues);
            chooseParaText.current.style.display = "block";
            spinner.current.style.display = "none";
            formMain1.current.style.display = "block";
            button1.current.style.display = "none";
            setShowSavedSearchButton(false);
            if (filtersDiv.current)
              setheightMain(heightMain + filtersDiv.current.clientHeight + 300);
            if (typeof chrome !== "undefined" && chrome && chrome.runtime)
              chrome.runtime.sendMessage(yourExtensionId, {
                dashboard: true,
                saveValues: {
                  fetchFilters: 1,
                },
                indeed: true,
                message: "indeedFiltersFetched",
              });

            // Call the function
            smoothScrollToBottom();
          }
        } else if (request.message == "debugSession") {
          fetchbutton.current.disabled = false;
          spinner.current.style.display = "none";
          setIndeedRunning(0);
          setmessage(
            "We have detected some issue in LazyApply. Our team has been notified and we will fix this issue within next 1 hour. You have to do nothing in your part, this will be automatically fixed. \n\nThank you for being a wonderful customer."
          );
          settitle("Some problem occured");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 20000);
          resetFull();
        } else if (request.message2 == "planRequired") {
          setShowPlanPurchase(true);
          setIndeedRunning(0);
          button2.current.innerText = "Completed";
          processing.current.style.display = "none";
          smoothScrollToTop();
          initprocessingFinal.current.style.display = "flex";
          initprocessing.current.style.display = "none";
          if (selectResumeRef.current)
            selectResumeRef.current.style.display = "none";
          indeedReset();
        } else if (request.message == "completed") {
          //++count.current;
          // console.log(count.current);
          // setc(count.current);
          if (typeof chrome !== "undefined" && chrome && chrome.runtime)
            chrome.runtime.sendMessage(yourExtensionId, {
              dashboard: true,
              indeed: true,
              message: "resetUA",
            });
          setIndeedRunning(0);
          console.log("completed");
          if (selectResumeRef.current)
            selectResumeRef.current.style.display = "none";
          button2.current.innerText = "Completed";
          processing.current.style.display = "none";
          // setProgressPercent(100);
          progressPercent.current = 100;
          setpp(progressPercent.current);
          smoothScrollToTop();

          const v2Id = userData?.planDetails?.v2Id;
          axios
            .post(
              `https://backend.lazyapply.com/v2/jobCountInLastSession/${v2Id}`,
              { sessionId: sessionId.current },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((response) => {
              console.log("first response", response.data);
              setFinalJobCount(response.data.jobCount || 0);
              initprocessingFinal.current.style.display = "flex";
              initprocessing.current.style.display = "none";
            })
            .catch((err) => {
              console.log("error in request", err);
            });

          if (!window.wasRun) {
            messages()[MESSAGES.ZEROJOBSAPPLIED](
              MESSAGETYPE.USER_REPORT_FRONTEND_INDEED,
              MESSAGES.TOTALJOBSAPPLIED,
              count.current,
              JSON.stringify(formRef.current),
              `${userData.email},total jobs applied this session ${count.current},${userData.name},${userData.planDetails.planName}`,
              chromeVersion
            );
            saveSession(count.current, "indeed");
            window.wasRun = true;
          }
        } else if (request.message == "unauthorized") {
          fetchbutton.current.disabled = false;
          setIndeedRunning(0);
          console.log("reauthenticate");
          setmessage("Please relogin again!");
          settitle("Not authorized");
          setmodalshow(true);
          setTimeout(() => {
            globalLogout();
            history.push("/");
          }, 1500);
          resetFull();
        }
      }
    }
  }

  function saveSession(count, platformName) {
    console.log(formRef.current);
    console.log(saveSessionState.current, "savesessionstate0");
    if (
      (count > 0 && saveSessionState.current == 0) ||
      (count == 0 &&
        saveSessionState.current == 0 &&
        sessionLinksBefore.current.length > 0)
    ) {
      getUnappliedJobsDueToResumeScore();
      console.log(saveSessionState.current, "savesessionstate1");
      let todaysessioncount = configDummy.todaysessioncount + count;
      let totalJobs = configDummy.totalJobs + count;
      let indeedJobsApplied = configDummy.jobsByPlatform["indeed"] + count;
      let totalsessions = configDummy.totalsessions + 1;
      let totalsessionstoday = configDummy.totalsessionstoday + 1;
      // totalCountUpdate({
      //   indeedJobsApplied: indeedJobsApplied,
      //   todaysessioncount: todaysessioncount,
      //   totalJobs: totalJobs,
      //   totalsessions: totalsessions,
      //   totalsessionstoday: totalsessionstoday,
      // });
      let indeedJobApplicationLinksFinal = [...sessionLinks.current];
      if ("indeedJobApplicationLinksFinal" in userData) {
        indeedJobApplicationLinksFinal = [
          ...userData.indeedJobApplicationLinksFinal,
          ...indeedJobApplicationLinksFinal,
        ];
      }
      let totalsession = configDummy.totalsession;
      // setStreakAnalytics({
      //   ...streakAnalytics,
      //   indeed: streakAnalytics.indeed + count,
      // });
      configDummyUpdate({
        ...configDummy,
        jobsByPlatform: {
          ...configDummy.jobsByPlatform,
          indeed: indeedJobsApplied,
        },
        todaysessioncount: todaysessioncount,
        totalJobs: totalJobs,
        totalsessions: totalsessions,
        totalsessionstoday: totalsessionstoday,
        totalsession: [
          ...totalsession,
          {
            resumeData: resumeData.current,
            sessionLinks: sessionLinks.current,
            sessionLinksBefore: sessionLinksBefore.current,
            platformName: "indeed",
            form: formRef.current,
            count: count,
            email: userData.email,
          },
        ],
      });
      userLinksUpdate({
        indeedJobApplicationLinksFinal: indeedJobApplicationLinksFinal,
      });
      // setSaveSession(1);
      saveSessionState.current = 1;
      const v2Id = userData?.planDetails?.v2Id;
      const url = v2Id
        ? `https://backend.lazyapply.com/v2/saveSession/${v2Id}`
        : "http://backend.lazyapply.com/savesessionv2";

      axios
        .post(
          url,
          {
            sessionLinks: sessionLinks.current,
            sessionLinksBefore: sessionLinksBefore.current,
            resumeData: resumeData.current,
            session: {
              count: count,
              platformName: platformName,
              form: formRef.current,
            },
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          indeedReset();
          console.log("configDummy final", configDummy);
          console.log(response);
          setmessage("Process completed!");
          settitle("Success");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2500);
        })
        .catch((error) => {
          indeedReset();

          console.log("configDummy final", configDummy);
          console.log(error);
          if (error.message == "Request failed with status code 403") {
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
              history.push("/");
            }, 1500);
          } else {
            console.log("error");
            setmessage("Some error occured, please try again in some time!");
            settitle("Error");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2500);
          }
        });
    } else {
      getUnappliedJobsDueToResumeScore();
      saveSessionState.current = 1;
      // setSaveSession(1);
      indeedReset();
    }
  }

  const [resumefetch, setresumefetch] = useState(false);
  const fetchAllResumes = (v2Id) => {
    axios
      .get(
        `https://backend.lazyapply.com/v2/resumesV3/checkResumesValidation/${v2Id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setresumefetch(true);
        setUserResumeV3(response.data.resumes);
      })
      .catch((err) => {
        setresumefetch(true);
        console.log("some error occured");
      });
  };

  useEffect(() => {
    const v2Id = userData?.planDetails?.v2Id;
    if (v2Id) fetchAllResumes(v2Id);
  }, [userData]);

  useEffect(() => {
    if (advanceSearch) {
      setShowAdvanceSearch(true);
    }
  }, [advanceSearch]);

  useEffect(() => {
    if (form.skill != "" && form.numberOfJobs > 0 && resumeId != "") {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
  }, [form, resumeId]);

  useEffect(() => {
    console.log(
      "selected search data --> do processing here",
      selectedSearchData
    );
    if (selectedSearchData.form) {
      setForm((formData) => {
        let newFormData = {
          ...formData,
          numberOfJobs: selectedSearchData.form.numberOfJobs,
          skill: selectedSearchData.form.skill,
          location: selectedSearchData.form.location,
        };
        return newFormData;
      });
    } else {
      setForm((formData) => {
        let newFormData = {
          ...formData,
          numberOfJobs: 0,
          skill: "",
          location: "",
        };
        return newFormData;
      });
    }
    if (selectedSearchData.advanceSearch) {
      setAdvanceSearch(selectedSearchData.advanceSearch.advanceSearch);
      setExcludeString(selectedSearchData.advanceSearch.excludeString);
      setMayIncludeString(selectedSearchData.advanceSearch.mayIncludeString);
    } else {
      setAdvanceSearch(false);
      setExcludeString("");
      setMayIncludeString("");
    }
    if (selectedSearchData.extraConfig) {
      if (selectedSearchData.extraConfig.resumeId) {
        const found = userResumeV3.filter((key) => {
          return key.resumeId == selectedSearchData.extraConfig.resumeId;
        });
        if (found.length > 0) {
          userResumeV3.map((key) => {
            const item = key;
            if (item.resumeId === selectedSearchData.extraConfig.resumeId) {
              resumeData.current = {
                resumeId: item.resumeId,
                resumeName: item.resumename,
              };
            }
          });
          setResumeId(selectedSearchData.extraConfig.resumeId);
        } else {
          setResumeId("");
        }
      }
      if (selectedSearchData.extraConfig.countryoption) {
        setCountryOption(selectedSearchData.extraConfig.countryoption);
      }
    } else {
      setResumeId("");
    }
  }, [selectedSearchData]);

  useEffect(() => {
    if (countryoption != "") {
      setCountry(countryoption);
    } else {
      setCountryOption(country);
      console.log("countryoption is empty");
    }
  }, [countryoption, country]);

  useEffect(() => {
    console.log("form1....", form1);
    Object.keys(form1).length > 1 ? setOpenHelper(true) : setOpenHelper(false);
  }, [form1]);

  useEffect(() => {
    console.log("added");
    window.addEventListener("message", messageFn);
    return () => {
      console.log("removed");
      window.removeEventListener("message", messageFn);
    };
  }, []);

  useEffect(() => {
    console.log("Sending initial request to extension");
    const usermain = JSON.parse(window.localStorage.getItem("user") || "{}");
    const tokenmain = window.localStorage.getItem("token");
    if (
      tokenmain != "" &&
      usermain != undefined &&
      Object.keys(usermain).length > 0
    ) {
      if (typeof chrome !== "undefined" && chrome && chrome.runtime)
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            dashboard: true,
            saveValues: {
              token: tokenmain,
              user: usermain,
            },
            injected: scriptInjectedGlobal,
            global: true,
            message: "loginToken",
          },
          (response) => {
            console.log(
              "response from extension",
              response,
              chrome.runtime.lastError
            );
            var lastError = chrome.runtime?.lastError;
            if (lastError) {
              setScriptInjectedGlobal(false);
              setExtensionPresent(false);
              history.push("/dashboard");
              return;
            } else {
              console.log("response of initial request", response);
              setScriptInjectedGlobal(true);
              setGlobalTabId(response.globalTabId);
            }
          }
        );
    }
  }, []);

  useEffect(() => {
    findIP();
    // const checking = localStorage.getItem("stepcheckindeed");
    // if (checking && checking == 'true' ) {
    //   setChecks(true);
    //   console.info('checking1',checking)
    // }
    mixpanel.identify(userData.email);
    mixpanel.people.increment("indeed_page dashboard");
    mixpanel.track("indeed_page dashboard", {
      href: window.location.href,
      email: userData?.email,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    console.log("todays total count", configDummy);
    spinner.current.style.display = "none";
    para.current.style.display = "none";
    initprocessing.current.style.display = "none";
    chooseParaText.current.style.display = "none";
    formMain1.current.style.display = "none";
    indeedReset();
  }, []);

  useEffect(() => {
    console.log("ppcurrent", progressPercent.current);
    if (progressPercent.current === 100) {
      formMain.current.style.display = "none";
      formMain1.current.style.display = "none";
      button2.current.style.display = "none";
      chooseParaText.current.style.display = "none";
    } else {
      formMain.current.style.display = "block";
      button2.current.style.display = "block";
    }
  }, [progressPercent.current]);
  // const [checks, setChecks] = useState(false);

  const isFirstRender = useRef(true);

  useEffect(() => {
    questionRefs.current.forEach((question) => {
      const clickHandler = (event) => {
        const active = document.querySelector(".question.active");
        if (active && active !== question) {
          active.classList.toggle("active");
          active.nextElementSibling.style.maxHeight = 0;
        }
        question.classList.toggle("active");
        const answer = question.nextElementSibling;
        if (question.classList.contains("active")) {
          answer.style.maxHeight = answer.scrollHeight + "px";
        } else {
          answer.style.maxHeight = 0;
        }
      };

      if (question) {
        question.addEventListener("click", clickHandler);
      }

      return () => {
        if (question) question.removeEventListener("click", clickHandler);
      };
    });
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (count.current == 0) {
      smoothScrollToBottom();
    }
  }, [progressPercent.current]);

  const demoSubmit = () => {
    mixpanel.track("start_auto_apply_button dashboard", {
      href: window.location.href,
      email: userData?.email,
      platformName: "indeed",
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    setTimeout(() => {
      history.push("/pricing");
    }, 200);
  };

  const showFilters = () => {
    filterValues.current = mainFilters.data.indeed;
    chooseParaText.current.style.display = "block";
    spinner.current.style.display = "none";
    formMain1.current.style.display = "block";
    button1.current.style.display = "none";
    setShowSavedSearchButton(false);
    if (filtersDiv.current)
      setheightMain(heightMain + filtersDiv.current.clientHeight + 300);
    smoothScrollToBottom();
  };

  const checkIfDemoAndNoPlan = () => {
    return !userData.planDetails.planStarted && showDemo;
  };

  const showDemo = useStateStore((state) => state.showDemo);
  useEffect(() => {
    const fn = async () => {
      const result = await GrantAccess("indeed");
      console.log("grantAccess", result);
      if (!result.access && !showDemo) {
        history.push("/dashboard");
      } else {
        if (checkIfDemoAndNoPlan()) {
          showFilters();
        }
      }
    };
    fn();
  }, [userData]);

  return (
    <div style={{ position: "relative" }}>
      {/* {!checks && (
        <>
          <button
            className="buttonGoBack btn buttonSetup"
            onClick={() => {
              localStorage.setItem("stepcheckindeed", true);
              setChecks(true);
            }}
          >
            Skip Setup Steps
          </button>
          <button className="buttonGoBack btn" onClick={goBack}>
            <i className="fas fa-arrow-left"></i> Go Back
          </button>
          <div className="stepswithcutmain">
            <p className="stepswithcutmainheading">
              One time setup steps for Indeed
            </p>
            <StepsWithCut
              platform="indeed"
              setComplete={(value) => {
                if (value) {
                  localStorage.setItem("stepcheckindeed", true);
                }
                setChecks(value);
              }}
            />
          </div>
        </>
      )} */}
      <div>
        <MainWatchDemoModal
          platform="Indeed"
          showDemoPopup={showDemoPopup}
          customFn={() => {
            setShowDemoPopup(false);
          }}
        ></MainWatchDemoModal>
        <ExtensionInstallPopup
          title={"Indeed"}
          onClickWatchDemo={onClickWatchDemo}
          s={showExtensionInstallPopup}
          closeFn={closeExtensionInstallPopup}
          showUpdateExtension={showUpdateExtension}
        />
        <MoreFiltersHelp
          openHelper={openHelper}
          setOpenHelper={setOpenHelper}
        />
        <CustomAlert
          message={message}
          s={modalshow}
          title={title}
        ></CustomAlert>
        <p className="startApplying">Start Applying on indeed</p>
        {/* <button
          className="buttonGoBack btn buttonSetup"
          onClick={() => setChecks(false)}
        >
          <i className="fas fa-wrench"></i> One Time Setup Steps
        </button> */}
        <button className="buttonGoBack btn" onClick={goBack}>
          <i className="fas fa-arrow-left"></i> Go Back
        </button>
        {userData?.planDetails?.v2Id && (
          <div className="v2resumeSelect" ref={selectResumeRef}>
            <p className="makesure" style={{ marginBottom: "40px" }}>
              Make sure to login in the indeed account of the person with the
              selected resume and his/her indeed profile should be completed
              before applying
            </p>
            <SaveSearchModal
              platformName="indeed"
              showProp={showSavedSearchModal}
              hideShow={hideSavedSearchModal}
              form={{ ...form }}
              advanceSearch={{
                advanceSearch: advanceSearch,
                excludeString,
                mayIncludeString,
              }}
              extraConfig={{
                resumeId: resumeId,
                countryoption: countryoption,
              }}
              setSelectedSearchData={setSelectedSearchData}
              setSavedSuccessfully={setSavedSuccessfully}
              selectedSearchData={selectedSearchData}
            />
            <GetSavedSearches
              platformName="indeed"
              savedSuccessfully={savedSuccessfully}
              setSelectedSearchData={setSelectedSearchData}
              searchInputValue={searchInputValue}
              setSearchInputValue={setSearchInputValue}
            />
            <Form.Group>
              <Form.Label>Choose a Profile to apply jobs for</Form.Label>
              {Object.keys(userResumeV3).length == 0 ||
              !userResumeV3.some((resume) => resume.validation === 1) ? (
                <>
                  <Form.Control
                    as="select"
                    id="resumeId"
                    value={"demo_id_lazyapply"}
                    onChange={(e) => {
                      if (e.target.value == "create_own_profile") {
                        mixpanel.track(
                          "create_your_own_profile_select dashboard",
                          {
                            href: window.location.href,
                            email: userData?.email,
                            platformName: "indeed",
                            plan_details: userData?.planDetails || {},
                            v2Id: userData?.planDetails?.v2Id || "",
                          }
                        );
                        history.push("/dashboard/editresumesv2");
                      }
                    }}
                  >
                    <option value="demo_id_lazyapply">
                      Demo Profile Selected
                    </option>
                    <option value="create_own_profile">
                      Create Your Own Profile
                    </option>
                  </Form.Control>
                </>
              ) : (
                <>
                  <Form.Control
                    as="select"
                    id="resumeId"
                    disabled={resumeDisable}
                    isInvalid={resumeChanged}
                    value={resumeId}
                    onChange={(e) => {
                      if (e.target.value != "") {
                        setResumeChanged(false);
                      }

                      userResumeV3.map((key) => {
                        const item = key;
                        if (item.resumeId === e.target.value) {
                          resumeData.current = {
                            resumeId: item.resumeId,
                            resumeName: item.resumename,
                          };
                        }
                      });
                      setResumeId(e.target.value);
                    }}
                  >
                    <option value="">Select a resume</option>
                    {userResumeV3.map((key) => {
                      const item = key;
                      if (key.validation == 1)
                        return (
                          <option value={item.resumeId}>
                            {item.resumename}
                          </option>
                        );
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select a resume/cv
                  </Form.Control.Feedback>
                </>
              )}
            </Form.Group>
          </div>
        )}
        <Form onSubmit={handleSubmit} ref={formMain}>
          <Row style={styleform}>
            <Col md={12}>
              <Form.Group>
                <Form.Label htmlFor="country">
                  Choose country (Optional)
                </Form.Label>
                <Form.Control
                  as="select"
                  value={countryoption}
                  id="country"
                  onChange={(e) => setCountryOption(e.target.value)}
                >
                  {Object.keys(country_options).map((v) => {
                    return (
                      <option value={country_options[v].code}>
                        {country_options[v].name}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Text id="passwordHelpBlock" muted>
                  Only countries mentioned in the dropdown are available for
                  indeed.{" "}
                  <a
                    href="https://www.indeed.com/worldwide"
                    target="_blank"
                    style={{ color: "#007bff" }}
                  >
                    More info here.
                  </a>
                </Form.Text>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Label htmlFor="inputskill">
                Enter skill / job title*
              </Form.Label>
              <Form.Control
                disabled={formDisable}
                type="text"
                id="inputskill"
                value={form.skill}
                aria-describedby="passwordHelpBlock"
                onChange={(e) => setField("skill", e.target.value)}
                isInvalid={!!errors.skill}
              />
              <Form.Control.Feedback type="invalid">
                {errors.skill}
              </Form.Control.Feedback>
              <Form.Text id="passwordHelpBlock" muted>
                For example - Enter only one single skill or job title like
                react or data analyst or marketing manager etc
              </Form.Text>
            </Col>
            <Col md={4}>
              <Form.Label htmlFor="inputlocation">
                Enter the job location / city (Optional)
              </Form.Label>
              <Form.Control
                disabled={formDisable}
                type="text"
                value={form.location}
                id="inputlocation"
                aria-describedby="passwordHelpBlock"
                onChange={(e) => setField("location", e.target.value)}
              />
              <Form.Text id="passwordHelpBlock" muted>
                For example - you can enter your job location like - bangalore
                or delhi or any other place etc.
              </Form.Text>
            </Col>
            <Col md={4}>
              <Form.Label htmlFor="inputnumber">
                Number of jobs to apply*
              </Form.Label>
              <Form.Control
                disabled={formDisable}
                type="number"
                id="inputnumber"
                value={form.numberOfJobs}
                aria-describedby="passwordHelpBlock"
                onChange={(e) => setField("numberOfJobs", e.target.value)}
                isInvalid={!!errors.numberOfJobs}
              />
              <Form.Control.Feedback type="invalid">
                {errors.numberOfJobs}
              </Form.Control.Feedback>
              <Form.Text id="passwordHelpBlock" muted>
                For example - 50 or 100 etc.
              </Form.Text>
            </Col>
            <Col md={12}>
              <FeedbackModal
                showFeedbackModal={showFeedbackModal}
                setShowFeedbackModal={setShowFeedbackModal}
              />

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                marginTop={5}
                marginBottom={2}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    // marginTop: "25px",
                    margin: 0,
                  }}
                >
                  Job Match Score
                </p>
                <Button
                  variant="contained"
                  onClick={handleFeedbackModal}
                  style={{
                    fontSize: "1.2rem",
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    padding: "10px 20px",
                    borderRadius: 20,
                    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, 0.2)",
                    textTransform: "none",
                    fontWeight: 600,
                    // marginRight: "10px",
                    // marginTop: "20px",
                    margin: 0,
                  }}
                >
                  Feedback Job Match Score
                </Button>
              </Box>

              <p className="makesure" style={{ marginBottom: "40px" }}>
                Set the minimum match score using the slider below. We'll only
                apply to jobs that meet or exceed this score based on how well
                your resume matches the job description and other job
                parameters.
              </p>

              <Box
                border={1}
                borderColor="rgba(0, 0, 0, 0.12)"
                borderRadius={8}
                bgcolor="steelblue"
                p={3} // Padding of 3 spacing units
                m={2} // Margin of 2 spacing units
                display="flex"
                alignItems="center"
                justifyContent="space-between"

                // sx={{
                //   maxWidth: "90%",
                //   margin: "10px auto",
                //   marginBottom: "50px",
                //   // display: "flex",
                //   // alignItems: "center",

                //   // display: "flex",
                //   // justifyContent: "center",
                // }}
              >
                <Box width="20%" textAlign="center">
                  <Typography
                    variant="h6"
                    style={{ color: "#fff", fontWeight: 600 }}
                    gutterBottom
                  >
                    Job Match Score
                  </Typography>
                  <Typography
                    variant="h3"
                    color="textSecondary"
                    style={{ fontWeight: "bold", color: "papayawhip" }}
                  >
                    {form.resumeJobScore}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{ marginTop: 8, color: "#fff" }}
                  >
                    Will only apply to jobs with a score greater than{" "}
                    {form.resumeJobScore}
                  </Typography>
                </Box>
                <Box width="80%" ml={10} mr={3}>
                  <Slider
                    aria-label="Resume Job Score"
                    value={form.resumeJobScore}
                    onChange={(e) => setField("resumeJobScore", e.target.value)}
                    defaultValue={40}
                    getAriaValueText={resumeJobScoreValues}
                    step={1}
                    id="sliderResumeJobScore"
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => {
                      return "You";
                    }}
                    marks={customResumeJobScores}
                    min={0}
                    max={100}
                    sx={{
                      color: "#79c6fc",
                      height: 14,
                      "& .MuiSlider-thumb": {
                        height: 40,
                        width: 40,
                        backgroundColor: "#fff",
                        "&:hover": {
                          boxShadow: "none",
                        },
                      },
                      "& .MuiSlider-track": {
                        border: "none",
                      },
                      "& .MuiSlider-rail": {
                        opacity: 1.0,
                        backgroundColor: "silver",
                      },
                      "& .MuiSlider-markLabel": {
                        color: "white",
                        fontSize: "1.4rem",
                        fontWeight: 800,
                        marginTop: "8px",
                        maxWidth: "120px",
                        whiteSpace: "normal",
                      },
                      "& .MuiSlider-mark": {
                        backgroundColor: "#fff",
                        height: 14,
                        width: 5,
                      },
                      "& .MuiSlider-valueLabel": {
                        color: "#fff",
                        fontWeight: "800",
                        fontSize: "1.4rem",
                        // backgroundColor: "#2a196e", // Change the background color of the box
                        backgroundColor: "steelblue",
                      },
                    }}
                  />
                </Box>
              </Box>

              {/* <Form.Label htmlFor="resumeJobScore">
                Enter resume score
              </Form.Label>

              <Form.Control
                disabled={formDisable}
                type="text"
                id="resumeJobScore"
                value={form.resumeJobScore}
                aria-describedby="passwordHelpBlock"
                onChange={(e) => setField("resumeJobScore", e.target.value)}
                isInvalid={!!errors.skill}
              />
              <Form.Control.Feedback type="invalid">
                {errors.skill}
              </Form.Control.Feedback>
              <Form.Text id="passwordHelpBlock" muted>
                For example - Enter only one single skill or job title like
                react or data analyst or marketing manager etc
              </Form.Text> */}
            </Col>

            {/* <div className="advanceSearchContainer">
              <div className="advanceSearchContainerLeft">
                Job Title Filters - Advance Search
              </div>
              <div className="advanceSearchContainerRight">
                <div className="exactsearch">
                  <div className="exactsearchchild">
                    <p
                      style={{
                        color: "#007bff",
                        marginBottom: "0px",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        cursor: "pointer",
                      }}
                    >
                      {advanceSearch ? (
                        <span onClick={() => setShowAdvanceSearch(true)}>
                          Configure
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                    <label
                      className={
                        "switch3" + (advanceSearch ? " switch3-checked" : "")
                      }
                    >
                      <input
                        type="checkbox"
                        checked={advanceSearch}
                        onChange={(e) => {
                          setAdvanceSearch(!advanceSearch);
                        }}
                      />
                      <div></div>
                    </label>
                  </div>
                </div>
              </div>
            </div> */}
            <AdvanceSearchModal
              show={showAdvanceSearch}
              excludeString={excludeString}
              setExcludeString={setExcludeString}
              mayIncludeString={mayIncludeString}
              setMayIncludeString={setMayIncludeString}
              handleClose={handleCloseSearch}
            />
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={"/assests/alert.svg"}
                style={{
                  marginLeft: "30px",
                  marginTop: "10px",
                  height: "40px",
                }}
              />
              <p
                className="makesure"
                style={{ marginLeft: "10px", marginTop: "20px" }}
              >
                We are currently fixing the issue you might face on Indeed.
                <br />
                The extension update will be released with the fix between 6 to
                12 hours.
              </p>
            </div> */}

            {/* Testing resume match score  */}

            {/* testing finished resume match score  */}

            <Col md={8} ref={button1}>
              <button
                type="submit"
                className="btn linkedinSubmitButton"
                ref={fetchbutton}
              >
                Click to fetch filters first
                <i className="fas fa-arrow-right"></i>{" "}
                <span
                  className="spinner-border spinner-border-sm"
                  style={{ marginLeft: "5px" }}
                  role="status"
                  aria-hidden="true"
                  ref={spinner}
                ></span>
              </button>
            </Col>
            {showSavedSearchButton && (
              <Col
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {configDummy?.totalJobs && configDummy.totalJobs >= 200 && (
                  <button
                    type="button"
                    style={saveButtonDisabled ? buttonMain : buttonMain1}
                    disabled={saveButtonDisabled}
                    onClick={() => {
                      setShowSavedSearchModal(true);
                    }}
                  >
                    {checkIfPresent()
                      ? "Update Search Configuration"
                      : "Save Search Configuration"}
                  </button>
                )}
              </Col>
            )}
          </Row>
        </Form>
        <p ref={chooseParaText} style={{ marginLeft: "20px", color: "gray" }}>
          Choose any one filter only (optional)
        </p>
        <Form onSubmit={handleSubmit1} ref={formMain1}>
          <Row style={filtersStyle} ref={filtersDiv}>
            {Object.keys(filterValues.current).map((key, index) => {
              return (
                <>
                  <Col md={6}>
                    {index === 1 && (
                      <button
                        className="btn filterReset"
                        type="button"
                        onClick={filterReset}
                      >
                        Reset
                      </button>
                    )}

                    <Form.Label>{key}</Form.Label>
                    <Select
                      id={key}
                      onChange={(option) => {
                        console.log("onchange", option);
                        console.log("filtervalues", filterValues.current);
                        if (option) {
                          urlParts[key] = option.label.replace(/\(.*?\)/, "");
                          console.info("url-parts", urlParts);
                        }
                      }}
                      key={index}
                      name={key}
                      ref={filterRefs[index]}
                      placeholder={key}
                      options={filterValues.current[key]}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Col>
                </>
              );
            })}
          </Row>
          <Row>
            <Col md={12}>
              {checkIfDemoAndNoPlan() ? (
                <button
                  type="button"
                  ref={button2}
                  onClick={() => demoSubmit()}
                  className="btn linkedinSubmitButton"
                >
                  Start Auto Applying{" "}
                  {form.numberOfJobs != 0 ? `To ${form.numberOfJobs} Jobs` : ""}
                  <i className="fas fa-arrow-right"></i>
                </button>
              ) : (
                <button
                  disabled={startApplying}
                  ref={button2}
                  type="submit"
                  className="btn linkedinSubmitButton"
                >
                  Start Applying <i className="fas fa-arrow-right"></i>
                </button>
              )}
            </Col>
          </Row>

          <p ref={para} style={parastyle}>
            Choose only one filter value!
          </p>
        </Form>
        <Row style={styleform1}>
          {progressPercent.current === 100 ? (
            <Col md={5} style={{ marginBottom: "20px" }}>
              <Feedback
                platformName="indeed"
                totalJobsApplied={count.current}
              />
            </Col>
          ) : (
            <Col md={5}></Col>
          )}
          <Col md={7} ref={initprocessingFinal} className="linkedinInit">
            <button
              type="button"
              className="linkedinReset btn"
              onClick={resetFull}
            >
              Reset
            </button>
            {showPlanPurchase ? (
              <>
                <div className="planPurchaseRequiredMainDiv">
                  <p className="planPurchaseRequired">
                    You don't have a plan, please purchase a plan to continue
                  </p>
                  <button
                    onClick={() => {
                      mixpanel.track("purchase_plan_button dashboard", {
                        href: window.location.href,
                        email: userData?.email,
                        platformName: "indeed",
                        plan_details: userData?.planDetails || {},
                        v2Id: userData?.planDetails?.v2Id || "",
                      });
                      setTimeout(() => {
                        history.push("/pricing");
                      }, 500);
                    }}
                    className="button1"
                  >
                    Purchase Plan
                  </button>
                </div>
              </>
            ) : (
              <div className="linkedinInitTop">
                <div>Percentage complete ({100} %)</div>
                <div>Total jobs applied ({finalJobCount})</div>
              </div>
            )}
            {showPlanPurchase ? (
              <></>
            ) : (
              <div className="linkedinInitTop">
                <div>
                  <Progress done={100} />
                </div>
                <div className="jobsApplied">{finalJobCount}</div>
              </div>
            )}
          </Col>
          <Col md={7} ref={initprocessing} className="linkedinInit">
            <button
              type="button"
              className="linkedinReset btn"
              onClick={resetFull}
            >
              Reset
            </button>
            <div className="linkedinInitTop1">
              <div ref={processing} className="processing">
                Automation in Process...
              </div>
            </div>
            <div className="linkedinInitTop1">
              <div>
                Please do not switch tabs till this session's completion.
              </div>
            </div>
          </Col>
        </Row>
        {/* <Row>
          {progressPercent.current == 100 && (
            <>
              <div class="col-10 wrapper mx-auto">
                <h3 class="feedbackform_header issuesHeader">Common Issues</h3>

                {(() => {
                  let allIssues = [
                    ...faqObj.indeed.issues,
                    ...faqObj.common.issues,
                  ];
                  return allIssues.map((x, index) => (
                    <div className="container" key={index}>
                      <div
                        className="question"
                        ref={(el) => (questionRefs.current[index] = el)}
                      >
                        {x.question}
                      </div>
                      <div class="answercont">
                        <div class="answer">
                          {x.answer}
                          {x.answerPoints && (
                            <div className="answerPointsDiv">
                              {x.answerPoints.map((point, index) => (
                                <p>{index + "). " + point}</p>
                              ))}
                            </div>
                          )}

                          {x.question(
                            <a
                              href="#"
                              className="text-primary"
                              style={{
                                marginLeft: "0px",
                                marginTop: "20px",
                                marginBottom: "10px",
                                display: "inline-block",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setModalTitle(x.question);
                                setShowIndeedModal(true);
                              }}
                            >
                              {x.anchorText}
                            </a>
                          )}
                          {x.videoId && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "20px",
                                marginBottom: "20px",
                              }}
                            >
                              <iframe
                                className="myVideoClass"
                                loop="true"
                                autoPlay="true"
                                controls="true"
                                id="vid"
                                src={`https://www.youtube.com/embed/${x.videoId}?autoplay=1&loop=1&vq=hd1080`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ));
                })()}
              </div>
              <div class="col-12" style={{ height: "100px" }}>
                {" "}
              </div>
              <IndeedArticle
                showProp={showIndeedModal}
                hideShow={closeModal}
                title={modalTitle}
              />
            </>
          )}
        </Row> */}
      </div>
    </div>
  );
}

export default Indeed;
