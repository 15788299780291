// import { useStateStore } from "../../store/index";

export const MESSAGES = {
  NOFILTERSFETCHED: "NO FILTERS FETCHED",
  ZEROJOBSAPPLIED: "ZERO JOBS APPLIED",
  TOTALJOBSAPPLIED: "TOTAL JOBS APPLIED",
};

export const MESSAGETYPE = {
  USER_REPORT_FRONTEND: "USER_REPORT_FRONTEND",
  USER_REPORT_FRONTEND_TEST: "USER_REPORT_FRONTEND_TEST",
  USER_REPORT_FRONTEND_LINKEDIN: "USER_REPORT_FRONTEND_LINKEDIN",
  USER_REPORT_FRONTEND_GLASSDOOR: "USER_REPORT_FRONTEND_GLASSDOOR",
  USER_REPORT_FRONTEND_DICE: "USER_REPORT_FRONTEND_DICE",
  USER_REPORT_FRONTEND_ZIPRECRUITER: "USER_REPORT_FRONTEND_ZIPRECRUITER",
  USER_REPORT_FRONTEND_INDEED: "USER_REPORT_FRONTEND_INDEED",
  USER_REPORT_FRONTEND_SEEK: "USER_REPORT_FRONTEND_SEEK",
  USER_REPORT_FRONTEND_CAREERBUILDER: "USER_REPORT_FRONTEND_CAREERBUILDER",
  USER_REPORT_FRONTEND_MONSTER: "USER_REPORT_FRONTEND_MONSTER",
  USER_REPORT_FRONTEND_FOUNDIT: "USER_REPORT_FRONTEND_FOUNDIT",
  USER_REPORT_FRONTEND_SIMPLYHIRED: "USER_REPORT_FRONTEND_SIMPLYHIRED",
};

export const messages = () => {
  console.log("call0");
  return {
    [MESSAGES.NOFILTERSFETCHED]: (messageType, name, data) => {
      console.log("call1", data);
      window.analytics.track(
        messageType,
        {
          label: name,
          data: data.replaceAll('"', "“"),
        },
        {},
        (eventData) => {
          console.log(
            "success call analytics",
            name,
            messageType,
            eventData.properties.data,
            data
          );
        }
      );
    },
    [MESSAGES.ZEROJOBSAPPLIED]: (
      messageType,
      name,
      count,
      filters,
      data,
      version
    ) => {
      console.log("call1", data);
      window.analytics.track(
        messageType,
        {
          label: name,
          data: data.replaceAll('"', ""),
          filters: filters,
          count: count,
          version: version,
        },
        {},
        (eventData) => {
          console.log(
            "success call analytics",
            name,
            messageType,
            eventData.properties.data,
            data
          );
        }
      );
    },
  };
};
