import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import "./ReferralFilters.css";
import mainCompanies from "./data/companies.json";
import { mixpanel } from "../../index";
import { useStateStore } from "../../store";

function ReferralFilters({
  findButtonSubmit,
  country,
  selected,
  setSelected,
  setCountry,
  companyQuery,
  setCompanyQuery,
  employees,
  setEmployees,
  revenue,
  setRevenue,
  showFilters,
  setShowFilters,
  resetFilters,
}) {
  const userData = useStateStore((state) => state.userData);
  const optionsRevenue = mainCompanies.revenue;
  const optionsEmployees = mainCompanies.employees;
  const optionsCountry = mainCompanies.countries;

  return (
    <div className="referral-filters-main-block">
      <div className="referral-filters-search-box">
        <div className="referral-fitlers-seach-box-block">
          <Form.Control
            required
            type="text"
            id="input_companies"
            value={companyQuery}
            placeholder="Find Companies By Name"
            onChange={(e) => setCompanyQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                mixpanel.identify(userData.email);
                mixpanel.people.increment(
                  "find_button secret_network_page dashboard"
                );
                mixpanel.track("find_button secret_network_page dashboard", {
                  email: userData?.email,
                  query: e?.target?.value,
                  plan_details: userData?.planDetails || {},
                  v2Id: userData?.planDetails?.v2Id || "",
                });
                //setSelected(false);
                findButtonSubmit();
              }
            }}
          />
        </div>
        <div className="referral-fitlers-search-box-button-block">
          <button
            onClick={() => {
              mixpanel.identify(userData.email);
              mixpanel.people.increment(
                "find_button secret_network_page dashboard"
              );
              mixpanel.track("find_button secret_network_page dashboard", {
                email: userData?.email,
                query: companyQuery,
                plan_details: userData?.planDetails || {},
                v2Id: userData?.planDetails?.v2Id || "",
              });

              findButtonSubmit();
              //setSelected(false);
            }}
          >
            <i class="fa fa-search"></i> Find
          </button>
        </div>
      </div>
      <div className="referral-filters-select">
        {showFilters && (
          <div className="referral-fitlers-select-input">
            <button
              className={
                "referral-filters-most-popular" +
                (selected ? " referral-filters-most-popular_selected" : "")
              }
              onClick={() => {
                mixpanel.identify(userData.email);
                mixpanel.people.increment(
                  "most_popular_button secret_network_page dashboard"
                );
                mixpanel.track(
                  "most_popular_button secret_network_page dashboard",
                  {
                    clicked: !selected,
                    email: userData?.email,
                    plan_details: userData?.planDetails || {},
                    v2Id: userData?.planDetails?.v2Id || "",
                  }
                );
                setSelected(!selected);
              }}
            >
              Most Popular
              <i class="fas fa-bolt ml-2"></i>
            </button>
          </div>
        )}

        {showFilters && (
          <div className="referral-fitlers-select-input">
            <Form.Group controlId="followersQuestion">
              <Form.Control
                className="main_referral_select_companies"
                as="select"
                required={true}
                value={
                  mainCompanies.countries.filter((c) => c.code == country)?.[0]
                    ?.code || ""
                }
                onChange={(e) => {
                  setSelected(false);
                  mixpanel.identify(userData.email);
                  mixpanel.people.increment(
                    "country_select_change secret_network_page dashboard"
                  );
                  mixpanel.track(
                    "country_select_change secret_network_page dashboard",
                    {
                      selected_option:
                        mainCompanies.countries.filter(
                          (c) => c.code == country
                        )?.[0]?.code || "",
                      email: userData?.email,
                      plan_details: userData?.planDetails || {},
                      v2Id: userData?.planDetails?.v2Id || "",
                    }
                  );
                  setCountry(e.target.value);
                }}
              >
                <option value="">Select Country</option>
                {optionsCountry.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        )}
        {showFilters && (
          <div className="referral-fitlers-select-input">
            <Form.Group controlId="followersQuestion">
              <Form.Control
                as="select"
                required={true}
                value={employees}
                onChange={(e) => {
                  setSelected(false);
                  mixpanel.identify(userData.email);
                  mixpanel.people.increment(
                    "employee_select_change secret_network_page dashboard"
                  );
                  mixpanel.track(
                    "employee_select_change secret_network_page dashboard",
                    {
                      selected_option: employees,
                      email: userData?.email,
                      plan_details: userData?.planDetails || {},
                      v2Id: userData?.planDetails?.v2Id || "",
                    }
                  );
                  setEmployees(e.target.value);
                }}
              >
                <option value="">Select Employees Count</option>
                {optionsEmployees.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        )}
        {showFilters && (
          <div className="referral-fitlers-select-input">
            <Form.Group controlId="followersQuestion">
              <Form.Control
                as="select"
                required={true}
                value={revenue}
                onChange={(e) => {
                  setSelected(false);
                  mixpanel.identify(userData.email);
                  mixpanel.people.increment(
                    "revenue_select_change secret_network_page dashboard"
                  );
                  mixpanel.track(
                    "revenue_select_change secret_network_page dashboard",
                    {
                      selected_option: revenue,
                      email: userData?.email,
                      plan_details: userData?.planDetails || {},
                      v2Id: userData?.planDetails?.v2Id || "",
                    }
                  );
                  setRevenue(e.target.value);
                }}
              >
                <option value="">Revenue</option>
                {optionsRevenue.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        )}
        {showFilters && (
          <div className="referral-fitlers-select-input">
            <button
              className="referral-filters-most-popular"
              onClick={() => {
                mixpanel.identify(userData.email);
                mixpanel.people.increment(
                  "reset_button secret_network_page dashboard"
                );
                mixpanel.track("reset_button secret_network_page dashboard", {
                  email: userData?.email,
                  plan_details: userData?.planDetails || {},
                  v2Id: userData?.planDetails?.v2Id || "",
                });
                resetFilters();
              }}
            >
              Reset
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReferralFilters;
