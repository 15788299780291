import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import axios from "axios";
import { mixpanel } from "../../index";
import { useStateStore } from "../../store/index";

function SaveSearchModal({
  showProp,
  hideShow,
  setSelectedSearchData,
  platformName,
  setSavedSuccessfully,
  form,
  advanceSearch,
  selectedSearchData,
  extraConfig,
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    hideShow();
  };
  const [disable, setDisable] = useState(false);
  const userData = useStateStore((state) => state.userData);
  const [value, setValue] = useState("");
  const token = useStateStore((state) => state.token);
  let buttonSave = {
    backgroundColor: "#35AD46",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "10px 20px",
    textAlign: "center",
    borderRadius: "10px",
    width: "100%",
    outline: "none",
    border: "none",
    marginTop: "20px",
  };

  let showr = {
    textAlign: "center",
    fontWeight: "bold",
    color: "black",
    fontSize: "16.5px",
    marginTop: "10px",
  };
  const [showMessage, setShowMessage] = useState("");

  const showCustomMessage = (message) => {
    setShowMessage(message);
    setTimeout(() => {
      setShowMessage("");
    }, 2500);
  };

  const saveSearchCall = () => {
    const update = checkIfPresent();
    setDisable(true);
    const v2Id = userData?.planDetails?.v2Id;
    mixpanel.identify(userData?.email || "");
    mixpanel.people.increment(
      "save_configuration_button save_configuration_popup dashboard"
    );
    mixpanel.track(
      "save_configuration_button save_configuration_popup dashboard",
      {
        email: userData?.email,
        platformName: platformName,
        searchName: value,
        searchId: selectedSearchData.searchId,
        form,
        advanceSearch,
        extraConfig,
        plan_details: userData?.planDetails || {},
        v2Id: userData?.planDetails?.v2Id || "",
      }
    );

    if (value != "") {
      axios
        .post(
          `https://backend.lazyapply.com/v2/savedSearches/${v2Id}`,
          {
            platformName: platformName,
            searchName: value,
            searchId: selectedSearchData.searchId,
            form,
            advanceSearch,
            extraConfig,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          if (update) {
            setDisable(false);
          }
          if (response.data.message == "success") {
            setSavedSuccessfully(true);
            setSelectedSearchData(response.data.searchData[0]);
            showCustomMessage("Saved Successfully");
            setTimeout(() => {
              if (!update) {
                setValue("");
              }
              setSavedSuccessfully(false);
              handleClose();
            }, 1500);
          } else {
            showCustomMessage("Some error occured, please try again!");
          }
        })
        .catch((error) => {
          if (update) {
            setDisable(false);
          }
          showCustomMessage("Some error occured, please try again!");
        });
    } else {
      if (update) {
        setDisable(false);
      }
      showCustomMessage("Please enter a search name");
    }
  };

  function saveSearch(e) {
    e.preventDefault();
    saveSearchCall();
  }

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  useEffect(() => {
    console.log("selectedSearchData", selectedSearchData);
    if (selectedSearchData.searchName != "") {
      setDisable(true);
      console.log("here", selectedSearchData.searchName);
      setValue(selectedSearchData.searchName);
    } else {
      setValue("");
      setDisable(false);
    }
  }, [selectedSearchData]);

  const checkIfPresent = () => {
    if (selectedSearchData.searchName != "") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Save Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ padding: "20px" }}>
            <Form onSubmit={saveSearch}>
              <p style={{}}>Enter a name for this search configuration</p>
              <Form.Control
                required={true}
                type="text"
                id="searchname"
                disabled={disable}
                value={value}
                placeholder="example - Software Developer Search"
                onChange={(e) => setValue(e.target.value)}
              />
              <button style={buttonSave}>
                {checkIfPresent()
                  ? "Update Configuration"
                  : "Save Configuration"}
              </button>
            </Form>
            <p style={showr}>{showMessage}</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SaveSearchModal;
