import React from "react";
import "./privacy.css";
import { useHistory } from "react-router-dom";

function Privacy() {
  const history = useHistory();

  function goBack() {
    console.log("go back");
    history.goBack();
  }

  return (
    <>
      <div className="privacyContainer">
      <button className="buttonGoBack btn" onClick={goBack}>
        <i className="fas fa-arrow-left"></i> Go Back
      </button>
        <div className="privacyHeading">Data Usage & Privacy Policy</div>
        <div className="privacyDescription">
          <div className="privacyDescriptionHeadings"> Introduction:</div>
          <div className="privacyDescriptionData">
            At Lazyapply, we prioritize your privacy and ensure that your data
            is handled with the utmost care. This policy provides detailed
            information about how we utilize and protect the data you entrust us
            with.
          </div>
          <div className="privacyDescriptionHeadings">
            Data Collection & Usage:
          </div>
          <div className="privacyDescriptionData">
            1. Resume Data: We collect and process the details provided in your
            resume. This includes your educational background, work experience,
            skills, and other related information.
          </div>
          <div className="privacyDescriptionData">
            2. Job Details: When you use Lazyapply to search or apply for jobs,
            we process job-related data such as the job title, location, and any
            other details you provide.
          </div>
          <div className="privacyDescriptionHeadings">
            Purpose of Data Processing:
          </div>
          <div className="privacyDescriptionData">
            The primary purpose of processing this data is to create
            personalized emails for HR outreach. By analyzing the details in
            your resume and the specific job you're interested in, our system,
            with the assistance of a third-party AI model, crafts a tailored
            email that increases the likelihood of a positive response from
            potential employers.
          </div>
          <div className="privacyDescriptionHeadings">
            Third-Party AI Model:
          </div>
          <div className="privacyDescriptionData">
            To enhance the efficiency and accuracy of our email generation
            process, we utilize a third-party AI model. This model solely
            processes your resume and job data for the aforementioned purpose
            and doesn't retain any of your information post-processing.
          </div>
          <div className="privacyDescriptionHeadings">
            Data Collection & Usage:
          </div>
          <div className="privacyDescriptionData">
            Your data is precious, and its confidentiality is paramount. We
            assure you that apart from the AI model processing, your data is not
            shared with any external entity. Our collaboration with the
            third-party AI model is strictly limited to generating personalized
            emails, and we have stringent measures in place to prevent any
            unauthorized access or use of your data.
          </div>
          <div className="privacyDescriptionHeadings">
            Your Rights & Control:
          </div>
          <div className="privacyDescriptionData">
            You have complete control over your data. At any point, if you wish
            to update, retrieve, or delete your data from our system, please
            reach out to us, and we will assist you promptly.
          </div>
          <div className="privacyDescriptionHeadings"> Updates & Changes:</div>
          <div className="privacyDescriptionData">
            We may occasionally update this policy to reflect changes in our
            practices or for other operational, legal, or regulatory reasons. We
            recommend revisiting this section periodically to stay informed
            about our latest data handling practices.
          </div>
          <div className="privacyDescriptionData">
            For further queries or concerns about our data usage policy, please
            feel free to{" "}
            <span style={{ color: "#007bff" }}>
              contact us ( info@lazyapply.com +91 8839209610 )
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Privacy;
