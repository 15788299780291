import React, { useEffect } from "react";
import "./faq.css";
import { mixpanel } from "../index";
import { useStateStore } from "../store/index";

function Faq() {
  const userData = useStateStore((state) => state.userData);
  useEffect(() => {
    mixpanel.identify(userData.email);
    mixpanel.people.increment("faq_page dashboard");
    mixpanel.track("faq_page dashboard", {
      email: userData?.email,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
  }, []);

  const faqs = {
    faq: [
      {
        q: "Do I get a free trial?",
        a: "No, we don't offer a free trial. If you wish to have a live demo of the product shoot us an email at info@lazyapply.com and we will be happy to assist you.",
      },
      {
        q: "What is your refund policy?",
        a: "30 DAYS MONEY BACK GUARANTEE We guarantee interview. You can have your money back if you go without interview in 30 days.",
      },
      {
        q: "Do I get free updates?",
        a: "YES! You get 100% free auto-updates and access to all the features that we add. Whenever a new update is out since your extension will be updated automatically without you need to do a thing.",
      },
      {
        q: "Do I get support and tutorials on how to start?",
        a: "Yes, we have full tutorials plus support if you ever have questions or concerns. Our dedicated support team can answer to any questions and guide you when needed.",
      },
      {
        q: "Does LazyApply protect my privacy?",
        a: "At LazyApply we respect and protect your privacy with industry-leading security infrastructure, responsible data practices. You are in safe hands.",
      },
      {
        q: "How will you bill me?",
        a: "We offer lifetime billing.",
      },
      {
        q: "Is there any limit on the number of applications?",
        a: "Yes, we do have limits on no of applications you can make per day depending on your plan. Please visit pricing section for more information.",
      },
    ],
  };

  return (
    <div className="mainfaq">
      <div className="initial">
        <p className="heading">Frequently Asked Questions</p>

        <div style={{ margin: "0 auto" }}>
          {faqs.faq.map(function (value, index) {
            return (
              <>
                <div className="row main1" key={index}>
                  <div className="col-10 align-self-center ">
                    {index + 1}. {value.q}
                    <p className="mobileQ">
                      <i className="fas fa-chevron-right mr-2"></i> {value.a}
                    </p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Faq;
