import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useStateStore } from "../../store";
import ReferralMainBlockModal from "./ReferralMainBlockModal";
import { mixpanel } from "../../index";

function ReferralModal({ s, closeFn }) {
  const setSendEmail = useStateStore((state) => state.setSendEmail);
  const mainModalTitle = useStateStore((state) => state.mainModalTitle);
  const [show, setShow] = useState(false);
  const userData = useStateStore((state) => state.userData);
  const setShowReferralFeedback = useStateStore(
    (state) => state.setShowReferralFeedback
  );
  const stepNoReferralFeedback = useStateStore(
    (state) => state.stepNoReferralFeedback
  );

  const handleClose = () => {
    setShow(false);
    closeFn();
  };

  useEffect(() => {
    setShow(s);
  }, [s]);

  return (
    <Modal
      className="main-referral-modal"
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      backdrop="static"
    >
      {/* <Modal.Header closeButton className="main-referral-modal-header">
        <Modal.Title>{mainModalTitle}</Modal.Title>
      </Modal.Header> */}
      <div
        className="main-referral-modal-header-cross"
        onClick={() => {
          handleClose();
          mixpanel.identify(userData.email);
          mixpanel.people.increment(
            `cross_icon_button secret_network_popup step_${stepNoReferralFeedback} dashboard`
          );
          mixpanel.track(
            `cross_icon_button secret_network_popup step_${stepNoReferralFeedback} dashboard`,
            {
              email: userData?.email,
              plan_details: userData?.planDetails || {},
              v2Id: userData?.planDetails?.v2Id || "",
            }
          );
          setSendEmail(false);
        }}
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </div>

      <button
        onClick={() => {
          setShowReferralFeedback(true);
          mixpanel.identify(userData.email);
          mixpanel.people.increment(
            `feedback_button secret_network_popup step_${stepNoReferralFeedback} dashboard`
          );
          mixpanel.track(
            `feedback_button secret_network_popup step_${stepNoReferralFeedback} dashboard`,
            {
              email: userData?.email,
              plan_details: userData?.planDetails || {},
              v2Id: userData?.planDetails?.v2Id || "",
            }
          );
        }}
        className="main-referral-modal-feedback-open-button"
      >
        Feedback
      </button>

      <Modal.Body className="main-referral-modal-body">
        <ReferralMainBlockModal />
      </Modal.Body>
    </Modal>
  );
}

export default ReferralModal;
