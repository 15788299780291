import React, { useEffect, useState, useRef } from "react";
import { useStateStore } from "../../store";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { v1 as uuidv1 } from "uuid";
import CustomAlert from "../modal";
import { mixpanel } from "../../index";
import FeedbackPopup from "./FeedbackPopup";

function LazyapplyXSessions() {
  const setLazyapplyXSessions = useStateStore(
    (state) => state.setLazyapplyXSessions
  );
  const setShowFeedbackPopup = useStateStore(
    (state) => state.setShowFeedbackPopup
  );
  const showFeedbackPopup = useStateStore((state) => state.showFeedbackPopup);
  const setSettingsClick = useStateStore((state) => state.setSettingsClick);
  const userData = useStateStore((state) => state.userData);
  const globalLogout = useStateStore((state) => state.globalLogout);
  const history = useHistory();
  const loading = useRef(null);
  const lazyapplyXSessions = useStateStore((state) => state.lazyapplyXSessions);
  const lazyapplyXAnalytics = useStateStore(
    (state) => state.lazyapplyXAnalytics
  );
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const setLazyapplyXAnalytics = useStateStore(
    (state) => state.setLazyapplyXAnalytics
  );
  const token = useStateStore((state) => state.token);

  const createDate = (createdAt) => {
    if (createdAt) {
      let time = {
        seconds: createdAt._seconds,
        nanoseconds: createdAt._nanoseconds,
      };
      const fireBaseTime = new Date(
        time.seconds * 1000 + time.nanoseconds / 1000000
      );
      const date = fireBaseTime.toDateString();
      return date;
    } else {
      return "";
    }
  };

  const setLazyapplyXData = useStateStore((state) => state.setLazyapplyXData);
  const setGeneratedSampleEmail = useStateStore(
    (state) => state.setGeneratedSampleEmail
  );
  const getSessions = () => {
    loading.current.style.display = "block";
    axios
      .get(`https://backend.lazyapply.com/targetSearchData`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("data", response.data);
        setLazyapplyXData(response.data);
        if (response.data.sampleEmailGenerated) {
          setGeneratedSampleEmail(response.data.sampleEmailGenerated);
        }

        axios
          .get(`https://backend.lazyapply.com/lazyapplyxSessions`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            loading.current.style.display = "none";
            console.log("dataSessions", response.data);
            setLazyapplyXAnalytics({
              totalsessionslength: response.data?.totalsessionslength || 0,
              companiesCount: response.data?.companiesCount || 0,
            });
            setLazyapplyXSessions(response.data?.totalsessions || []);
          })
          .catch((err) => {
            loading.current.style.display = "none";
            if (err.message == "Request failed with status code 403") {
              console.log("reauthenticate");
              setmessage("Please relogin again!");
              settitle("Not authorized");
              setmodalshow(true);
              setTimeout(() => {
                globalLogout();
                history.push("/");
              }, 1500);
            }
          });
      })
      .catch((err) => {
        loading.current.style.display = "none";
        if (err.message == "Request failed with status code 403") {
          console.log("reauthenticate");
          setmessage("Please relogin again!");
          settitle("Not authorized");
          setmodalshow(true);
          setTimeout(() => {
            globalLogout();
            history.push("/");
          }, 1500);
        }
      });
  };

  useEffect(() => {
    setSettingsClick(false);
    loading.current.style.display = "none";
    mixpanel.identify(userData.email);
    mixpanel.people.increment("Dashboard - LaxyapplyX sessions page visit");
    mixpanel.track("Dashboard - LaxyapplyX sessions page visit", {
      email: userData.email,
    });
    getSessions();
  }, []);

  const [sessionloading, setSessionloading] = useState(false);
  const [loadingAtFirstSessionCreation, setLoadingAtFirstSessionCreation] =
    useState(false);
  const createNewSession = () => {
    mixpanel.identify(userData.email);
    mixpanel.people.increment(
      "Dashboard - LaxyapplyX create new session button click initial"
    );
    mixpanel.track(
      "Dashboard - LaxyapplyX create new session button click initial",
      {
        email: userData.email,
      }
    );
    setLoadingAtFirstSessionCreation(true);
    const uniqueid = uuidv1();
    axios
      .post(
        `https://backend.lazyapply.com/getCompanies`,
        {
          id: uniqueid,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setLoadingAtFirstSessionCreation(true);
        console.log("created session");
        setmessage("Session created succssfully");
        settitle("Successfully created!");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
          history.push(`/dashboard/lazyapply-x/sessions/${uniqueid}`);
        }, 2000);
      })
      .catch((err) => {
        setLoadingAtFirstSessionCreation(true);
        if (err.message == "Request failed with status code 403") {
          console.log("reauthenticate");
          setmessage("Please relogin again!");
          settitle("Not authorized");
          setmodalshow(true);
          setTimeout(() => {
            globalLogout();
            history.push("/");
          }, 1500);
        }
      });
  };

  const emails = [
    "prakhar@lazyapply.com",
    "oauthtest222@gmail.com",
    "oauthtest121@gmail.com",
    "gupta.19@iitj.ac.in",
  ];

  const verifyIfAnySessionsToday = () => {
    setSessionloading(true);
    mixpanel.identify(userData.email);
    mixpanel.people.increment(
      "Dashboard - LaxyapplyX create new session top-button click"
    );
    mixpanel.track(
      "Dashboard - LaxyapplyX create new session top-button click",
      {
        email: userData.email,
      }
    );
    axios
      .get(`https://backend.lazyapply.com/lazyapplyxSessionsToday`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (!response.data.status) {
          // create new session
          mixpanel.identify(userData?.email);
          mixpanel.people.increment(
            "Dashboard - LaxyapplyX new session created"
          );
          mixpanel.track("Dashboard - LaxyapplyX new session created", {
            email: userData?.email,
          });
          const uniqueid = uuidv1();
          const v2Id = userData?.planDetails?.v2Id;
          axios
            .post(`https://backend.lazyapply.com/lazyapplyx/getSessionCount`, {
              v2Id: v2Id,
            })
            .then((response) => {
              console.log("response getsession count", response.data.count);
              if (response.data.count > 0) {
                if (
                  userData?.email &&
                  userData.email != "" &&
                  emails.includes(userData.email)
                ) {
                  setShowFeedbackPopup(false);
                } else {
                  setShowFeedbackPopup(true);
                }
              }
            })
            .catch((err) => {
              console.log("err");
            });
          axios
            .post(
              `https://backend.lazyapply.com/getCompanies`,
              {
                id: uniqueid,
              },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((response) => {
              setSessionloading(false);
              console.log("created session");
              setmessage("Session created succssfully");
              settitle("Successfully created!");
              setmodalshow(true);
              setTimeout(() => {
                setmodalshow(false);
                history.push(`/dashboard/lazyapply-x/sessions/${uniqueid}`);
              }, 2000);
            })
            .catch((err) => {
              setSessionloading(false);
              if (err.message == "Request failed with status code 403") {
                console.log("reauthenticate");
                setmessage("Please relogin again!");
                settitle("Not authorized");
                setmodalshow(true);
                setTimeout(() => {
                  globalLogout();
                  history.push("/");
                }, 1500);
              }
            });
        } else {
          setSessionloading(false);
          setmessage("Session already exist for today.");
          settitle("Session already exist");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
            history.push(
              `/dashboard/lazyapply-x/sessions/${response.data.session[0].uniqueId}`
            );
          }, 2000);

          // Error, session already present
        }
        console.log("response", response.data);
      })
      .catch((err) => {
        setSessionloading(false);
        if (err.message == "Request failed with status code 403") {
          console.log("reauthenticate");
          setmessage("Please relogin again!");
          settitle("Not authorized");
          setmodalshow(true);
          setTimeout(() => {
            globalLogout();
            history.push("/");
          }, 1500);
        }
      });
  };

  const hideShow = () => {
    setShowFeedbackPopup(false);
  };

  return (
    <>
      {" "}
      <FeedbackPopup showProp={showFeedbackPopup} hideShow={hideShow} />
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      <div style={{ position: "relative" }}>
        <p className="editresumeheading">All Email Automation Sessions</p>
        <div className="loading loadingresume" ref={loading}>
          <div className="boxLoading"></div>
        </div>
        <div className="mainnavlazyapplyx">
          <button
            className={
              "mainnavlazyapplyxbutton " +
              (lazyapplyXSessions.length > 0 ? "" : "gearButtonShift")
            }
            onClick={() => {
              setSettingsClick(true);
              history.push("/dashboard/lazyapply-x");
            }}
          >
            Settings <i class="fa fa-cog gearbuttonnew" aria-hidden="true"></i>
          </button>
          {lazyapplyXSessions.length > 0 && (
            <button
              className="mainnavlazyapplyxbutton"
              onClick={() => {
                verifyIfAnySessionsToday();
              }}
            >
              Create New Automation Session{" "}
              {sessionloading && (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ marginLeft: "10px" }}
                />
              )}
            </button>
          )}
        </div>

        <div className="lazyapplyXSessions">
          <div>Total Sessions - {lazyapplyXAnalytics.totalsessionslength}</div>
          <div>
            Total Companies Applied To - {lazyapplyXAnalytics.companiesCount}
          </div>
        </div>
        <div className="v2resumeAlreadyPresent" style={{ marginTop: "20px" }}>
          <div className="row">
            {lazyapplyXSessions.length === 0 ? (
              <>
                <div className="v2resume">
                  <button
                    className="createfirstresume"
                    onClick={() => createNewSession()}
                  >
                    Create Your First Email Automation Session{" "}
                    {loadingAtFirstSessionCreation && (
                      <Spinner
                        animation="border"
                        size="sm"
                        style={{ marginLeft: "5px", marginBottom: "5px" }}
                      />
                    )}
                  </button>
                </div>
              </>
            ) : (
              <>
                {lazyapplyXSessions.map((data, index) => {
                  return (
                    <>
                      <div className="col-3">
                        <div
                          className="resumeblock"
                          key={index}
                          style={{ cursor: "pointer" }}
                        >
                          {data.processCompleted === 1 ? (
                            <span className="resumeblockstatus statuscompleted">
                              Completed
                            </span>
                          ) : (
                            <span className="resumeblockstatus statuspending">
                              Not Completed
                            </span>
                          )}
                          <div className="resumeEditOptions">
                            <div className="resumeblockimg">
                              <img src={"/assests/email-logo.png"} />
                            </div>
                            <div className="resumeblocktext">
                              {createDate(data.createdAt)}
                            </div>
                            {data.processCompleted === 1 && (
                              <div
                                className="resumeblocktext"
                                style={{ fontSize: "14px" }}
                              >
                                {`Companies Applied -`}
                                {data.companiesCount}
                              </div>
                            )}
                            <div className="resumeblockedit">
                              <button
                                onClick={() => {
                                  history.push(
                                    `/dashboard/lazyapply-x/sessions/${data.uniqueId}`
                                  );
                                }}
                              >
                                {data.processCompleted === 1 ? (
                                  <span>View Session Details</span>
                                ) : (
                                  <span>Continue Session</span>
                                )}
                              </button>
                            </div>
                            {userData?.email &&
                              emails.includes(userData.email) &&
                              data.processCompleted === 1 && (
                                <div
                                  className="checkanalytics"
                                  onClick={() => {
                                    history.push(
                                      `/dashboard/lazyapply-x/analytics/${data.uniqueId}`
                                    );
                                  }}
                                >
                                  Check analytics{" "}
                                  <i
                                    style={{ color: "black", fontSize: "20px" }}
                                    className="fas fa-external-link-alt ml-2"
                                  ></i>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default LazyapplyXSessions;
