import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import CustomAlert from "../modal";
import { useStateStore } from "../../store/index";
import CreateResumeModal from "./createResumeModalV2";
// import resumeimg from "../../resume.png";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import ChangeResumeNameModal from "./changeResumeNameModalV2";
import ModalConfirm from "./modalConfirmNewV2";
import { mixpanel } from "../../index";

function EditResumes() {
  const token = useStateStore((state) => state.token);
  const history = useHistory();
  const userResumeV3 = useStateStore((state) => state.userResumeV3);
  const resumeModifiedV3 = useStateStore((state) => state.resumeModifiedV3);
  const userData = useStateStore((state) => state.userData);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const setResumesCompleteV3 = useStateStore(
    (state) => state.setResumesCompleteV3
  );
  const [resumePresent, setResumePresent] = useState(false);
  const [completedResumes, setCompletedResumes] = useState({});
  const globalLogout = useStateStore((state) => state.globalLogout);
  function goBack() {
    mixpanel.track("go_back_button edit_profile_page dashboard", {
      email: userData?.email,
      href: window.location.href,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    history.goBack();
  }

  useEffect(() => {
    mixpanel.track("edit_profile_page dashboard", {
      email: userData?.email,
      href: window.location.href,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
  }, []);

  useEffect(() => {
    // get all resumes
    const v2Id = userData?.planDetails?.v2Id;
    if (loading.current) loading.current.style.display = "block";
    axios
      .get(`https://backend.lazyapply.com/v2/resumesV3/completed/${v2Id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("resumescompleted", response);
        if (loading.current) loading.current.style.display = "none";
        if (
          "resumes" in response.data &&
          Object.keys(response.data.resumes).length > 0
        ) {
          setResumesCompleteV3(response.data.resumes);
          setCompletedResumes(response.data.resumes);
        }
      })
      .catch((err) => {
        if (loading.current) loading.current.style.display = "none";
        console.log("some error occured");
      });
  }, [userData, resumeModifiedV3]);

  const [resumemeta, setresumemeta] = useState(0);
  const organization = useStateStore((state) => state.organization);
  const setUserResumeV3 = useStateStore((state) => state.setUserResumeV3);
  const userInvitations = useStateStore((state) => state.userInvitations);
  const [mainData, setMainData] = useState([]);

  useEffect(() => {
    if (resumemeta === 0 && userData?.planDetails?.v2Id) {
      if (loading.current) loading.current.style.display = "block";
      // setresumemeta(1);
      const v2Id = userData?.planDetails?.v2Id;
      // organization.organization_id
      // organization.users
      console.log("organisation_main", organization, userInvitations);
      if (organization.organization_id && userInvitations?.invited_users) {
        console.log("here", organization?.organization_id);
        setresumemeta(1);
        axios
          .post(
            `https://backend.lazyapply.com/enterprise-service/resumes/organization/${organization?.organization_id}`,
            {
              emails: [
                ...userInvitations.invited_users.map((x) => x.user_email),
                userData.email,
              ],
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            setResumePresent(true);
            if (loading.current) loading.current.style.display = "none";
            // setMainData(
            //   groupResumesByLevel(
            //     [...userInvitations.invited_users, userInvitations.main_user],
            //     response.data,
            //     userData?.email
            //   )
            // );

            const groupResumesByLevel = (
              invited_users,
              resumes,
              main_user_email
            ) => {
              const groupedResumes = {
                main_user: [],
                level1: [],
                level2: [],
                level3: [],
              };

              const userRolesMap = {};
              invited_users.forEach((user) => {
                userRolesMap[user.user_email] = user.role;
              });

              resumes.forEach((resume) => {
                if (resume.email === main_user_email) {
                  // Add the resumes of the main user under the 'main_user' key
                  groupedResumes.main_user.push(resume);
                } else {
                  // Group resumes based on user roles
                  const userRole = userRolesMap[resume.email];
                  if (userRole) {
                    groupedResumes[userRole].push(resume);
                  }
                }
              });

              return groupedResumes;
            };

            const allinvited = [
              ...userInvitations.invited_users,
              userInvitations.main_user,
            ];
            const allresumes = response.data;

            const data = groupResumesByLevel(
              allinvited,
              allresumes,
              userData?.email
            );
            setMainData(data);
            // setResumePresent(true);
            // if (
            //   "resumes" in response.data &&
            //   response.data.resumes.length > 0
            // ) {
            //   setUserResumeV3(response.data.resumes);
            //   setResumePresent(true);
            // } else {
            //   setResumePresent(false);
            // }
          })
          .catch((err) => {
            if (loading.current) loading.current.style.display = "none";
            console.log("some error occured");
            if (err.message == "Request failed with status code 403") {
              console.log("reauthenticate");
              setmessage("Please relogin again!");
              settitle("Not authorized");
              setmodalshow(true);
              setTimeout(() => {
                globalLogout();
              }, 1500);
            } else {
              console.log("error");
            }
          });
      }
      // } else {
      //   axios
      //     .get(
      //       `https://backend.lazyapply.com/v2/resumesV3/checkResumesValidation/${v2Id}`,
      //       {
      //         headers: { Authorization: `Bearer ${token}` },
      //       }
      //     )
      //     .then((response) => {
      //       if (loading.current) loading.current.style.display = "none";
      //       console.log("resumesall", response);
      //       if (
      //         "resumes" in response.data &&
      //         response.data.resumes.length > 0
      //       ) {
      //         setUserResumeV3(response.data.resumes);
      //         setResumePresent(true);
      //       } else {
      //         setResumePresent(false);
      //       }
      //     })
      //     .catch((err) => {
      //       if (loading.current) loading.current.style.display = "none";
      //       console.log("some error occured");
      //       if (err.message == "Request failed with status code 403") {
      //         console.log("reauthenticate");
      //         setmessage("Please relogin again!");
      //         settitle("Not authorized");
      //         setmodalshow(true);
      //         setTimeout(() => {
      //           globalLogout();
      //         }, 1500);
      //       } else {
      //         console.log("error");
      //       }
      //     });
      // }
    }
  }, [userData, userInvitations]);

  const createNew = () => {
    mixpanel.track("upload_resume_button edit_profile_page dashboard", {
      email: userData?.email,
      href: window.location.href,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });

    if (
      userData?.planDetails?.resumeLimit &&
      userData?.planDetails?.resumeLimit > userResumeV3.length
    )
      setShowCreateResumeModal(true);
    else {
      setmessage("Cannot create more resumes, resume limit reached");
      settitle("Resume limit reached!");
      setmodalshow(true);
      setTimeout(() => {
        setmodalshow(false);
      }, 3000);
    }
  };

  function closeModal() {
    setShowCreateResumeModal(false);
  }
  const [showCreateResumeModal, setShowCreateResumeModal] = useState(false);
  const loading = useRef(null);

  function closeModalNameChange() {
    setShowCreateResumeModalNameChange(false);
  }
  const [showCreateResumeModalNameChange, setShowCreateResumeModalNameChange] =
    useState(false);
  const [selectResume, setSelectedResume] = useState({
    currentName: "",
    resumeId: "",
  });

  function closeModalConfirm() {
    setShowModalConfirm(false);
  }
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  useEffect(() => {
    if (userResumeV3.length == 0) {
      setResumePresent(false);
    }
  }, [userResumeV3]);

  // let mainData = {
  //   main_user: [
  //     {
  //       _id: "66e335f6b5c2d3ace6e6c3b7",
  //       resumeId: "resume1i239933",
  //       resumename: "My Resumi99e1",
  //       resumevalidation: 0,
  //       email: "thedddss@gmail.com",
  //       v2Id: "thedddss@gmail.comv2Id",
  //       organization_id: "12345",
  //       __v: 0,
  //     },
  //   ],
  //   level1: [
  //     {
  //       _id: "66e335f6b2c2d30ce6e6c3b7",
  //       resumeId: "resume12039933",
  //       resumename: "My Resum99e01",
  //       resumevalidation: 0,
  //       email: "theddooodss@gmail.com",
  //       v2Id: "theddooodss@gmail.comv2Id",
  //       organization_id: "12345",
  //       __v: 0,
  //     },
  //   ],
  //   level2: [
  //     {
  //       _id: "66e335f6b3c2d3ace6e6c3b7",
  //       resumeId: "resume12333",
  //       resumename: "My Resume1",
  //       resumevalidation: 0,
  //       email: "theboss@gmail.com",
  //       v2Id: "theboss@gmail.comv2Id",
  //       organization_id: "12345",
  //       __v: 0,
  //     },
  //   ],
  //   level3: [
  //     {
  //       _id: "66e335f6b3c2d4ace6e6c3b7",
  //       resumeId: "resume123",
  //       resumename: "My Resume",
  //       resumevalidation: 0,
  //       email: "prakhargupta.2106@gmail.com",
  //       v2Id: "prakhargupta.2106@gmail.comv2Id",
  //       organization_id: "12345",
  //       __v: 0,
  //     },
  //     {
  //       _id: "66e335f6b3000c2d4ace6e6c3b7",
  //       resumeId: "resume99123",
  //       resumename: "My R00esume",
  //       resumevalidation: 0,
  //       email: "prakhaprgupta.2106@gmail.com",
  //       v2Id: "prakhaprgupta.2106@gmail.comv2Id",
  //       organization_id: "12345",
  //       __v: 0,
  //     },
  //     {
  //       _id: "66e335f6b3c200d4ace6e6c3b7",
  //       resumeId: "resume999123",
  //       resumename: "My R000esume",
  //       resumevalidation: 0,
  //       email: "prakhaorgupta.2106@gmail.com",
  //       v2Id: "prakharogupta.2106@gmail.comv2Id",
  //       organization_id: "12345",
  //       __v: 0,
  //     },
  //   ],
  // };

  return (
    <div style={{ position: "relative" }}>
      <div className="loading loadingresume" ref={loading}>
        <div className="boxLoading"></div>
      </div>
      <CreateResumeModal
        showProp={showCreateResumeModal}
        hideShow={closeModal}
      />
      <ModalConfirm
        show={showModalConfirm}
        handleClose={closeModalConfirm}
        currentName={selectResume.currentName}
        resumeId={selectResume.resumeId}
      />
      <ChangeResumeNameModal
        showProp={showCreateResumeModalNameChange}
        hideShow={closeModalNameChange}
        currentName={selectResume.currentName}
        resumeId={selectResume.resumeId}
      />
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      <button className="additionalInfo btn" onClick={createNew}>
        Upload Resume/CV
      </button>
      <button className="buttonGoBack btn" onClick={goBack}>
        <i className="fas fa-arrow-left"></i> Go Back
      </button>

      {resumePresent ? (
        <>
          <p className="editresumeheading">Edit your resumes/CV's</p>
          <div className="v2resumeAlreadyPresent" style={{ marginTop: "20px" }}>
            <div className="row">
              {Object.keys(mainData).map((key) => {
                return (
                  <>
                    {mainData[key].length > 0 ? (
                      <>
                        <p className="editresumeheading1">
                          {key == "main_user" ? "Your" : key} Resumes
                        </p>
                        <div className="col-12 row">
                          {mainData[key].map((data, index) => {
                            return (
                              <div className="col-lg-3 col-md-4 col-sm-6">
                                <div
                                  onClick={() => {
                                    mixpanel.track(
                                      "edit_resume_button edit_profile_page dashboard",
                                      {
                                        email: userData?.email,
                                        resumeId: data.resumeId,
                                        resumeName: data.resumename,
                                        href: window.location.href,
                                        plan_details:
                                          userData?.planDetails || {},
                                        v2Id: userData?.planDetails?.v2Id || "",
                                      }
                                    );
                                    history.push(
                                      `/dashboard/editresumesv2/${data.resumeId}`
                                    );
                                  }}
                                  className="resumeblock"
                                  key={index}
                                  style={{ cursor: "pointer" }}
                                >
                                  {data.validation === 1 ? (
                                    <span className="resumeblockstatus statuscompleted">
                                      Completed
                                    </span>
                                  ) : (
                                    <span className="resumeblockstatus statuspending">
                                      Not Completed
                                    </span>
                                  )}
                                  <div className="resumeEditOptions">
                                    <Dropdown
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                      />

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() => {
                                            mixpanel.track(
                                              "delete_resume_button edit_profile_page dashboard",
                                              {
                                                email: userData?.email,
                                                currentName: data.resumename,
                                                resumeId: data.resumeId,
                                                href: window.location.href,
                                                plan_details:
                                                  userData?.planDetails || {},
                                                v2Id:
                                                  userData?.planDetails?.v2Id ||
                                                  "",
                                              }
                                            );
                                            setSelectedResume({
                                              currentName: data.resumename,
                                              resumeId: data.resumeId,
                                            });
                                            setShowModalConfirm(true);
                                          }}
                                        >
                                          Delete Resume
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() => {
                                            mixpanel.track(
                                              "edit_resume_name_button edit_profile_page dashboard",
                                              {
                                                email: userData?.email,
                                                currentName: data.resumename,
                                                resumeId: data.resumeId,
                                                href: window.location.href,
                                                plan_details:
                                                  userData?.planDetails || {},
                                                v2Id:
                                                  userData?.planDetails?.v2Id ||
                                                  "",
                                              }
                                            );
                                            setSelectedResume({
                                              currentName: data.resumename,
                                              resumeId: data.resumeId,
                                            });
                                            setShowCreateResumeModalNameChange(
                                              true
                                            );
                                          }}
                                        >
                                          Edit Resume Name
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>

                                  <div className="resumeblockimg">
                                    <img src={"/assests/resume.png"} />
                                  </div>
                                  <div className="resumeblocktext">
                                    {index}.{" " + data.resumename}
                                  </div>

                                  <div className="resumeblockedit">
                                    <button
                                      onClick={() => {
                                        mixpanel.track(
                                          "edit_resume_button edit_profile_page dashboard",
                                          {
                                            email: userData?.email,
                                            resumeId: data.resumeId,
                                            resumeName: data.resumename,
                                            href: window.location.href,
                                            plan_details:
                                              userData?.planDetails || {},
                                            v2Id:
                                              userData?.planDetails?.v2Id || "",
                                          }
                                        );
                                        history.push(
                                          `/dashboard/editresumesv2/${data.resumeId}`
                                        );
                                      }}
                                    >
                                      Edit Resume
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="v2resume">
            <button className="createfirstresume" onClick={createNew}>
              Upload your first Resume/Cv now
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default EditResumes;
