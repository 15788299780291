import React from "react";
import "./footer.css";
import {Link} from 'react-router-dom'
function footer() {
  return (
    <div className="footerMain">
      <div className="footerLeft">
       <Link className="footerRightLink" to="/pricing">Pricing</Link>
        <Link className="footerRightLink" to="/refundpolicy">Refund Policy</Link>
      </div>
      <div className="footerCenter">
        <div className="footerHeading">LazyApply</div>
        <div>
          <p>© {new Date().getFullYear()} Copyright LazyApply.com</p>
        </div>
      </div>
      <div className="footerRight">
        <Link className="footerRightLink" to="/privacy">Privacy</Link>
        <Link className="footerRightLink" to="/terms">Terms</Link>
        <Link  className="footerRightLink" to="/faq">FAQ</Link>
      </div>
    </div>
  );
}

export default footer;
