import React from "react";
import ReferralMainBlock from "./ReferralMainBlock";

function ReferralList({ setShow, companies, showLoader }) {
  return (
    <>
      {showLoader ? (
        <>
          {[1, 2, 3].map((index) => {
            return (
              <>
                <div className="main-referral-container skeleton-post main-referral-skeleton-post">
                  <div className="main-referral-top">
                    <div className="main-referral-top-first">
                      <div className="skeleton-logo"></div>
                    </div>
                    <div className="main-referral-top-second">
                      <div className="main-referral-company-name">
                        <div className="skeleton-line"></div>
                      </div>
                      <div className="main-referral-company-domain">
                        <div className="skeleton-line"></div>
                      </div>
                      <div className="main-referral-company-otherdetails">
                        <div className="skeleton-line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="main-referral-bottom">
                    <div>
                      <button className="skeleton-button"></button>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </>
      ) : (
        companies.map((company) => {
          return <ReferralMainBlock company={company} setShow={setShow} mixpanel_page="secret_network_page"/>;
        })
      )}
    </>
  );
}

export default ReferralList;
