import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import {
  Card,
  Button,
  Col,
  Row,
  Form,
  Image,
  Modal,
  Spinner,
} from "react-bootstrap";
import "./editresumesv2.css";
import CustomAlert from "../modal";
import { useHistory, useParams } from "react-router-dom";
import data from "./editresumev2.json";
import { useStateStore } from "../../store/index";
import axios from "axios";
import ModalResumeTracker from "./modalResumeTracker";
import ReplaceResumeModal from "./replaceResumeModal";
import { mixpanel } from "../../index";

const {
  educationMajorOptions,
  degreeTypeOptions,
  race_ethnicity_options,
  valid_driver_license_options,
  veteran_options,
  disability_options,
  relocate_options,
  visa_status_options,
  countryOptions,
  currencyOptions,
  languageOptions,
} = data;

const initialData = {
  personalInformation: {
    firstName: "",
    lastName: "",
    email: "",
    city: "",
    country: "",
    countryCode: "",
    state: "",
    address: "",
    zipCode: "",
    phoneNumber: "",
    gender: "",
  },
  workExperience: [],
  skills: [],
  languages: [],
  education: [],
  achievements: [],
  certifications: [],
  miscellaneous: {
    totalExperience: "",
    veteranStatus: "",
    disability: "",
    race: "",
    willingToRelocation: "",
    expectedSalaryAmount: "",
    expectedSalaryCurrency: "",
    currentSalary: "",
    defaultExperience: "",
    currentSalaryCurrency: "",
    highestEducationLevel: "",
    drivingLicense: "",
    visaRequirement: "",
    coverLetter: "",
    expectedDateOfJoining: "",
    securityClearanceActive: "",
    securityClearanceType: "",
    otherSecurityClearanceType: "",
    companiesToExclude: "",
    authorisedToWork: [],
    dribbleUrl: "",
    linkedinUrl: "",
    githubUrl: "",
    portfolioUrl: "",
    otherUrl: "",
    noticePeriod: "",
  },
};

function EditResumeV2() {
  // State variables
  const params = useParams();
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const setResumeModifiedV3 = useStateStore(
    (state) => state.setResumeModifiedV3
  );
  const globalLogout = useStateStore((state) => state.globalLogout);
  const history = useHistory();
  const userData = useStateStore((state) => state.userData);
  const userResumeV3 = useStateStore((state) => state.userResumeV3);
  const [skill, setSkill] = useState("");
  const [experience, setExperience] = useState("");
  const [skills, setSkills] = useState([]);
  const [error, setError] = useState("");
  const [resume, setResume] = useState(initialData);
  const [loading, setLoading] = useState(true);
  const [workExperiences, setWorkExperiences] = useState([]);
  const token = useStateStore((state) => state.token);
  const [workExperienceError, setWorkExperienceError] = useState(false);
  const [workExperienceForm, setWorkExperienceForm] = useState({
    company: "",
    jobTitle: "",
    startDate: "",
    endDate: "",
    currentlyWorkHere: false,
    description: "",
    experienceType: "",
    workLocation: "",
  });
  const [mainfileDetails, setMainfileDetails] = useState({});
  const [resumeName, setResumeName] = useState("");
  const [personalInfoOpen, setPersonalInfoOpen] = useState(true);
  const [miscellaneousInfoOpen, setMiscellaneousInfoOpen] = useState(true);
  const [editingExperienceIndex, setEditingExperienceIndex] = useState(null);
  const [language, setLanguage] = useState("");
  const [level, setLevel] = useState("");
  const [languages, setLanguages] = useState([]);
  const [languageError, setLanguageError] = useState("");
  const [achievements, setAchievements] = useState([]);
  const [awardTitle, setAwardTitle] = useState("");
  const [issuer, setIssuer] = useState("");
  const [issuingDate, setIssuingDate] = useState("");
  const [description, setDescription] = useState("");
  const [achievementError, setAchievementError] = useState("");
  const [showAchievementModal, setShowAcheivementModal] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [certifications, setCertifications] = useState([]);
  const [certificationTitle, setCertificationTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showCertificationModal, setShowCertificationModal] = useState(false);
  const [certificationUrl, setCertificationUrl] = useState("");
  const [certificationError, setCertificationError] = useState("");
  const [hasExtraValue, setHasExtraValue] = useState(false);
  const [editingCertificationIndex, setEditingCertificationIndex] =
    useState(null);
  const [saveChangesLoading, setSaveChangesLoading] = useState(false);

  const [editWorkIconClicked, setEditWorkIconClicked] = useState(false);
  const [editEducationIconClicked, setEditEducationIconClicked] =
    useState(false);
  const [resumemeta, setresumemeta] = useState(0);
  const [resumeMetaDetails, setResumeMetaDetails] = useState({});
  function closeModalConfirm() {
    setShowModalConfirm(false);
  }
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [resumeTrackerChanged, setResumeTrackerChanged] = useState(false);
  const [replaced, setReplaced] = useState(false);

  const isReplaced = () => {
    setReplaced(true);
  };

  const buttonContainerRef = useRef(null);

  // function scrollToField(id) {
  //   document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  // }
  function scrollToField(id) {
    const element = document.getElementById(id);
    const fromTop = element.getBoundingClientRect().top;
    const desiredPosition = window.innerHeight * 0.25; // 25% from the top
    const scrollPosition = window.pageYOffset + fromTop - desiredPosition;

    window.scrollTo({ top: scrollPosition, behavior: "smooth" });
  }
  function scrollToElement(element) {
    const fromTop = element.getBoundingClientRect().top;
    const desiredPosition = window.innerHeight * 0.25; // 25% from the top
    const scrollPosition = window.pageYOffset + fromTop - desiredPosition;

    window.scrollTo({ top: scrollPosition, behavior: "smooth" });
  }

  // Handle adding or updating a certification
  const handleAddCertification = (e) => {
    e.preventDefault();

    if (
      certificationTitle.trim() === "" ||
      startDate.trim() === "" ||
      endDate.trim() === "" ||
      certificationUrl.trim() === ""
    ) {
      setCertificationError("Please fill in all fields.");
      return;
    }

    const newCertification = {
      certificationTitle,
      startDate,
      endDate,
      certificationUrl,
    };

    if (editingCertificationIndex !== null) {
      // Editing existing certification
      const updatedCertifications = [...certifications];
      updatedCertifications[editingCertificationIndex] = newCertification;
      setCertifications(updatedCertifications);
      setEditingCertificationIndex(null);
      setResume((prevResume) => ({
        ...prevResume,
        certifications: updatedCertifications,
      }));
    } else {
      // Adding new certification
      setCertifications([...certifications, newCertification]);
      setResume((prevResume) => ({
        ...prevResume,
        certifications: [...certifications, newCertification],
      }));
    }

    setCertificationTitle("");
    setStartDate("");
    setEndDate("");
    setCertificationUrl("");
    setCertificationError("");
    setShowCertificationModal(false);
  };

  useEffect(() => {
    console.log("resumeTrackerChanged", resumeTrackerChanged);
    if ((userData?.planDetails?.v2Id && params.id) || replaced) {
      const v2Id = userData?.planDetails?.v2Id;
      axios
        .get(
          `https://backend.lazyapply.com/v2/resumesV3/metaspecific/${v2Id}/${params.id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log("resumesall", response);
          if ("resumes" in response.data && response.data.resumes.length > 0) {
            setResumeMetaDetails(response.data.resumes[0]);
          }
        })
        .catch((err) => {
          console.log("some error occured");
          if (err.message == "Request failed with status code 403") {
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
            }, 1500);
          } else {
            console.log("error");
          }
        });
    }
  }, [userData, resumeTrackerChanged, replaced]);
  // Handle editing a certification
  const handleEditCertification = (index) => {
    const editedCertification = certifications[index];
    setCertificationTitle(editedCertification.certificationTitle);
    setStartDate(editedCertification.startDate);
    setEndDate(editedCertification.endDate);
    setCertificationUrl(editedCertification.certificationUrl);
    setEditingCertificationIndex(index);
    setShowCertificationModal(true);
  };

  // Handle deleting a certification
  const handleDeleteCertification = (index) => {
    const updatedCertifications = [...certifications];
    updatedCertifications.splice(index, 1);
    setCertifications(updatedCertifications);
    setEditingCertificationIndex(null);
    setResume((prevResume) => ({
      ...prevResume,
      certifications: updatedCertifications,
    }));
  };
  // Handle adding an achievement to the list
  const handleAddAchievement = (e) => {
    e.preventDefault();

    if (
      awardTitle.trim() === "" ||
      issuer.trim() === "" ||
      issuingDate.trim() === "" ||
      description.trim() === ""
    ) {
      setAchievementError("Please fill in all the fields.");
      return;
    }

    const newAchievement = {
      awardTitle,
      issuer,
      issuingDate,
      description,
    };

    if (editIndex !== -1) {
      // Update existing achievement
      const updatedAchievements = [...achievements];
      updatedAchievements[editIndex] = newAchievement;
      setAchievements(updatedAchievements);
      setResume((prevResume) => ({
        ...prevResume,
        achievements: updatedAchievements,
      }));
    } else {
      // Add new achievement
      setAchievements([...achievements, newAchievement]);
      setResume((prevResume) => ({
        ...prevResume,
        achievements: [...achievements, newAchievement],
      }));
    }

    // Clear form inputs
    setAwardTitle("");
    setIssuer("");
    setIssuingDate("");
    setDescription("");
    setAchievementError("");
    setEditIndex(-1);
    setShowAcheivementModal(false);
  };

  // Handle editing an achievement
  const handleEditAchievement = (index) => {
    const achievementToEdit = achievements[index];
    setAwardTitle(achievementToEdit.awardTitle);
    setIssuer(achievementToEdit.issuer);
    setIssuingDate(achievementToEdit.issuingDate);
    setDescription(achievementToEdit.description);
    setEditIndex(index);
    setShowAcheivementModal(true);
  };

  // Handle deleting an achievement from the list
  const handleDeleteAchievement = (index) => {
    const updatedAchievements = [...achievements];
    updatedAchievements.splice(index, 1);
    setAchievements(updatedAchievements);
    setResume((prevResume) => ({
      ...prevResume,
      achievements: updatedAchievements,
    }));
  };

  // Handle adding a language to the resume
  const handleAddLanguage = (e) => {
    e.preventDefault();

    if (language.trim() === "" || level.trim() === "") {
      setLanguageError("Please select a language and proficiency level.");
      setTimeout(() => setError(""), 2000);
      return;
    }

    const existingLanguage = languages.find(
      (lang) => lang.language === language
    );

    if (existingLanguage) {
      setLanguageError("Language already added.");
      return;
    }

    setResume((prevResume) => ({
      ...prevResume,
      languages: [...prevResume.languages, newLanguage],
    }));
    const newLanguage = { language, level };
    setLanguages([...languages, newLanguage]);

    setLanguage("");
    setLevel("");
    setLanguageError("");
  };

  // Handle deleting a language from the resume
  const handleDeleteLanguage = (index) => {
    const updatedLanguages = [...languages];
    updatedLanguages.splice(index, 1);
    setLanguages(updatedLanguages);
    setResume((prevResume) => ({
      ...prevResume,
      languages: updatedLanguages,
    }));
  };

  // Fetch data from the "database" when the component is first rendered
  useEffect(() => {
    if (params.id || replaced) {
      const resumeId = params.id;
      const v2Id = userData?.planDetails?.v2Id;
      axios
        .get(
          `https://backend.lazyapply.com/v2/resumesV3/${v2Id}?id=${resumeId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setLoading(false);
          if (
            "resume" in response.data &&
            Object.keys(response.data.resume).length > 0
          ) {
            if ("mainfileDetails" in response.data) {
              setMainfileDetails(response.data.mainfileDetails);
            }
            if ("resumename" in response.data) {
              setResumeName(response.data.resumename);
            }
            const storedResume = response.data.resume;
            if (storedResume) {
              setResume(storedResume);
              setSkills(storedResume.skills);
              if (Array.isArray(storedResume.workExperience)) {
                setWorkExperiences(storedResume.workExperience);
              }
              setCertifications(storedResume.certifications);
              setAchievements(storedResume.achievements);
              setLanguages(storedResume.languages);
              setEducations(storedResume.education);
            } else {
              setSkills(initialData.skills);
              setWorkExperiences([]);
              setCertifications([]);
              setAchievements([]);
              setLanguages([]);
              setEducations([]);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [replaced]);

  // Handle input change for personal information section
  const handleInputChange = (section, field) => (e) => {
    setResume({
      ...resume,
      [section]: {
        ...resume[section],
        [field]: e.target.value,
      },
    });
  };

  // Handle adding a skill to the resume
  const handleAddSkill = (e) => {
    e.preventDefault();

    const experienceNumber = Number(experience);

    if (
      skill.trim() === "" ||
      isNaN(experienceNumber) ||
      experienceNumber < 0
    ) {
      setError("Please enter a valid skill and years of experience.");
      setTimeout(() => setError(""), 2000); // Clear error message after 3 seconds
      return;
    }

    const existingSkillIndex = skills.findIndex(
      (s) => s.skill.toLowerCase() === skill.toLowerCase()
    );

    if (existingSkillIndex > -1) {
      // Skill already exists, update the years of experience
      const updatedSkills = skills.map((s, index) =>
        index === existingSkillIndex
          ? { ...s, experience: experienceNumber }
          : s
      );
      setSkills(updatedSkills);
      setResume((prevResume) => ({
        ...prevResume,
        skills: updatedSkills,
      }));
    } else {
      // Add new skill
      const newSkill = { skill, experience: experienceNumber };
      setSkills([...skills, newSkill]);
      setResume((prevResume) => ({
        ...prevResume,
        skills: [...prevResume.skills, newSkill],
      }));
    }

    setSkill("");
    setExperience("");
    setError(""); // Clear any error messages
  };

  // Handle deleting a skill from the resume
  const handleDeleteSkill = (index) => {
    const updatedSkills = [...skills];
    updatedSkills.splice(index, 1);
    setSkills(updatedSkills);
    setResume((prevResume) => ({
      ...prevResume,
      skills: updatedSkills,
    }));
  };

  // Update data in the "database" when any changes are made
  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        localStorage.setItem("resume", JSON.stringify(resume));
      }, 1000); // Simulate a delay of 1 second

      errorsObj = checkDetails(resume);
      let objj = Object.keys(errorsObj).filter((key) =>
        checkValue(errorsObj[key])
      );
      setErrorSections(objj);
      setErrorsLeft(objj.length > 0);
    }
  }, [resume, loading]);

  // Handle change in work experience form
  const handleWorkExperienceChange = (e) => {
    const { name, value } = e.target;

    setWorkExperienceForm({
      ...workExperienceForm,
      [name]: value,
    });

    setResume((prevResume) => ({
      ...prevResume,
      workExperience: prevResume.workExperience.map((experience, index) =>
        index === editingExperienceIndex
          ? { ...experience, [name]: value }
          : experience
      ),
    }));
  };

  // Handle submission of work experience form
  const handleWorkExperienceSubmit = (e) => {
    e.preventDefault();
    if (editingExperienceIndex !== null) {
      const updatedWorkExperiences = [...workExperiences];
      if (
        workExperienceForm.company &&
        workExperienceForm.jobTitle &&
        workExperienceForm.startDate &&
        workExperienceForm.description &&
        workExperienceForm.workLocation &&
        workExperienceForm.experienceType &&
        (workExperienceForm.currentlyWorkHere || workExperienceForm.endDate)
      ) {
        updatedWorkExperiences[editingExperienceIndex] = workExperienceForm;
        setWorkExperiences(updatedWorkExperiences);
        setEditingExperienceIndex(null);
        setWorkExperienceForm({
          company: "",
          jobTitle: "",
          startDate: "",
          endDate: "",
          currentlyWorkHere: false,
          description: "",
          experienceType: "",
          workLocation: "",
        });
        setResume((prevResume) => ({
          ...prevResume,
          workExperience: updatedWorkExperiences,
        }));
        closeModal();
      } else {
        setWorkExperienceError(true);
      }
    } else {
      if (
        workExperienceForm.company &&
        workExperienceForm.jobTitle &&
        workExperienceForm.startDate &&
        workExperienceForm.description &&
        workExperienceForm.workLocation &&
        workExperienceForm.experienceType &&
        (workExperienceForm.currentlyWorkHere || workExperienceForm.endDate)
      ) {
        setWorkExperiences([...workExperiences, workExperienceForm]);
        setWorkExperienceForm({
          company: "",
          jobTitle: "",
          startDate: "",
          endDate: "",
          currentlyWorkHere: false,
          description: "",
          experienceType: "",
          workLocation: "",
        });
        setWorkExperienceError(false);
        setResume((prevResume) => ({
          ...prevResume,
          workExperience: [...workExperiences, workExperienceForm],
        }));
        closeModal();
      } else {
        setWorkExperienceError(true);
      }
    }
  };

  // Handle editing a work experience entry
  const handleWorkExperienceEdit = (index) => {
    setEditingExperienceIndex(index);
    const workExperience = workExperiences[index];
    setWorkExperienceForm({
      company: workExperience.company,
      jobTitle: workExperience.jobTitle,
      startDate: workExperience.startDate,
      endDate: workExperience.endDate,
      currentlyWorkHere: workExperience.currentlyWorkHere,
      description: workExperience.description,
      workLocation: workExperience.workLocation,
      experienceType: workExperience.experienceType,
    });
    openModal();
  };

  // Handle deleting a work experience entry
  const handleWorkExperienceDelete = (index) => {
    const updatedWorkExperiences = [...workExperiences];
    updatedWorkExperiences.splice(index, 1);
    setWorkExperiences(updatedWorkExperiences);
    setResume((prevResume) => ({
      ...prevResume,
      workExperience: updatedWorkExperiences,
    }));
  };

  // Toggle personal information section
  const togglePersonalInfoOpen = () => {
    setPersonalInfoOpen(!personalInfoOpen);
  };

  // Toggle miscellaneous information section
  const toggleMiscellaneousInfoOpen = () => {
    setMiscellaneousInfoOpen(!miscellaneousInfoOpen);
  };

  const openModal = () => setShowModal(true);

  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setWorkExperienceForm({
      company: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
      currentlyWorkHere: false,
      description: "",
      experienceType: "",
      workLocation: "",
    });
  };

  const [education, setEducation] = useState({
    institution: {
      name: "",
      type: "",
      location: {
        city: "",
        state: "",
      },
    },
    degree: {
      degreeName: "",
      degreeType: "",
    },
    gpa: "",
    startDate: "",
    endDate: "",
  });
  const [educationError, setEducationError] = useState("");
  const [showEducationModal, setShowEducationModal] = useState(false);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [educations, setEducations] = useState([]);

  const handleAddEducation = (e) => {
    e.preventDefault();

    if (!validateEducationForm()) {
      return;
    }

    const updatedEducations = [...educations];
    if (editingIndex !== -1) {
      updatedEducations[editingIndex] = education;
    } else {
      updatedEducations.push(education);
    }

    setResume((prevResume) => ({
      ...prevResume,
      education: updatedEducations,
    }));
    setEducations(updatedEducations);
    setEducation({
      institution: {
        name: "",
        type: "",
        location: {
          city: "",
          state: "",
        },
      },
      degree: {
        degreeName: "",
        degreeType: "",
      },
      gpa: "",
      startDate: "",
      endDate: "",
    });
    setEducationError("");
    setShowEducationModal(false);
    setEditingIndex(-1);
  };

  const handleDeleteEducation = (index) => {
    const updatedEducations = [...educations];
    updatedEducations.splice(index, 1);
    setEducations(updatedEducations);
    setResume((prevResume) => ({
      ...prevResume,
      education: updatedEducations,
    }));
  };

  const handleEditEducation = (index) => {
    const editedEducation = educations[index];
    setEducation(editedEducation);
    setShowEducationModal(true);
    setEditingIndex(index);
  };

  const validateEducationForm = () => {
    if (
      education.institution.name.trim() === "" ||
      education.institution.type.trim() === "" ||
      education.institution.location.city.trim() === "" ||
      education.institution.location.state.trim() === "" ||
      education.degree.degreeName.trim() === "" ||
      education.degree.degreeType.trim() === ""
      // ||
      // education.gpa.trim() === "" ||
      // education.startDate.trim() === "" ||
      // education.endDate.trim() === ""
    ) {
      setEducationError("Please fill in all fields.");
      return false;
    }

    // Add additional validation logic here if needed

    return true;
  };
  const formattedText = (text) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const filterOption = (option, inputValue) => {
    const optionLabel = option.label.toLowerCase();
    const searchValue = inputValue.toLowerCase();

    if (optionLabel === "Other/Not Listed") {
      const result = educationMajorOptions.filter((opt) =>
        opt.toLowerCase().includes(searchValue)
      );
      setHasExtraValue(!result.length);
      return !result.length;
    }

    return optionLabel.includes(searchValue);
  };

  function goBack() {
    mixpanel.track(
      "go_back_button edit_profile_particular_resume_page dashboard",
      {
        href: window.location.href,
        email: userData?.email,
        plan_details: userData?.planDetails || {},
        v2Id: userData?.planDetails?.v2Id || "",
      }
    );
    history.goBack();
  }

  const optionsCheck = ["Select an option", "Select Currency"];

  let errorsObj = {};

  function checkDetails(jsonObj) {
    const errors = {
      personalInformation: [],
      workExperience: {},
      education: {},
      miscellaneous: [],
      skills: 0,
      languages: 0,
    };

    // Check personalInformation details
    const personalInfo = jsonObj.personalInformation;
    const personalInfoErrors = Object.entries(personalInfo)
      .filter(([field, value]) => value === "")
      .map(([field]) => field);
    if (personalInfoErrors.length > 0) {
      errors["personalInformation"] = personalInfoErrors;
      // errors.push({
      //   section: "personalInformation",
      //   fields: personalInfoErrors,
      // });
    }

    // Check email format
    const email = personalInfo.email;
    if (!email || !isValidEmail(email)) {
      // errors.push({ section: "personalInformation", fields: ["email"] });
      errors["personalInformation"].push("email");
    }

    // Check workExperience details
    const workExperience = jsonObj.workExperience;
    if (!Array.isArray(workExperience) || workExperience.length === 0) {
      // errors.push({ section: "workExperience", fields: [] });
    } else {
      workExperience.forEach((experience, index) => {
        const {
          company,
          jobTitle,
          startDate,
          endDate,
          experienceType,
          workLocation,
          currentlyWorkHere,
          description,
        } = experience;
        const invalidFields = [];
        if (!company) {
          invalidFields.push("company");
        }
        if (!jobTitle) {
          invalidFields.push("jobTitle");
        }
        if (!startDate || !isValidDate(startDate)) {
          invalidFields.push("startDate");
        }
        if (currentlyWorkHere == false && (!endDate || !isValidDate(endDate))) {
          invalidFields.push("endDate");
        }
        if (!experienceType) {
          invalidFields.push("experienceType");
        }
        if (!workLocation) {
          invalidFields.push("workLocation");
        }
        if (!description) {
          invalidFields.push("description");
        }

        if (invalidFields.length > 0) {
          errors["workExperience"][index] = invalidFields;
          // errors.push({
          //   section: `workExperience[${index}]`,
          //   fields: invalidFields,
          // });
        }
      });
    }

    // Check education details
    const education = jsonObj.education;
    if (!Array.isArray(education) || education.length === 0) {
      // errors.push({ section: "education", fields: [] });
    } else {
      education.forEach((edu, index) => {
        const { institution, degree, gpa, startDate, endDate } = edu;
        const invalidFields = [];
        if (
          !institution ||
          !institution.name ||
          !institution.type ||
          !institution.location ||
          !institution.location.city ||
          !institution.location.state
        ) {
          if (!institution) {
            invalidFields.push("institution");
          } else {
            if (!institution.name) {
              invalidFields.push("institution.name");
            }
            if (!institution.type) {
              invalidFields.push("institution.type");
            }
            if (
              !institution.location ||
              !institution.location.city ||
              !institution.location.state
            ) {
              if (!institution.location) {
                invalidFields.push("institution.location");
              } else {
                if (!institution.location.city) {
                  invalidFields.push("institution.location.city");
                }
                if (!institution.location.state) {
                  invalidFields.push("institution.location.state");
                }
              }
            }
          }
        }
        if (!degree.degreeType) {
          invalidFields.push("degree.degreeType");
        }
        if (!degree.degreeName) {
          invalidFields.push("degree.degreeName");
        }
        // if (!gpa) {
        //   invalidFields.push("gpa");
        // }
        // if (!startDate || !isValidDate(startDate)) {
        //   invalidFields.push("startDate");
        // }
        // if (!endDate || !isValidDate(endDate)) {
        //   invalidFields.push("endDate");
        // }
        if (invalidFields.length > 0) {
          errors["education"][index] = invalidFields;
          // errors.push({
          //   section: `education[${index}]`,
          //   fields: invalidFields,
          // });
        }
      });
    }

    // Check other fields
    const skills = jsonObj.skills;
    if (!Array.isArray(skills) || skills.length < 4) {
      errors["skills"] = 1;
      // errors.push({ section: "skills", fields: [] });
    }

    const languages = jsonObj.languages;
    if (!Array.isArray(languages) || languages.length < 1) {
      errors["languages"] = 1;
      // errors.push({ section: "languages", fields: [] });
    }

    const achievements = jsonObj.achievements;
    if (!Array.isArray(achievements) || achievements.length === 0) {
      // errors.push({ section: "achievements", fields: [] });
      errors["achievements"] = [];
    }

    const certifications = jsonObj.certifications;
    if (!Array.isArray(certifications) || certifications.length === 0) {
      errors["certifications"] = [];
      // errors.push({ section: "certifications", fields: [] });
    }

    const fieldsInMiscellaneousOptional = [
      "githubUrl",
      "companiesToExclude",
      "coverLetter",
      "linkedinUrl",
      "portfolioUrl",
      "otherUrl",
      "dribbleUrl",
      "otherSecurityClearanceType",
      "securityClearanceType",
      "securityClearanceActive",
      "currentSalary",
      "currentSalaryCurrency",
      "defaultExperience",
    ];

    const miscellaneous = jsonObj.miscellaneous;
    const miscellaneousErrors = Object.entries(miscellaneous)
      .filter(([field, value]) => {
        if (fieldsInMiscellaneousOptional.includes(field)) {
          return false;
        }
        return (
          value === "" ||
          optionsCheck.includes(value) ||
          (field === "authorisedToWork" && value.length == 0) ||
          (field === "highestEducationLevel" && value === "None")
        );
      })
      .map(([field]) => field);
    if (miscellaneousErrors.length > 0) {
      errors["miscellaneous"] = miscellaneousErrors;
      // errors.push({ section: "miscellaneous", fields: miscellaneousErrors });
    }

    // return errors.length === 0 ? true : errors;
    return errors;
  }

  // Function to validate email format
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  // Function to validate date format (yyyy-mm-dd)
  function isValidDate(date) {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateRegex.test(date);
  }

  const [errorsFinal, setErrorsFinal] = useState({
    personalInformation: [],
    workExperience: {},
    education: {},
    miscellaneous: [],
    skills: 0,
    languages: 0,
  });
  const [errorsLeft, setErrorsLeft] = useState(false);
  const [finalErrorMessage, setFinalErrorMessage] = useState("");
  const [errorSections, setErrorSections] = useState([]);

  function checkValue(value) {
    return Array.isArray(value)
      ? value.length > 0
      : typeof value === "object" && value !== null
      ? Object.keys(value).length > 0
      : value;
  }

  function safeValue(value) {
    return value !== undefined && value !== null ? value : "";
  }

  const mixpanelEventFormation = (profileData) => {
    function formatArrayValues(arr) {
      return arr.map((item) => safeValue(item).toLowerCase().trim());
    }

    const workExpJobTitles = formatArrayValues(
      profileData.workExperience.map((item) => item.jobTitle)
    );
    const workExpCompanies = formatArrayValues(
      profileData.workExperience.map((item) => item.company)
    );
    // const workExpDescriptions = formatArrayValues(
    //   profileData.workExperience.map((item) => item.description)
    // );
    const workExpStartDates = formatArrayValues(
      profileData.workExperience.map((item) => item.startDate)
    );
    const workExpEndDates = formatArrayValues(
      profileData.workExperience.map((item) => item.endDate)
    );

    const skillNames = formatArrayValues(
      profileData.skills.map((skill) => skill.skill)
    );
    const skillExperiences = formatArrayValues(
      profileData.skills.map((skill) => skill.experience.toString())
    );

    const educationInstitutions = formatArrayValues(
      profileData.education.map((edu) => edu.institution.name)
    );
    const educationDegrees = formatArrayValues(
      profileData.education.map((edu) => edu.degree.degreeName)
    );
    const educationStartDates = formatArrayValues(
      profileData.education.map((edu) => edu.startDate)
    );
    const educationEndDates = formatArrayValues(
      profileData.education.map((edu) => edu.endDate)
    );

    const certificationTitles = formatArrayValues(
      profileData.certifications.map((cert) => cert.certificationTitle)
    );
    const certificationStartDates = formatArrayValues(
      profileData.certifications.map((cert) => cert.startDate)
    );
    const certificationEndDates = formatArrayValues(
      profileData.certifications.map((cert) => cert.endDate)
    );

    const achievementTitles = formatArrayValues(
      profileData.achievements.map((achieve) => achieve.awardTitle)
    );
    const achievementIssuingDates = formatArrayValues(
      profileData.achievements.map((achieve) => achieve.issuingDate)
    );
    const achievementIssuers = formatArrayValues(
      profileData.achievements.map((achieve) => achieve.issuer)
    );

    const personalInformationProperties = {
      personal_firstName: safeValue(profileData.personalInformation.firstName),
      personal_lastName: safeValue(profileData.personalInformation.lastName),
      personal_email: safeValue(profileData.personalInformation.email),
      personal_phoneNumber: safeValue(
        profileData.personalInformation.phoneNumber
      ),
      personal_address: safeValue(profileData.personalInformation.address),
      personal_city: safeValue(profileData.personalInformation.city),
      personal_state: safeValue(profileData.personalInformation.state),
      personal_zipCode: safeValue(profileData.personalInformation.zipCode),
      personal_country: safeValue(profileData.personalInformation.country),
    };

    const miscellaneousProperties = {
      misc_githubUrl: safeValue(profileData.miscellaneous.githubUrl),
      misc_linkedinUrl: safeValue(profileData.miscellaneous.linkedinUrl),
      misc_portfolioUrl: safeValue(profileData.miscellaneous.portfolioUrl),
      misc_totalExperience: safeValue(
        profileData.miscellaneous.totalExperience
      ),
      misc_expectedSalary: `${safeValue(
        profileData.miscellaneous.expectedSalaryAmount
      )} ${safeValue(profileData.miscellaneous.expectedSalaryCurrency)}`,
      misc_currentSalary: `${safeValue(
        profileData.miscellaneous.currentSalary
      )} ${safeValue(profileData.miscellaneous.currentSalaryCurrency)}`,
      misc_noticePeriod: safeValue(profileData.miscellaneous.noticePeriod),
      misc_expectedDateOfJoining: safeValue(
        profileData.miscellaneous.expectedDateOfJoining
      ),
      misc_authorisedToWork: safeValue(
        profileData.miscellaneous.authorisedToWork
          .map((auth) => auth.value)
          .join(", ")
      ),
      misc_visaRequirement: safeValue(
        profileData.miscellaneous.visaRequirement
      ),
      misc_securityClearanceType: safeValue(
        profileData.miscellaneous.securityClearanceType
      ),
      misc_disability: safeValue(profileData.miscellaneous.disability),
      misc_veteranStatus: safeValue(profileData.miscellaneous.veteranStatus),
      misc_race: safeValue(profileData.miscellaneous.race),
      misc_willingToRelocation: safeValue(
        profileData.miscellaneous.willingToRelocation
      ),
    };

    const eventProperties = {
      ...personalInformationProperties,
      ...miscellaneousProperties,
      workExp_jobTitles: workExpJobTitles,
      workExp_companies: workExpCompanies,
      // workExp_descriptions: workExpDescriptions,
      workExp_startDates: workExpStartDates,
      workExp_endDates: workExpEndDates,
      skills_names: skillNames,
      skills_experiences: skillExperiences,
      education_institutions: educationInstitutions,
      education_degrees: educationDegrees,
      education_startDates: educationStartDates,
      education_endDates: educationEndDates,
      certification_titles: certificationTitles,
      certification_startDates: certificationStartDates,
      certification_endDates: certificationEndDates,
      achievement_titles: achievementTitles,
      achievement_dates: achievementIssuingDates,
      achievement_issuers: achievementIssuers,
    };

    return eventProperties;
  };

  const saveChanges = () => {
    setSaveChangesLoading(true);
    errorsObj = checkDetails(resume);
    console.log("eers", errorsObj);
    setErrorsFinal(errorsObj);

    let objj = Object.keys(errorsObj).filter((key) =>
      checkValue(errorsObj[key])
    );
    setErrorSections(objj);
    if (objj.length) setErrorsLeft(true);

    if (
      errorsObj.personalInformation.length > 0 ||
      errorsObj.skills == 1 ||
      errorsObj.languages == 1 ||
      errorsObj.miscellaneous.length > 0 ||
      Object.keys(errorsObj.workExperience).length > 0 ||
      Object.keys(errorsObj.education).length > 0
    ) {
      setFinalErrorMessage("Please fill all the fields correctly.");
      setSaveChangesLoading(false);
      setTimeout(() => {
        setFinalErrorMessage("");
      }, 2500);
    } else {
      if (params.id) {
        const v2Id = userData?.planDetails?.v2Id;
        const resumeId = params.id;
        let resumename = resumeMetaDetails.resumename;
        // userResumeV3.forEach((item) => {
        //   if (item.resumeId === resumeId) {
        //     resumename = item.resumename;
        //   }
        // });
        console.log("resumename", resumename);
        axios
          .post(
            `https://backend.lazyapply.com/v2/updateResumeV3/${v2Id}?id=${resumeId}`,
            {
              resume: resume,
              resumename: resumename,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            mixpanel.track(
              "save_changes_button edit_profile_particular_resume_page dashboard",
              {
                href: window.location.href,
                email: userData?.email,
                resumeName: resumename,
                resumeId: resumeId,
                resumeData: mixpanelEventFormation(resume),
                plan_details: userData?.planDetails,
                v2Id: userData?.planDetails?.v2Id,
              }
            );
            setSaveChangesLoading(false);
            console.log(response);
            setResumeModifiedV3(1);
            setmessage("Successfully updated");
            settitle("Success");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
              function smoothScrollToTop() {
                let start = null;
                const initialScrollTop = window.scrollY;

                function easeOutCubic(t) {
                  return 1 - Math.pow(1 - t, 3);
                }

                function step(timestamp) {
                  if (start === null) start = timestamp;
                  const elapsed = timestamp - start;
                  const progress = Math.min(elapsed / 1000, 1); // take 2 seconds to scroll

                  const easedProgress = easeOutCubic(progress);
                  const newScrollTop = initialScrollTop * (1 - easedProgress);

                  window.scrollTo(0, newScrollTop);

                  if (newScrollTop > 0) {
                    window.requestAnimationFrame(step);
                  }
                }

                window.requestAnimationFrame(step);
              }

              // Call the function
              smoothScrollToTop();
            }, 2000);
          })
          .catch((error) => {
            setSaveChangesLoading(false);
            console.log(error);
            if (error.message == "Request failed with status code 403") {
              console.log("reauthenticate");
              setmessage("Please relogin again!");
              settitle("Not authorized");
              setmodalshow(true);
              setTimeout(() => {
                globalLogout();
                history.push("/");
              }, 1500);
            } else {
              console.log("error");
              setmessage("Some error occured, please try again in some time!");
              settitle("Error");
              setmodalshow(true);
              setTimeout(() => {
                setmodalshow(false);
              }, 2500);
            }
          });
      }
    }
  };

  const showRedBorder = (array) => {
    for (let index = 0; index < array.length; index++) {
      const element = document.getElementById(array[index]);
      if (element) {
        element.style.border = "2px solid red";
        if (element.parentNode.querySelector("label"))
          element.parentNode.querySelector("label").style.color = "red";
      }
    }
  };

  const removeRedBorder = (array) => {
    for (let index = 0; index < array.length; index++) {
      const element = document.getElementById(array[index]);
      if (element) {
        element.style.border = "1px solid #ced4da";
        if (element.parentNode.querySelector("label"))
          element.parentNode.querySelector("label").style.color = "slategray";
      }
    }
  };

  const changeColor = (array) => {
    for (let index = 0; index < array.length; index++) {
      const element = document.getElementById(array[index]);
      if (element) element.style.color = "red";
    }
  };

  const removeColor = (array) => {
    for (let index = 0; index < array.length; index++) {
      const element = document.getElementById(array[index]);
      if (element) element.style.color = "#007bff";
    }
  };

  const [showCreateResumeModal, setShowCreateResumeModal] = useState(false);
  function closeModalReplaceResume() {
    setShowCreateResumeModal(false);
  }

  useEffect(() => {
    const errorsObj = checkDetails(resume);
    console.log("errs", errorsObj);

    // personal info
    removeRedBorder(Object.keys(initialData.personalInformation));
    if (errorsObj.personalInformation.length > 0) {
      showRedBorder(errorsObj.personalInformation);
    }

    // miscellaneous
    removeRedBorder(Object.keys(initialData.miscellaneous));
    if (errorsObj.miscellaneous.length > 0) {
      showRedBorder(errorsObj.miscellaneous);
    }

    removeColor(
      Object.keys(workExperiences).map((keyIndex) => {
        return "workExperienceIcon_" + keyIndex;
      })
    );
    if (Object.keys(errorsObj.workExperience).length > 0) {
      const allKeys = Object.keys(errorsObj.workExperience).map((keyIndex) => {
        return "workExperienceIcon_" + keyIndex;
      });
      changeColor(allKeys);
    }

    removeColor(
      Object.keys(educations).map((keyIndex) => {
        return "educationIcon_" + keyIndex;
      })
    );
    if (Object.keys(errorsObj.education).length > 0) {
      const allKeys = Object.keys(errorsObj.education).map((keyIndex) => {
        return "educationIcon_" + keyIndex;
      });
      changeColor(allKeys);
    }

    setErrorsFinal(errorsObj);
  }, [resume]);

  useEffect(() => {
    console.log("workExperienceForm", workExperienceForm);
    const {
      company,
      jobTitle,
      startDate,
      endDate,
      experienceType,
      workLocation,
      currentlyWorkHere,
      description,
    } = workExperienceForm;
    const invalidFields = [];
    if (!company) {
      invalidFields.push("company");
    }
    if (!jobTitle) {
      invalidFields.push("jobTitle");
    }
    if (!startDate || !isValidDate(startDate)) {
      invalidFields.push("startDate");
    }
    if (currentlyWorkHere == false && (!endDate || !isValidDate(endDate))) {
      invalidFields.push("endDate");
    }
    if (!experienceType) {
      invalidFields.push("experienceType");
    }
    if (!workLocation) {
      invalidFields.push("workLocation");
    }
    if (!description) {
      invalidFields.push("description");
    }
    removeRedBorder(
      Object.keys(workExperienceForm).map((x) => {
        return "workExperienceForm_" + x;
      })
    );
    if (invalidFields.length > 0) {
      const keys = invalidFields.map((x) => {
        return "workExperienceForm_" + x;
      });
      showRedBorder(keys);
    }
  }, [workExperienceForm, editWorkIconClicked]);

  useEffect(() => {
    const { institution, degree, gpa, startDate, endDate } = education;
    const invalidFields = [];
    if (
      !institution ||
      !institution.name ||
      !institution.type ||
      !institution.location ||
      !institution.location.city ||
      !institution.location.state
    ) {
      if (!institution) {
        invalidFields.push("institution");
      } else {
        if (!institution.name) {
          invalidFields.push("institution.name");
        }
        if (!institution.type) {
          invalidFields.push("institution.type");
        }
        if (
          !institution.location ||
          !institution.location.city ||
          !institution.location.state
        ) {
          if (!institution.location) {
            invalidFields.push("institution.location");
          } else {
            if (!institution.location.city) {
              invalidFields.push("institution.location.city");
            }
            if (!institution.location.state) {
              invalidFields.push("institution.location.state");
            }
          }
        }
      }
    }
    if (!degree.degreeType) {
      invalidFields.push("degree.degreeType");
    }
    if (!degree.degreeName) {
      invalidFields.push("degree.degreeName");
    }

    // if (!gpa) {
    //   invalidFields.push("gpa");
    // }
    // if (!startDate || !isValidDate(startDate)) {
    //   invalidFields.push("startDate");
    // }
    // if (!endDate || !isValidDate(endDate)) {
    //   invalidFields.push("endDate");
    // }

    removeRedBorder([
      "endDate",
      "startDate",
      "gpa",
      "degree.degreeName",
      "degree.degreeType",
      "institution.location.state",
      "institution.location.city",
      "institution.location",
      "institution.type",
      "institution.name",
      "institution",
    ]);
    if (invalidFields.length > 0) {
      showRedBorder(invalidFields);
    }
  }, [education, editEducationIconClicked]);

  useEffect(() => {
    mixpanel.track("edit_profile_particular_resume_page dashboard", {
      href: window.location.href,
      email: userData?.email,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
  }, []);

  if (loading) {
    return (
      <div className="testingContainer">
        <h1>Edit Resume</h1>
        {/* Skeleton loading for personal information */}
        <Card className="skeleton-loading">
          <Card.Header className="header skeleton-loading"></Card.Header>
          <Card.Body className="skeleton-loading"></Card.Body>
          <Card className="skeleton-loading"></Card>
        </Card>
        <Card className="skeleton-loading">
          <Card.Header className="header skeleton-loading"></Card.Header>
          <Card.Body className="skeleton-loading"></Card.Body>
          <Card className="skeleton-loading"></Card>
        </Card>
      </div>
    );
  }

  async function downloadFileFromObject(fileObject) {
    // Add a cache-busting query parameter to the file's URL
    const cacheBustedUrl = `${fileObject.location}?_=${Date.now()}`;
    // Fetch the file content from the provided URL
    const response = await fetch(cacheBustedUrl);
    const fileContent = await response.blob();

    // Create a new Blob with the extracted content type
    const blob = new Blob([fileContent], { type: fileObject.mimetype });

    // Create a download link using the Blob
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.target = "_blank"; // Open the link in a new tab/window
    link.download = fileObject.originalname;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }

  function splitCamelCase(str) {
    return (
      str
        // insert a space before all capital letters
        .replace(/([A-Z])/g, " $1")
        // make the first character of the string uppercase
        .replace(/^./, function (str) {
          return str.toUpperCase();
        })
        // trim any leading/trailing white spaces
        .trim()
    );
  }

  return (
    <>
      <ModalResumeTracker
        show={showModalConfirm}
        handleClose={closeModalConfirm}
        resumeId={params.id}
        mainfileDetails={mainfileDetails}
        userData={userData}
        setResumeTrackerChanged={setResumeTrackerChanged}
        resumeMetaDetails={resumeMetaDetails}
      />
      <div className="testingContainer">
        <CustomAlert
          message={message}
          s={modalshow}
          title={title}
        ></CustomAlert>
        <ReplaceResumeModal
          isReplaced={isReplaced}
          showProp={showCreateResumeModal}
          hideShow={closeModalReplaceResume}
        />
        <h1>Edit Resume</h1>
        <button className="buttonGoBackV3 btn" onClick={goBack}>
          <i className="fas fa-arrow-left"></i> Go Back
        </button>
        <button
          className="buttonGoBackV3 replaceResume btn"
          onClick={() => {
            mixpanel.track(
              "replace_resume_button edit_profile_particular_resume_page dashboard",
              {
                href: window.location.href,
                email: userData?.email,
                plan_details: userData?.planDetails || {},
                v2Id: userData?.planDetails?.v2Id || "",
              }
            );
            setShowCreateResumeModal(true);
            setReplaced(false);
          }}
        >
          Replace Resume
        </button>

        <div className="uploadbox">
          <p>Your Uploaded Resume </p>
          <div className="resume-view">
            <div className="resume-view-img">
              <img className="stripeimgsecure" src={"/assests/pdf.svg"}></img>
            </div>
            <div className="resume-view-label">
              {mainfileDetails.originalname}
              {"  "}({resumeName != "" ? resumeName : ""})
            </div>
            {mainfileDetails?.mimetype &&
              mainfileDetails.mimetype.includes("pdf") && (
                <></>
                // <div
                //   className="resume-view-cancel"
                //   onClick={() => {
                //     setShowModalConfirm(true);
                //   }}
                // >
                //   Track Your Resume
                // </div>
              )}
            <div
              className="resume-view-cancel"
              onClick={() => {
                mixpanel.track(
                  "view_resume_button edit_profile_particular_resume_page dashboard",
                  {
                    href: window.location.href,
                    email: userData?.email,
                    plan_details: userData?.planDetails || {},
                    v2Id: userData?.planDetails?.v2Id || "",
                  }
                );
                downloadFileFromObject(mainfileDetails);
              }}
            >
              View
            </div>
          </div>
        </div>
        {/* {resumeMetaDetails.resumeTrackerUserId && (
          <p>
            Your Resume Tracker Url -{" "}
            <a
              target="_blank"
              href={`https://${resumeMetaDetails.resumeTrackerUserId}.rsme.io`}
            >
              https://{resumeMetaDetails.resumeTrackerUserId}.rsme.io
            </a>
          </p>
        )} */}
        <Card>
          <Card.Header
            className="header"
            onClick={togglePersonalInfoOpen}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span
              style={{ textAlign: "left", fontSize: "20px", color: "black" }}
            >
              Personal Information
            </span>
            {personalInfoOpen ? (
              <i className="fas fa-angle-up"></i>
            ) : (
              <i className="fas fa-angle-down"></i>
            )}
          </Card.Header>
          <div
            style={{
              overflow: "hidden",
              transition: "max-height 0.5s ease-in-out",
              maxHeight: personalInfoOpen ? "1000px" : "0",
            }}
          >
            <Card.Body>
              <Row>
                <Col md={12} style={{ textAlign: "left" }}>
                  {errorsFinal["personalInformation"].length > 0 ? (
                    <p style={{ color: "red" }}>Some fields are not filled.</p>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col md={6}>
                  <Row>
                    <Col
                      md={4}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "70px",
                          height: "70px",
                          borderRadius: "50%",
                          backgroundColor: "slategray",
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "2rem",
                          margin: "auto",
                        }}
                      >
                        {resume.personalInformation.firstName.charAt(0)}
                        {resume.personalInformation.lastName.charAt(0)}
                      </div>
                    </Col>
                    <Col md={8}>
                      <Form.Group>
                        <Form.Label>First Name *</Form.Label>
                        <Form.Control
                          id="firstName"
                          type="text"
                          value={resume.personalInformation.firstName}
                          onChange={handleInputChange(
                            "personalInformation",
                            "firstName"
                          )}
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Last Name *</Form.Label>
                        <Form.Control
                          type="text"
                          id="lastName"
                          value={resume.personalInformation.lastName}
                          onChange={handleInputChange(
                            "personalInformation",
                            "lastName"
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group>
                    <div className="input-container">
                      <Image
                        src={"/assests/github-icon.png"}
                        alt="Github Logo"
                        className="input-icon"
                        roundedCircle
                      />
                      <Form.Control
                        type="text"
                        placeholder="Github Url ( Optional )"
                        aria-describedby="urlHelpBlock"
                        value={resume.miscellaneous.githubUrl}
                        onChange={handleInputChange(
                          "miscellaneous",
                          "githubUrl"
                        )}
                        className="input-field"
                      />
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <div className="input-container">
                      <Image
                        src={"/assests/linkedin-icon.png"}
                        alt="Linkedin Logo"
                        className="input-icon"
                      />
                      <Form.Control
                        type="text"
                        placeholder="Linkedin Url ( Optional )"
                        value={resume.miscellaneous.linkedinUrl}
                        onChange={handleInputChange(
                          "miscellaneous",
                          "linkedinUrl"
                        )}
                        className="input-field"
                      />
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <div className="input-container">
                      <Image
                        src={"/assests/dribble-icon.png"}
                        alt="Dribble Logo"
                        className="input-icon"
                        roundedCircle
                      />
                      <Form.Control
                        type="text"
                        placeholder="Dribble Url ( Optional )"
                        value={resume.miscellaneous.dribbleUrl}
                        onChange={handleInputChange(
                          "miscellaneous",
                          "dribbleUrl"
                        )}
                        className="input-field"
                      />
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <div className="input-container">
                      <Image
                        src={"/assests/portfolio-icon.png"}
                        alt="Portfolio Logo ( Optional )"
                        className="input-icon"
                        roundedCircle
                      />
                      <Form.Control
                        type="text"
                        placeholder="Portfolio Url"
                        value={resume.miscellaneous.portfolioUrl}
                        onChange={handleInputChange(
                          "miscellaneous",
                          "portfolioUrl"
                        )}
                        className="input-field"
                      />
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <div className="input-container">
                      <Form.Control
                        type="text"
                        placeholder="Other Url ( Optional )"
                        value={resume.personalInformation.githubUrl}
                        onChange={handleInputChange(
                          "miscellaneous",
                          "otherUrl"
                        )}
                        className="input-field"
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form>
                    <Form.Row>
                      <Form.Group as={Col} md={6}>
                        <Form.Label>Phone Number *</Form.Label>
                        <Form.Control
                          type="number"
                          id="phoneNumber"
                          value={resume.personalInformation.phoneNumber}
                          onChange={handleInputChange(
                            "personalInformation",
                            "phoneNumber"
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md={6}>
                        <Form.Label>Gender *</Form.Label>
                        <Form.Control
                          as="select"
                          id="gender"
                          value={resume.personalInformation.gender}
                          onChange={handleInputChange(
                            "personalInformation",
                            "gender"
                          )}
                        >
                          <option value="">Select...</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Not To Say">Not To Say</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md={6}>
                        <Form.Label>City *</Form.Label>
                        <Form.Control
                          type="text"
                          id="city"
                          value={resume.personalInformation.city}
                          onChange={handleInputChange(
                            "personalInformation",
                            "city"
                          )}
                        />
                      </Form.Group>

                      <Form.Group as={Col} md={6}>
                        <Form.Label>State *</Form.Label>
                        <Form.Control
                          type="text"
                          id="state"
                          value={resume.personalInformation.state}
                          onChange={handleInputChange(
                            "personalInformation",
                            "state"
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Group>
                      <Form.Label>Email *</Form.Label>
                      <Form.Control
                        type="email"
                        id="email"
                        value={resume.personalInformation.email}
                        onChange={handleInputChange(
                          "personalInformation",
                          "email"
                        )}
                      />
                    </Form.Group>
                    <Form.Row>
                      <Form.Group as={Col} md={12}>
                        <Form.Label>Address *</Form.Label>
                        <Form.Control
                          type="text"
                          id="address"
                          value={resume.personalInformation.address}
                          onChange={handleInputChange(
                            "personalInformation",
                            "address"
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Group>
                      <Form.Group>
                        <Form.Label htmlFor="countryCode">
                          Phone Country Code*
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={resume.personalInformation.countryCode}
                          id="countryCode"
                          onChange={handleInputChange(
                            "personalInformation",
                            "countryCode"
                          )}
                        >
                          {data.country_codes.map((v) => {
                            if (v == "Select an option") {
                              return <option value={""}>{v}</option>;
                            } else return <option value={v}>{v}</option>;
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Country *</Form.Label>
                      <Form.Control
                        type="text"
                        id="country"
                        value={resume.personalInformation.country}
                        onChange={handleInputChange(
                          "personalInformation",
                          "country"
                        )}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Zip Code *</Form.Label>
                      <Form.Control
                        type="text"
                        id="zipCode"
                        value={resume.personalInformation.zipCode}
                        onChange={handleInputChange(
                          "personalInformation",
                          "zipCode"
                        )}
                      />
                    </Form.Group>
                  </Form>
                </Col>
                <Col md={4}>
                  <Form></Form>
                </Col>
              </Row>
            </Card.Body>
          </div>
        </Card>

        <Modal show={showModal} onHide={closeModal}>
          <Form onSubmit={handleWorkExperienceSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Work Experience</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* {workExperienceError && (
              <p
                style={{ color: "brown", fontSize: "16px", textAlign: "left" }}
              >
                Please complete all required fields
              </p>
            )} */}
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="company"
                      id="workExperienceForm_company"
                      value={workExperienceForm.company}
                      onChange={handleWorkExperienceChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Job Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="jobTitle"
                      id="workExperienceForm_jobTitle"
                      value={workExperienceForm.jobTitle}
                      onChange={handleWorkExperienceChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                      type="text"
                      name="workLocation"
                      id="workExperienceForm_workLocation"
                      value={workExperienceForm.workLocation}
                      onChange={handleWorkExperienceChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Experience Type</Form.Label>
                    <Form.Control
                      as="select"
                      value={workExperienceForm.experienceType}
                      id="workExperienceForm_experienceType"
                      onChange={(e) =>
                        setWorkExperienceForm({
                          ...workExperienceForm,
                          experienceType: e.target.value,
                        })
                      }
                    >
                      <option value="">Select...</option>
                      <option value="internship">Internship</option>
                      <option value="fulltime">Full-Time</option>
                      <option value="parttime">Part-Time</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      id="workExperienceForm_startDate"
                      type="date"
                      name="startDate"
                      value={workExperienceForm.startDate}
                      onChange={handleWorkExperienceChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      id="workExperienceForm_endDate"
                      name="endDate"
                      value={workExperienceForm.endDate}
                      onChange={handleWorkExperienceChange}
                      disabled={workExperienceForm.currentlyWorkHere}
                    />
                  </Form.Group>
                </Col>
                <Col className="d-flex align-items-center">
                  <Form.Group>
                    <Form.Check
                      type="checkbox"
                      label="Currently Work Here"
                      name="currentlyWorkHere"
                      id="workExperienceForm_currentlyWorkHere"
                      checked={workExperienceForm.currentlyWorkHere}
                      onChange={(e) => {
                        setWorkExperienceForm({
                          ...workExperienceForm,
                          currentlyWorkHere: e.target.checked,
                          endDate: "",
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  id="workExperienceForm_description"
                  value={workExperienceForm.description}
                  onChange={handleWorkExperienceChange}
                />
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                {editingExperienceIndex !== null
                  ? "Update Experience"
                  : "Add Experience"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Card>
          <>
            <Card.Header className="header">
              <div className="d-flex justify-content-between align-items-center">
                <span
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                    color: "black",
                  }}
                >
                  Work Experience
                </span>
                <Button
                  variant="primary"
                  onClick={openModal}
                  className="add-experience-btn"
                >
                  Add Another Experience
                </Button>
              </div>
            </Card.Header>

            <Card.Body>
              {Object.keys(errorsFinal.workExperience).length > 0 && (
                <p
                  style={{
                    color: "brown",
                    fontSize: "16px",
                    textAlign: "left",
                  }}
                >
                  Some fields are incorrect or not filled properly. Please check
                  all the fields by clicking on edit icon
                </p>
              )}
              {workExperiences.length === 0 ? (
                <span style={{ color: "gray" }}>No experience added</span>
              ) : (
                <>
                  {workExperiences.map((workExperience, index) => (
                    <div key={index} className="experience-section">
                      <div className="experience-icons">
                        <div className="icons">
                          <i
                            className="fas fa-edit mr-2 icon"
                            id={`workExperienceIcon_${index}`}
                            onClick={() => {
                              handleWorkExperienceEdit(index);
                              setEditWorkIconClicked(!editWorkIconClicked);
                            }}
                          ></i>
                          <i
                            className="fas fa-trash-alt icon"
                            onClick={() => handleWorkExperienceDelete(index)}
                          ></i>
                        </div>
                      </div>
                      <div className="experience-maindiv">
                        <div className="experience-mainicon">
                          <img src="/assests/work-experience.png"></img>
                        </div>
                        <div className="experience-details">
                          <div>
                            <span className="job-title">
                              {workExperience.jobTitle}
                            </span>{" "}
                            |{" "}
                            <span className="job-title">
                              {workExperience.experienceType}
                            </span>
                          </div>

                          <div className="job-company">
                            <span className="company-name">
                              {workExperience.company}
                            </span>
                            <div class="mx-1">•</div>
                            <span className="company-name">
                              {workExperience.workLocation}
                            </span>
                          </div>
                          <p className="date-range">
                            {workExperience.startDate} -{" "}
                            {workExperience.currentlyWorkHere
                              ? "Present"
                              : workExperience.endDate}
                          </p>
                          {/* <p className="salary">{workExperience.annualSalary}</p> */}
                          <p className="description">
                            {formattedText(workExperience.description)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </Card.Body>
          </>
        </Card>

        <Card>
          <Card.Header className="header">
            <div className="d-flex justify-content-between align-items-center">
              <span
                style={{ textAlign: "left", fontSize: "20px", color: "black" }}
              >
                Education
              </span>
              <Button
                variant="primary"
                onClick={() => setShowEducationModal(true)}
                className="add-experience-btn"
              >
                Add Education
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            {Object.keys(errorsFinal.education).length > 0 && (
              <p
                style={{ color: "brown", fontSize: "16px", textAlign: "left" }}
              >
                Some fields are incorrect or not filled properly. Please check
                all the fields by clicking on edit icon
              </p>
            )}
            {educations.length === 0 ? (
              <span style={{ color: "gray" }}>No education added</span>
            ) : (
              <div>
                {educations.map((item, index) => (
                  <div key={index} className="experience-section">
                    <div className="experience-icons">
                      <div className="icons">
                        <i
                          className="fas fa-edit mr-2 icon"
                          id={`educationIcon_${index}`}
                          onClick={() => {
                            handleEditEducation(index);
                            setEditEducationIconClicked(
                              !editEducationIconClicked
                            );
                          }}
                        ></i>
                        <i
                          className="fas fa-trash-alt icon"
                          onClick={() => handleDeleteEducation(index)}
                        ></i>
                      </div>
                    </div>

                    <div className="experience-maindiv">
                      <div className="experience-mainicon achievement-mainicon">
                        <img src="/assests/education.png"></img>
                      </div>
                      <div className="experience-details">
                        <p className="job-title">{item.institution.name}</p>
                        <div className="job-company">
                          <span className="company-name">
                            {item.degree.degreeType}
                          </span>

                          <div class="mx-1">•</div>
                          <span className="company-name">
                            {item.degree.degreeName}
                          </span>
                        </div>
                        {item?.startDate && (
                          <p className="date-range">
                            {item.startDate} - {item.endDate}
                          </p>
                        )}
                        <p style={{ fontSize: "15px", color: "gray" }}>
                          Location -{item.institution.location.city},{" "}
                          {item.institution.location.state}
                        </p>
                        {item?.gpa && (
                          <p style={{ fontSize: "15px", color: "gray" }}>
                            GPA - <span>{item.gpa}</span>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Card.Body>
        </Card>

        <Card>
          <Card.Header className="header">
            <div className="d-flex justify-content-between align-items-center">
              <span>Skills</span>
            </div>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Row>
                {errorsFinal["skills"] == 1 ? (
                  <p style={{ color: "red" }}>Add atleast 4 skills</p>
                ) : (
                  <></>
                )}
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={7}>
                  <Form.Label>Skill</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter a skill"
                    value={skill}
                    onChange={(e) => setSkill(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} md={3}>
                  <Form.Label>Years of Experience</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Years"
                    value={experience}
                    onChange={(e) => setExperience(e.target.value)}
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  md={2}
                  className="d-flex align-items-end addskill"
                >
                  <Button variant="primary" onClick={handleAddSkill}>
                    Add
                  </Button>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Label>
                  Default Experience In Case Added Skills Not Found ( Integer ){" "}
                </Form.Label>
                <Form.Control
                  type="number"
                  id="totalExperience"
                  value={resume.miscellaneous.defaultExperience}
                  onChange={handleInputChange(
                    "miscellaneous",
                    "defaultExperience"
                  )}
                />
              </Form.Row>
              {error && (
                <p
                  style={{
                    color: "brown",
                    fontSize: "16px",
                    textAlign: "left",
                  }}
                >
                  {error}
                </p>
              )}

              <div className="skills-container">
                {skills.map((s, i) => (
                  <div key={i} className="skill-button">
                    <span className="skill-name">
                      {s.skill} - {s.experience} years
                    </span>
                    <span
                      className="delete-skill"
                      onClick={() => handleDeleteSkill(i)}
                    >
                      &#x2715;
                    </span>
                  </div>
                ))}
              </div>
            </Form>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header className="header">Languages</Card.Header>
          <Card.Body>
            <Form>
              <Form.Row>
                {errorsFinal["languages"] == 1 ? (
                  <p style={{ color: "red" }}>Add atleast 1 language</p>
                ) : (
                  <></>
                )}
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={7}>
                  <Form.Label>Language</Form.Label>
                  <Form.Control
                    as="select"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                  >
                    <option value="">Select a language...</option>
                    {languageOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} md={3}>
                  <Form.Label>Proficiency Level</Form.Label>
                  <Form.Control
                    as="select"
                    value={level}
                    onChange={(e) => setLevel(e.target.value)}
                  >
                    <option value="">Select a level...</option>
                    <option value="Conversational">Conversational</option>
                    <option value="Professional">Professional</option>
                    <option value="Native">Native</option>
                    <option value="Bilingual">Bilingual</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group
                  as={Col}
                  md={2}
                  className="d-flex align-items-end addskill"
                >
                  <Button variant="primary" onClick={handleAddLanguage}>
                    Add
                  </Button>
                </Form.Group>
              </Form.Row>

              {languageError && (
                <p
                  style={{
                    color: "brown",
                    fontSize: "16px",
                    textAlign: "left",
                  }}
                >
                  {languageError}
                </p>
              )}

              <div className="skills-container">
                {languages.map((lang, index) => (
                  <div key={index} className="skill-button">
                    <span className="skill-name">
                      {lang.language} - {lang.level}
                    </span>
                    <span
                      className="delete-skill"
                      onClick={() => handleDeleteLanguage(index)}
                    >
                      &#x2715;
                    </span>
                  </div>
                ))}
              </div>
            </Form>
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Header className="header">
            <div className="d-flex justify-content-between align-items-center">
              <span>Achievements</span>
              <Button
                variant="primary"
                onClick={() => setShowAcheivementModal(true)}
                className="add-experience-btn"
              >
                Add Achievement
              </Button>
            </div>
          </Card.Header>

          <Card.Body>
            {achievements.length === 0 ? (
              <span style={{ color: "gray" }}>No achievement added</span>
            ) : (
              <>
                {achievements.map((achievement, index) => (
                  <div key={index} className="experience-section">
                    <div className="experience-icons">
                      <div className="icons">
                        <i
                          className="fas fa-edit mr-2 icon"
                          onClick={() => handleEditAchievement(index)}
                        ></i>
                        <i
                          className="fas fa-trash-alt icon"
                          onClick={() => handleDeleteAchievement(index)}
                        ></i>
                      </div>
                    </div>

                    <div className="experience-maindiv">
                      <div className="experience-mainicon achievement-mainicon">
                        <img src="/assests/achievement.png"></img>
                      </div>
                      <div className="experience-details">
                        <p className="job-title">{achievement.awardTitle}</p>
                        <div className="job-company">
                          <span className="company-name">
                            {achievement.issuer}
                          </span>
                          <div class="mx-1">•</div>
                          <span className="company-name">
                            {achievement.issuingDate}
                          </span>
                        </div>
                        <p className="description">
                          {formattedText(achievement.description)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </Card.Body>
          <Modal
            show={showAchievementModal}
            onHide={() => setShowAcheivementModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Certification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {achievementError && (
                <p
                  style={{
                    color: "brown",
                    fontSize: "16px",
                    textAlign: "left",
                  }}
                >
                  {achievementError}
                </p>
              )}

              <Form onSubmit={handleAddAchievement}>
                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>Award Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter the award title"
                      value={awardTitle}
                      onChange={(e) => setAwardTitle(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md={6}>
                    <Form.Label>Issuer</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter the issuer"
                      value={issuer}
                      onChange={(e) => setIssuer(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md={12}>
                    <Form.Label>Issuing Date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter the issuing date"
                      value={issuingDate}
                      onChange={(e) => setIssuingDate(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md={12}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Group>
                  {/* <Form.Group as={Col} md={2} className="d-flex align-items-end">
                  <Button variant="primary" type="submit">
                    {editIndex !== -1 ? "Save" : "Add"}
                  </Button>
                </Form.Group> */}
                </Form.Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowAcheivementModal(false)}
              >
                Close
              </Button>
              <Button variant="primary" onClick={handleAddAchievement}>
                {editIndex !== -1 ? "Update Achievement" : "Add Achievement"}
              </Button>
            </Modal.Footer>
          </Modal>
        </Card>

        <Card>
          <Card.Header className="header">
            <div className="d-flex justify-content-between align-items-center">
              <span>Certifications</span>
              <Button
                variant="primary"
                onClick={() => setShowCertificationModal(true)}
                className="add-experience-btn"
              >
                Add Certification
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            {certifications.length === 0 ? (
              <span style={{ color: "gray" }}>No certification added</span>
            ) : (
              <>
                {certifications.map((certification, index) => (
                  <div key={index} className="experience-section">
                    <div className="experience-icons">
                      <div className="icons">
                        <i
                          className="fas fa-edit mr-2 icon"
                          onClick={() => handleEditCertification(index)}
                        ></i>
                        <i
                          className="fas fa-trash-alt icon"
                          onClick={() => handleDeleteCertification(index)}
                        ></i>
                      </div>
                    </div>

                    <div className="experience-maindiv">
                      <div className="experience-mainicon achievement-mainicon">
                        <img src="/assests/certification.png"></img>
                      </div>
                      <div className="experience-details">
                        <p className="job-title">
                          {certification.certificationTitle}
                        </p>
                        <p className="date-range">
                          {certification.startDate} - {certification.endDate}
                        </p>
                        <p className="description">
                          <a href={certification.certificationUrl}>
                            <i className="fas fa-link"></i> Certification URL
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </Card.Body>

          <Modal
            show={showCertificationModal}
            onHide={() => setShowCertificationModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Certification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {certificationError && (
                  <p
                    style={{
                      color: "brown",
                      fontSize: "16px",
                      textAlign: "left",
                    }}
                  >
                    {certificationError}
                  </p>
                )}
                <Form.Row>
                  <Form.Group as={Col} md={12}>
                    <Form.Label>Certification Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter certification title"
                      value={certificationTitle}
                      onChange={(e) => setCertificationTitle(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md={6}>
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter start date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md={6}>
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter end date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md={12}>
                    <Form.Label>Certification URL</Form.Label>
                    <Form.Control
                      type="url"
                      placeholder="Enter certification URL"
                      value={certificationUrl}
                      onChange={(e) => setCertificationUrl(e.target.value)}
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowCertificationModal(false)}
              >
                Close
              </Button>
              <Button variant="primary" onClick={handleAddCertification}>
                {editingCertificationIndex !== null
                  ? "Update Certification"
                  : "Add Certification"}
              </Button>
            </Modal.Footer>
          </Modal>
        </Card>

        {/* Education Modal */}
        <Modal
          show={showEducationModal}
          onHide={() => setShowEducationModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Education</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Row>
                <Form.Group as={Col} md={12}>
                  <Form.Label>Institution Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter institution name"
                    id="institution.name"
                    value={education.institution.name}
                    onChange={(e) =>
                      setEducation({
                        ...education,
                        institution: {
                          ...education.institution,
                          name: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>Institution Type</Form.Label>
                  <Form.Control
                    as="select"
                    id="institution.type"
                    value={education.institution.type}
                    onChange={(e) =>
                      setEducation({
                        ...education,
                        institution: {
                          ...education.institution,
                          type: e.target.value,
                        },
                      })
                    }
                  >
                    <option value="">Select Institution Type</option>
                    <option value="University">University</option>
                    <option value="School">School</option>
                    <option value="College">College</option>
                    {/* Add more options as needed */}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>Institution City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter institution city"
                    value={education.institution.location.city}
                    id="institution.location.city"
                    onChange={(e) =>
                      setEducation({
                        ...education,
                        institution: {
                          ...education.institution,
                          location: {
                            ...education.institution.location,
                            city: e.target.value,
                          },
                        },
                      })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>Institution State</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter institution state"
                    value={education.institution.location.state}
                    id="institution.location.state"
                    onChange={(e) =>
                      setEducation({
                        ...education,
                        institution: {
                          ...education.institution,
                          location: {
                            ...education.institution.location,
                            state: e.target.value,
                          },
                        },
                      })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label> Major</Form.Label>
                  <Select
                    value={{
                      label: education.degree.degreeName,
                      value: education.degree.degreeName,
                    }}
                    id="degree.degreeName"
                    onChange={(value) => {
                      setEducation({
                        ...education,
                        degree: {
                          ...education.degree,
                          degreeName: value.label,
                        },
                      });
                    }}
                    placeholder="Select Major"
                    options={[
                      ...educationMajorOptions.map((x) => {
                        return { value: x, label: x };
                      }),
                    ]}
                    isMulti={false}
                    filterOption={filterOption}
                    noOptionsMessage={() => "No more options"}
                  />
                </Form.Group>

                <Form.Group as={Col} md={6}>
                  <Form.Label>Degree Type</Form.Label>
                  <Form.Control
                    as="select"
                    id="degree.degreeType"
                    value={education.degree.degreeType}
                    onChange={(e) =>
                      setEducation({
                        ...education,
                        degree: {
                          ...education.degree,
                          degreeType: e.target.value,
                        },
                      })
                    }
                  >
                    <option value="">Select...</option>
                    {degreeTypeOptions.map((v) => {
                      return <option value={v}>{v}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>GPA ( Optional ) </Form.Label>
                  <Form.Control
                    type="number"
                    max={10}
                    id="gpa"
                    value={education.gpa}
                    onChange={(e) =>
                      setEducation({ ...education, gpa: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>Start Date ( Optional )</Form.Label>
                  <Form.Control
                    type="date"
                    id="startDate"
                    value={education.startDate}
                    onChange={(e) =>
                      setEducation({ ...education, startDate: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>End Date ( Optional )</Form.Label>
                  <Form.Control
                    type="date"
                    id="endDate"
                    value={education.endDate}
                    onChange={(e) =>
                      setEducation({ ...education, endDate: e.target.value })
                    }
                  />
                </Form.Group>
                {educationError && (
                  <p
                    style={{
                      color: "brown",
                      fontSize: "16px",
                      textAlign: "left",
                    }}
                  >
                    {educationError}
                  </p>
                )}
              </Form.Row>
            </Form>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowEducationModal(false)}
              >
                Close
              </Button>
              <Button variant="primary" onClick={handleAddEducation}>
                {editingIndex !== -1 ? "Update Education" : "Add Education"}
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>

        <Card className="mb-4">
          <Card.Header
            className="header"
            onClick={toggleMiscellaneousInfoOpen}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span
              style={{ textAlign: "left", fontSize: "20px", color: "black" }}
            >
              Miscellaneous
            </span>
            {/* {miscellaneousInfoOpen ? (
            <i className="fas fa-angle-up"></i>
          ) : (
            <i className="fas fa-angle-down"></i>
          )} */}
          </Card.Header>
          {/* <div
          style={{
            overflow: "hidden",
            transition: "max-height 0.5s ease-in-out",
            maxHeight: miscellaneousInfoOpen ? "1000px" : "0",
          }}
        > */}
          <Card.Body>
            <Form>
              <Form.Row>
                {errorsFinal["miscellaneous"].length > 0 ? (
                  <p style={{ color: "red" }}>Some fields are not filled.</p>
                ) : (
                  <></>
                )}
              </Form.Row>
              <Form.Row>
                <Form.Group controlId="formTotalExperience" as={Col} md={6}>
                  <Form.Label>Total Experience ( In Years ) *</Form.Label>
                  <Form.Control
                    type="number"
                    id="totalExperience"
                    value={resume.miscellaneous.totalExperience}
                    onChange={handleInputChange(
                      "miscellaneous",
                      "totalExperience"
                    )}
                  />
                </Form.Group>

                <Form.Group controlId="formVeteranStatus" as={Col} md={6}>
                  <Form.Label>Veteran Status *</Form.Label>
                  <Form.Control
                    as="select"
                    value={resume.miscellaneous.veteranStatus}
                    id="veteranStatus"
                    onChange={handleInputChange(
                      "miscellaneous",
                      "veteranStatus"
                    )}
                  >
                    {veteran_options.map((v) => {
                      return <option value={v}>{v}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formDisability" as={Col} md={6}>
                  <Form.Label>Disability *</Form.Label>
                  <Form.Control
                    as="select"
                    id="disability"
                    value={resume.miscellaneous.disability}
                    onChange={handleInputChange("miscellaneous", "disability")}
                  >
                    {disability_options.map((v) => {
                      return <option value={v}>{v}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formWillingToRelocate" as={Col} md={6}>
                  <Form.Label>Willing to relocate ? *</Form.Label>
                  <Form.Control
                    as="select"
                    value={resume.miscellaneous.willingToRelocation}
                    id="willingToRelocation"
                    onChange={handleInputChange(
                      "miscellaneous",
                      "willingToRelocation"
                    )}
                  >
                    {relocate_options.map((v) => {
                      return <option value={v}>{v}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formRaceAndEthnicity" as={Col} md={6}>
                  <Form.Label>Race / Ethnicity *</Form.Label>
                  <Form.Control
                    as="select"
                    value={resume.miscellaneous.race}
                    id="race"
                    onChange={handleInputChange("miscellaneous", "race")}
                  >
                    {race_ethnicity_options.map((v) => {
                      return <option value={v}>{v}</option>;
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={12}>
                    Expected Salary *
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type="number"
                      placeholder="Salary Number"
                      id="expectedSalaryAmount"
                      value={resume.miscellaneous.expectedSalaryAmount}
                      onChange={handleInputChange(
                        "miscellaneous",
                        "expectedSalaryAmount"
                      )}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Control
                      as="select"
                      value={resume.miscellaneous.expectedSalaryCurrency}
                      id="expectedSalaryCurrency"
                      onChange={handleInputChange(
                        "miscellaneous",
                        "expectedSalaryCurrency"
                      )}
                    >
                      <option value="">Select Currency</option>
                      {currencyOptions.map((option) => (
                        <option key={option.code} value={option.code}>
                          {option.code} - {option.name}
                        </option>
                      ))}
                      {/* Add more currency options as needed */}
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={12}>
                    Current Salary ( Optional )
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type="number"
                      placeholder="Salary Number"
                      value={resume.miscellaneous.currentSalary}
                      onChange={handleInputChange(
                        "miscellaneous",
                        "currentSalary"
                      )}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Control
                      as="select"
                      value={resume.miscellaneous.currentSalaryCurrency}
                      onChange={handleInputChange(
                        "miscellaneous",
                        "currentSalaryCurrency"
                      )}
                    >
                      <option value="">Select Currency</option>
                      {currencyOptions.map((option) => (
                        <option key={option.code} value={option.code}>
                          {option.code} - {option.name}
                        </option>
                      ))}
                      {/* Add more currency options as needed */}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group controlId="formNoticePeriod" as={Col} md={6}>
                  <Form.Label>Notice Period ( In Days ) * </Form.Label>

                  <Form.Control
                    type="number"
                    className="mr-2"
                    id="noticePeriod"
                    placeholder="60 or 90 or 30 etc."
                    value={resume.miscellaneous.noticePeriod}
                    onChange={handleInputChange(
                      "miscellaneous",
                      "noticePeriod"
                    )}
                  />
                </Form.Group>

                <Form.Group controlId="formDrivingLicense" as={Col} md={6}>
                  <Form.Label>Driving License *</Form.Label>
                  <Form.Control
                    as="select"
                    id="drivingLicense"
                    value={resume.miscellaneous.drivingLicense}
                    onChange={handleInputChange(
                      "miscellaneous",
                      "drivingLicense"
                    )}
                  >
                    {valid_driver_license_options.map((v) => {
                      return <option value={v}>{v}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formDrivingLicense" as={Col} md={6}>
                  <Form.Label>Highest Education Level *</Form.Label>
                  <Form.Control
                    as="select"
                    id="highestEducationLevel"
                    value={resume.miscellaneous.highestEducationLevel}
                    onChange={handleInputChange(
                      "miscellaneous",
                      "highestEducationLevel"
                    )}
                  >
                    {degreeTypeOptions.map((v) => {
                      return <option value={v}>{v}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group
                  controlId="formExpectedDateOfJoining"
                  as={Col}
                  md={6}
                >
                  <Form.Label>Expected Date of Joining *</Form.Label>
                  <Form.Control
                    type="date"
                    id="expectedDateOfJoining"
                    value={resume.miscellaneous.expectedDateOfJoining}
                    onChange={handleInputChange(
                      "miscellaneous",
                      "expectedDateOfJoining"
                    )}
                  />
                </Form.Group>

                <Form.Group
                  controlId="formExpectedDateOfJoining"
                  as={Col}
                  md={12}
                >
                  <Form.Label>
                    Companies to exclude from applying (comma seperated)
                    (Optional)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="google, amazon, stripe"
                    value={resume.miscellaneous.companiesToExclude}
                    onChange={handleInputChange(
                      "miscellaneous",
                      "companiesToExclude"
                    )}
                  />
                </Form.Group>
                <Form.Group controlId="formVisaRequirement" as={Col} md={12}>
                  <Form.Label>
                    Will you now, or in the future, require sponsorship for
                    employment visa status (e.g. H-1B visa status)? *
                  </Form.Label>
                  <Form.Control
                    as="select"
                    id="visaRequirement"
                    value={resume.miscellaneous.visaRequirement}
                    onChange={handleInputChange(
                      "miscellaneous",
                      "visaRequirement"
                    )}
                  >
                    {visa_status_options.map((v) => {
                      return <option value={v}>{v}</option>;
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} md={12} style={{ display: "flex" }}>
                  <Form.Label style={{ marginRight: "10px" }}>
                    Do you have an active security clearance? (Optional)
                  </Form.Label>
                  <Form.Check
                    style={{ marginRight: "10px" }}
                    type="radio"
                    label="Yes"
                    name="securityClearance"
                    value="Yes"
                    checked={
                      resume.miscellaneous.securityClearanceActive === "Yes"
                    }
                    onChange={handleInputChange(
                      "miscellaneous",
                      "securityClearanceActive"
                    )}
                  />
                  <Form.Check
                    style={{ marginRight: "10px" }}
                    type="radio"
                    label="No"
                    name="securityClearance"
                    value="No"
                    checked={
                      resume.miscellaneous.securityClearanceActive === "No"
                    }
                    onChange={handleInputChange(
                      "miscellaneous",
                      "securityClearanceActive"
                    )}
                  />
                  <Form.Check
                    type="radio"
                    label="Not applicable"
                    name="securityClearance"
                    value="Not applicable"
                    checked={
                      resume.miscellaneous.securityClearanceActive ===
                      "Not applicable"
                    }
                    onChange={handleInputChange(
                      "miscellaneous",
                      "securityClearanceActive"
                    )}
                  />
                </Form.Group>

                {resume.miscellaneous.securityClearanceActive === "Yes" && (
                  <Form.Group as={Col} md={12}>
                    <Form.Label>
                      If yes, please select the type of security clearance you
                      hold:
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={resume.miscellaneous.securityClearanceType}
                      onChange={handleInputChange(
                        "miscellaneous",
                        "securityClearanceType"
                      )}
                    >
                      <option value="">Select an option:</option>
                      <option value="Confidential">Confidential</option>
                      <option value="Secret">Secret</option>
                      <option value="Top Secret">Top Secret</option>
                      <option value="Other">Other (Please specify)</option>
                    </Form.Control>
                    {resume.miscellaneous.securityClearanceType === "Other" && (
                      <Form.Control
                        style={{ marginTop: "20px" }}
                        type="text"
                        value={resume.miscellaneous.otherSecurityClearanceType}
                        onChange={handleInputChange(
                          "miscellaneous",
                          "otherSecurityClearanceType"
                        )}
                        placeholder="Please specify"
                      />
                    )}
                  </Form.Group>
                )}
                <Form.Group as={Col} md={12}>
                  <Form.Label>
                    Countries you are authorised to work ? *
                  </Form.Label>
                  <Select
                    isMulti
                    id="authorisedToWork"
                    onChange={(value) => {
                      setResume({
                        ...resume,
                        miscellaneous: {
                          ...resume["miscellaneous"],
                          authorisedToWork: value,
                        },
                      });
                    }}
                    value={resume.miscellaneous?.authorisedToWork}
                    name="authorisedToWork"
                    placeholder="Select Country:"
                    options={countryOptions.map((x) => {
                      return { label: x.name, value: x.name };
                    })}
                  />
                </Form.Group>
                <Form.Group as={Col} md={12}>
                  <Form.Label>Cover Letter ( Optional )</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Your cover letter here.."
                    value={resume.miscellaneous.coverLetter}
                    onChange={handleInputChange("miscellaneous", "coverLetter")}
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </Card.Body>
          {/* </div> */}
        </Card>

        <div className="save-button-container" ref={buttonContainerRef}>
          <Button
            className="btn-save"
            onClick={() => {
              saveChanges();
            }}
          >
            Save Changes{" "}
            {saveChangesLoading && (
              <Spinner
                animation="border"
                size="sm"
                style={{ marginLeft: "5px" }}
              />
            )}
          </Button>
          <br></br>
          {errorsLeft && (
            <div
              style={{ fontSize: "20px", justifyContent: "center" }}
              class="row"
            >
              <div class="col-12" style={{ justifyContent: "center" }}>
                <p style={{ textAlign: "center" }}>
                  You have the following unfinished sections :
                </p>
              </div>
              {(() => {
                let sections = [];
                let headers = [...document.querySelectorAll(".card-header")];
                for (let section of errorSections) {
                  let text = splitCamelCase(section);
                  let header = headers.filter((x) =>
                    x?.outerText
                      ?.trim()
                      ?.toLowerCase()
                      ?.includes(text?.toLowerCase())
                  )[0];
                  if (text && header) {
                    sections.push({ text, element: header });
                  }
                }

                return (
                  <ul class="row errorUl">
                    {(() => {
                      const x = "haha";
                      let leftColElms = sections.filter(
                        (x, index) => index % 2 === 0
                      );
                      let rightColElms = sections.filter(
                        (x, index) => index % 2 === 1
                      );
                      return (
                        <>
                          <div
                            class="col-6"
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {leftColElms.map(({ text, element }) => (
                              <li
                                key={text}
                                onClick={() => scrollToElement(element)}
                                style={{
                                  fontSize: "16px",
                                  cursor: "pointer", // this changes the cursor to a hand
                                  padding: "5px", // some padding for better visual appeal
                                  marginBottom: "5px", // space between list items
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  marginLeft: "20%",
                                }}
                                onMouseOver={(e) =>
                                  (e.target.style.backgroundColor = "#f7f7f7")
                                } // light gray background on hover
                                onMouseOut={(e) =>
                                  (e.target.style.backgroundColor = "")
                                }
                              >
                                <div style={{ display: "flex" }}>
                                  <div style={{ marginRight: "10px" }}>
                                    {text}
                                  </div>
                                  <a
                                    class="link-primary"
                                    style={{
                                      marginLeft: "auto",
                                      marginRight: "0",
                                    }}
                                  >
                                    Fill now ...
                                  </a>
                                </div>
                              </li>
                            ))}
                          </div>
                          <div
                            class="col-6"
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {rightColElms.map(({ text, element }) => (
                              <li
                                key={text}
                                onClick={() => scrollToElement(element)}
                                style={{
                                  fontSize: "16px",
                                  cursor: "pointer", // this changes the cursor to a hand
                                  padding: "5px", // some padding for better visual appeal
                                  marginBottom: "5px", // space between list items
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  marginLeft: "20%",
                                }}
                                onMouseOver={(e) =>
                                  (e.target.style.backgroundColor = "#f7f7f7")
                                } // light gray background on hover
                                onMouseOut={(e) =>
                                  (e.target.style.backgroundColor = "")
                                }
                              >
                                <div style={{ display: "flex" }}>
                                  <div style={{ marginRight: "10px" }}>
                                    {text}
                                  </div>
                                  <a
                                    class="link-primary"
                                    style={{
                                      marginLeft: "auto",
                                      marginRight: "0",
                                    }}
                                  >
                                    Fill now ...
                                  </a>
                                </div>
                              </li>
                            ))}
                          </div>
                        </>
                      );
                    })()}
                  </ul>
                );
              })()}
            </div>
          )}

          <p style={{ color: "red", fontSize: "15px", marginTop: "5px" }}>
            {finalErrorMessage}
          </p>
        </div>
      </div>
    </>
  );
}

export default EditResumeV2;
