const newData = (value) => {
  console.log("data", value);
  return {
    companyName: value.name,
    companyDomain: value.domain,
    totalEmployees: value.totalEmployees,
    yearFounder: value.yearFounded ,
    revenue:value.revenue
  };
};

export default function makeData(lens, data) {
  const makeDataLevel = (depth = 0) => {
    return data.reduce(function (result, d) {
      result.push({
        ...newData(d),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
      });

      return result;
    }, []);
  };

  return makeDataLevel();
}
