import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import targetJson from "../targeted.json";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { FileUploader } from "react-drag-drop-files";
import { useStateStore } from "../../store";
// import CustomAlert from "../modal";
import { v1 as uuidv1 } from "uuid";
// import commerce from "../../commerce.svg";
import { mixpanel } from "../../index";
function LazyapplyXSecond() {
  const history = useHistory();
  const [step, setstep] = useState(0);
  const fileTypes = ["PDF"];
  const loading = useRef(null);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const token = useStateStore((state) => state.token);
  const [file, setFile] = useState(null);
  const settingsClick = useStateStore((state) => state.settingsClick);
  const setHeightChange = useStateStore((state) => state.setHeightChange);
  const userData = useStateStore((state) => state.userData);
  const [presentdata, setpresentdata] = useState(false);
  const globalLogout = useStateStore((state) => state.globalLogout);
  const setResumesCompleteV3 = useStateStore(
    (state) => state.setResumesCompleteV3
  );
  const setSettingsClick = useStateStore((state) => state.setSettingsClick);
  const [resumePresent, setResumePresent] = useState(false);
  const [completedResumes, setCompletedResumes] = useState({});
  const resumeModifiedV3 = useStateStore((state) => state.resumeModifiedV3);
  const [formdata, setformdata] = useState({
    // companiesisanyof: [],
    companiesisnotanyof: [],
    industryisanyof: [],
    industryisnotanyof: [],
    employees: [],
    title: "",
    countries: [],
    cities: [],
    // full_name: "",
    resumeReferenceId: "",
    resumename: "",
  });

  const showmessage = (text, title) => {
    setmessage(text);
    settitle(title);
    setmodalshow(true);
    setTimeout(() => {
      setmodalshow(false);
    }, 2500);
  };

  const setField = (key, value) => {
    setformdata((prevFormData) => {
      return { ...prevFormData, [key]: value };
    });
  };

  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      if (inputValue.length > 0) {
        axios
          .get(
            `https://autocomplete.clearbit.com/v1/companies/suggest?query=${inputValue}`
          )
          .then((res) => {
            const data = res.data;
            const namearray = data.map((options) => {
              return {
                label: `${options.name}, (domain - ${options.domain})`,
                value: options.domain,
              };
            });
            resolve(namearray);
          })
          .catch((err) => {
            console.log("some error occured");
            resolve(targetJson.companyDefaultOptions);
          });
      } else {
        resolve(targetJson.companyDefaultOptions);
      }
    });
  };

  const promiseOptionsCities = (inputValue) => {
    console.log("input value", inputValue);
    return new Promise((resolve) => {
      if (inputValue.length > 0) {
        axios
          .get(
            `https://api.thecompaniesapi.com/v1/locations/cities?token=BB6wLOVu&search=${inputValue}`
          )
          .then((res) => {
            const data = res.data.cities;
            console.log("data", data);
            const namearray = data.map((options) => {
              return {
                label: options.name,
                value: options.code,
              };
            });
            resolve(namearray);
          })
          .catch((err) => {
            console.log("some error occured");
            resolve([]);
          });
      } else {
        resolve([]);
      }
    });
  };

  const promiseOptionsCountries = (inputValue) => {
    return new Promise((resolve) => {
      if (inputValue.length > 0) {
        axios
          .get(
            `https://api.thecompaniesapi.com/v1/locations/countries?token=BB6wLOVu&search=${inputValue}`
          )
          .then((res) => {
            const data = res.data.countries;
            console.log("data", data);
            const namearray = data.map((options) => {
              return {
                label: options.name,
                value: options.code,
              };
            });
            resolve(namearray);
          })
          .catch((err) => {
            console.log("some error occured");
            resolve([]);
          });
      } else {
        resolve([]);
      }
    });
  };

  const promiseOptionsPeople = (inputValue) => {
    return new Promise((resolve) => {
      if (inputValue.length > 0) {
        axios
          .get(
            `https://autocomplete.clearbit.com/v1/companies/suggest?query=${inputValue}`
          )
          .then((res) => {
            const data = res.data;
            const namearray = data.map((options) => {
              return {
                label: `${options.name}, (domain - ${options.domain})`,
                value: options.domain,
              };
            });
            resolve(namearray);
          })
          .catch((err) => {
            console.log("some error occured");
            resolve(targetJson.companyDefaultOptions);
          });
      } else {
        resolve(targetJson.companyDefaultOptions);
      }
    });
  };

  const titlePromiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      if (inputValue.length > 0) {
        console.log("input", encodeURIComponent(inputValue));
        axios
          .get(
            `https://app.apollo.io/api/v1/tags/search?q_tag_fuzzy_name=${encodeURIComponent(
              inputValue
            )}&kind=person_title&display_mode=fuzzy_select_mode`
          )
          .then((res) => {
            const data = res.data.tags;
            const namearray = data.map((options) => {
              return {
                label: options.cleaned_name,
                value: options.cleaned_name,
              };
            });
            resolve(namearray);
          })
          .catch((err) => {
            console.log("some error occured", err);
            resolve(defaultFn(targetJson.titleDefaultOptions));
          });
      } else {
        resolve(defaultFn(targetJson.titleDefaultOptions));
      }
    });
  };

  const defaultFn = (options) => {
    return options.map((option) => ({ value: option, label: option }));
  };

  const styleform = {
    padding: "20px",
  };

  function goBack() {
    history.goBack();
  }

  function goToDataManagement() {
    history.push("/dashboard/lazyapplyx-data-management");
  }

  const lazyapplyXData = useStateStore((state) => state.lazyapplyXData);
  const setLazyapplyXData = useStateStore((state) => state.setLazyapplyXData);

  const handleSubmit = (e) => {
    e.preventDefault();
    const v2Id = userData?.planDetails?.v2Id;
    loading.current.style.display = "block";
    mixpanel.identify(userData.email);
    mixpanel.people.increment(
      "Dashboard - LaxyapplyX details page submit button clicked"
    );
    mixpanel.track(
      "Dashboard - LaxyapplyX details page submit button clicked",
      {
        email: userData.email,
      }
    );

    mixpanel.track("Dashboard - targetsearch submit button clicked");
    axios
      .post(
        "https://backend.lazyapply.com/targetedsearchdata",
        {
          data: { ...formdata, v2Id },
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        console.log("successfully updated");
        // openPayment();
        setLazyapplyXData({ ...lazyapplyXData, stepNo: 2 });
        setSettingsClick(false);
        showmessage("data successfully updated", "Updated success");
        setTimeout(() => {
          setpresentdata(true);
        }, 1000);
      })
      .catch((err) => {
        loading.current.style.display = "none";
        console.log("some error occured in uploading form");
      });
    console.log("form", formdata);
  };

  const handleChange = async (file) => {
    const uid = uuidv1();
    const url = `https://backend.lazyapply.com/targetedSearch/presignedurl/${uid}`;
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.data && "url" in response.data) {
      const uploadurl = response.data.url;
      console.log(
        "upload url",
        uploadurl,
        `${userData.email}/${uid}_resume`,
        file
      );
      axios
        .put(uploadurl, file)
        .then((response) => {
          const key = `${userData.email}/${uid}_resume`;
          setField("resumeReferenceId", key);
          showmessage("File uploaded successfully", "Uploaded success");
          console.log("blob uploaded successfully", response.data);
        })
        .catch((error) => {
          console.log("error", error);
          showmessage("Error occured in uploading file", "Error occured");
          console.log("blob uploaded error", error.message);
        });
    }
    setFile(file);
  };

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    let valid = true;
    console.log("formdata", formdata);
    Object.keys(formdata).forEach((key) => {
      const data = formdata[key];
      console.log("formdata0", data);
      if (key === "resumeReferenceId" && data == "") {
        valid = false;
      } else if (key === "title" && data == "") {
        valid = false;
      }
      // else if (key === "full_name" && data == "") {
      //   valid = false;
      // }
    });
    setIsValid(valid);
  }, [formdata]);

  useEffect(() => {
    loading.current.style.display = "none";
  }, []);

  const buttonStyle = {
    background: "#007bff",
    padding: "10px",
    width: "350px",
    margin: "10px 0",
    borderRadius: "5px",
    border: "none",
    color: "white",
  };

  useEffect(() => {
    // get all resumes
    const v2Id = userData?.planDetails?.v2Id;
    loading.current.style.display = "block";
    axios
      .get(`https://backend.lazyapply.com/v2/resumesV3/completed/${v2Id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("resumescompleted", response);
        loading.current.style.display = "none";
        if (
          "resumes" in response.data &&
          Object.keys(response.data.resumes).length > 0
        ) {
          setResumesCompleteV3(response.data.resumes);
          setCompletedResumes(response.data.resumes);
        }
      })
      .catch((err) => {
        loading.current.style.display = "none";
        console.log("some error occured");
      });
  }, [userData, resumeModifiedV3]);

  useEffect(() => {
    axios
      .get(`https://backend.lazyapply.com/targetedsearchdata`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("data", response);
        if (response.data.message == "success" && response.data.found) {
          const mainformdata = response.data.data;
          delete mainformdata["v2Id"];
          console.log("mainformdata", mainformdata);
          setformdata(mainformdata);
          setResumeId(mainformdata.resumeReferenceId);
        }
      })
      .catch((err) => {
        console.log("some error occured", err);
      });
  }, []);

  const [resumeId, setResumeId] = useState("");
  const [resumeChanged, setResumeChanged] = useState(false);
  const [payment, setpayment] = useState(false);
  const [resumeDisable, setResumeDisable] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <div className="loading" ref={loading}>
        <div className="boxLoading"></div>
      </div>
      <button className="buttonGoBack btn" onClick={goBack}>
        <i className="fas fa-arrow-left"></i> Go Back
      </button>
      <button
        className="buttonGoBack btn buttonPrivacy"
        onClick={goToDataManagement}
      >
        Privacy Settings{" "}
        <i
          class="fa fa-cog"
          style={{ marginLeft: "5px" }}
          aria-hidden="true"
        ></i>
      </button>
      <div style={{ marginTop: "50px" }}>
        <p className="targetedFormText">
          Fill in the details to start your targeted search process
        </p>
        <Form onSubmit={handleSubmit}>
          <Row style={styleform}>
            {/* <Col md={6}> */}
            {/* <Form.Group>
                <Form.Label>
                  Full Name{" "}
                  <span className="compulsoryf">( compulsory field )*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  id="fullName"
                  value={formdata.full_name}
                  placeholder="For ex - Jason M"
                  onChange={(e) => setField("full_name", e.target.value)}
                />
              </Form.Group> */}
            {/* </Col> */}
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Job Title{" "}
                  <span className="compulsoryf">( compulsory field )*</span>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  id="jobtitle"
                  value={formdata.title}
                  placeholder="For ex- Software Developer"
                  aria-describedby="passwordHelpBlock"
                  onChange={(e) => setField("title", e.target.value)}
                />
                <Form.Text id="passwordHelpBlock" muted>
                  For example:- Type only one job title at a time like (
                  Software Developer or Data Analyst or Sales Manager).
                  {`Note -> this is the title that goes into your email so write only one title here`}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Countries To Search Companies For</Form.Label>
                <AsyncSelect
                  isMulti
                  cacheOptions
                  value={formdata.countries}
                  loadOptions={promiseOptionsCountries}
                  id="countries"
                  onChange={(value) => setField("countries", value)}
                  placeholder="Select countries"
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Cities To Search Companies For</Form.Label>
                <AsyncSelect
                  isMulti
                  cacheOptions
                  value={formdata.cities}
                  loadOptions={promiseOptionsCities}
                  id="cities"
                  onChange={(value) => setField("cities", value)}
                  placeholder="Select cities"
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Companies To Exclude</Form.Label>
                <AsyncSelect
                  isMulti
                  cacheOptions
                  value={formdata.companiesisnotanyof}
                  defaultOptions={defaultFn([])}
                  loadOptions={promiseOptions}
                  id="companiesisnotanyof"
                  onChange={(value) => setField("companiesisnotanyof", value)}
                  placeholder="Select job companies"
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Number Of Employees</Form.Label>
                <Select
                  isMulti
                  id="employees"
                  value={formdata.employees}
                  onChange={(value) => setField("employees", value)}
                  name="employees"
                  placeholder="Select no of employees:"
                  options={defaultFn(targetJson.employeesOptions)}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Industries To Include</Form.Label>
                <Select
                  isMulti
                  value={formdata.industryisanyof}
                  id="industryisanyof"
                  onChange={(value) => setField("industryisanyof", value)}
                  name="industryisanyof"
                  placeholder="Select industry"
                  options={defaultFn(targetJson.industryOptions)}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Industries To Exclude</Form.Label>
                <Select
                  isMulti
                  value={formdata.industryisnotanyof}
                  id="industryisnotanyof"
                  onChange={(value) => setField("industryisnotanyof", value)}
                  name="industryisnotanyof"
                  placeholder="Select industry"
                  options={defaultFn(targetJson.industryOptions)}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </Form.Group>
            </Col>
            {/* <Col md={6}>
                  <Form.Group>
                    <Form.Label>Revenue</Form.Label>
                    <Select
                      isMulti
                      id="revenues"
                      value={formdata.revenues}
                      onChange={(value) => setField("revenues", value)}
                      name="revenues"
                      placeholder="Select revenue"
                      options={defaultFn(targetJson.revenueOptions)}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Funding Round</Form.Label>
                    <Select
                      isMulti
                      value={formdata.fundings}
                      id="fundings"
                      onChange={(value) => setField("fundings", value)}
                      name="revenues"
                      placeholder="Select funding round"
                      options={defaultFn(targetJson.fundingOptions)}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Form.Group>
                </Col> */}

            {/* <Col md={12}>
                  <Form.Group>
                    <Form.Label>Additional Information </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      id="additionalInfo"
                      value={formdata.additionalData}
                      placeholder="Enter additional requirements here.."
                      onChange={(e) => {
                        setField("additionalData", e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col> */}

            {/* <Col md={12}>
              <p style={{ color: "grey" }}>
                Upload your cv/resume in pdf format only ( Other formats coming
                soon) <span className="compulsoryf">( compulsory field )*</span>
              </p>
              <FileUploader
                handleChange={handleChange}
                name="file"
                types={fileTypes}
              />
            </Col> */}
            <Col md={12}>
              {Object.keys(completedResumes).length > 0 ? (
                <>
                  <Form.Group>
                    <Form.Label>Choose a Profile to apply jobs for</Form.Label>
                    <Form.Control
                      as="select"
                      id="resumeId"
                      disabled={resumeDisable}
                      isInvalid={resumeChanged}
                      value={resumeId}
                      onChange={(e) => {
                        console.log("resumeid", e.target.value);
                        if (e.target.value != "") {
                          setResumeChanged(false);
                        }
                        Object.keys(completedResumes).map((key) => {
                          const item = completedResumes[key];
                          if (item.resumeId === e.target.value) {
                            console.log("resumeid", item.resumeId);
                            setField("resumeReferenceId", item.resumeId);
                            setField("resumename", item.resumename);
                          }
                        });
                        setField("resumeReferenceId", e.target.value);
                        setResumeId(e.target.value);
                      }}
                    >
                      <option value="">Select a resume</option>
                      {Object.keys(completedResumes).map((key) => {
                        const item = completedResumes[key];
                        return (
                          <option value={item.resumeId}>
                            {item.resumename}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                  {settingsClick == true && (
                    <Col md={12} style={{ color: "red" }}>
                      Please note: Updating your details will clear your
                      previous company application history and provide you with
                      a new list of companies to apply to.
                    </Col>
                  )}
                  <Col md={6}>
                    <button
                      type="submit"
                      disabled={!isValid}
                      className={
                        "buttontarget" + (!isValid ? " buttontargetvalid" : "")
                      }
                    >
                      {settingsClick == true
                        ? "Update Details"
                        : "Get Companies And Send Emails"}
                    </button>
                  </Col>
                </>
              ) : (
                <button
                  style={buttonStyle}
                  onClick={() => history.push("/dashboard/editresumesv2")}
                >
                  Create A Resume To Continue
                </button>
              )}
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default LazyapplyXSecond;
