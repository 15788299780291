import React from "react";
import { FileUploader } from "react-drag-drop-files";

const UploadImage = ({ setPreviewUrl, setImage, image, previewUrl }) => {
  const handleImageChange = (file) => {
    if (file && file.type.substr(0, 5) === "image") {
      console.log("Selected file:", file);
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImage(null);
    }
  };

  const fileTypes = ["PNG","JPG", "JPEG", "GIF", "WEBP"];

  return (
    <div className="upload-popup">
      <FileUploader
        disabled={image ? true : false}
        multiple={false}
        handleChange={handleImageChange}
        name="file"
        fileOrFiles={image}
        types={fileTypes}
      />
      {previewUrl && (
        <img src={previewUrl} alt="Preview" className="image-preview" />
      )}
    </div>
  );
};

export default UploadImage;
