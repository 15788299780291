import React, { useState } from "react";
import "./ReferralMainBlock.css";
import EmailBlock from "./EmailBlock.js";
import ReferralMainBlock from "./ReferralMainBlock";
import { useStateStore } from "../../store";

function ReferralMainBlockModal() {
  const mainCompanySet = useStateStore((state) => state.mainCompanySet);
  const setSendEmail = useStateStore((state) => state.setSendEmail);
  const sendEmail = useStateStore((state) => state.sendEmail);
  // const [sendEmail, setSendEmail] = useState(false);

  return (
    <>
      {sendEmail === false ? (
        <ReferralMainBlock
          setSendEmail={setSendEmail}
          showSendEmail={false}
          company={mainCompanySet}
          mixpanel_page="secret_network_popup step1"
        />
      ) : (
        <>
          <EmailBlock setSendEmail={setSendEmail} />
        </>
      )}
    </>
  );
}

export default ReferralMainBlockModal;
