const createDate = (createdAt) => {
  if (createdAt) {
    const date = new Date(createdAt);
    return date.toDateString();
  } else {
    return "";
  }
};

const newData = (value) => {
  console.log("data", value);
  return {
    companyName: value?.companyName,
    jobTitle: value?.jobTitle,
    jobLink: value?.href,
    jobResume: value?.resumeName || "",
    jobDate: createDate(value?.createdAt),
  };
};

export default function makeData(lens, data) {
  const makeDataLevel = (depth = 0) => {
    return data.reduce(function (result, d) {
      if (d) {
        console.log('new dataaaa', newData(d));
        result.push({
          ...newData(d),
          subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
        });
      }
      return result;
    }, []);
  };

  return makeDataLevel();
}
