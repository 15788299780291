/*global chrome*/
import { GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useStateStore } from "../../store";
import FeedbackPopup from "./FeedbackPopup";
import InitBlock from "./InitBlock";
import NotFound from "./NotFound";
import Pagination from "./Pagination";
import ReferralFilters from "./ReferralFilters";
import ReferralList from "./ReferralList";
import "./ReferralMain.css";
import ReferralModal from "./ReferralModal";
import SendEmailSuccess from "./SendEmailSuccess";
import { mixpanel } from "../../index";

function ReferralMain() {
  const CLIENT_ID =
    "715818145189-7geg3j3td9u21nc9qe8eujb2l4dcbt88.apps.googleusercontent.com";
  const [selected, setSelected] = useState(1);
  const token = useStateStore((state) => state.token);
  const [showLoader, setShowLoader] = useState(false);
  const [companies, setCompanies] = useState([]);
  const userData = useStateStore((state) => state.userData);
  const yourExtensionId = useStateStore((state) => state.yourExtensionId);
  const setGlobalTabId = useStateStore((state) => state.setGlobalTabId);
  const setScriptInjectedGlobal = useStateStore(
    (state) => state.setScriptInjectedGlobal
  );
  const scriptInjectedGlobal = useStateStore(
    (state) => state.scriptInjectedGlobal
  );
  const setExtensionPresent = useStateStore(
    (state) => state.setExtensionPresent
  );
  const setReferralInitData = useStateStore(
    (state) => state.setReferralInitData
  );
  const setReferralResumeData = useStateStore(
    (state) => state.setReferralResumeData
  );
  const showEmailPermissionModal = useStateStore(
    (state) => state.showEmailPermissionModal
  );
  const showReferralFeedback = useStateStore(
    (state) => state.showReferralFeedback
  );
  const setShowReferralFeedback = useStateStore(
    (state) => state.setShowReferralFeedback
  );
  const setShowEmailPermissionModal = useStateStore(
    (state) => state.setShowEmailPermissionModal
  );
  const setShowEmailSentSuccess = useStateStore(
    (state) => state.setShowEmailSentSuccess
  );
  const showEmailSentSuccess = useStateStore(
    (state) => state.showEmailSentSuccess
  );
  const showMainReferralModal = useStateStore(
    (state) => state.showMainReferralModal
  );
  const setShowMainReferralModal = useStateStore(
    (state) => state.setShowMainReferralModal
  );
  // const [show, setShow] = useState(false);
  const [meta, setMeta] = useState({
    currentPage: 1,
    firstPage: 1,
    lastPage: 1000,
    perPage: 10,
    total: 10000,
  });
  const [initialDataLoader, setInitialDataLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [country, setCountry] = useState("");
  const [companyQuery, setCompanyQuery] = useState("");
  const [employees, setEmployees] = useState("");
  const [revenue, setRevenue] = useState("");
  const [showFilters, setShowFilters] = useState(true);

  const closeFn = () => {
    setShowMainReferralModal(false);
  };

  const closePermissionModal = () => {
    setShowEmailPermissionModal(false);
  };

  function smoothScrollToTop() {
    let start = null;
    const initialScrollTop = window.scrollY;

    function easeOutCubic(t) {
      return 1 - Math.pow(1 - t, 3);
    }

    function step(timestamp) {
      if (start === null) start = timestamp;
      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / 1000, 1); // take 2 seconds to scroll

      const easedProgress = easeOutCubic(progress);
      const newScrollTop = initialScrollTop * (1 - easedProgress);

      window.scrollTo(0, newScrollTop);

      if (newScrollTop > 0) {
        window.requestAnimationFrame(step);
      }
    }

    window.requestAnimationFrame(step);
  }

  const findButtonSubmit = async () => {
    if (companyQuery === "") {
      setShowFilters(true);
      resetFilters();
    } else {
      setShowLoader(true);
      setShowFilters(false);
      const response = await axios.post(
        `https://backend.lazyapply.com/referral/findCompaniesWithName`,
        {
          currentPage: 1,
          companyQuery,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowLoader(false);
      setMeta(
        response?.data?.meta || {
          currentPage: 1,
          firstPage: 1,
          lastPage: 1,
          perPage: 10,
          total: 1,
        }
      );
      setCompanies(response?.data?.companies || []);
      smoothScrollToTop();
      console.log("response", response);
    }
  };

  const setInitialCompanies = async () => {
    setCompanyQuery("");
    setShowFilters(true);
    setCountry("");
    setEmployees("");
    setRevenue("");
    setShowLoader(true);
    const response = await axios.get(
      `https://backend.lazyapply.com/referral/getMostPopular`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setShowLoader(false);
    setMeta({
      currentPage: 1,
      firstPage: 1,
      lastPage: 1,
      perPage: 1,
      total: 1,
    });
    setCompanies(response?.data?.initial_companies || []);
    smoothScrollToTop();
  };

  const setMainFilters = async (
    country_main,
    revenue_main,
    employees_main,
    company_query_main
  ) => {
    console.log("country", country_main);
    console.log("revenue", revenue_main);
    console.log("employees", employees_main);
    setShowLoader(true);
    if (selected) {
      getCompanies();
    } else {
      const response = await axios.post(
        `https://backend.lazyapply.com/referral/getCompaniesWithFilters`,
        {
          country: country_main,
          revenue: revenue_main,
          employees: employees_main,
          companyQuery: company_query_main,
          currentPage,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMeta(
        response?.data?.meta || {
          currentPage: 1,
          firstPage: 1,
          lastPage: 1,
          perPage: 10,
          total: 1,
        }
      );
      setCompanies(response?.data?.companies || []);
    }
    setShowLoader(false);
    smoothScrollToTop();
    console.log("response");
  };

  const getInitData = async () => {
    setInitialDataLoader(true);
    const initDataResponse = await axios.post(
      "https://backend.lazyapply.com/referral/getReferralData",
      {
        v2Id: userData?.planDetails?.v2Id || "",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setInitialDataLoader(false);
    console.log("initData", initDataResponse, initDataResponse.data);
    setReferralInitData(initDataResponse.data);
  };

  const setCurrentPageAndQuery = async (page) => {
    setShowLoader(true);
    setMeta((meta) => ({
      ...meta,
      currentPage: page,
    }));
    const response = await axios.post(
      `https://backend.lazyapply.com/referral/getCompaniesWithFilters`,
      {
        currentPage: page,
        country,
        revenue,
        employees,
        companyQuery,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setShowLoader(false);
    setCompanies(response?.data?.companies || []);
    smoothScrollToTop();
    console.log("response", response);
  };

  const getCompanies = () => {
    setInitialCompanies();
    // setMainFilters("", "", "", "");
  };

  const closeEmailSuccessModal = () => {
    setShowEmailSentSuccess(false);
  };

  const closeReferralFeedback = () => {
    setShowReferralFeedback(false);
  };

  const resetFilters = () => {
    setCompanyQuery("");
    setShowFilters(true);
    setCountry("");
    setEmployees("");
    setRevenue("");
    setSelected(true);
    getCompanies();
    // setMainFilters("", "", "", "");
  };

  const fetchUserResumeData = async () => {
    const response = await axios.get(
      `https://backend.lazyapply.com/v2/resumesV3/completed/${userData.planDetails.v2Id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setReferralResumeData(response.data);
  };

  useEffect(() => {
    setMainFilters(country, revenue, employees, "");
  }, [country, revenue, employees, selected]);

  useEffect(() => {
    fetchUserResumeData();
    getInitData();
    getCompanies();

    mixpanel.identify(userData.email);
    mixpanel.people.increment("secret_network_page dashboard");
    mixpanel.track("secret_network_page dashboard", {
      email: userData?.email,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
  }, []);

  useEffect(() => {
    console.log("Sending initial request to extension");
    const usermain = JSON.parse(window.localStorage.getItem("user") || "{}");
    const tokenmain = window.localStorage.getItem("token");
    if (
      tokenmain != "" &&
      usermain != undefined &&
      Object.keys(usermain).length > 0
    ) {
      if (typeof chrome !== "undefined" && chrome && chrome.runtime)
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            dashboard: true,
            saveValues: {
              token: tokenmain,
              user: usermain,
            },
            injected: scriptInjectedGlobal,
            global: true,
            message: "loginToken",
          },
          (response) => {
            console.log(
              "response from extension",
              response,
              chrome.runtime.lastError
            );
            var lastError = chrome.runtime?.lastError;
            if (lastError) {
              console.log("some error");
              setScriptInjectedGlobal(false);
              setExtensionPresent(false);
            } else {
              console.log("response of initial request", response);
              setScriptInjectedGlobal(true);
              setGlobalTabId(response.globalTabId);
            }
          }
        );
    }
  }, []);

  return (
    <div className="main-referral-block">
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <InitBlock
          s={showEmailPermissionModal}
          closeFn={closePermissionModal}
        />
      </GoogleOAuthProvider>
      <ReferralFilters
        selected={selected}
        setSelected={setSelected}
        country={country}
        setCountry={setCountry}
        companyQuery={companyQuery}
        setCompanyQuery={setCompanyQuery}
        employees={employees}
        setEmployees={setEmployees}
        revenue={revenue}
        setRevenue={setRevenue}
        findButtonSubmit={findButtonSubmit}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        resetFilters={resetFilters}
      />
      <p
        style={{
          textAlign: "end",
          fontSize: "12px",
          color: "grey",
          marginRight: "20px",
        }}
      >
        page {meta?.currentPage} - {meta?.lastPage}
      </p>
      {selected && (
        <p
          style={{
            textAlign: "start",
            fontSize: "14px",
            color: "grey",
            marginLeft: "25px",
          }}
        >
          Most Popular Companies
        </p>
      )}
      {!showLoader && companies.length === 0 && <NotFound />}
      <ReferralList
        setShow={setShowMainReferralModal}
        showLoader={showLoader || initialDataLoader}
        companies={companies}
      />
      <SendEmailSuccess
        s={showEmailSentSuccess}
        closeFn={closeEmailSuccessModal}
      />
      <FeedbackPopup s={showReferralFeedback} closeFn={closeReferralFeedback} />
      <Pagination pagination={meta} setCurrentPage={setCurrentPageAndQuery} />
      <ReferralModal s={showMainReferralModal} closeFn={closeFn} />
    </div>
  );
}

export default ReferralMain;
