import { Modal } from "react-bootstrap";

function SuccessModal({ successReportModal, setSuccessReportModal }) {
  const handleClose = () => {
    setSuccessReportModal(false);
  };

  return (
    <Modal
      show={successReportModal}
      onHide={handleClose}
      centered
      size="md"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>Question Reported Successfully</div>
      </Modal.Body>
    </Modal>
  );
}

export default SuccessModal;
