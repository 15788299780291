import React, { useEffect, useState, useCallback } from "react";
import { useStateStore } from "../../store";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import Pagination from "./Pagination";

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, id },
}) {
  const count = preFilteredRows.length;

  const [inputValue, setInputValue] = useState("");
  const searchJobTitle = useStateStore((state) => state.searchJobTitle);
  const setSearchJobTitle = useStateStore((state) => state.setSearchJobTitle);

  const searchCompanyName = useStateStore((state) => state.searchCompanyName);
  const setSearchCompanyName = useStateStore(
    (state) => state.setSearchCompanyName
  );
  
  const setSearchingJobsWithFilters = useStateStore((state) => state.setSearchingJobsWithFilters);

  useEffect(() => {
    if (id === "jobTitle") {
      setInputValue(searchJobTitle);
    } else if (id === "companyName") {
      setInputValue(searchCompanyName);
    }
    if(searchJobTitle != "" || searchCompanyName != ""){
      setSearchingJobsWithFilters(true);
    }
  }, [id, searchJobTitle, searchCompanyName]);

  const updateFilter = useCallback(() => {
    if (id === "jobTitle") {
      setSearchJobTitle(inputValue);
    } else if (id === "companyName") {
      setSearchCompanyName(inputValue);
    }
  }, [id, inputValue, setSearchJobTitle, setSearchCompanyName]);

  return (
    <>
      {id === "jobTitle" && (
        <input
          id="jobTitle"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={updateFilter}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              updateFilter();
            }
          }}
          placeholder={`Search ${count} job titles...`}
          style={{ width: "100%" }}
        />
      )}
      {id === "companyName" && (
        <input
          id="companyName"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={updateFilter}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              updateFilter();
            }
          }}
          placeholder={`Search ${count} company names...`}
          style={{ width: "100%" }}
        />
      )}
    </>
  );
}

function MainTable({ totalJobs, columns, data, paginationActivePageNumber }) {
  // Use the state and functions returned from useTable to build your UI
  console.log("totaljobs", totalJobs);
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    rows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn,
    },
    useGlobalFilter,
    useFilters,
    usePagination
  );

  // const firstPageRows = rows.slice(0, pageSize);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [activePage, setActivePage] = useState(1);
  const count = totalJobs;
  const totalPages = Math.ceil(count / rowsPerPage);

  const calculatedRows = rows.slice(
    (activePage - 1) * rowsPerPage,
    activePage * rowsPerPage
  );

  useEffect(() => {
    console.log("active", activePage, rows);
  }, [activePage]);

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.render("Header")}
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {calculatedRows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <Pagination
        activePage={paginationActivePageNumber}
        count={count}
        rowsPerPage={rowsPerPage}
        totalPages={totalPages}
        setActivePage={setActivePage}
        setPageSize={setRowsPerPage}
        pageSize={rowsPerPage}
      />
    </>
  );
}

export default MainTable;
