import React, { useState, useEffect } from "react";
import { Modal, Form, Container, Button } from "react-bootstrap";
import axios from "axios";
import { useStateStore } from "../../store";

function LazyapplyXSurveyModal({ s, customFn = () => {} }) {
  const [show, setShow] = useState(false);
  const token = useStateStore((state) => state.token);
  const [showSurvey, setShowSurvey] = useState(false);
  const [refreshSurvey, setRefreshSurvey] = useState(false);
  const [followers, setFollowers] = useState("");
  const [interested, setInterested] = useState("");

  const optionsFollowers = [
    "<1000",
    ">=1000 and <2000",
    ">=2000 and <5000",
    ">=5000",
  ];
  const optionsInterest = ["Yes", "No"];

  const handleClose = () => {
    setShow(false);
    customFn();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can use the values of 'followers' and 'interested'
    console.log("Followers:", followers);
    console.log("Interested:", interested);
    let surveyObject = {
      questions: [
        "How many Instagram followers do you currently have?",
        "Would you be interested in sharing a post about Lazyapply on your Instagram?",
      ],
      answers: [followers, interested],
    };
    console.log("surveyobject", surveyObject);
    axios
      .post(
        "https://backend.lazyapply.com/lazyapplySurvey",
        {
          survey: surveyObject,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        window.localStorage.setItem("survey", true);
        setRefreshSurvey(true);
      })
      .catch((err) => {
        alert("some error occured");
      });
  };

  useEffect(() => {
    const data = localStorage.getItem("survey");
    if (data && data != null) {
      setShowSurvey(false);
    } else {
      setShowSurvey(true);
    }
  }, [refreshSurvey]);

  useEffect(() => {
    console.log("show", s);
    setShow(s);
  }, [s]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="md"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Lazyapply-X Beta Access</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {showSurvey ? (
            <>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="followersQuestion">
                  <Form.Label>
                    How many Instagram followers do you currently have?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    required={true}
                    value={followers}
                    onChange={(e) => setFollowers(e.target.value)}
                  >
                    <option value="">Select an option</option>
                    {optionsFollowers.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="interestQuestion">
                  <Form.Label>
                    Would you be interested in sharing a post about Lazyapply on
                    your Instagram?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    required={true}
                    value={interested}
                    onChange={(e) => setInterested(e.target.value)}
                  >
                    <option value="">Select an option</option>
                    {optionsInterest.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  style={{ cursor: "pointer" }}
                >
                  Submit
                </Button>
              </Form>
            </>
          ) : (
            <>Survey Filled Successfully</>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default LazyapplyXSurveyModal;
