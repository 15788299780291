import {useState, useEffect, createRef } from 'react';

export function useMultipleRefs(count) {
    const [refs, setRefs] = useState([]);

    useEffect(() => {
      setRefs((prevRefs) => [
        ...prevRefs,
        ...Array(count - prevRefs.length)
          .fill(0)
          .map(() => createRef()),
      ]);
    }, [count]);

    return refs;
  }