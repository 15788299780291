import React, { useState, useEffect } from "react";
import { Modal, Form, Row, Col, Spinner } from "react-bootstrap";
import { v1 as uuidv1 } from "uuid";
import { useStateStore } from "../store/index";
import axios from "axios";
import CustomAlert from "./modal";
import { mixpanel } from "../index";

function FeatureRequest({ s, hideFn }) {
  const [show, setShow] = useState(false);
  const [process, setProcess] = useState(false);
  const token = useStateStore((state) => state.token);
  const [featureRequestText, setFeatureRequestText] = useState("");
  const handleClose = () => {
    setShow(false);
    hideFn();
    mixpanel.track("feature_request_popup sidebar dashboard", {
      href: window.location.href,
      email: userData?.email,
      popup_event: false,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
  };
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const userData = useStateStore((state) => state.userData);

  useEffect(() => {
    setShow(s);
    if (s) {
      mixpanel.track("feature_request_popup sidebar dashboard", {
        href: window.location.href,
        email: userData?.email,
        popup_event: s,
        plan_details: userData?.planDetails || {},
        v2Id: userData?.planDetails?.v2Id || "",
      });
    }
  }, [s]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const postBody = {
      feedback: {
        desc: featureRequestText,
        type: "featureRequest",
      },
      id: uuidv1(),
    };
    setProcess(true);
    window.analytics.track(
      "USER_FEATURE_REQUEST",
      {
        label: "feature request",
        email: userData.email,
        text: featureRequestText,
      },
      {},
      () => {
        console.log("success call analytics");
      }
    );
    mixpanel.track(
      "submit_feature_request_button feature_request_popup sidebar dashboard",
      {
        href: window.location.href,
        email: userData?.email,
        text: featureRequestText,
        plan_details: userData?.planDetails || {},
        v2Id: userData?.planDetails?.v2Id || "",
      }
    );
    axios
      .post("https://backend.lazyapply.com/saveFeedbackUpdated", postBody, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        handleClose();
        setProcess(false);
        setFeatureRequestText("");
        setmessage("Successfully submitted the request!");
        settitle("Success!");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 2000);
      })
      .catch(() => {
        setProcess(false);
        setmessage("Some error occured in submitted the request!");
        settitle("Failed!");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 2000);
      });
  };

  const buttonStyle = {
    background: "#007bff",
    padding: "10px",
    width: "100%",
    margin: "10px 0",
    borderRadius: "5px",
    border: "none",
    color: "white",
  };

  return (
    <>
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Need something more on lazy apply? make a feature request now!!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <Form.Control
                  as="textarea"
                  rows={3}
                  required
                  id="featureRequest"
                  value={featureRequestText}
                  placeholder="Enter your feature request here...."
                  aria-describedby="passwordHelpBlock"
                  onChange={(e) => setFeatureRequestText(e.target.value)}
                />
              </Col>
            </Row>
            <button style={buttonStyle}>
              Submit Feature Request
              {process && (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ marginLeft: "5px", marginBottom: "5px" }}
                />
              )}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FeatureRequest;
