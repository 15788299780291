import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useStateStore } from "../store/index";
import axios from "axios";
import { v1 as uuidv1 } from "uuid";
import "./feedback.css";
import CustomAlert from "./modal";
import { useHistory } from "react-router-dom";

function Feedback({ platformName, totalJobsApplied }) {
  const [mainMark, setMainMark] = useState({
    marked: false,
    index: -1,
  });
  const history = useHistory();
  const userData = useStateStore((state) => state.userData);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const globalLogout = useStateStore((state) => state.globalLogout);
  const [submitted, setSubmitted] = useState(false);
  const token = useStateStore((state) => state.token);
  const [problemdesc, setProblemDesc] = useState("");
  const [error, setError] = useState("");

  const [stars, setStars] = useState([
    {
      id: 1,
      checked: false,
      newClass: false,
    },
    {
      id: 2,
      checked: false,
      newClass: false,
    },
    {
      id: 3,
      checked: false,
      newClass: false,
    },
    {
      id: 4,
      checked: false,
      newClass: false,
    },
    {
      id: 5,
      checked: false,
      newClass: false,
    },
  ]);

  const setMark = (finalIndex) => {
    const main = {
      ...mainMark,
    };
    main.index = finalIndex;
    const newStars = stars.map((k, index) => {
      if (index <= finalIndex) {
        if (mainMark.index === finalIndex && mainMark.marked) {
          main.marked = false;
          return {
            ...k,
            checked: false,
            newClass: false,
          };
        } else {
          main.marked = true;
          return {
            ...k,
            checked: true,
            newClass: false,
          };
        }
      } else {
        return {
          ...k,
          checked: false,
          newClass: false,
        };
      }
    });
    setStars(newStars);
    setMainMark(main);
    if (!submitted) submitStars(main.index);
  };
  const [feedbackId, setFeedbackId] = useState("");
  const submitStars = (rating) => {
    let id = feedbackId;
    if (id == "") {
      id = uuidv1();
      setFeedbackId(id);
    }
    const feedback = {
      rating: rating + 1,
      desc: problemdesc,
      type: "autoapply-feedback",
    };
    axios
      .post(
        "https://backend.lazyapply.com/saveFeedbackUpdated",
        {
          feedback: { ...feedback, totalJobsApplied, platformName },
          id: id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("feedbackr", response.data);
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 403") {
          console.log("reauthenticate");
          setmessage("Please relogin again!");
          settitle("Not authorized");
          setmodalshow(true);
          setTimeout(() => {
            globalLogout();
          }, 1500);
        }
        console.log("some error occured");
      });
  };
  const addClass = (finalIndex) => {
    if (!mainMark.marked) {
      const newStars = stars.map((k, index) => {
        if (index <= finalIndex) {
          return {
            ...k,
            checked: true,
            newClass: false,
          };
        } else {
          return {
            ...k,
            checked: false,
            newClass: false,
          };
        }
      });
      setStars(newStars);
    } else {
      if (finalIndex > mainMark.index) {
        //mainMark.index +1 to finalIndex
        const newStars = stars.map((k, index) => {
          if (index > mainMark.index && index <= finalIndex) {
            return {
              ...k,
              newClass: true,
            };
          } else {
            return {
              ...k,
              newClass: false,
            };
          }
        });
        setStars(newStars);
      } else if (finalIndex < mainMark.index) {
        // 0 to finalIndex
        const newStars = stars.map((k, index) => {
          if (index <= finalIndex) {
            return {
              ...k,
              newClass: true,
            };
          } else {
            return {
              ...k,
              newClass: false,
            };
          }
        });
        setStars(newStars);
      } else {
        console.log("equal,no change");
      }
    }
  };

  const removeClass = () => {
    if (!mainMark.marked) {
      const newStars = stars.map((k, index) => {
        return {
          ...k,
          checked: false,
          newClass: false,
        };
      });
      setStars(newStars);
      setMainMark({ ...mainMark, index: -1 });
    } else {
      const newStars = stars.map((k, index) => {
        return {
          ...k,
          newClass: false,
        };
      });
      setStars(newStars);
    }
  };

  const handleSubmitFeedback = () => {
    if (problemdesc === "" || mainMark.index === -1) {
      setError("Please complete the feedback form!");
      setTimeout(() => {
        setError("");
      }, 2000);
    } else {
      setError("");
      const feedback = {
        rating: mainMark.index + 1,
        desc: problemdesc,
        type: "autoapply-feedback",
      };
      window.analytics.track(
        "USER_FEEDBACK_REQUEST",
        {
          label: "feedback request",
          email: userData.email,
          rating: mainMark.index + 1,
          platformName,
          totalJobsApplied: totalJobsApplied,
          desc: problemdesc,
        },
        {},
        () => {
          console.log("success call analytics");
        }
      );
      axios
        .post(
          "https://backend.lazyapply.com/saveFeedbackUpdated",
          {
            feedback: { ...feedback, totalJobsApplied, platformName },
            id: feedbackId,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log("feedbackr", response.data);
          if (response.data === "success") {
            setSubmitted(true);
            setFeedbackId("");
            setMainMark({
              marked: false,
              index: -1,
            });
            setProblemDesc("");
            setStars([
              {
                id: 1,
                checked: false,
                newClass: false,
              },
              {
                id: 2,
                checked: false,
                newClass: false,
              },
              {
                id: 3,
                checked: false,
                newClass: false,
              },
              {
                id: 4,
                checked: false,
                newClass: false,
              },
              {
                id: 5,
                checked: false,
                newClass: false,
              },
            ]);
          } else {
            setmessage("Some error occured, please try again in some time!");
            settitle("Error");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2500);
          }
        })
        .catch((error) => {
          if (error.message == "Request failed with status code 403") {
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
            }, 1500);
          }
          console.log("some error occured");
        });
    }
  };

  const openmeeting = () => {
    window.open("https://calendly.com/ajay_lazy_apply", "_blank");
  };
  useEffect(() => {
    console.log("useeffecttest");
    setSubmitted(false);
  }, []);
  return (
    <div className="feedbackform">
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      <div className="feedbackform_header">Feedback - LAZYAPPLY</div>
      <div className="feedbackform_body">
        <div className="feedbackform_rating">
          {stars.map((k, index) => {
            return (
              <i
                onClick={() => setMark(index)}
                onMouseEnter={() => addClass(index)}
                onMouseLeave={() => removeClass()}
                key={k.id}
                className={
                  "fas fa-star" +
                  (k.checked ? " starchecked" : " starnotchecked") +
                  (k.newClass ? " starcheckedhover" : "")
                }
              ></i>
            );
          })}
        </div>
        <div className="feedbackform_desc">
          <Form.Label style={{ textAlign: "center" }}>
            Any suggestions/ what went wrong / what should we improve ?
          </Form.Label>
          <Form.Control
            value={problemdesc}
            as="textarea"
            placeholder="Type your issue, suggestion, feedback here..."
            rows={3}
            onChange={(e) => setProblemDesc(e.target.value)}
          />
        </div>
        {/* <div className="feedbackform_schedule">
          <p>Stuck somewhere? Schedule a meeting with us </p>
          <p className="feedbackform_meeting" onClick={() => openmeeting()}>
            click here to schedule a meeting
          </p>
        </div> */}

        {error != "" ? <p className="feedbackform_errorpara">{error}</p> : ""}
      </div>
      <div className="feedbackform_submit">
        <button
          onClick={handleSubmitFeedback}
          disabled={submitted ? true : false}
          type="button"
          className={
            "feedbackform_submitbutton" +
            (submitted ? " feedbackform_submitbutton_success" : "")
          }
        >
          {submitted ? "Submitted Successfully" : "Submit Feedback"}
        </button>
      </div>
      <div className="feedbackform_submit">
        <div className="feedbackform_schedule" style={{ textAlign: "center" }}>
          <p style={{ color: "black", fontWeight: "bold" }}>
            Wrong Answer Submitted While Automating ?{" "}
          </p>
          <p
            className="feedbackform_meeting"
            style={{ color: "darkred", fontSize: "20px" }}
            onClick={() => history.push("/dashboard/queries")}
          >
            Click here to report question / answer's
          </p>
        </div>
      </div>
    </div>
  );
}

export default Feedback;
