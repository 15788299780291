import React, { useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useStateStore } from "../../store";
import axios from "axios";
import { mixpanel } from "../../index";
import CustomAlert from "../modal";
import LazyapplyXSurveyModal from "./lazyapplySurvey";
import { useHistory } from "react-router-dom";

function LazyapplyXFirst() {
  const history = useHistory();
  const CLIENT_ID =
    "715818145189-7geg3j3td9u21nc9qe8eujb2l4dcbt88.apps.googleusercontent.com";
  const token = useStateStore((state) => state.token);
  const [data, setData] = useState({});
  const [lazyapplylink, setlazyapplylink] = useState("");
  const userData = useStateStore((state) => state.userData);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const [userTerms, setUserTerms] = useState(false);
  const [userTerms1, setUserTerms1] = useState(false);
  const [surveyModalShow, setSurveyModalShow] = useState(false);
  const saveTokensAndConnectEmail = (code) => {
    console.log("code", code);
    mixpanel.identify(userData.email);
    mixpanel.people.increment(
      "Dashboard - Linkedin send email connect email button clicked"
    );
    mixpanel.track(
      "Dashboard - Linkedin send email connect email button clicked",
      {
        email: userData.email,
      }
    );

    axios
      .post(
        "https://backend.lazyapply.com/lazyapplyx/saveemailpermission",
        {
          code,
          v2Id: userData?.planDetails?.v2Id || "",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("response", response.data);
        if (
          response?.data?.message &&
          response?.data?.message === "Successfully Linked"
        ) {
          mixpanel.identify(userData?.email);
          mixpanel.people.increment(
            "Dashboard - Linkedin send email connected successfully"
          );
          mixpanel.track(
            "Dashboard - Linkedin send email connected successfully",
            {
              email: userData?.email,
            }
          );
          setmessage("Permission given successfully");
          settitle("Success!");
          setData({
            refresh_token: "something",
          });
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2000);
        } else if (
          response?.data?.message &&
          response?.data?.message === "Access not given to Lazyapply"
        ) {
          setmessage("Access not given to Lazyapply");
          settitle("Relogin again!");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2000);
        } else {
          console.log("something went wrong");
          setmessage("Some error occured");
          settitle("Error!");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2000);
        }
      })
      .catch((err) => {
        console.log("errorhai", err);
      });
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      saveTokensAndConnectEmail(codeResponse.code);
      console.log("code", codeResponse.code);
    },
    onError: (err) => {
      console.log("code err", err.error);
      setmessage("Access not given to Lazyapply");
      settitle("Relogin again!");
      setmodalshow(true);
      setTimeout(() => {
        setmodalshow(false);
      }, 2000);
    },
    clientId: CLIENT_ID,
    access_type: "offline",
    flow: "auth-code",
    scope:
      "https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly",
    uxMode: "popup",
  });

  const customFn = () => {
    setmodalshow(false);
    setlazyapplylink("");
  };

  const surveyCustomFn = () => {
    setSurveyModalShow(false);
  };

  const getData = () => {
    axios
      .get(`https://backend.lazyapply.com/lazyapplyx/targetSearchData`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("data", response.data);
        setData(response.data);
      })
      .catch((err) => {
        console.log("some error occured");
      });
  };

  const getAuthorizationCode = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    const permission = searchParams.get("permission");

    if (code) {
      console.log("Authorization code:", code);
      saveTokensAndConnectEmail(code);
    }
    if (permission) {
      smoothScrollToBottom();
    }
  };

  function smoothScrollToBottom() {
    let start = null;
    const initialScrollTop = window.scrollY;
    const targetScrollTop =
      document.body.scrollHeight - window.innerHeight + 300;

    function easeOutCubic(t) {
      return 1 - Math.pow(1 - t, 3);
    }

    function step(timestamp) {
      if (start === null) start = timestamp;
      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / 500, 1); // take 2 seconds to scroll

      const easedProgress = easeOutCubic(progress);
      const newScrollTop =
        initialScrollTop + (targetScrollTop - initialScrollTop) * easedProgress;

      window.scrollTo(0, newScrollTop);

      if (newScrollTop < targetScrollTop) {
        window.requestAnimationFrame(step);
      }
    }

    window.requestAnimationFrame(step);
  }

  useEffect(() => {
    getData();
    getAuthorizationCode();
  }, []);

  return (
    <>
      <CustomAlert
        message={message}
        s={modalshow}
        title={title}
        lazyapplyxlink={lazyapplylink}
        customFn={customFn}
      ></CustomAlert>
      <LazyapplyXSurveyModal s={surveyModalShow} customFn={surveyCustomFn} />
      <div className="targetedSearchInfo">
        <p className="targetedSearchInfoTitle">Linkedin Send Email</p>

        <p className="targetedSearchInfoDesc">Overview:</p>
        <p className="targetedSearchInfoDesc1">
          Introducing LinkedIn Send Email – a groundbreaking feature designed to
          simplify your job application process on LinkedIn. This innovative
          tool enables you to instantly send a personalized email to the hiring
          manager or recruiter associated with any job posting. Additionally,
          you can request referrals directly from anyone’s profile on LinkedIn.
        </p>

        <p className="targetedSearchInfoDesc">{`What is it basically?`}</p>
        <div className="targetedSearchInfoList">
          <ul className="row">
            <li className="col-12">
              1. On a job posting, you will find a 'Send Email AI' button
              displayed next to the hirer’s information. Additionally, on a
              person's profile, there is a 'Request Referral' button available.
            </li>
            <div className="col-6">
              <img src={"/assests/main-email.png"} className="mainemail"></img>
            </div>
            <div className="col-6">
              <img
                src={"/assests/referralrequest.png"}
                className="mainemail"
              ></img>
            </div>
            <li className="col-12">
              2. Click either the 'Send Email AI' button on a job posting or the
              'Request Referral' button on a person's profile to view the email.
              You can then use our AI to generate and send an email directly for
              that job.
            </li>
            <div className="col-12">
              <img
                src={"/assests/main-email-ai.png"}
                className="mainemail1"
              ></img>
            </div>
          </ul>
        </div>

        {"refresh_token" in data ? (
          <p
            style={{
              color: "white",
              background: "green",
              padding: "10px",
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            Permissions Already Given!{" "}
          </p>
        ) : (
          <>
            <div>
              <p className="givepermissions">
                Click below to give permissions to use it feature
              </p>
            </div>
            <div
              style={{
                margin: "10px",
              }}
            >
              <button
                onClick={() => login()}
                disabled={!userTerms || !userTerms1}
                className={
                  "login-with-google-btn" + (userTerms ? " login-disabled" : "")
                }
              >
                Give Permissions To Send Email
              </button>
              <div style={{ display: "block", margin: "15px" }}>
                <p style={{ fontWeight: "bold", color: "black" }}>
                  Data Use Compliance
                </p>
                <p style={{ color: "#007bff" }}>
                  "LazyApply confirms that it does not use data obtained through
                  Google APIs for the purpose of developing, improving, or
                  training generalized artificial intelligence or machine
                  learning models. We are committed to complying with Google's
                  API Services User Data Policy, including the Limited Use
                  requirements."
                </p>
              </div>
              <div className="userTerms">
                <div className="userTermsCheckbox">
                  <input
                    type="checkbox"
                    checked={userTerms}
                    onChange={(e) => {
                      setUserTerms(!userTerms);
                    }}
                  />
                </div>
                <div>
                  Lazyapply's use and transfer of information received from
                  Google APIs to any other app will adhere to{" "}
                  <a
                    style={{ textDecoration: "underline" }}
                    href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Google API Services User Data Policy
                  </a>{" "}
                  , including the Limited Use requirements.
                </div>{" "}
              </div>
              <div className="userTerms">
                <div className="userTermsCheckbox">
                  <input
                    type="checkbox"
                    checked={userTerms1}
                    onChange={(e) => {
                      setUserTerms1(!userTerms1);
                    }}
                  />
                </div>
                <div>
                  Lazyapply utilizes a third-party AI model to process job and
                  resume details provided by the user, with the sole purpose of
                  generating personalized emails for HR outreach. Rest assured,
                  this data remains confidential and is not shared with any
                  external entity, apart from the AI model processing. For a
                  detailed understanding, please refer to our{" "}
                  <span
                    onClick={() =>
                      history.push("/dashboard/lazyapplyx-privacy")
                    }
                    style={{
                      textDecoration: "underline",
                      color: "#007bff",
                      cursor: "pointer",
                    }}
                  >
                    Data Usage and Privacy Explanation
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default LazyapplyXFirst;
