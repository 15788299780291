import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useStateStore } from "../../store/index";
import axios from "axios";
import { mixpanel } from "../../index";

function GetSavedSearches({
  platformName = "not_known",
  savedSuccessfully,
  setSelectedSearchData,
  searchInputValue,
  setSearchInputValue,
}) {
  const userData = useStateStore((state) => state.userData);

  const [searchData, setSearchData] = useState([]);
  const token = useStateStore((state) => state.token);
  const globalLogout = useStateStore((state) => state.globalLogout);

  const getAllSearches = () => {
    const v2Id = userData?.planDetails?.v2Id;
    axios
      .get(`https://backend.lazyapply.com/v2/savedSearches/${v2Id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.data.found) {
          console.log("response", response.data);
          setSearchData(response.data.searchData);
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 403") {
          setTimeout(() => {
            globalLogout();
          }, 1500);
        }
      });
  };

  useEffect(() => {
    getAllSearches();
  }, [savedSuccessfully]);

  return (
    <>
      {searchData.length > 0 ? (
        <>
          <div
            style={{
              marginBottom: "15px",
              background: "aliceblue",
              padding: "20px",
              fontWight: 700,
            }}
          >
            <p
              style={{
                color: "black",
                fontWeight: "bold",
              }}
            >
              Saved Search Settings ( Optional )
            </p>
            <Select
              placeholder="Select a saved search..."
              isMulti={false}
              id="savedSearchSelectBox"
              onChange={(data) => {
                mixpanel.track("saved_search_selectbox dashboard", {
                  href: window.location.href,
                  email: userData?.email,
                  platformName: platformName,
                  data: data,
                  plan_details: userData?.planDetails || {},
                  v2Id: userData?.planDetails?.v2Id || "",
                });
                setSearchInputValue(data);
                if (data.value != "") {
                  const fullData = searchData.filter((x) => {
                    if (data.value == x.searchId) return true;
                  });
                  setSelectedSearchData(fullData[0]);
                } else {
                  setSelectedSearchData({
                    searchId: "",
                    searchName: "",
                  });
                }
              }}
              value={searchInputValue}
              options={[
                {
                  value: "",
                  label: "Select a saved search setting",
                },
                ...searchData.map((x) => {
                  return {
                    value: x.searchId,
                    label: x.searchName,
                  };
                }),
              ]}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default GetSavedSearches;
