import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import YouTube from "react-youtube";
import { mixpanel } from "../../index";
import { useStateStore } from "../../store";

function MainWatchDemoModal({
  platform,
  showDemoPopup,
  customFn,
  home = false,
  directClick = false,
}) {
  const userData = useStateStore((state) => state.userData);
  let demos = {
    Linkedin: "DHh-bt1-EAE",
    Indeed: "aR8_Ij13orQ",
    "Ask For Referral": "dKED0TgBhiE",
    Ziprecruiter: "s-BzI4creaA",
    Dice: "WwDmgk_x7_0",
    "Career Builder": "E3-fnIsIrRM",
    "Simply Hired": "nk2j-S2amco",
    Glassdoor: "1MOnnJ86820",
  };

  let mainReference = {
    linkedin: "Linkedin",
    indeed: "Indeed",
    askForReferral: "Ask For Referral",
    ziprecruiter: "Ziprecruiter",
    dice: "Dice",
    careerBuilder: "Career Builder",
    simplyHiredLogo: "Simply Hired",
    glassdoor: "Glassdoor",
  };

  const [show, setShow] = useState(false);
  const [demoSelected, setDemoSelectedMain] = useState("none");

  const setDemoSelected = () => {
    setDemoSelectedMain(platform);
    mixpanel.track(`watch_demo_button home_page dashboard`, {
      email: userData.email,
      platform: platform,
      home: home,
      clicked_through: directClick
        ? "direct_click"
        : "click_through_extension_install_popup",
      href: window.location.href,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
  };

  const opts = {
    width: "100%",
    height: "500",
    playerVars: {
      autoplay: 1,
    },
  };

  function onReady(event) {
    console.log(event);
  }

  const handleClose = () => {
    mixpanel.track(`cross_button watch_demo_popup home_page dashboard`, {
      email: userData.email,
      platform: demoSelected,
      home: home,
      href: window.location.href,
      clicked_through: directClick
      ? "direct_click"
      : "click_through_extension_install_popup",
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    setShow(false);
    customFn();
  };

  useEffect(() => {
    setShow(showDemoPopup);
    if (showDemoPopup) {
      setDemoSelected();
    }
  }, [showDemoPopup]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{demoSelected} Demo</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "10px" }}>
        <YouTube videoId={demos[demoSelected]} opts={opts} onReady={onReady} />
      </Modal.Body>
    </Modal>
  );
}

export default MainWatchDemoModal;
