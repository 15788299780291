import React, { useState, useEffect } from "react";
// import stars from "../../stars-5.svg";
import "./reviews.css";
// import location from "../../location.png";
import data from "../../review.json";

function Review() {
  const getContent = (content) => {
    console.log("words", content.split(" ").length);
    if (content.split(" ").length > 40) {
      return content.substring(0, 160) + "......";
    }
    return content;
  };

  const contentOutflow = (content) => {
    if (content.split(" ").length > 40) {
      return true;
    } else {
      return false;
    }
  };
  const [indexArray, setIndexArray] = useState({});

  useEffect(() => {
    if (data?.data) {
      const obj = {};
      data.data.forEach((_, index) => {
        obj[index] = false;
      });
      setIndexArray(obj);
    }
  }, []);

  return (
    <div className="mainreviews">
      <h1 className="featureHeading">What people say about us? ( LazyApply Reviews )</h1>
      <div className="row">
        {data.data.map((value, index) => {
          return (
            <>
              <div className="col-lg-6 col-md-12">
                <div
                  className={
                    "mainreview" +
                    (indexArray[index] ? " mainreviewheight" : "")
                  }
                >
                  <div className="mainreview-header">
                    <div
                      className="mainreview-avataar"
                      style={{ backgroundColor: value.color }}
                    >
                      <div
                        style={
                          value?.img && {
                            backgroundImage: `url(${value?.img})`,
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                          }
                        }
                      >
                        {value?.img ? "" : value?.person}
                      </div>
                    </div>
                    <div className="mainreview-name">
                      <div className="mainreview-nametext">{value.name}</div>
                      <div className="mainreview-noreviews">
                        <div>{value.reviews}</div>
                        <div className="mainreview-location">
                          {" "}
                          <img src={'/assests/location.png'} alt="location"></img>
                          {value.country}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mainreview-border"></div>
                  <div className="mainreview-second-container">
                    <div className="mainreview-rating">
                      <img src={'/assests/stars-5.svg'} alt="5-star-rating"></img>
                    </div>
                    <div className="mainreview-time">{value.date}</div>
                  </div>
                  <div className="mainreview-content">
                    <div className="mainreview-title">{value.heading}</div>
                    <div className="mainreview-desc">
                      {contentOutflow(value.content) ? (
                        <>
                          {!indexArray[index]
                            ? getContent(value.content)
                            : value.content}
                          <span
                            className="showmore"
                            onClick={() => {
                              setIndexArray({
                                ...indexArray,
                                [index]: !indexArray[index],
                              });
                            }}
                          >
                            {!indexArray[index] ? "show more" : "show less"}
                          </span>
                        </>
                      ) : (
                        getContent(value.content)
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default Review;
