import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { mixpanel } from "../index";
import { useStateStore } from "../store/index";
import PayPalButton from "./dashboard/paypalButton";

function PricingBlock({
  title,
  paymentsMain,
  goto,
  processing,
  defaultCutPrice,
  defaultMainPrice,
  mostPopularTag = false,
  resumeLimit,
  userPlan,
  planValue,
  features = [
    "Linkedin automation",
    "Indeed automation",
    "Ziprecruiter automation",
  ],
  featuresCut = [],
  extrafeatures = ["Day wise analytics", "CV Improvement Tips"],
  jobLimit,
  country,
  showbnpl,
  setbnplplan,
  setshowbnpl,
  showSplitPayments,
  processingSplitPayments,
  setSplitPaymentsPlan,
  setShowSplitPayments,
  gotoSplitPayments,
}) {
  const userData = useStateStore((state) => state.userData);
  const isPaymentsFetched = () => {
    return Object.keys(paymentsMain).length > 0;
  };

  const planNames = {
    premium: { hirerchy: 2 },
    basic: { hirerchy: 1 },
    unlimited: { hirerchy: 3 },
  };

  const findUpgrade = () => {
    if (userPlan == "gold" || userPlan == "platinum" || userPlan == "diamond") {
      return true;
    } else {
      if (planValue) {
        let upgrade = false;
        const hirerchyValueCurrent = planNames[title].hirerchy;
        const hirerchyValueMain = planNames[userPlan].hirerchy;
        if (hirerchyValueCurrent > hirerchyValueMain) {
          upgrade = true;
        }
        return upgrade;
      } else {
        return true;
      }
    }
  };

  const buttonText = () => {
    const planText = title.toUpperCase();

    if (planValue) {
      if (
        userPlan == "gold" ||
        userPlan == "platinum" ||
        userPlan == "diamond"
      ) {
        return `Get ${planText} Plan`;
      } else if (findUpgrade()) {
        return `Upgrade To ${planText} Plan`;
      } else {
        return `Already on ${userPlan} Plan`;
      }
    } else {
      return `Get ${planText} Plan`;
    }
  };

  const [cutpricetext, setCutPriceText] = useState("");
  const [mainpricetext, setMainPriceText] = useState("");
  const [chooseDefault, setChooseDefault] = useState("default1");
  const [currency, setCurrency] = useState("$");
  useEffect(() => {
    setCutPriceText("cutprice" + title);
    setMainPriceText("mainprice" + title);
    if (country === "IN") {
      setChooseDefault("default5");
      setCurrency("$");
    } else {
      setChooseDefault("default1");
      setCurrency("$");
    }
  }, [title, country]);

  const ShowUpgrade = () => {
    console.log("planvalue", planValue);
    if (
      planValue &&
      (userPlan === "basic" || userPlan == "unlimited" || userPlan == "premium")
    ) {
      // here show upgrade buttons
      if (findUpgrade()) {
        if (showPriceAndCutPrice().value) {
          return (
            <PayPalButton
              titlemain={title}
              titleSecondary={country == "IN" ? title + "_indian" : title}
              amount={showPriceAndCutPrice().amount}
            />
          );
        } else {
          return (
            <PayPalButton
              titlemain={title}
              titleSecondary={country == "IN" ? title + "_indian" : title}
            />
          );
        }
      } else {
        return (
          <button
            type="button"
            disabled={!findUpgrade()}
            className={!findUpgrade() && "buttonDisabled"}
          >
            {buttonText()}
          </button>
        );
      }
    } else {
      if (showPriceAndCutPrice().value) {
        return (
          <PayPalButton
            titlemain={title}
            titleSecondary={country == "IN" ? title + "_indian" : title}
            amount={showPriceAndCutPrice().amount}
          />
        );
      } else {
        return (
          <PayPalButton
            titlemain={title}
            titleSecondary={country == "IN" ? title + "_indian" : title}
          />
        );
      }
    }
  };

  const priceList = {
    basic: "99",
    premium: "129",
    unlimited: "249",
    basic_indian: "29",
    premium_indian: "39",
    unlimited_indian: "59",
  };

  const showPriceAndCutPrice = () => {
    if (
      // country != "IN" &&
      planValue &&
      (userPlan == "basic" || userPlan == "unlimited" || userPlan == "premium")
    ) {
      if (userPlan == title || !findUpgrade()) {
        return {
          value: false,
        };
      } else {
        if (
          userPlan == "basic" &&
          (title == "premium" || title == "unlimited")
        ) {
          return {
            value: true,
            amount:
              country == "IN"
                ? Number(
                    priceList[title + "_indian"] -
                      priceList[userPlan + "_indian"]
                  )
                : Number(priceList[title] - priceList[userPlan]) + 20,
            cutprice:
              country == "IN"
                ? Number(
                    priceList[title + "_indian"] -
                      priceList[userPlan + "_indian"]
                  ) + 20
                : Number(priceList[title] - priceList[userPlan]) + 50,
          };
        } else if (userPlan == "premium" && title == "unlimited") {
          return {
            value: true,
            amount:
              country == "IN"
                ? Number(
                    priceList[title + "_indian"] -
                      priceList[userPlan + "_indian"]
                  )
                : Number(priceList[title] - priceList[userPlan]) + 20,
            cutprice:
              country == "IN"
                ? Number(
                    priceList[title + "_indian"] -
                      priceList[userPlan + "_indian"]
                  ) + 20
                : Number(priceList[title] - priceList[userPlan]) + 50,
          };
        }
      }
    } else {
      return {
        value: false,
      };
    }
  };

  return (
    <div className="card cardone cardoutside">
      {mostPopularTag && (
        <div className="card-ribbon">
          <span>MOST POPULAR</span>
        </div>
      )}

      <div className="card-title">
        <h3>{title} Plan</h3>
      </div>
      <div className="card-price">
        <h1>
          <p>
            {showPriceAndCutPrice().value == true ? (
              <>
                <>
                  <span className="cutprice1">
                    {currency}
                    {showPriceAndCutPrice().cutprice}
                  </span>
                  {currency}
                  {showPriceAndCutPrice().amount}
                </>
              </>
            ) : (
              <>
                {isPaymentsFetched() ? (
                  <>
                    <span className="cutprice1">
                      {currency}
                      {paymentsMain[chooseDefault][cutpricetext]}
                    </span>
                    {currency}
                    {paymentsMain[chooseDefault][mainpricetext]}
                  </>
                ) : (
                  <>
                    <span className="cutprice1">
                      {currency}
                      {defaultCutPrice}
                    </span>
                    {currency}
                    {defaultMainPrice}
                  </>
                )}
              </>
            )}
          </p>
        </h1>
      </div>
      <div className="card-description">
        <ul>
          <li className="lifetime">Lifetime Access</li>
          {features.map((feature, index) => {
            return <li key={index}>{feature}</li>;
          })}
          {featuresCut.map((feature, index) => {
            return (
              <li key={index}>
                {" "}
                <span className="cutpricezip">{feature}</span>
              </li>
            );
          })}
          {/* <li>Unlimited emails from Linkedin profiles</li> */}
          <li className="lifetime">
            Daily limit - {jobLimit} Job Applications
          </li>
          <li className="lifetime">Up to - {resumeLimit} Resumes / CV's</li>
          {extrafeatures.map((feature, index) => {
            return <li key={index}>{feature}</li>;
          })}
        </ul>
      </div>
      {/* <div className="row referral"></div> */}
      <div className="card-action">
        <>
          {showbnpl && paymentsMain?.bnpl?.status && (
            <button
              type="button"
              className="buttonPricing1 buttonPricing2 bnplbutton"
              onClick={() => {
                mixpanel.track("Dashboard - bnpl", {
                  email: userData.email,
                });
                setbnplplan(title);
                setshowbnpl(true);
              }}
            >
              Buy Now Pay Later
            </button>
          )}
          {showSplitPayments && paymentsMain?.splitpayments?.status ? (
            <>
              <p
                className="aboutsplit"
                onClick={() => {
                  mixpanel.track("Dashboard - know about split payment", {
                    planName: title,
                  });
                  setSplitPaymentsPlan(title);
                  setShowSplitPayments(true);
                }}
              >
                Know about split payment
              </p>
              <button
                type="button"
                // className="buttonPricing1 buttonPricing2 bnplbutton buttonsplit"
                className={!findUpgrade() && "buttonDisabled"}
                disabled={processingSplitPayments || !findUpgrade()}
                onClick={() => {
                  gotoSplitPayments(title);
                }}
                style={{ marginBottom: "10px" }}
              >
                Split Payment Into Half (1/2)
                {processingSplitPayments === title ? (
                  <Spinner
                    animation="border"
                    size="sm"
                    style={{ marginLeft: "5px", marginBottom: "5px" }}
                  />
                ) : (
                  <></>
                )}
              </button>
            </>
          ) : (
            <></>
          )}
          {/* {country != "IN" && <ShowUpgrade />} */}
          <ShowUpgrade />
          {/* {country == "IN" && (
            <>
              <button
                type="button"
                onClick={() => goto(title)}
                disabled={processing || !findUpgrade()}
                className={!findUpgrade() && "buttonDisabled"}
              >
                {buttonText()}
                {processing === title ? (
                  <Spinner
                    animation="border"
                    size="sm"
                    style={{ marginLeft: "5px", marginBottom: "5px" }}
                  />
                ) : (
                  <></>
                )}
              </button>
            </>
          )} */}
        </>
      </div>
    </div>
  );
}

export default PricingBlock;
