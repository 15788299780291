import React, { useState } from "react";
import axios from "axios";
import { useStateStore } from "../../store/index";
import { PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import CustomAlert from "../modal";
import { paddle, mixpanel } from "../../index";

function PayPalButton({ titlemain, amount, titleSecondary = "" }) {
  const FUNDING_SOURCES = [FUNDING.PAYPAL];
  const token = useStateStore((state) => state.token);
  const [message, setmessage] = useState("");
  const userData = useStateStore((state) => state.userData);
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);

  const handleCreateOrder = async (data, actions) => {
    try {
      const response = await axios.post(
        "https://backend.lazyapply.com/create-paypal-order",
        {
          title: titlemain,
          amount: amount,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const details = response.data;
      return details.id;
    } catch (error) {
      console.error(error);
      // Handle the error or display an appropriate error message to the user
    }
  };

  const openCheckout = (priceId, email) => {
    paddle.Checkout.open({
      settings: {
        theme: "light",
        displayMode: "overlay",
      },
      customData: {
        accountemail: email,
        name: userData?.name || "",
      },
      customer: {
        email: email,
      },
      items: [
        {
          priceId: priceId,
          quantity: 1,
        },
      ],
    });
  };

  const handlePaddleOrder = async () => {
    try {
      mixpanel.identify(userData?.email);
      mixpanel.people.increment("purchase_plan_button pricing_page dashboard");
      mixpanel.track("purchase_plan_button pricing_page dashboard", {
        email: userData?.email,
        planname: titlemain,
        amount: amount,
        plan_details: userData?.planDetails || {},
        v2Id: userData?.planDetails?.v2Id || "",
      });
      const response = await axios.post(
        "https://backend.lazyapply.com/create-link-paddle",
        {
          title: titlemain,
          amount: amount,
          titleSecondary: titleSecondary,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const product_id = response.data.product_id;
      const email = response.data.email;
      openCheckout(product_id, email);
    } catch (error) {
      console.error(error);
    }
  };

  const handleLemonCreateOrder = async () => {
    try {
      const response = await axios.post(
        "https://backend.lazyapply.com/create-link-lemon",
        {
          title: titlemain,
          amount: amount,
          titleSecondary: titleSecondary,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const details = response.data;
      window.open(details.checkoutUrl, "_blank");
    } catch (error) {
      console.error(error);
      // Handle the error or display an appropriate error message to the user
    }
  };

  const handleOnApprove = async (data, actions) => {
    try {
      const response = await axios.post(
        `https://backend.lazyapply.com/capture-paypal-order`,
        {
          orderID: data.orderID,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const details = response.data;
      // Three cases to handle:
      //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
      //   (2) Other non-recoverable errors -> Show a failure message
      //   (3) Successful transaction -> Show confirmation or thank you message

      // This example reads a v2/checkout/orders capture response, propagated from the server
      // You could use a different API or structure for your 'orderData'
      const errorDetail = Array.isArray(details.details) && details.details[0];

      if (errorDetail && errorDetail.issue === "INSTRUMENT_DECLINED") {
        return actions.restart();
        // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
      }

      if (errorDetail) {
        let msg = "Sorry, your transaction could not be processed.";
        msg += errorDetail.description ? " " + errorDetail.description : "";
        msg += details.debug_id ? " (" + details.debug_id + ")" : "";

        setmessage(msg);
        settitle("Some Error Occured");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 2500);
      } else {
        // Successful capture! For demo purposes:
        console.log(
          "Capture result",
          details,
          JSON.stringify(details, null, 2)
        );
        const transaction = details.purchase_units[0].payments.captures[0];
        console.log("transaction", transaction);
        const number = transaction.amount.value;
        const orderId = details.id;
        const amount = parseFloat(number);
        if (amount) {
          const finalresponse = await axios.post(
            `https://backend.lazyapply.com/activatePlanPaypalExtension`,
            {
              orderId: orderId,
              amount: amount,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("final response", finalresponse);
        }
        setmessage(`Transaction success, plan is activated!`);
        settitle("Paid Successfully");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
          window.location.reload();
        }, 5000);
        //   alert(
        //     "Transaction " +
        //       transaction.status +
        //       ": " +
        //       transaction.id +
        //       "See console for all available details"
        //   );
      }
    } catch (error) {
      console.error(error);
      // Handle the error or display an appropriate error message to the user
    }
  };

  return (
    <>
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      {/* <PayPalButtons
        fundingSource={FUNDING.PAYPAL}
        style={{
          layout: "vertical",
          shape: "pill",
          color: "gold",
          label: "buynow",
        }}
        createOrder={handleCreateOrder}
        onApprove={handleOnApprove}
      /> */}
      {/* <button onClick={() => handlePaddleOrder()}>Purchase {titlemain}</button> */}
      <button onClick={() => handleLemonCreateOrder()}>
        Purchase {titlemain}
      </button>
    </>
  );
}

export default PayPalButton;
