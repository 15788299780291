import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useStateStore } from "../../store";
import { Spinner } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import CustomAlert from "../modal";
import { mixpanel } from "../../index";

function LazyapplyXFourth() {
  const generatedSampleEmail = useStateStore(
    (state) => state.generatedSampleEmail
  );
  const userData = useStateStore((state) => state.userData);
  const { id } = useParams();
  console.log("id", id);
  const globalLogout = useStateStore((state) => state.globalLogout);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const history = useHistory();
  const loadingMain = useRef(null);
  const token = useStateStore((state) => state.token);
  const lazyapplyXData = useStateStore((state) => state.lazyapplyXData);
  const [loading, setLoading] = useState(false);
  const [loadingSendEmails, setLoadingSendEmails] = useState(false);
  const replaceWithBr = (text) => {
    return text.replace(/\n/g, "<br />");
  };

  const purchasePlan = () => {
    setLoading(true);
    mixpanel.identify(userData.email);
    mixpanel.people.increment(
      "Dashboard - LaxyapplyX session start purchase plan button click"
    );
    mixpanel.track(
      "Dashboard - LaxyapplyX session start purchase plan button click",
      {
        email: userData.email,
      }
    );
    axios
      .get(`https://backend.lazyapply.com/create-checkout-session-lazyapplyx`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setLoading(false);
        console.log(response);
        if (
          typeof response.data === "object" &&
          response.data !== null &&
          "url" in response.data
        ) {
          window.open(response.data.url, "_blank");
        } else {
          console.log("error");
          alert("Some error occured");
        }
      })
      .catch((error) => {
        setLoading(false);
        alert("Some error ocurred");
      });
  };

  useEffect(() => {
    mixpanel.identify(userData.email);
    mixpanel.people.increment(
      "Dashboard - LaxyapplyX session start send emails page visit"
    );
    mixpanel.track(
      "Dashboard - LaxyapplyX session start send emails page visit",
      {
        email: userData.email,
      }
    );
  }, []);

  const setGeneratedSampleEmail = useStateStore(
    (state) => state.setGeneratedSampleEmail
  );
  const setLazyapplyXData = useStateStore((state) => state.setLazyapplyXData);
  useEffect(() => {
    if (loadingMain.current) {
      loadingMain.current.style.display = "block";
      axios
        .get(`https://backend.lazyapply.com/targetSearchData`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          loadingMain.current.style.display = "none";
          console.log("data", response.data);
          setLazyapplyXData(response.data);
          if (response.data.sampleEmailGenerated) {
            setGeneratedSampleEmail(response.data.sampleEmailGenerated);
          }
        })
        .catch((err) => {
          loadingMain.current.style.display = "none";
          console.log("some error occured");
          if (err.message == "Request failed with status code 403") {
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
              history.push("/");
            }, 1500);
          }
        });
    }
  }, [loadingMain]);

  function goBack() {
    history.goBack();
  }

  const sendEmails = () => {
    setLoadingSendEmails(true);
    mixpanel.identify(userData.email);
    mixpanel.people.increment(
      "Dashboard - LaxyapplyX session start send emails button click"
    );
    mixpanel.track(
      "Dashboard - LaxyapplyX session start send emails button click",
      {
        email: userData.email,
      }
    );
    axios
      .post(
        `https://backend.lazyapply.com/lazyapplyXGetEmails`,
        {
          uniqueId: id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setLoadingSendEmails(false);
        if (response.data.message === "success") {
          setmessage(
            "Process is started, you will receive a email once the process is completed."
          );
          settitle("Process Started");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
            history.push(`/dashboard/lazyapply-x/sessions`);
          }, 4000);
        } else {
          setmessage("Some error occured, please try again in some time!");
          settitle("Error");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2500);
        }
      })
      .catch((err) => {
        setLoadingSendEmails(false);
        if (err.message == "Request failed with status code 403") {
          console.log("reauthenticate");
          setmessage("Please relogin again!");
          settitle("Not authorized");
          setmodalshow(true);
          setTimeout(() => {
            globalLogout();
            history.push("/");
          }, 1500);
        }
      });
  };

  return (
    <>
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      <div style={{ position: "relative" }}>
        <button className="buttonGoBack btn" onClick={goBack}>
          <i className="fas fa-arrow-left"></i> Go Back
        </button>
        <div className="loading loadingresume" ref={loadingMain}>
          <div className="boxLoading"></div>
        </div>
        <div className="EmailTemplate" style={{ marginTop: "50px" }}>
          This is how content of <span>sample email for Facebook.com</span>{" "}
          using your input parameters will look like
        </div>

        <div
          className="coverletteroutput emailTemplateBody"
          dangerouslySetInnerHTML={{
            __html: replaceWithBr(generatedSampleEmail),
          }}
        />
        {/* 
        {lazyapplyXData.planStarted === 1 ||
        userData.planDetails.planStarted == 1 ||
        userData.email === "oauthtest222@gmail.com" ? ( */}
        <div className="purchaseToContinue">
          <button className="getStarted" onClick={sendEmails}>
            Get Started And Send Emails
            {loadingSendEmails && (
              <Spinner
                animation="border"
                size="sm"
                style={{ marginLeft: "10px" }}
              />
            )}
          </button>
        </div>
        {/* ) : (
          <>
            <div className="purchaseToContinue" onClick={purchasePlan}>
              <button className="purchase">
                Purchase Plan To Continue{" "}
                {loading && (
                  <Spinner
                    animation="border"
                    size="sm"
                    style={{ marginLeft: "10px" }}
                  />
                )}
              </button>
            </div>
          </>
        )} */}
      </div>
    </>
  );
}

export default LazyapplyXFourth;
