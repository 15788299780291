import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import { Spinner } from "react-bootstrap";
import { useStateStore } from "../../store/index";
import { v1 as uuidv1 } from "uuid";
import UploadImage from "./uploadImage";
import "./FeedbackPopup.css";
import CustomAlert from "../modal";

function FeedbackPopup({ showProp, hideShow }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    hideShow();
  };
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const userData = useStateStore((state) => state.userData);
  const [showSpinner, setShowSpinner] = useState(false);
  const token = useStateStore((state) => state.token);
  const [image, setImage] = useState(null);

  const [data, setData] = useState({
    url: "",
    feedbackText: "",
    feedbackType: [
      {
        label: "Select a option",
        value: "",
      },
    ],
  });

  let buttonSave = {
    backgroundColor: "#35AD46",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "10px 20px",
    textAlign: "center",
    borderRadius: "10px",
    width: "100%",
    outline: "none",
    border: "none",
    marginTop: "50px",
  };

  let showr = {
    textAlign: "center",
    fontWeight: "bold",
    color: "black",
    fontSize: "16.5px",
    marginTop: "10px",
  };
  const [showMessage, setShowMessage] = useState("");

  const showCustomMessage = (message) => {
    setShowMessage(message);
    setTimeout(() => {
      setShowMessage("");
    }, 2500);
  };

  function submitCoupanCode(e) {
    e.preventDefault();
    setDisable(true);
    const v2Id = userData?.planDetails?.v2Id;
    console.log("data", data, image);
    if (
      data.feedbackText != "" &&
      data.feedbackText.length >= 120 &&
      data.feedbackType.value != "" &&
      data.url != "" &&
      image
    ) {
      const id = uuidv1();
      console.log("id", id);
      const formData = new FormData();
      formData.append("feedbackType", data.feedbackType.value);
      formData.append("feedbackText", data.feedbackText);
      formData.append("feedbackId", id);
      formData.append("v2Id", v2Id);
      formData.append("file", image);
      console.log("formData", formData);
      setShowSpinner(true);
      axios
        .post(`https://backend.lazyapply.com/lazyapplyx/feedback`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setShowSpinner(false);
          setDisable(false);
          setData({
            url: "",
            feedbackText: "",
            feedbackType: [
              {
                label: "Select a option",
                value: "",
              },
            ],
          });
          setImage(null);
          setmessage("Successfully Created!");
          settitle("Success");
          setmodalshow(true);
          setTimeout(() => {
            handleClose();
            setmodalshow(false);
          }, 2000);
        })
        .catch((error) => {
          setShowSpinner(false);
          setDisable(false);
          showCustomMessage("Some error occured, please try again!");
          console.log(error, "error submitcode");
        });
    } else {
      setDisable(false);
      if (data.feedbackText.length < 120) {
        showCustomMessage("Feedback length is less than 120 characters");
      } else {
        showCustomMessage("Some fields are empty, please fill them");
      }
    }
  }

  const resetResumeFile = () => {
    setImage(null);
    setField("url", "");
  };

  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  const setField = (key, value) => {
    console.log(`Setting field ${key}: `, value); // Check what's being set
    setData({
      ...data,
      [key]: value,
    });
  };

  return (
    <Modal
      show={show}
      //   onHide={handleClose}
      centered
      dialogClassName="custom-modal-width3"
      backdrop="static"
    >
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      <Modal.Header closeButton>
        <Modal.Title>Lazyapply-X Feedback / Feature Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ color: "black", fontWeight: "bold" }}>
          We Value Your Feedback on Lazyapply-X Beta!
        </p>
        <p style={{ color: "blue" }}>🌟 Help Us Enhance Your Experience! </p>
        <p style={{ color: "blue" }}>
          🌟 As part of our ongoing commitment to improve Lazyapply-X, we're
          eager to hear from you. Your insights and suggestions are vital in
          shaping the future of our service.
        </p>
        <p style={{ color: "blue" }}>
          Please share your genuine feedback or feature requests with us. What
          We're Looking For: Constructive Feedback: Share your experiences, both
          positive and challenging, with our beta version. Feature Requests:
          Tell us what features would make Lazyapply-X even better for you
        </p>
        <p style={{ color: "red" }}>
          Please Note: Quality Matters: We seek thoughtful, specific feedback.
          Vague or nonsensical responses may lead to removal from our beta
          program. Filling feedback is important after every session.
        </p>
        <div style={{ padding: "20px", maxWidth: "100%" }}>
          <Form onSubmit={submitCoupanCode}>
            <Form.Group>
              <Form.Label>Type Of Request</Form.Label>
              <Select
                onChange={(option) => {
                  console.log("option", option);
                  setField("feedbackType", option);
                }}
                isMulti={false}
                placeholder={"Select a option"}
                value={data.feedbackType}
                options={[
                  {
                    value: "",
                    label: "Select a option",
                  },
                  {
                    value: "Feedback",
                    label: "Feedback",
                  },
                  {
                    value: "Feature Request",
                    label: "Feature Request",
                  },
                ]}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                required={true}
                as="textarea"
                rows={4}
                id="resumename"
                value={data.feedbackText}
                placeholder="Enter Feedback / Feature Request Here .. (more than 120 characters)"
                onChange={(e) => setField("feedbackText", e.target.value)}
              />
            </Form.Group>

            <div className="resumeTrackerUpload">
              <p style={{ marginTop: "20px" }}>
                Upload Relevant Image Here ( for example - follow up email, some
                configuration etc)
                <span
                  onClick={resetResumeFile}
                  style={{
                    border: "none",
                    outline: "none",
                    background: "orange",
                    color: "white",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                >
                  Reset Image
                </span>
              </p>
              <UploadImage
                setImage={setImage}
                setPreviewUrl={(value) => setField("url", value)}
                previewUrl={data.url}
                image={data.image}
              />
            </div>

            {data.url && (
              <p style={{ color: "green" }}>
                Image Uploaded Successfully! press create now button
              </p>
            )}

            <button style={buttonSave} disabled={disable}>
              Create Now{" "}
              {showSpinner && (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ marginLeft: "5px" }}
                />
              )}
            </button>
          </Form>
          <p style={showr}>{showMessage}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default FeedbackPopup;
