import React, { useEffect, useState } from "react";
import "../dashboard/homedash.css";
import { useHistory } from "react-router-dom";
import { useStateStore } from "../../store/index";
import axios from "axios";
import CustomAlert from "../modal";
import { mixpanel } from "../../index";
import ApplyCoupanCode from "../dashboard/applycoupan.js";
import { GrantAccess } from "../dashboard/grantAccess";
import SkeletonLoader from "../dashboard/skeletonLoader";
import "./maindashboard.css";

function Homedash() {
  const history = useHistory();
  const emailAutomationLastSession = useStateStore(
    (state) => state.emailAutomationLastSession
  );
  const [data, setData] = useState({});
  const setEmailAutomationLastSession = useStateStore(
    (state) => state.setEmailAutomationLastSession
  );
  const [lazyapplyXLoading, setLazyapplyXLoading] = useState(false);
  const [emailSessionLoading, setEmailSessionLoading] = useState(false);
  const mainLoadingState = useStateStore((state) => state.mainLoadingState);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const globalLogout = useStateStore((state) => state.globalLogout);
  const userData = useStateStore((state) => state.userData);
  const configDummy = useStateStore((state) => state.configDummy);
  const token = useStateStore((state) => state.token);
  const [country, setCountry] = useState("");

  useEffect(() => {
    mixpanel.identify(userData?.email);
    mixpanel.people.increment("email_automation_page dashboard");
    mixpanel.track("email_automation_page dashboard", {
      email: userData?.email,
    });
    if (country === "") findIP();
  }, []);

  const [resumefetch, setresumefetch] = useState(0);
  const setResumesComplete = useStateStore(
    (state) => state.setResumesCompleteV3
  );
  const setResumeFetchLoadingState = useStateStore(
    (state) => state.setResumeFetchLoadingState
  );

  useEffect(() => {
    if (userData?.planDetails?.v2Id) {
      const fetchData = async () => {
        console.log("fetchcall");
        try {
          const response = await axios.post(
            `https://lazyapplycore.lazyapply.com/automationQueue/getLastSession`,
            { v2Id: userData?.planDetails?.v2Id },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setEmailSessionLoading(true);
          if (response.data.lastAddedDoc.found) {
            console.log("data lastAddedDoc", response.data.lastAddedDoc);
            setEmailAutomationLastSession(response.data.lastAddedDoc);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }
  }, [userData]);

  const getData = () => {
    axios
      .get(`https://backend.lazyapply.com/targetSearchData`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("data", response.data);
        setLazyapplyXLoading(true);
        setData(response.data);
      })
      .catch((err) => {
        console.log("some error occured");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (resumefetch === 0 && userData?.planDetails?.v2Id) {
      setResumeFetchLoadingState(0);
      const v2Id = userData?.planDetails?.v2Id;
      axios
        .get(`https://backend.lazyapply.com/v2/resumesV3/completed/${v2Id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setresumefetch(1);
          setResumeFetchLoadingState(1);
          console.log("resumescompleted", response);
          if ("resumes" in response.data) {
            console.log("resumescompleted1");
            setResumesComplete(response.data.resumes);
          }
        })
        .catch((err) => {
          setresumefetch(1);
          setResumeFetchLoadingState(1);
          console.log("some error occured");
          if (err.message == "Request failed with status code 403") {
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
            }, 1500);
          } else {
            console.log("error");
          }
        });
    }
  }, [userData, token]);

  function goToPricing() {
    // mixpanel.identify(userData.email);
    // mixpanel.people.increment(
    //   "Dashboard - upgrade plan button clicked - dashboard home"
    // );
    // mixpanel.track("Dashboard - upgrade plan button clicked - dashboard home", {
    //   email: userData.email,
    // });
    // window.open("https://lazyapply.com/pricing", "_blank");
    history.push("/pricing");
  }

  function findIP() {
    axios.get("https://ipinfo.io/json?token=7987a2c0dfe3af").then((res) => {
      const data = res.data;
      setCountry(data.country);
    });
  }

  function planFun() {
    if (configDummy.plan == 0 || configDummy.planName == "free") {
      return (
        <>
          <span onClick={() => goToPricing()}>
            Purchase Plan{" "}
            <i
              className="fas fa-credit-card ml-2"
              style={{ color: "white" }}
            ></i>
          </span>
        </>
      );
    } else if (
      configDummy.planName == "basic" ||
      configDummy.planName == "premium"
    ) {
      return (
        <>
          <span onClick={() => goToPricing()}>
            Upgrade plan{" "}
            <i
              className="fas fa-credit-card ml-2"
              style={{ color: "white" }}
            ></i>
          </span>
        </>
      );
    } else {
      return <>{configDummy.planName.toUpperCase()} Plan</>;
    }
  }
  const [showProp, setShowProp] = useState(false);
  function planFun1() {
    setShowProp(true);
  }

  function closePlanFun1() {
    setShowProp(false);
  }

  function setA(platform) {
    mixpanel.identify(userData?.email);
    mixpanel.people.increment(
      "start_now_button email_automation_page dashboard"
    );
    mixpanel.track(`start_now_button email_automation_page dashboard`, {
      email: userData?.email,
    });
  }

  const waitFn = async (time) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, time);
    });
  };

  const accessPlatform = async (platformName) => {
    const response = await GrantAccess(platformName);
    if (!response.access) {
      setmessage(response.resultObj.message);
      settitle(response.resultObj.title);
      setmodalshow(true);
      await waitFn(2500);
      setmodalshow(false);
      if (response?.resultObj?.gotolinknew) {
        window.open(response.resultObj.gotolinknew, "_blank");
      }

      if (response?.resultObj?.gotolink) {
        history.push(response.resultObj.gotolink);
      }
    } else {
      if (!("refresh_token" in data)) {
        setmessage("Please grant the email permission to start applying");
        settitle("Email Permission Required!");
        setmodalshow(true);
        await waitFn(2500);
        setmodalshow(false);
        history.push("/dashboard/emailautomationpermission");
      } else {
        // if (getStatus().text == "Process Pending") {
        //   setmessage("Your last email automation session is in pending state.");
        //   settitle("Last Session In Pending State");
        //   setmodalshow(true);
        //   await waitFn(2500);
        //   setmodalshow(false);
        // } else {
        history.push(`/dashboard/${platformName}`);
        // }
      }
    }
  };

  const getStatus = () => {
    if (
      emailAutomationLastSession?.statusText &&
      emailAutomationLastSession.statusText == "jobsFound"
    ) {
      return {
        text: "Process Pending",
        color: "red",
        color1: "red",
      };
    } else if (
      emailAutomationLastSession?.statusText &&
      emailAutomationLastSession.statusText == "jobsSelected"
    ) {
      return {
        text: "Finding Contacts",
        color: "green",
        color1: "#007bff",
      };
    } else if (
      emailAutomationLastSession?.statusText &&
      emailAutomationLastSession.statusText == "emailsSend"
    ) {
      return {
        text: "Completed",
        color: "green",
        color1: "#007bff",
      };
    } else if (
      emailAutomationLastSession?.statusText &&
      emailAutomationLastSession.statusText == "contactsFound"
    ) {
      return { text: "Finding Emails", color: "green", color1: "#007bff" };
    } else if (
      emailAutomationLastSession?.statusText &&
      emailAutomationLastSession.statusText == "emailsFound"
    ) {
      return { text: "Sending Emails", color: "green", color1: "#007bff" };
    }
    return { text: "In Process", color: "green", color1: "#007bff" };
  };

  if (!mainLoadingState || !emailSessionLoading || !lazyapplyXLoading) {
    return (
      <>
        <SkeletonLoader />
      </>
    );
  }

  return (
    <>
      <div>
        {"refresh_token" in data ? (
          <p
            style={{
              color: "white",
              fontWeight: "bold",
              background: "green",
              padding: "10px",
              marginTop: "0px",
              textAlign: "center",
            }}
          >
            Email Permissions Already Given
          </p>
        ) : (
          <></>
        )}
        <ApplyCoupanCode showProp={showProp} hideShow={closePlanFun1} />
        <CustomAlert
          message={message}
          s={modalshow}
          title={title}
        ></CustomAlert>
        {Object.keys(emailAutomationLastSession).length > 0 && (
          <div className="emailAutomationLastSession">
            <span className="emailAutomationLastSessionHeading">
              Last Active Session
            </span>
            <div
              className="emailAutomationLastSessionBlock"
              onClick={() => {
                mixpanel.identify(userData?.email);
                mixpanel.people.increment(
                  "view_email_automation_session_details_link email_automation_page dashboard"
                );
                mixpanel.track(
                  "view_email_automation_session_details_link email_automation_page dashboard",
                  {
                    email: userData?.email,
                    href: window.location.href,
                  }
                );
                history.push(
                  `/dashboard/emailAutomation/${emailAutomationLastSession.sessionId}`
                );
              }}
            >
              Status -{" "}
              <span
                style={{
                  color: getStatus()?.color || "green",
                }}
              >
                {getStatus().text}
              </span>
              {getStatus().text == "Process Pending" ? (
                <>
                  <div
                    className="emailAutomationLastSessionBlockLink"
                    style={{
                      color: getStatus()?.color1 || "#007bff",
                    }}
                  >
                    Click to select jobs and start process{" "}
                    <i className="fas fa-external-link-alt ml-2"></i>
                  </div>
                </>
              ) : (
                <>
                  <div className="emailAutomationLastSessionBlockLink">
                    View email automation session details{" "}
                    <i className="fas fa-external-link-alt ml-2"></i>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {/* <div className="dashboardHeading">
          <div className="dashboardHeadingLeft">
            <span className="dashboardText">Email Automation Dashboard</span>
          </div>
          <div className="dashboardHeadingRight">
            {configDummy.plan == 0 ? (
              <button onClick={planFun1} className="button1">
                Apply Coupon Code
              </button>
            ) : (
              <></>
            )}
            <button>{planFun()}</button>
          </div>
        </div> */}
      </div>
      <div
        className="dashboardRightMiddle"
        style={{
          display: "block",
        }}
      >
        <div className="dashboardRightMiddleCard">
          <div className="jobdiv jobdivimage">
            <img
              src={"/assests/email-automation.webp"}
              className="linkedinLogo"
            ></img>
          </div>
          <div className="jobdiv">
            <p>Email Automation</p>
            <p className="maindashboardtext">
              Find and select companies to send emails to HRs/Employees. Our
              algorithm finds the contacts and sends automated AI-written emails
              for you. We will attach your resume and other details in a
              structured format in the emails
            </p>
          </div>
          <div className="jobdiv jobdivbutton maindashboardbutton">
            <button
              className="startNow"
              style={{
                backgroundColor: "steelblue",
              }}
              onClick={() => {
                setA("emailAutomation");
                accessPlatform("emailAutomation");
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>Start Now</p>
                <i
                  className="fa fa-plus maindashboardplus"
                  aria-hidden="true"
                ></i>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Homedash;
