import React, { useState, useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useStateStore } from "../../store/index";
import axios from "axios";
import CustomAlert from "../modal";
import { mixpanel } from "../../index";

const PrivacySettings = () => {
  const history = useHistory();
  const token = useStateStore((state) => state.token);
  const userData = useStateStore((state) => state.userData);
  const [showModal, setShowModal] = useState(false);
  const [process, setprocess] = useState(false);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const lazyapplyXData = useStateStore((state) => state.lazyapplyXData);
  const setLazyapplyXData = useStateStore((state) => state.setLazyapplyXData);
  const [modalshow, setmodalshow] = useState(false);
  const setSettingsClick = useStateStore((state) => state.setSettingsClick);

  const handleDeleteClick = () => {
    mixpanel.track("Dashboard - LaxyapplyX data deleted button click", {
      email: userData?.email,
    });
    setShowModal(true);
  };

  const handleConfirmDelete = () => {
    setprocess(true);
    axios
      .get("https://backend.lazyapply.com/deleteAllDataLazyapplyX", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        mixpanel.track("Dashboard - LaxyapplyX data deleted successfully", {
          email: userData?.email,
        });
        setShowModal(false);
        setprocess(false);
        setmessage("Data Deleted Successfully.");
        settitle("Successfully Deleted");
        setmodalshow(true);
        setSettingsClick(false);
        setLazyapplyXData({
          ...lazyapplyXData,
          stepNo: 0,
        });
        setTimeout(() => {
          setmodalshow(false);
          history.push("/dashboard/lazyapply-x");
        }, 2000);
      })
      .catch((error) => {
        setShowModal(false);
        setmessage("There was an error. Please try again.");
        settitle("Error");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 2000);
        console.error("Error deleting data:", error);
        setprocess(false);
      });
  };

  function goBack() {
    history.goBack();
  }

  return (
    <div style={{ padding: "30px", position: "relative" }}>
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      {!process && (
        <button className="buttonGoBack btn" onClick={goBack}>
          <i className="fas fa-arrow-left"></i> Go Back
        </button>
      )}
      <h2>Privacy Settings</h2>
      <p>
        At LazyApply, we respect your right to data privacy and are committed to
        ensuring the protection of your personal information. On this page, you
        can manage your data privacy settings.
      </p>
      <p>
        If you choose to delete your data:
        <ul>
          <li>
            All your personal information will be permanently removed from our
            system.
          </li>
          <li>
            We will cease any and all processing of your data, including using
            the AI model for personalized email generation or any other purpose.
          </li>
          <li>
            You will no longer benefit from any personalized services, and
            certain features of the platform may become unavailable.
          </li>
        </ul>
      </p>
      <p>
        We recommend reviewing all implications before making a decision. If you
        have any questions about how your data is used, please refer to our
        detailed privacy policy or reach out to our support team.
      </p>
      <Button
        variant="danger"
        onClick={handleDeleteClick}
        style={{ marginTop: "20px" }}
      >
        Delete Data
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Data Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete all your data? This action cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            {process ? (
              <>
                Deleting..
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ marginLeft: "5px" }}
                />
              </>
            ) : (
              <>Confirm Delete</>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PrivacySettings;
