import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import axios from "axios";
import { useStateStore } from "../../store/index";
// import { ReactPixel } from "../../index";
function ApplyCoupanCode({ showProp, hideShow }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    hideShow();
  };
  const userData = useStateStore((state) => state.userData);
  const [value, setValue] = useState("");
  const token = useStateStore((state) => state.token);
  let buttonSave = {
    backgroundColor: "#35AD46",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "10px 20px",
    textAlign: "center",
    borderRadius: "10px",
    width: "100%",
    outline: "none",
    border: "none",
    marginTop: "10px",
  };

  let showr = {
    textAlign: "center",
    fontWeight: "bold",
    color: "black",
    fontSize: "16.5px",
    marginTop: "10px",
  };
  const [showMessage, setShowMessage] = useState("");

  const paymentSuccess = () => {
    // ReactPixel.trackCustom("Purchase_success_outside_platforms", {
    //   email: userData.email,
    // });
  };

  function submitCoupanCode(e) {
    e.preventDefault();
    setDisable(true);
    if (value.length == 10 || value.length == 15) {
      axios
        .post(
          "https://backend.lazyapply.com/updateWithCoupanCode",
          {
            code: value.toUpperCase(),
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response, "response submitcode");
          setShowMessage(response.data);
          setTimeout(() => {
            setShowMessage("");
          }, 2000);
          if (response.data == "Plan updated successfully") {
            paymentSuccess();
            setTimeout(() => {
              window.location.reload();
            }, 2500);
          } else {
            setDisable(false);
            console.log("do nothing");
          }
        })
        .catch((error) => {
          setDisable(false);
          setShowMessage("Some error occured, please try again!");
          setTimeout(() => {
            setShowMessage("");
          }, 2500);
          console.log(error, "error submitcode");
        });
    } else {
      setDisable(false);
      setShowMessage(
        "Invalid! Coupon code should be of 10 characters or 15 characters"
      );
      setTimeout(() => {
        setShowMessage("");
      }, 2500);
    }
  }

  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="md"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Enter Coupon Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: "20px" }}>
          <Form onSubmit={submitCoupanCode}>
            <Form.Control
              required={true}
              type="text"
              id="inputcoupan"
              placeholder="Enter coupon code to continue"
              onChange={(e) => setValue(e.target.value)}
            />
            <button style={buttonSave} disabled={disable}>
              Submit To Continue
            </button>
          </Form>
          <p style={showr}>{showMessage}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ApplyCoupanCode;
