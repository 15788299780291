import React, { useEffect, useState } from "react";
import makeData from "./makeData";
import MainTable from "./mainTable";
import { useStateStore } from "../../store";
import axios from "axios";

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function AppliedJobsTableSeperate({
  totalJobs,
  showSessionLinks = {},
  goBack = () => {},
}) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Job Details",
        columns: [
          {
            Header: "Company Name",
            accessor: "companyName",
            minWidth: 200,
            id: "companyName",
          },
          {
            Header: "Job Title",
            accessor: "jobTitle",
            minWidth: 200,
            id: "jobTitle",
          },
          {
            Header: "By Resume",
            accessor: "jobResume",
            filter: "includes",
            Filter: SelectColumnFilter,
            collapse: true,
          },
          {
            Header: "Job Date",
            accessor: "jobDate",
            disableFilters: true,
            collapse: true,
          },
          {
            Header: "Job Link",
            accessor: "jobLink",
            Cell: (props) => (
              <a href={props.value} target="_blank">
                Link
              </a>
            ),
            disableFilters: true,
            collapse: true,
          },
        ],
      },
    ],
    []
  );

  // const tabledata = React.useMemo(
  //   () =>
  //     makeData(
  //       [(showSessionLinks || []).length],
  //       showSessionLinks.map((d) => {
  //         return {
  //           ...d
  //         };
  //       })
  //     ),
  //   []
  // );

  const [tabledata, settabledata] = useState([]);
  const userData = useStateStore((state) => state.userData);
  const token = useStateStore((state) => state.token);
  const setDisableNextPage = useStateStore((state) => state.setDisableNextPage);

  const paginationActivePageNumber = useStateStore(
    (state) => state.paginationActivePageNumber
  );

  const [scanning, setScanning] = useState(0);

  const searchJobTitle = useStateStore((state) => state.searchJobTitle);

  const searchCompanyName = useStateStore((state) => state.searchCompanyName);

  const sessionIdAnalytics = useStateStore((state) => state.sessionIdAnalytics);

  const getNextPageJobs = async (v2Id) => {
    setScanning(1);
    settabledata([]);
    let bodymain = {
      filters: {
        jobTitle: searchJobTitle,
        companyName: searchCompanyName,
        sessionId: sessionIdAnalytics,
      },
    };

    bodymain.pageNumber = paginationActivePageNumber;

    console.log("pagination dirrr", bodymain);

    const response = await axios.post(
      `https://backend.lazyapply.com/v2/getSessionLinksWithFilter/${v2Id}`,
      bodymain,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("pagination response", response.data);
    if (response.data.totaljobs.length) {
      settabledata(
        makeData(response.data.totaljobs.length, response.data.totaljobs)
      );
      if (response.data.totaljobs.length < 50) {
        setDisableNextPage(true);
      } else {
        setDisableNextPage(false);
      }
    }
    setScanning(0);
  };

  useEffect(() => {
    console.log("pagination", paginationActivePageNumber);
    console.log("pagination company", searchCompanyName);
    if (userData.planDetails.v2Id) {
      const v2Id = userData.planDetails.v2Id;
      getNextPageJobs(v2Id);
    }
  }, [paginationActivePageNumber, searchCompanyName, searchJobTitle]);

  return Object.keys(showSessionLinks).length > 0 ? (
    <div>
      <div className="linkedinAppliedDetailsSeperateTop row">
        <div className="col-8">
          <div className="sessionLeft">
            Total Applied - {showSessionLinks?.length || 0}
          </div>
          {userData?.planDetails?.v2Id && (
            <>
              <p style={{ fontSize: "16px", color: "#007bff" }}>
                User Account- {showSessionLinks[0]?.email}
              </p>
              <p style={{ fontSize: "16px", color: "#007bff" }}>
                {`Resume Choosen -> `}
                {showSessionLinks[0]?.resumeName}
              </p>
            </>
          )}
          <div className="sessionbottom">
            {`Skill searched - `}
            <span>{showSessionLinks[0].skill || "No skill"}</span>
          </div>
        </div>
        <div className="col-4">
          <button className="buttonGoBackCustom btn" onClick={goBack}>
            <i className="fas fa-arrow-left"></i> Go Back
          </button>
          <span className="sessionPlatformName">
            {" "}
            {showSessionLinks[0]?.platformName || "No Plat."}
          </span>
        </div>
      </div>
      <div className="appliedJobsTable">
        {scanning ? (
          "scanning"
        ) : (
          <MainTable
            totalJobs={totalJobs}
            columns={columns}
            data={tabledata}
            paginationActivePageNumber={paginationActivePageNumber}
          />
        )}
      </div>
    </div>
  ) : (
    <>
      <div>
        <div className="linkedinAppliedDetailsSeperateTop row notfound">
          <div className="col-12" style={{ paddingTop: "20px" }}>
            <button className="buttonGoBackCustom btn" onClick={goBack}>
              <i className="fas fa-arrow-left"></i> Go Back
            </button>
            <div className="col-12">No Job Links Found</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppliedJobsTableSeperate;
