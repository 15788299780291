import React from "react";
import { Modal, Form } from "react-bootstrap";

function AdvanceSearchModal({
  show,
  handleClose,
  excludeString,
  mayIncludeString,
  setExcludeString,
  setMayIncludeString,
}) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Advance Search</Modal.Title>
        </Modal.Header>
        <Modal.Body className="advanceSearchBody">
          <div className="advanceSearchHeading">
            List of keywords to include in a job title ( comma-seperated )
          </div>

          <div className="advanceSearchInputBlock">
            <div className="advanceSearchInputBlockLeft">
              <Form.Control
                value={mayIncludeString}
                as="textarea"
                placeholder="for example - software, manager, devops, architect"
                rows={3}
                onChange={(e) => setMayIncludeString(e.target.value)}
                style={{ color: "darkmagenta" }}
              />
            </div>
          </div>

          <div className="advanceSearchHeading">
            List of keywords to exclude from the job title ( comma-separated )
          </div>

          <div className="advanceSearchInputBlock">
            <div className="advanceSearchInputBlockLeft">
              <Form.Control
                value={excludeString}
                as="textarea"
                placeholder="for example - hr, sales, data, analyst"
                rows={3}
                onChange={(e) => setExcludeString(e.target.value)}
                style={{ color: "darkmagenta" }}
              />
            </div>
          </div>

          <button
            onClick={() => {
              const mustIncludeAnyOne = mayIncludeString
                .split(",")
                .map((s) => s.trim())
                .filter((s) => s.length > 0);
              const excludeKeywords = excludeString
                .split(",")
                .map((s) => s.trim())
                .filter((s) => s.length > 0);

              if (
                Object.keys(mustIncludeAnyOne).length > 0 ||
                Object.keys(excludeKeywords).length > 0
              ) {
                handleClose();
              } else {
                alert("No keyword added");
              }
            }}
            className="advanceSearchSaveConfiguration"
          >
            Save Configuration
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AdvanceSearchModal;
